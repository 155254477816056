import { addMembershipToUserApiCall, archiveMembershipApiCall, archiveMembershipRuleApiCall, changeUserMembershipStatusApiCall, checkMembershipApiCall, checkMembershipByOwnerApiCall, checkMembershipByOwnerByOrderIdApiCall, createMembershipRuleApiCall, deleteGroupApiRequest, getAllBookingsForMembershipApiCall, getArchivedMembershipsApiCall, getAvailableMembershipsForMultipleReservationsApiCall, getMembershipRulesApiCall, getMembershipsApiCall, getMyMembershipsApiCall, getProviderMembershipsApiCall, getProviderMembershipsApiRequest, getSingleMembershipDetailsApiCall, getUserMembershipsApiCall, reorderMembershipApiCall, requestMembershipCustomerApiCall, restoreMembershipApiCall, saveGroupApiRequest, saveMembershipApiCall, toggleMembershipStatusApiRequest, updateMembershipRuleApiCall, updateOrderPaidWithMembershipApiCall, updateUserMembershipRulesApiCall } from '@guryou/api-client-gql';
import { parseAsBool, printInputDateTime } from '@guryou/utils';
import { execute } from '../actions/actionWrapper';
import { resetState } from '../actions/resetActions';
import { Action, basicReducer } from '../core';
export const MY_MEMBERSHIPS = {
  entity: 'MY_MEMBERSHIPS',
  action: Action.GET
};
export const PROVIDER_MEMBERSHIPS = {
  entity: 'PROVIDER_MEMBERSHIPS',
  action: Action.GET_MANY
};
export const PROVIDER_ARCHIVED_MEMBERSHIPS = {
  entity: 'PROVIDER_ARCHIVED_MEMBERSHIPS',
  action: Action.GET_MANY
};
export const MEMBERSHIP_PUBLISHED = {
  entity: 'MEMBERSHIP_PUBLISHED',
  action: Action.HANDLE
};
export const SAVE_MEMBERSHIP = {
  entity: 'SAVE_MEMBERSHIP',
  action: Action.UPDATE
};
export const ACTIVE_MEMBERSHIPS = {
  entity: 'ACTIVE_MEMBERSHIPS',
  action: Action.GET_MANY
};
export const MEMBERSHIP_DETAILS = {
  entity: 'MEMBERSHIP_DETAILS',
  action: Action.GET
};
export const SINGLE_MEMBERSHIP_DETAILS = {
  entity: 'SINGLE_MEMBERSHIP_DETAILS',
  action: Action.GET
};
export const MEMBERSHIP_GROUPS = {
  entity: 'MEMBERSHIP_GROUPS',
  action: Action.GET_MANY
};
export const MEMBERSHIP_DELETE = {
  entity: 'MEMBERSHIP',
  action: Action.DELETE
};
export const RESTORE_MEMBERSHIP = {
  entity: 'RESTORE_MEMBERSHIP',
  action: Action.HANDLE
};
export const ADD_MEMBERSHIP_TO_USER = {
  entity: 'ADD_MEMBERSHIP_TO_USER',
  action: Action.HANDLE
};
export const ADD_MEMBERSHIP_TO_NEW_USER = {
  entity: 'ADD_MEMBERSHIP_TO_NEW_USER',
  action: Action.HANDLE
};
export const USER_MEMBERSHIPS = {
  entity: 'USER_MEMBERSHIPS',
  action: Action.GET_MANY
};
export const USER_MEMBERSHIP_STATUS = {
  entity: 'USER_MEMBERSHIP_STATUS',
  action: Action.UPDATE
};
export const MEMBERSHIP_REORDER = {
  entity: 'MEMBERSHIP_REORDER',
  action: Action.HANDLE
};
export const SAVE_GROUP_FOR_MEMBERSHIP = {
  entity: 'SAVE_GROUP_FOR_MEMBERSHIP',
  action: Action.UPDATE
};
export const DELETE_GROUP_FOR_MEMBERSHIP = {
  entity: 'DELETE_GROUP_FOR_MEMBERSHIP',
  action: Action.DELETE
};
export const MEMBERSHIP_CHECK = {
  entity: 'MEMBERSHIP_CHECK',
  action: Action.GET
};
export const CHECK_USER_MEMBERSHIP_BY_ORDER = {
  entity: 'CHECK_USER_MEMBERSHIP_BY_ORDER',
  action: Action.GET
};
export const UPDATE_ORDER_PAID_WITH_MEMBERSHIP = {
  entity: 'UPDATE_ORDER_PAID_WITH_MEMBERSHIP',
  action: Action.UPDATE
};
export const CHECK_USER_MEMBERSHIP_MULTIPLE_RES = {
  entity: 'CHECK_USER_MEMBERSHIP_MULTIPLE_RES',
  action: Action.GET
};
export const CHECK_USER_MEMBERSHIP = {
  entity: 'CHECK_USER_MEMBERSHIP',
  action: Action.HANDLE
};
export const CHECK_BOOKINGS_FOR_MEMBERSHIP = {
  entity: 'CHECK_BOOKINGS_FOR_MEMBERSHIP',
  action: Action.GET_MANY
};
export const REQUEST_MEMBERSHIP = {
  entity: 'REQUEST_MEMBERSHIP',
  action: Action.INSERT
};
export const MEMBERSHIP_RULES = {
  entity: 'MEMBERSHIP_RULES',
  action: Action.GET_MANY
};
export const CREATE_MEMBERSHIP_RULE = {
  entity: 'CREATE_MEMBERSHIP_RULE',
  action: Action.CREATE
};
export const UPDATE_MEMBERSHIP_RULE = {
  entity: 'UPDATE_MEMBERSHIP_RULE',
  action: Action.UPDATE
};
export const ARCHIVE_MEMBERSHIP_RULE = {
  entity: 'ARCHIVE_MEMBERSHIP_RULE',
  action: Action.ARCHIVE
};
export const UPDATE_USER_MEMBERSHIP_RULES = {
  entity: 'UPDATE_USER_MEMBERSHIP_RULES',
  action: Action.UPDATE
};
export const getMemberships = (silent, groupId) => async dispatch => execute(dispatch, () => getMembershipsApiCall({
  groupId
}, {
  group: ['id', 'name', 'color']
}), PROVIDER_MEMBERSHIPS, false, null, silent);
export const getArchivedMemberships = silent => async dispatch => execute(dispatch, () => getArchivedMembershipsApiCall({
  group: ['id', 'name', 'color']
}), PROVIDER_ARCHIVED_MEMBERSHIPS, false, null, silent);
export const getProviderMemberships = silent => async dispatch => execute(dispatch, () => getProviderMembershipsApiRequest(), ACTIVE_MEMBERSHIPS, false, null, silent);
export const getMembershipDetails = membershipId => async dispatch => execute(dispatch, () => getProviderMembershipsApiCall({
  membershipId
}, {
  membership: ['name', 'price', 'sessionsCounter', 'description', 'startTime', 'endTime', 'isPublished'],
  user: ['name', 'email']
}), MEMBERSHIP_DETAILS);
export const getSingleMembershipDetails = id => async dispatch => execute(dispatch, () => getSingleMembershipDetailsApiCall({
  id
}), SINGLE_MEMBERSHIP_DETAILS);
export const getMyMemberships = function () {
  let all = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  return async dispatch => execute(dispatch, () => getMyMembershipsApiCall({
    all
  }, {
    membership: ['id', 'name', 'sessionsCounter'],
    provider: ['id', 'name'],
    services: ['id', 'name']
  }), MY_MEMBERSHIPS);
};
export const toggleMembershipStatus = (id, isPublished) => async dispatch => execute(dispatch, () => toggleMembershipStatusApiRequest(id, isPublished), MEMBERSHIP_PUBLISHED, true);
export const reorderMembership = (oldPosition, newPosition, groupId) => async dispatch => {
  execute(dispatch, () => reorderMembershipApiCall({
    oldPosition,
    newPosition,
    groupId
  }), MEMBERSHIP_REORDER, false, null, true);
};

// Create / Update
export const saveMembership = data => async dispatch => {
  const request = {
    name: data.name,
    description: data.description,
    price: +data.price,
    alwaysAvailable: data.alwaysAvailable,
    duration: data.duration ? +data.duration : null,
    durationType: data.durationType || null,
    sessionsCounter: +data.sessionsCounter,
    reservationsCounter: +data.reservationsCounter,
    svcGroupId: +data.svcGroupId,
    startTime: printInputDateTime(data.startTime),
    endTime: printInputDateTime(data.endTime),
    isPublished: parseAsBool(data.isPublished),
    id: data.id ? +data.id : null,
    allInclusive: data.allInclusive,
    membershipType: data.membershipType,
    membershipTypeIds: data.allInclusive ? null : Array.isArray(data.membershipTypeIds) ? data.membershipTypeIds.join(',') : data.membershipTypeIds,
    rules: data.membershipType !== 'subscription' ? null : Array.isArray(data.rules) ? data.rules.join(',') : data.rules
  };
  execute(dispatch, () => saveMembershipApiCall(request), SAVE_MEMBERSHIP);
};
export const archiveMembership = id => async dispatch => execute(dispatch, () => archiveMembershipApiCall({
  id
}), MEMBERSHIP_DELETE, true);
export const restoreMembership = id => async dispatch => execute(dispatch, () => restoreMembershipApiCall({
  id
}), RESTORE_MEMBERSHIP, true);
export const saveGroupForMembership = (name, type, color, id) => async dispatch => execute(dispatch, () => saveGroupApiRequest(name, type, color, id), SAVE_GROUP_FOR_MEMBERSHIP);
export const deleteGroupForMembership = id => async dispatch => execute(dispatch, () => deleteGroupApiRequest(id), DELETE_GROUP_FOR_MEMBERSHIP);
export const addMembershipToUser = (userId, email, membershipId, startDate, endDate, sessionsCounter, reservationsCounter, price, membershipType, allInclusive, membershipTypeIds, customerId, paid, isPaid, method, channel, rules) => async dispatch => {
  const startTime = startDate ? "".concat(startDate, "T00:00:00.000Z") : null;
  const endTime = endDate ? "".concat(endDate, "T23:59:59.000Z") : null;
  const memRules = membershipType !== 'subscription' ? null : Array.isArray(rules) ? rules.join(',') : rules;
  execute(dispatch, () => addMembershipToUserApiCall({
    userId,
    email,
    membershipId: +membershipId,
    startTime,
    endTime,
    sessionsCounter,
    reservationsCounter,
    price,
    membershipType,
    allInclusive,
    membershipTypeIds,
    customerId,
    paid,
    isPaid,
    method,
    channel,
    rules: memRules
  }), ADD_MEMBERSHIP_TO_USER, true);
};
export const addMembershipToNewUser = (email, membershipId, startDate, endDate, sessionsCounter, price) => async dispatch => {
  const startTime = "".concat(startDate, "T00:00:00.000Z");
  const endTime = "".concat(endDate, "T23:59:59.000Z");
  execute(dispatch, () => addMembershipToUserApiCall({
    email,
    membershipId,
    startTime,
    endTime,
    sessionsCounter,
    price
  }), ADD_MEMBERSHIP_TO_NEW_USER, true);
};
export const getUserMemberships = function (userId) {
  let all = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  let customerId = arguments.length > 2 ? arguments[2] : undefined;
  return async dispatch => execute(dispatch, () => getUserMembershipsApiCall({
    userId,
    all,
    customerId
  }, {
    membership: ['name', 'duration', 'durationType']
  }), USER_MEMBERSHIPS, false);
};
export const userMembershipStatus = (id, userId, isActive, customerId) => async dispatch => execute(dispatch, () => changeUserMembershipStatusApiCall({
  id,
  userId,
  isActive,
  customerId
}), USER_MEMBERSHIP_STATUS, true);
export const checkMembership = (serviceId, providerId, serviceType) => async dispatch => execute(dispatch, () => checkMembershipApiCall({
  serviceId,
  providerId,
  serviceType
}), MEMBERSHIP_CHECK, true);
export const checkMembershipByOwner = (userId, serviceId, serviceType, customerId) => async dispatch => execute(dispatch, () => checkMembershipByOwnerApiCall({
  userId,
  serviceId,
  serviceType,
  customerId
}), CHECK_USER_MEMBERSHIP, false);
export const checkMembershipForMultipleReservations = (serviceId, beginDate, endDate, userId, customerId) => async dispatch => execute(dispatch, () => getAvailableMembershipsForMultipleReservationsApiCall({
  serviceId,
  beginDate,
  endDate,
  userId,
  customerId
}, {
  availableMemberships: ['id', 'name', 'startTime', 'endTime', 'reservationsCounter', 'totalReservationsCounter', 'sessionsCounter', 'totalSessionsCounter']
}), CHECK_USER_MEMBERSHIP_MULTIPLE_RES, false);
export const checkMembershipByOwnerByOrder = (orderId, serviceId) => async dispatch => execute(dispatch, () => checkMembershipByOwnerByOrderIdApiCall({
  orderId,
  serviceId
}, {
  availableMemberships: ['id', 'name', 'reservationsCounter', 'totalReservationsCounter', 'sessionsCounter', 'totalSessionsCounter']
}), CHECK_USER_MEMBERSHIP_BY_ORDER, false, {
  orderId
});
export const updateOrderPaidWithMembership = (orderId, membershipId) => async dispatch => execute(dispatch, () => updateOrderPaidWithMembershipApiCall({
  orderId,
  membershipId: +membershipId
}), UPDATE_ORDER_PAID_WITH_MEMBERSHIP, false);
export const getAllBookingsForMembership = id => async dispatch => execute(dispatch, () => getAllBookingsForMembershipApiCall({
  id
}), CHECK_BOOKINGS_FOR_MEMBERSHIP, false);
export const requestMembershipCustomer = (membershipId, startTime, quantity) => async dispatch => execute(dispatch, () => requestMembershipCustomerApiCall({
  membershipId,
  startTime,
  quantity
}), REQUEST_MEMBERSHIP, false);
export const getMembershipRules = () => async dispatch => execute(dispatch, () => getMembershipRulesApiCall(), MEMBERSHIP_RULES, false);
export const saveMembershipRule = data => async dispatch => {
  const request = {
    name: data.name,
    type: data.type,
    period: data.period,
    amount: +data.amount
  };
  execute(dispatch, () => createMembershipRuleApiCall({
    request
  }), CREATE_MEMBERSHIP_RULE, false);
};
export const updateMembershipRule = data => async dispatch => {
  const request = {
    id: +data.id,
    name: data.name,
    type: data.type,
    period: data.period,
    amount: +data.amount
  };
  execute(dispatch, () => updateMembershipRuleApiCall({
    request
  }), UPDATE_MEMBERSHIP_RULE, false);
};
export const updateUserMembershipRules = (id, rules, startTime, endTime) => async dispatch => {
  const memRules = Array.isArray(rules) ? rules.join(',') : rules;
  execute(dispatch, () => updateUserMembershipRulesApiCall({
    id,
    rules: memRules,
    startTime,
    endTime
  }), UPDATE_USER_MEMBERSHIP_RULES, false);
};
export const archiveMembershipRule = id => async dispatch => {
  execute(dispatch, () => archiveMembershipRuleApiCall({
    id
  }), ARCHIVE_MEMBERSHIP_RULE, false);
};
export const resetMembershipState = () => async dispatch => {
  dispatch(resetState(PROVIDER_MEMBERSHIPS));
  dispatch(resetState(SAVE_MEMBERSHIP));
  dispatch(resetState(MEMBERSHIP_DELETE));
};
export const resetAddMembershipToUserState = () => async dispatch => {
  dispatch(resetState(ADD_MEMBERSHIP_TO_USER));
};
export const resetCheckMembershipByOwnerState = () => async dispatch => {
  dispatch(resetState(CHECK_USER_MEMBERSHIP));
};
export const resetRequestMembershipCustomerState = () => async dispatch => {
  dispatch(resetState(REQUEST_MEMBERSHIP));
};
export const membershipReducers = {
  all: basicReducer(PROVIDER_MEMBERSHIPS),
  status: basicReducer(MEMBERSHIP_PUBLISHED),
  save: basicReducer(SAVE_MEMBERSHIP),
  active: basicReducer(ACTIVE_MEMBERSHIPS),
  single: basicReducer(MEMBERSHIP_DETAILS),
  my: basicReducer(MY_MEMBERSHIPS),
  delete: basicReducer(MEMBERSHIP_DELETE),
  reorder: basicReducer(MEMBERSHIP_REORDER),
  archived: basicReducer(PROVIDER_ARCHIVED_MEMBERSHIPS),
  restore: basicReducer(RESTORE_MEMBERSHIP),
  addMembershipToUser: basicReducer(ADD_MEMBERSHIP_TO_USER),
  addMembershipToNewUser: basicReducer(ADD_MEMBERSHIP_TO_NEW_USER),
  user: basicReducer(USER_MEMBERSHIPS),
  userMembershipStatus: basicReducer(USER_MEMBERSHIP_STATUS),
  deleteGroup: basicReducer(DELETE_GROUP_FOR_MEMBERSHIP),
  check: basicReducer(CHECK_USER_MEMBERSHIP),
  checkMembershipMultipleRes: basicReducer(CHECK_USER_MEMBERSHIP_MULTIPLE_RES),
  checkMembershipByOrder: basicReducer(CHECK_USER_MEMBERSHIP_BY_ORDER),
  updateOrderPaidMembership: basicReducer(UPDATE_ORDER_PAID_WITH_MEMBERSHIP),
  bookings: basicReducer(CHECK_BOOKINGS_FOR_MEMBERSHIP),
  singleDetails: basicReducer(SINGLE_MEMBERSHIP_DETAILS),
  membershipRules: basicReducer(MEMBERSHIP_RULES),
  createMembershipRule: basicReducer(CREATE_MEMBERSHIP_RULE),
  updateMembershipRule: basicReducer(UPDATE_MEMBERSHIP_RULE),
  archiveMembershipRule: basicReducer(ARCHIVE_MEMBERSHIP_RULE),
  updateUserMembershipRules: basicReducer(UPDATE_USER_MEMBERSHIP_RULES)
};
export const membershipFeelReducers = {
  check: basicReducer(MEMBERSHIP_CHECK),
  request: basicReducer(REQUEST_MEMBERSHIP)
};