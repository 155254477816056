import { handleError, handleSuccess, triggerDelete, triggerGet, triggerPost, triggerPostFormData, triggerPut, triggerStripeGet, triggerStripePost, triggerStripePostCreate, triggerStripePut } from './utils'; // STRIPE ACTIONS ==================================================================>
// STRIPE-PAY-ENDPOINT

const stripeUrl = process.env.REACT_APP_PAYMENT_API_ENDPOINT; // Check coupon

const checkCouponApiRequest = async (coupon, validMessage, invalidMessage) => {
  const apiResponse = await triggerStripeGet("".concat(stripeUrl, "/stripe-coupon/").concat(coupon));
  const responseData = await apiResponse.json();
  if (responseData.status_code === 200 && responseData.data.valid) {
    return handleSuccess({
      success: true,
      data: responseData.data,
      message: validMessage,
      report: {}
    });
  }
  return handleError({
    ...responseData,
    message: invalidMessage
  });
}; // Connect provider stripe auth code

const sendStripeAuthCodeApiReqeust = async (auth_code, id) => {
  const newData = [];
  newData['authorizationCode'] = auth_code;
  const apiResponse = await triggerStripePost("".concat(stripeUrl, "stripe-connect"), newData);
  const responseData = await apiResponse.json();
  if ([200, 201].includes(responseData.status_code)) {
    return confirmConnectToBackEndApiRequest(id, responseData.data.stripe_user_id);
  }
  return handleError({
    ...responseData,
    message: responseData.message || ''
  });
};
const confirmConnectToBackEndApiRequest = async (id, stripe_user_id) => {
  const newData = [];
  newData['connectedAccountId'] = stripe_user_id;
  const apiResponse = await triggerStripePut("".concat(stripeUrl, "customer/").concat(id), newData);
  const responseData = await apiResponse.json();
  if (responseData.status_code === 200) {
    return handleSuccess(responseData);
  }
  return handleError({
    ...responseData,
    message: responseData.message || ''
  });
}; // Get owner all subscriptions

const getOwnerSubscriptionsApiRequest = async ownerId => {
  const apiResponse = await triggerStripeGet("".concat(stripeUrl, "subscriptionb2b-owner/").concat(ownerId));
  const responseData = await apiResponse.json();
  if (responseData.status_code === 200) {
    return handleSuccess({
      success: true,
      data: responseData.data,
      report: {}
    });
  }
  return {
    success: false
  };
}; // Get provider Subscription

const getProviderSubscriptionApiRequest = async providerId => {
  const apiResponse = await triggerStripeGet("".concat(stripeUrl, "subscriptionb2b/").concat(providerId));
  const responseData = await apiResponse.json();
  if (responseData.status_code === 200) {
    return handleSuccess({
      success: true,
      data: [{
        ...responseData.data
      }],
      report: {}
    });
  }
  if (responseData.status_code === 404) {
    return handleSuccess({
      success: true,
      data: [],
      report: {}
    });
  }
  return {
    success: false
  };
}; // Get Plans

const getPlansApiRequest = async () => {
  const apiResponse = await triggerStripeGet("".concat(stripeUrl, "plan"));
  const responseData = await apiResponse.json();
  if (responseData.status_code === 200) {
    const newData = responseData.data.data.filter(x => !!x.active);
    return handleSuccess({
      success: true,
      data: [...newData],
      report: {}
    });
  }
  return {
    success: false
  };
}; // Send confirm to back end - B2B - success

const sendConfirmSubB2bApiRequest = async data => {
  const newData = [];
  for (const key in data) {
    newData[key] = data[key];
  }
  const apiResponse = await triggerStripePost("".concat(stripeUrl, "subscriptionb2b"), newData);
  const responseData = await apiResponse.json();
  if (responseData.status_code === 201) {
    return handleSuccess({
      success: true,
      data: [{
        ...responseData.data
      }],
      report: {}
    });
  }
  return {
    success: false
  };
}; // Send billing info to back end - from other provider, and subscribe

const sendBillingInfoToBackEndAndSubscribeApiRequest = async (plan, ownerId, providerId, customerId, customerName) => {
  const data = {
    id: customerId,
    name: customerName
  };
  const myResponse = await sendCustomerBillingInfoToBackEnd(data, providerId, ownerId, true);
  if (myResponse.status_code === 201) {
    // return handleSuccess({ success: true, data: { ...myResponse.data }, report: {} });
    return sendSubscribeToBackEndApiRequest(plan, customerId);
  }
  return {
    success: false
  };
}; // Send Payment Method To Back End

const sendPaymentMethodToBackEndApiRequest = async (plan, customerId, paymentMethodId, coupon) => {
  const newData = [];
  newData['customerId'] = customerId;
  const apiResponse = await triggerStripePost("".concat(stripeUrl, "payment-method/").concat(paymentMethodId, "/attach"), newData);
  const responseData = await apiResponse.json();
  if (responseData.status_code === 201) {
    return sendPaymentMethodToBackEndConfirmApiRequest(plan, customerId, paymentMethodId, coupon); // sendSubscribeToBackEndApiRequest(data.customerId);
    // return handleSuccess({ success: true, data: [{ ...responseData.data }], report: {} });
  }
  return handleError({
    ...responseData,
    message: responseData.message.split(':')[0]
  });
}; // Put payment Method To Customer-Back end

const sendPaymentMethodToBackEndConfirmApiRequest = async (plan, customerId, paymentMethodId, coupon) => {
  const newData = [];
  newData['paymentMethodId'] = paymentMethodId;
  const apiResponse = await triggerStripePut("".concat(stripeUrl, "stripe-customer/").concat(customerId), newData);
  const responseData = await apiResponse.json();
  if (responseData.status_code === 200) {
    return sendSubscribeToBackEndApiRequest(plan, customerId, coupon); // return handleSuccess({ success: true, data: [{ ...responseData.data }], report: {} });
  }
  return {
    success: false
  };
}; // Send Subscribe To Back End

const sendSubscribeToBackEndApiRequest = async (plan, customerId, coupon) => {
  const newData = [];
  newData['customerId'] = customerId;
  newData['planId'] = plan;
  if (coupon) {
    newData['coupon'] = coupon;
  }
  const apiResponse = await triggerStripePost("".concat(stripeUrl, "stripe-subscription"), newData);
  const responseData = await apiResponse.json();
  if (responseData.status_code === 201) {
    return handleSuccess({
      success: true,
      data: [{
        ...responseData.data
      }],
      report: {}
    });
  }
  return {
    success: false
  };
}; // Creating Customer Billing Info

const createCustomerBillingInfoApiReqeust = async (data, providerId) => {
  const apiResponse = await triggerStripePostCreate("".concat(stripeUrl, "stripe-customer"), data);
  const responseData = await apiResponse.json();
  if (responseData.status_code === 201) {
    return sendCustomerBillingInfoToBackEnd(responseData.data, providerId, data.metadata['B_OWNER_ID']);
  }
  return handleError(responseData); // return { success: false };
};
const sendCustomerBillingInfoToBackEnd = async function (data, providerId, ownerId) {
  let returnOnlyResponse = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  const newData = {
    ownerId,
    providerId,
    customerId: data.id,
    customerName: data.name
  };
  const apiResponse = await triggerStripePost("".concat(stripeUrl, "customer"), newData);
  const responseData = await apiResponse.json();
  if (returnOnlyResponse) {
    return responseData;
  }
  if (responseData.status_code === 201) {
    return handleSuccess({
      success: true,
      data: [{
        ...responseData.data
      }],
      report: {}
    });
  }
  return {
    success: false
  };
}; // Send existing stripe customer account from other provider

const stripeCustomerAccountFromOtherProviderApiRequest = async id => {
  const apiResponse = await triggerStripeGet("".concat(stripeUrl, "stripe-customer/").concat(id));
  const responseData = await apiResponse.json();
  if (responseData.status_code === 200) {
    return handleSuccess({
      success: true,
      data: [{
        ...responseData.data
      }],
      report: {}
    });
  }
  return {
    success: false
  };
}; // Check for provider stripe customer (if providerId is passed only for that specific provider checks otherwise returns all for that owner)

const checkOwnerProviderStriperCustomerApiRequest = async function (id) {
  let providerId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  const apiResponse = await triggerStripeGet(providerId ? "".concat(stripeUrl, "customer/").concat(id, "?provider_id=").concat(providerId) : "".concat(stripeUrl, "customer/").concat(id));
  const responseData = await apiResponse.json();
  if (responseData.status_code === 200) {
    return handleSuccess({
      success: true,
      data: responseData.data,
      report: {}
    });
  }
  return {
    success: false
  };
}; // <==================================================================
// Get the Course Groups

const getCoursesGroupApiRequest = async () => {
  const apiResponse = await triggerGet('/business/svcgroups/course');
  const responseData = await apiResponse.json();
  if (responseData.report.status === 200) {
    return handleSuccess(responseData);
  }
  return handleError(responseData);
}; // Get the Event Groups

const getEventsGroupApiRequest = async () => {
  const apiResponse = await triggerGet('/business/svcgroups/event');
  const responseData = await apiResponse.json();
  if (responseData.report.status === 200) {
    return handleSuccess(responseData);
  }
  return handleError(responseData);
}; //

const getOneTimeGroupApiRequest = async () => {
  const apiResponse = await triggerGet('/business/svcgroups/onetime');
  const responseData = await apiResponse.json();
  if (responseData.report.status === 200) {
    return handleSuccess(responseData);
  }
  return handleError(responseData);
};

// Create a class for a course
const getGroupApiRequest = async () => {
  const apiResponse = await triggerGet('/business/svcgroups');
  const responseData = await apiResponse.json();
  if ([200, 201].includes(responseData.report.status)) {
    return handleSuccess(responseData);
  }
  return handleError(responseData);
};

// Create group
const addGroupApiRequest = async data => {
  const apiResponse = await triggerPost('/business/svcgroups', data);
  const responseData = await apiResponse.json();
  if ([200, 201].includes(responseData.report.status)) {
    return handleSuccess(responseData);
  }
  return handleError(responseData);
}; // Edit Group

const editGroupApiRequest = async (id, data) => {
  const apiResponse = await triggerPut("/business/svcgroups/".concat(id), data);
  const responseData = await apiResponse.json();
  if ([200, 201].includes(responseData.report.status)) {
    return handleSuccess(responseData);
  }
  return handleError(responseData);
}; // Delete Group

// Get the personnel/resources for Services/Courses/Events
const getResourcesApiRequest = async () => {
  const apiResponse = await triggerGet('/business/resources');
  const responseData = await apiResponse.json();
  if ([200, 201].includes(responseData.report.status)) {
    return handleSuccess(responseData);
  }
  return handleError(responseData);
}; // Get the types/categories for Services/Courses/Events

const getCategoriesApiRequest = async () => {
  const apiResponse = await triggerGet('/business/svctypes');
  const responseData = await apiResponse.json();
  if ([200, 201].includes(responseData.report.status)) {
    return handleSuccess(responseData);
  }
  return handleError(responseData);
};

// Get Provider Images
const getProviderImagesApiCall = async () => {
  const apiResponse = await triggerGet('/business/images/');
  const responseData = await apiResponse.json();
  responseData.data && responseData.data.reverse();
  if (responseData.report.status === 200) {
    return handleSuccess(responseData);
  }
  if (responseData.report.status === 404) {
    return handleSuccess([]);
  }
  return handleError(responseData);
}; // Delete Provider Image

const deleteProviderImageApiRequest = async id => {
  const apiResponse = await triggerDelete("/business/images/".concat(id));
  if (apiResponse.status === 204) {
    return {
      success: true,
      data: {},
      report: {}
    };
  }
  return {
    success: false
  };
};
const insertProviderImageApiRequest = async data => {
  const apiResponse = await triggerPostFormData("/business/images/", data);
  const responseData = await apiResponse.json();
  if ([200, 201].includes(responseData.report.status)) {
    return handleSuccess(responseData);
  }
  return handleError(responseData);
};
const changeOrderApiRequest = async (newArr, type) => {
  const url = type !== 'P' ? "/business/services/".concat(type, "/") : "/business/products/";
  const ids = {};
  if (type !== 'P') {
    ids.servicesOrder = newArr.map(x => x.servicesId).join(',');
  }
  if (type === 'P') {
    ids.productsOrder = newArr.map(x => x.productsId).join(',');
  }
  const apiResponse = await triggerPut(url, ids);
  if (apiResponse.status === 200) {
    return {
      success: true,
      data: newArr,
      report: {},
      type
    };
  }
  return {
    success: false
  };
};
export { changeOrderApiRequest, checkCouponApiRequest, sendStripeAuthCodeApiReqeust, getOwnerSubscriptionsApiRequest, getProviderSubscriptionApiRequest, getPlansApiRequest, sendConfirmSubB2bApiRequest, sendBillingInfoToBackEndAndSubscribeApiRequest, sendPaymentMethodToBackEndApiRequest, sendSubscribeToBackEndApiRequest, createCustomerBillingInfoApiReqeust, stripeCustomerAccountFromOtherProviderApiRequest, checkOwnerProviderStriperCustomerApiRequest, insertProviderImageApiRequest, deleteProviderImageApiRequest, getProviderImagesApiCall, getCoursesGroupApiRequest, getEventsGroupApiRequest, getGroupApiRequest, addGroupApiRequest, getOneTimeGroupApiRequest, getResourcesApiRequest, editGroupApiRequest, getCategoriesApiRequest };