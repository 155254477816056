import { TextField, withStyles } from '@material-ui/core';
import '../assets/inputFields.css';
const CustomColorField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#e6616a',
      fontWeight: 500
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#252525'
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#252525'
      }
    }
  }
})(TextField);
export default CustomColorField;