import { addVideoToGroupOfUsersApiCall, addVideoToUserApiCall, archiveVideoApiCall, changeUserVideoStartEndTimeApiCall, duplicateProductApiCall, getAllNotAcquiredUserVideosApiCall, getArchivedVideosApiCall, getBoughtVideosApiCall, getUserVideoCourseFilesYouFeelApiCall, getUserVideoCoursesYouFeelApiCall, getUserVideosApiCall, getVideoCourseFilesYouFeelApiCall, getVideoCoursesYouFeelApiCall, getVideosApiCall, reorderVideosApiCall, restoreVideoApiCall, saveVideoApiCall, toggleVideoOrderStatusApiCall, toggleVideoStatusApiCall } from '@guryou/api-client-gql';
import { resetState } from '../actions';
import { execute } from '../actions/actionWrapper';
import { Action, basicReducer } from '../core';

// Types
export const PROVIDER_VIDEOS = {
  entity: 'PROVIDER_VIDEOS',
  action: Action.GET_MANY
};
export const CREATE_VIDEO = {
  entity: 'CREATE_VIDEO',
  action: Action.CREATE
};
export const UPDATE_VIDEO = {
  entity: 'UPDATE_VIDEO',
  action: Action.UPDATE
};
export const ARCHIVE_VIDEO = {
  entity: 'ARCHIVE_VIDEO',
  action: Action.UPDATE
};
export const STATUS_VIDEO = {
  entity: 'STATUS_VIDEO',
  action: Action.UPDATE
};
export const PROVIDER_ARCHIVED_VIDEOS = {
  entity: 'PROVIDER_ARCHIVED_VIDEOS',
  action: Action.GET
};
export const PROVIDER_RESTORE_VIDEO = {
  entity: 'PROVIDER_RESTORE_VIDEO',
  action: Action.UPDATE
};
export const USER_NOT_ACQUIRED_VIDEOS = {
  entity: 'USER_NOT_ACQUIRED_VIDEOS',
  action: Action.GET_MANY
};
export const ADD_VIDEO_TO_USER = {
  entity: 'ADD_VIDEO_TO_USER',
  action: Action.CREATE
};
export const USER_VIDEOS = {
  entity: 'USER_VIDEOS',
  action: Action.GET_MANY
};
export const BOUGHT_VIDEOS = {
  entity: 'BOUGHT_VIDEOS',
  action: Action.GET_MANY
};
export const VIDEO_DETAILS = {
  entity: 'VIDEO_DETAILS',
  action: Action.GET_MANY
};
export const VIDEO_REORDER = {
  entity: 'VIDEOS',
  action: Action.REORDER
};
export const VIDEO_DUPLICATE = {
  entity: 'VIDEO_DUPLICATE',
  action: Action.HANDLE
};
export const USER_VIDEO_CHANGE_START_END_TIME = {
  entity: 'USER_VIDEO_CHANGE_START_END_TIME',
  action: Action.HANDLE
};

// YouFeel WEB
export const VIDEO_COURSES_FEEL = {
  entity: 'VIDEO_COURSES_FEEL',
  action: Action.GET_MANY
};
export const USER_VIDEO_COURSE_FILES_FEEL = {
  entity: 'USER_VIDEO_COURSE_FILES_FEEL',
  action: Action.GET_MANY
};
export const USER_VIDEO_COURSES_FEEL = {
  entity: 'USER_VIDEO_COURSES_FEEL',
  action: Action.GET_MANY
};
export const VIDEO_COURSE_FILES_FEEL = {
  entity: 'VIDEO_COURSE_FILES_FEEL',
  action: Action.GET_MANY
};

// Actions
export const getVideos = silent => async dispatch => execute(dispatch, () => getVideosApiCall({
  provider: ['id', 'name', 'type'],
  category: ['id', 'name'],
  productGroup: ['id', 'name', 'color']
}), PROVIDER_VIDEOS, silent, null, silent);
export const getArchivedVideos = silent => async dispatch => execute(dispatch, () => getArchivedVideosApiCall(), PROVIDER_ARCHIVED_VIDEOS, false, null, silent);
export const restoreVideo = id => async dispatch => execute(dispatch, () => restoreVideoApiCall({
  id
}), PROVIDER_RESTORE_VIDEO);
export const getAllNotAcquiredUserVideos = userId => async dispatch => execute(dispatch, () => getAllNotAcquiredUserVideosApiCall({
  userId
}, {
  category: ['id'],
  productGroup: ['id']
}), USER_NOT_ACQUIRED_VIDEOS, false, {
  userId
});
export const addVideoToUser = (productId, userId, email, startTime, endTime, paid, isPaid, method, channel) => async dispatch => execute(dispatch, () => addVideoToUserApiCall({
  userId,
  productId,
  email,
  startTime,
  endTime,
  paid,
  isPaid,
  method,
  channel
}), ADD_VIDEO_TO_USER, true);
export const addVideoToGroupOfUsers = (productId, groupId, startTime, endTime) => async dispatch => execute(dispatch, () => addVideoToGroupOfUsersApiCall({
  productId,
  groupId,
  startTime,
  endTime
}), ADD_VIDEO_TO_USER, true);
export const getUserVideos = userId => async dispatch => execute(dispatch, () => getUserVideosApiCall({
  userId
}, {
  orderInfo: ['id', 'startTime', 'endTime', 'active']
}), USER_VIDEOS, false);
export const getBoughtVideos = function () {
  let silent = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  return async dispatch => execute(dispatch, () => getBoughtVideosApiCall({
    videoId: null
  }, {
    user: ['id', 'name', 'email', 'groups { id name color }', 'svcGroupId'],
    category: ['id', 'name'],
    productGroup: ['id', 'name', 'color'],
    orderInfo: ['id', 'startTime', 'endTime', 'active']
  }), BOUGHT_VIDEOS, false, null, silent);
};
export const getVideoDetails = videoId => async dispatch => execute(dispatch, () => getBoughtVideosApiCall({
  videoId
}, {
  user: ['id', 'name', 'email'],
  category: ['id', 'name'],
  productGroup: ['id', 'name', 'color']
}), VIDEO_DETAILS, false);
export const createVideo = request => async dispatch => {
  return execute(dispatch, () => saveVideoApiCall({
    request: {
      ...request,
      price: +request.price,
      prodGroupId: +request.prodGroupId,
      svcTypeId: +request.svcTypeId,
      duration: +request.duration
    }
  }), CREATE_VIDEO);
};
export const updateVideo = request => async dispatch => {
  return execute(dispatch, () => saveVideoApiCall({
    request: {
      ...request,
      price: +request.price,
      prodGroupId: +request.prodGroupId,
      svcTypeId: +request.svcTypeId,
      duration: +request.duration
    }
  }), UPDATE_VIDEO);
};
export const archiveVideo = id => async dispatch => {
  execute(dispatch, () => archiveVideoApiCall({
    id
  }), ARCHIVE_VIDEO);
};
export const toggleVideoStatus = (id, isPublished) => async dispatch => {
  execute(dispatch, () => toggleVideoStatusApiCall({
    id,
    isPublished
  }), STATUS_VIDEO);
};
export const toggleVideoOrderStatus = (id, active) => async dispatch => {
  execute(dispatch, () => toggleVideoOrderStatusApiCall({
    id,
    active
  }), STATUS_VIDEO);
};
export const reorderVideos = (oldPosition, newPosition) => async dispatch => {
  execute(dispatch, () => reorderVideosApiCall({
    oldPosition,
    newPosition
  }), VIDEO_REORDER, false, null, true);
};
export const duplicateProduct = id => async dispatch => execute(dispatch, () => duplicateProductApiCall({
  id
}), VIDEO_DUPLICATE);
export const changeUserVideoStartEndTime = (id, startTime, endTime) => async dispatch => execute(dispatch, () => changeUserVideoStartEndTimeApiCall({
  id,
  startTime,
  endTime
}), USER_VIDEO_CHANGE_START_END_TIME, true);
export const getVideoCoursesFeel = () => async dispatch => execute(dispatch, () => getVideoCoursesYouFeelApiCall(), VIDEO_COURSES_FEEL);
export const getUserVideoCoursesFeel = () => async dispatch => execute(dispatch, () => getUserVideoCoursesYouFeelApiCall(), USER_VIDEO_COURSES_FEEL);
export const getVideoCourseFilesFeel = id => async dispatch => execute(dispatch, () => getVideoCourseFilesYouFeelApiCall({
  id
}), VIDEO_COURSE_FILES_FEEL);
export const getUserVideoCourseFilesFeel = id => async dispatch => execute(dispatch, () => getUserVideoCourseFilesYouFeelApiCall({
  id
}), USER_VIDEO_COURSE_FILES_FEEL);
export const resetVideosState = () => async dispatch => {
  dispatch(resetState(PROVIDER_VIDEOS));
  dispatch(resetState(CREATE_VIDEO));
  dispatch(resetState(UPDATE_VIDEO));
  dispatch(resetState(USER_VIDEOS));
  dispatch(resetState(BOUGHT_VIDEOS));
  dispatch(resetState(VIDEO_DETAILS));
};

// Reducers
export const videoReducers = {
  all: basicReducer(PROVIDER_VIDEOS),
  create: basicReducer(CREATE_VIDEO),
  update: basicReducer(UPDATE_VIDEO),
  archive: basicReducer(ARCHIVE_VIDEO),
  toggleStatus: basicReducer(STATUS_VIDEO),
  archived: basicReducer(PROVIDER_ARCHIVED_VIDEOS),
  restore: basicReducer(PROVIDER_RESTORE_VIDEO),
  duplicate: basicReducer(VIDEO_DUPLICATE),
  userNotAcquiredVideos: basicReducer(USER_NOT_ACQUIRED_VIDEOS),
  addVideoToUser: basicReducer(ADD_VIDEO_TO_USER),
  user: basicReducer(USER_VIDEOS),
  bought: basicReducer(BOUGHT_VIDEOS),
  single: basicReducer(VIDEO_DETAILS),
  reorder: basicReducer(VIDEO_REORDER),
  changeUserVideoStartEndTime: basicReducer(USER_VIDEO_CHANGE_START_END_TIME),
  archivedAndNonArchived: basicReducer('TEMP')
};

// YouFeel WEB
export const feelVideoReducers = {
  userVideoCoursesFeel: basicReducer(USER_VIDEO_COURSES_FEEL),
  videoCoursesFeel: basicReducer(VIDEO_COURSES_FEEL),
  userVideoCourseFiles: basicReducer(USER_VIDEO_COURSE_FILES_FEEL),
  videoCourseFiles: basicReducer(VIDEO_COURSE_FILES_FEEL)
};