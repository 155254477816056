import { NOTIFICATION } from '../types';
const initialState = {
  message: null,
  variant: ''
};
const notification = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case NOTIFICATION:
      return {
        ...action.payload
      };
    default:
      return state;
  }
};
export { notification };