"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isCalendarDayNotAvailable = exports.getReservationInitialDate = void 0;
var _dateTimeUtils = require("./dateTimeUtils");
var getReservationInitialDate = exports.getReservationInitialDate = function getReservationInitialDate(eventDate, personnelDateSlots) {
  if (!personnelDateSlots.data || !eventDate) {
    var _newDate = new Date(eventDate || new Date());
    _newDate.setHours(_newDate.getHours() + 2); //TEMP FIX +1 for timezone
    return _newDate;
  }
  if ((0, _dateTimeUtils.dateIsBefore)(eventDate, personnelDateSlots.data.minDate, true)) {
    return new Date(personnelDateSlots.data.minDate);
  }
  if (typeof eventDate === 'string') {
    var _newDate2 = new Date(eventDate);
    _newDate2.setHours(_newDate2.getHours() + 2); //TEMP FIX +1 for timezone
    return _newDate2;
  }
  if (typeof eventDate.toDate === 'function') {
    var _newDate3 = new Date(eventDate.toDate());
    _newDate3.setHours(_newDate3.getHours() + 2); //TEMP FIX +1 for timezone
    return _newDate3;
  }
  var newDate = new Date(eventDate.toDate());
  newDate.setHours(newDate.getHours() + 2); //TEMP FIX +1 for timezone
  return newDate;
};
var isCalendarDayNotAvailable = exports.isCalendarDayNotAvailable = function isCalendarDayNotAvailable(date, personnelDateSlots) {
  var weekDay = date.day();
  if (!personnelDateSlots.data.availableDays.includes(weekDay) || !(0, _dateTimeUtils.dateIsBetween)(date, personnelDateSlots.data.minDate, personnelDateSlots.data.maxDate)) {
    return true;
  }
  if (personnelDateSlots.data.holidays) {
    return personnelDateSlots.data.holidays.some(function (x) {
      return x.isWholeDay && (0, _dateTimeUtils.dateIsBetween)(date, x.startTime, x.endTime);
    });
  }
  return false;
};