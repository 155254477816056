import { colors, dealThemePalette } from '@guryou/colors';
import typographyOverrides from './typography';
import { sizes, spacer } from '../constants';
const modalOverrides = {
  MuiDialogTitle: {
    root: {
      ...typographyOverrides.MuiTypography.h3,
      textAlign: 'left',
      color: dealThemePalette.primary.contrastText,
      backgroundColor: dealThemePalette.secondary.main,
      '& .MuiIconButton-root': {
        color: colors.white
      },
      padding: "".concat(spacer.spacer1, "px 0 0 ").concat(spacer.spacer2, "px")
    }
  },
  MuiDialog: {
    paperWidthSm: {
      maxWidth: sizes.small + spacer.spacer3,
      '& form button': {
        margin: 'auto !important'
      }
    },
    paperWidthMd: {
      maxWidth: 2 * sizes.small + spacer.spacer3
    },
    paperWidthLg: {
      maxWidth: 3 * sizes.small + spacer.spacer3
    }
  },
  MuiDialogContent: {
    root: {
      padding: '8px 16px',
      backgroundColor: dealThemePalette.primary.main,
      '&.myModal': {
        padding: '0px'
      }
    }
  }
};
export default modalOverrides;