import { Action } from '../core/Action';
const RANDOM_PROVIDERS = {
  entity: 'RANDOM_PROVIDERS',
  action: Action.GET
};
const PROVIDER_DETAILS = {
  entity: 'PROVIDER_DETAILS',
  action: Action.GET
};
const SEARCH_PROVIDERS = {
  entity: 'SEARCH_PROVIDERS',
  action: Action.GET
};
const CLASS_DETAILS = {
  entity: 'CLASS_DETAILS',
  action: Action.GET
};
const ALL_CATEGORIES = {
  entity: 'ALL_CATEGORIES',
  action: Action.GET
};
const ALL_CITITES = {
  entity: 'ALL_CITITES',
  action: Action.GET
};
const SESSIONS_FOR_DAY = {
  entity: 'SESSIONS_FOR_DAY',
  action: Action.GET
};
export { RANDOM_PROVIDERS, PROVIDER_DETAILS, SEARCH_PROVIDERS, CLASS_DETAILS, ALL_CATEGORIES, ALL_CITITES, SESSIONS_FOR_DAY };