import { addImageInProviderGalleryApiCall, adminUpdateProviderSubscriptionEndTimeApiCall, alignCodaApiCall, createProviderApiCall, createStripeCustomerApiCall, deleteProviderImageApiCall, getAllProvidersApiCall, getCurrentProviderCityApiRequest, getLatestWebIntegrationWithProviderIdApiCall, getMyProvidersApiCall, getNotificationsSettingsApiCall, getProviderGeneralInformationApiCall, getProviderImagesApiCall, getProvidersApiCall, getProvidersForLandingApiRequest, getProvidersWhereUserAssignedAsCollaboratorApiCall, getSmsNotificationsSettingsApiCall, getTop100MostActiveProvidersApiCall, reorderProviderGalleryApiCall, savePaymentGatewayCustomerApiCall, toggleProviderStatusApiCall, unlockProviderApiCall, updateNotificationsSettingsApiCall, updateProviderApiCall, updateProviderByAdminApiCall, updateProviderPaymentSettingsApiCall, updateSmsNotificationsSettingsApiCall } from '@guryou/api-client-gql';
import { execute } from '../actions/actionWrapper';
import { Action, basicReducer } from '../core';
export const PROVIDER_CITY_GET = {
  entity: 'PROVIDER_CITY',
  action: Action.GET_ONE
};
export const PROVIDER_CREATE = {
  entity: 'PROVIDERS',
  action: Action.CREATE
};
export const PROVIDER_GET = {
  entity: 'PROVIDER',
  action: Action.GET
};
export const PROVIDER_GET_GENERAL_INFO = {
  entity: 'PROVIDER_GENERAL_INFO',
  action: Action.GET
};
export const PROVIDERS_GET_MANY = {
  entity: 'PROVIDERS',
  action: Action.GET_MANY
};
export const PROVIDER_UPDATE = {
  entity: 'PROVIDERS',
  action: Action.UPDATE
};
export const PROVIDER_STRIPE_CUSTOMER_CREATE = {
  entity: 'PROVIDER_STRIPE_CUSTOMER',
  action: Action.CREATE
};
export const PROVIDER_IMAGES_CREATE = {
  entity: 'PROVIDER_IMAGES',
  action: Action.CREATE
};
export const PROVIDER_IMAGES_DELETE = {
  entity: 'PROVIDER_IMAGES',
  action: Action.DELETE
};
export const PROVIDER_IMAGES_GET = {
  entity: 'PROVIDER_IMAGES',
  action: Action.GET
};
export const PROVIDER_GALLERY_REORDER = {
  entity: 'PROVIDER_GALLERY_REORDER',
  action: Action.UPDATE
};
export const PROVIDER_LATEST_WIDGET = {
  entity: 'PROVIDER_LATEST_WIDGET',
  action: Action.GET_ONE
};
export const UNLOCK_PROVIDER = {
  entity: 'UNLOCK_PROVIDER',
  action: Action.UPDATE
};
export const ALL_PROVIDERS = {
  entity: 'ALL_PROVIDERS',
  action: Action.GET_MANY
};
export const TOGGLE_PROVIDER_STATUS = {
  entity: 'TOGGLE_PROVIDER_STATUS',
  action: Action.UPDATE
};
export const EDIT_PROVIDER_BY_ADMIN = {
  entity: 'EDIT_PROVIDER_BY_ADMIN',
  action: Action.UPDATE
};
export const TOP_10_MOST_ACTIVE_PROVIDERS = {
  entity: 'TOP_10_MOST_ACTIVE_PROVIDERS',
  action: Action.GET_MANY
};
export const NOTIFICATIONS_SETTINGS = {
  entity: 'NOTIFICATIONS_SETTINGS',
  action: Action.GET_ONE
};
export const UPDATE_NOTIFICATIONS_SETTINGS = {
  entity: 'UPDATE_NOTIFICATIONS_SETTINGS',
  action: Action.UPDATE
};
export const SMS_NOTIFICATIONS_SETTINGS = {
  entity: 'SMS_NOTIFICATIONS_SETTINGS',
  action: Action.GET_ONE
};
export const UPDATE_SMS_NOTIFICATIONS_SETTINGS = {
  entity: 'UPDATE_SMS_NOTIFICATIONS_SETTINGS',
  action: Action.UPDATE
};
export const PROVIDERS_ASSIGNED_USER = {
  entity: 'PROVIDERS_ASSIGNED_USER',
  action: Action.GET_MANY
};
export const PROVIDER_UPDATE_B2B_SUB_ADMIN = {
  entity: 'PROVIDER_UPDATE_B2B_SUB_ADMIN',
  action: Action.UPDATE
};
export const ALIGN_CODA = {
  entity: 'ALIGN_CODA',
  action: Action.SET
};
export const getMyProviders = () => async dispatch => execute(dispatch, () => getMyProvidersApiCall({
  images: ['medium'],
  b2bSubscription: ['endsAt', 'plan']
}), PROVIDERS_GET_MANY, false);
export const getProvidersAction = (onlyActive, categoryId, cityId, providerType, keyword, onlyMyMemberships) => async dispatch => execute(dispatch, () => getProvidersApiCall({
  onlyActive,
  categoryId,
  cityId,
  providerType,
  keyword,
  onlyMyMemberships
}, {
  categories: ['id', 'name'],
  images: ['medium']
}), PROVIDERS_GET_MANY, false);
export const getProvidersForLanding = (onlyActive, categoryId, cityId, providerType, keyword, onlyMyMemberships) => async dispatch => execute(dispatch, () => getProvidersForLandingApiRequest(onlyActive, categoryId, cityId, providerType, keyword, onlyMyMemberships), PROVIDERS_GET_MANY, false);
export const getProviderGeneralInformation = function () {
  let silent = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  let id = arguments.length > 1 ? arguments[1] : undefined;
  return async dispatch => {
    execute(dispatch, () => getProviderGeneralInformationApiCall({
      id
    }, {
      billingInformation: ['address', 'city', 'countryCode', 'email', 'taxId', 'fiscalCode', 'latitude', 'longitude', 'sdi'],
      categories: ['id', 'name'],
      city: ['id', 'name'],
      images: ['medium', 'large', 'xLarge'],
      plan: ['id', 'originalId', 'active', 'endsAt', 'amount', 'stripeId'],
      paymentAccount: ['id', 'customerName', 'dateCreated', 'customerId']
    }), PROVIDER_GET_GENERAL_INFO, false, null, silent);
  };
};
export const updateProviderDetailsAction = data => async dispatch => {
  let secondaryCategoryIds = null;
  if (typeof data.secondaryCategoryIds === 'string') {
    secondaryCategoryIds = [+data.secondaryCategoryIds];
  } else if (data.secondaryCategoryIds) {
    secondaryCategoryIds = data.secondaryCategoryIds.map(x => +x);
  }
  delete data.countryCode;
  execute(dispatch, () => updateProviderApiCall({
    request: {
      ...data,
      active: data.active === 'true' || false,
      latitude: +data.latitude,
      longitude: +data.longitude,
      categoryId: +data.categoryId,
      defaultTimeSlotStep: +data.defaultTimeSlotStep,
      secondaryCategoryIds
    }
  }), PROVIDER_UPDATE);
};
export const updateProviderDetailsActionByAdmin = (id, data) => async dispatch => {
  let secondaryCategoryIds = null;
  if (typeof data.secondaryCategoryIds === 'string') {
    secondaryCategoryIds = [+data.secondaryCategoryIds];
  } else if (data.secondaryCategoryIds) {
    secondaryCategoryIds = data.secondaryCategoryIds.map(x => +x);
  }
  delete data.countryCode;
  execute(dispatch, () => updateProviderByAdminApiCall({
    id,
    request: {
      ...data,
      active: data.active === 'true' ? 'Y' : 'N',
      latitude: +data.latitude,
      longitude: +data.longitude,
      categoryId: +data.categoryId,
      defaultTimeSlotStep: +data.defaultTimeSlotStep,
      secondaryCategoryIds
    }
  }), EDIT_PROVIDER_BY_ADMIN);
};
export const alignCoda = providerId => async dispatch => execute(dispatch, () => alignCodaApiCall({
  providerId
}), ALIGN_CODA);
export const getProviderImages = silent => async dispatch => execute(dispatch, () => getProviderImagesApiCall(), PROVIDER_IMAGES_GET, false, null, silent);
export const deleteProviderImage = id => async dispatch => execute(dispatch, () => deleteProviderImageApiCall({
  id
}), PROVIDER_IMAGES_DELETE, false, null);
export const getCurrentProviderCity = () => async dispatch => execute(dispatch, () => getCurrentProviderCityApiRequest(), PROVIDER_CITY_GET, false);
export const savePaymentGatewayCustomer = data => async dispatch => {
  execute(dispatch, () => savePaymentGatewayCustomerApiCall({
    request: {
      ...data
    }
  }), PROVIDER_UPDATE);
};
export const createProviderAction = data => async dispatch => execute(dispatch, () => createProviderApiCall({
  request: {
    ...data,
    categoryId: +data.categoryId
  }
}), PROVIDER_CREATE, false);
export const updateProviderPaymentSettings = _ref => {
  let {
    freeCancellationHours,
    onlineDiscount,
    reservationOffset,
    paymentOptions
  } = _ref;
  return async dispatch => execute(dispatch, () => updateProviderPaymentSettingsApiCall({
    freeCancellationHours: +freeCancellationHours,
    reservationOffset: +reservationOffset,
    onlineDiscount: +onlineDiscount || null,
    paymentOptions: paymentOptions
  }), PROVIDER_UPDATE);
};
export const addImageInProviderGallery = (name, metadata) => async dispatch => execute(dispatch, () => addImageInProviderGalleryApiCall({
  name,
  metadata
}), PROVIDER_IMAGES_CREATE, true);
export const createStripeCustomer = planId => async dispatch => execute(dispatch, () => createStripeCustomerApiCall(), PROVIDER_STRIPE_CUSTOMER_CREATE, true, {
  planId
});
export const reorderProviderGallery = (oldPosition, newPosition) => async dispatch => {
  execute(dispatch, () => reorderProviderGalleryApiCall({
    oldPosition,
    newPosition
  }), PROVIDER_GALLERY_REORDER, false, null, true);
};
export const getProviderLatestWidget = id => dispatch => execute(dispatch, () => getLatestWebIntegrationWithProviderIdApiCall({
  id
}), PROVIDER_LATEST_WIDGET, false);
export const unlockProvider = id => async dispatch => {
  execute(dispatch, () => unlockProviderApiCall({
    id
  }), UNLOCK_PROVIDER);
};
export const getAllProviders = function () {
  let silent = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  return dispatch => execute(dispatch, () => getAllProvidersApiCall({
    images: ['medium'],
    b2bSubscription: ['endsAt', 'plan']
  }), ALL_PROVIDERS, false, null, silent);
};
export const toggleProviderStatus = (id, status) => dispatch => execute(dispatch, () => toggleProviderStatusApiCall({
  id,
  status
}), TOGGLE_PROVIDER_STATUS);
export const getTop100MostActiveProviders = function (startDate, endDate) {
  let silent = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return async dispatch => execute(dispatch, () => getTop100MostActiveProvidersApiCall({
    startDate,
    endDate
  }, {
    b2bSubscription: ['endsAt', 'plan']
  }), TOP_10_MOST_ACTIVE_PROVIDERS, false, null, silent);
};
export const getNotificationsSettings = () => async dispatch => execute(dispatch, () => getNotificationsSettingsApiCall(), NOTIFICATIONS_SETTINGS, false, null);
export const updateNotificationsSettings = settings => async dispatch => execute(dispatch, () => updateNotificationsSettingsApiCall({
  settings
}), UPDATE_NOTIFICATIONS_SETTINGS, false, null);
export const getSmsNotificationsSettings = () => async dispatch => execute(dispatch, () => getSmsNotificationsSettingsApiCall(), SMS_NOTIFICATIONS_SETTINGS, false, null);
export const updateSmsNotificationsSettings = settings => async dispatch => execute(dispatch, () => updateSmsNotificationsSettingsApiCall({
  settings
}), UPDATE_SMS_NOTIFICATIONS_SETTINGS, false, null);
export const getProvidersWhereUserAssignedAsCollaborator = userId => async dispatch => execute(dispatch, () => getProvidersWhereUserAssignedAsCollaboratorApiCall({
  userId
}), PROVIDERS_ASSIGNED_USER, false);
export const adminUpdateProviderSubscriptionEndTime = (providerId, endsAt) => async dispatch => execute(dispatch, () => adminUpdateProviderSubscriptionEndTimeApiCall({
  providerId,
  endsAt
}), PROVIDER_UPDATE_B2B_SUB_ADMIN, true);
export const providerReducers = {
  info: basicReducer(PROVIDER_GET),
  stripeCustomerMutation: basicReducer(PROVIDER_STRIPE_CUSTOMER_CREATE),
  reorderImages: basicReducer(PROVIDER_GALLERY_REORDER),
  unlockProvider: basicReducer(UNLOCK_PROVIDER),
  notificationsSettings: basicReducer(NOTIFICATIONS_SETTINGS),
  modifyNotificationsSettings: basicReducer(UPDATE_NOTIFICATIONS_SETTINGS),
  smsNotificationsSettings: basicReducer(SMS_NOTIFICATIONS_SETTINGS),
  modifySmsNotificationsSettings: basicReducer(UPDATE_SMS_NOTIFICATIONS_SETTINGS),
  providersWhereUserAssignedAsCollab: basicReducer(PROVIDERS_ASSIGNED_USER),
  adminUpdateB2bSubET: basicReducer(PROVIDER_UPDATE_B2B_SUB_ADMIN),
  alignCoda: basicReducer(ALIGN_CODA)
};