const validationRules = {
  requiredstr: {
    method: (value, elAttrValue) => value && elAttrValue === 'true',
    message: 'GENERAL.requiredFieldMissingError'
  },
  minlengthval: {
    method: (value, elAttrValue) => !value || value.length > parseInt(elAttrValue),
    message: 'GENERAL.minLengthError'
  },
  maxlengthval: {
    method: (value, elAttrValue) => !value || value.length <= parseInt(elAttrValue),
    message: 'GENERAL.maxLengthError'
  },
  emailval: {
    method: (value, elAttrValue) => elAttrValue === 'false' || !value || /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value.toLowerCase()),
    message: 'GENERAL.invalidEmailFormat'
  }
};
const triggerValidationError = (element, validationAttr) => {
  const event = new Event('invalid-data', {
    bubbles: false,
    cancelable: true,
    isValid: false
  });
  element.errormsg = validationRules[validationAttr.name].message;
  parseInt(validationAttr.value) && (element.errormsgparam = validationAttr.value);
  element.dispatchEvent(event);
};
const validateForm = form => {
  let isValidForm = true;
  Array.from(form.querySelectorAll('input:not([type="radio"]),textarea')).forEach(element => {
    const validations = [element.attributes['requiredstr'], element.attributes['minlengthval'], element.attributes['maxlengthval'], element.attributes['emailval']].filter(x => x && x.value && x.value !== 'false');
    validations.forEach(val => {
      const isValid = validationRules[val.name].method(element.value, val.value);
      if (!isValid) {
        isValidForm = false;
        triggerValidationError(element, val);
      }
    });
  });
  Array.from(form.querySelectorAll('select')).forEach(element => {
    const validationAttr = element.attributes['requiredstr'];
    if (validationAttr && validationAttr.value === 'true') {
      const selectedValue = element[element.selectedIndex].value;
      const isValid = validationRules[validationAttr.name].method(selectedValue, validationAttr.value);
      if (!isValid) {
        isValidForm = false;
        triggerValidationError(element, validationAttr);
      }
    }
  });
  return isValidForm;
};
export default validateForm;