import { uploadFileToS3 as uploadToS3 } from '@guryou/api-client';
import { addFileToSectionApiCall, checkFileConnectionsApiCall, deleteAllFilesInSectionApiCall, deleteFileApiCall, getAllMediaFilesApiCall, getCustomerFileApiCall, getCustomerPublicFileApiCall, getFileApiCall, getFilesApiCall, getSeenFilesReportApiCall, markFileSeenUnseenApiCall, reorderFilesApiCall, saveFileApiCall } from '@guryou/api-client-gql';
import { parseAsBool } from '@guryou/utils';
import { resetState } from '../actions';
import { execute } from '../actions/actionWrapper';
import { Action, basicReducer, executeApiCall } from '../core';

// Types
export const FILES = {
  entity: 'FILES',
  action: Action.GET
};
export const MEDIA_GALLERY = {
  entity: 'MEDIA_GALLERY',
  action: Action.GET
};
export const UPDATE_FILE = {
  entity: 'UPDATE_FILE',
  action: Action.UPDATE
};
export const CREATE_FILE = {
  entity: 'CREATE_FILE',
  action: Action.CREATE
};
export const ADD_FILE_TO_SECTION = {
  entity: 'ADD_FILE_TO_SECTION',
  action: Action.HANDLE
};
export const DELETE_FILE = {
  entity: 'DELETE_FILE',
  action: Action.DELETE
};
export const GET_FILE = {
  entity: 'GET_FILE',
  action: Action.GET
};
export const UPLOAD_FILE = {
  entity: 'UPLOAD_FILE',
  action: Action.HANDLE
};
export const GET_CUSTOMER_FILE = {
  entity: 'GET_CUSTOMER_FILE',
  action: Action.GET
};
export const GET_CUSTOMER_PUBLIC_FILE = {
  entity: 'GET_CUSTOMER_PUBLIC_FILE',
  action: Action.GET
};
export const FILES_REORDER = {
  entity: 'FILES',
  action: Action.REORDER
};
export const FILE_CONNECTIONS = {
  entity: 'FILE_CONNECTIONS',
  action: Action.HANDLE
};
export const DELETE_ALL_FILES_IN_SECTION = {
  entity: 'ALL_FILES_IN_SECTION',
  action: Action.DELETE
};
export const MARK_FILE_SEEN_UNSEEN = {
  entity: 'MARK_FILE_SEEN_UNSEEN',
  action: Action.HANDLE
};
export const GET_SEEN_FILES_REPORT = {
  entity: 'GET_SEEN_FILES_REPORT',
  action: Action.GET_MANY
};

// Actions
export const getFiles = (sectionId, silent) => async dispatch => execute(dispatch, () => getFilesApiCall({
  sectionId
}), FILES, false, {
  sectionId
}, silent);
export const getAllMediaFiles = () => async dispatch => execute(dispatch, () => getAllMediaFilesApiCall(), MEDIA_GALLERY, false);
export const createFile = _ref => {
  let {
    free,
    isPublic,
    ...request
  } = _ref;
  return async dispatch => execute(dispatch, () => saveFileApiCall({
    request: {
      free: parseAsBool(free),
      intro: false,
      isPublic: parseAsBool(isPublic),
      ...request
    }
  }), CREATE_FILE, false);
};
export const updateFile = _ref2 => {
  let {
    free,
    isPublic,
    ...request
  } = _ref2;
  return async dispatch => execute(dispatch, () => saveFileApiCall({
    request: {
      free: parseAsBool(free),
      intro: false,
      isPublic: parseAsBool(isPublic),
      ...request
    }
  }), UPDATE_FILE);
};
export const deleteFile = function (id) {
  let sectionIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return async dispatch => execute(dispatch, () => deleteFileApiCall({
    id,
    sectionIndex
  }), DELETE_FILE);
};
export const getFile = id => async dispatch => execute(dispatch, () => getFileApiCall({
  id
}), GET_FILE);
export const uploadFileToS3 = (file, presignedUrl, onProgressCallback) => async dispatch => dispatch(executeApiCall(uploadToS3(file, presignedUrl, onProgressCallback), UPLOAD_FILE));
export const getCustomerFile = (id, providerId) => async dispatch => execute(dispatch, () => getCustomerFileApiCall({
  id,
  providerId
}), GET_CUSTOMER_FILE);
export const getCustomerPublicFile = (id, providerId) => async dispatch => execute(dispatch, () => getCustomerPublicFileApiCall({
  id,
  providerId
}), GET_CUSTOMER_PUBLIC_FILE);
export const reorderFiles = (sectionId, oldPosition, newPosition) => async dispatch => {
  execute(dispatch, () => reorderFilesApiCall({
    sectionId,
    oldPosition,
    newPosition
  }), FILES_REORDER, false, null, true);
};
export const checkFileConnections = id => async dispatch => {
  execute(dispatch, () => checkFileConnectionsApiCall({
    id
  }), FILE_CONNECTIONS, false, null, true);
};
export const addFileToSection = (id, sectionId, sectionIndex) => async dispatch => execute(dispatch, () => addFileToSectionApiCall({
  id,
  sectionId,
  sectionIndex
}), ADD_FILE_TO_SECTION);
export const deleteAllFilesInSection = (id, sectionIndex) => async dispatch => execute(dispatch, () => deleteAllFilesInSectionApiCall({
  id,
  sectionIndex
}), DELETE_ALL_FILES_IN_SECTION);
export const markFileSeenUnseen = (fileId, seen) => async dispatch => execute(dispatch, () => markFileSeenUnseenApiCall({
  fileId,
  seen
}), MARK_FILE_SEEN_UNSEEN);
export const getSeenFilesReport = (productId, users) => async dispatch => execute(dispatch, () => getSeenFilesReportApiCall({
  productId,
  users
}), GET_SEEN_FILES_REPORT);

//Reset actions
export const resetCustomerFile = () => async dispatch => dispatch(resetState(GET_CUSTOMER_FILE));
export const resetUploadToS3State = () => async dispatch => dispatch(resetState(UPLOAD_FILE));
export const resetGetFileState = () => async dispatch => dispatch(resetState(GET_FILE));
export const resetFileCreate = () => async dispatch => {
  dispatch(resetState(CREATE_FILE));
  dispatch(resetState(UPDATE_FILE));
  dispatch(resetState(UPLOAD_FILE));
};
export const resetFileState = () => async dispatch => {
  dispatch(resetState(CREATE_FILE));
  dispatch(resetState(UPLOAD_FILE));
  dispatch(resetState(FILES));
  dispatch(resetState(MEDIA_GALLERY));
};

// Reducers
export const fileReducers = {
  all: basicReducer(FILES),
  sharedMedia: basicReducer(MEDIA_GALLERY),
  create: basicReducer(CREATE_FILE),
  update: basicReducer(UPDATE_FILE),
  remove: basicReducer(DELETE_FILE),
  upload: basicReducer(UPLOAD_FILE),
  get: basicReducer(GET_FILE),
  reorder: basicReducer(FILES_REORDER),
  fileConnections: basicReducer(FILE_CONNECTIONS),
  addToSection: basicReducer(ADD_FILE_TO_SECTION),
  deleteAllFilesInSection: basicReducer(DELETE_ALL_FILES_IN_SECTION),
  seenFilesReport: basicReducer(GET_SEEN_FILES_REPORT)
};
export const fileCustomerReducers = {
  all: basicReducer(FILES),
  get: basicReducer(GET_CUSTOMER_FILE),
  getPublic: basicReducer(GET_CUSTOMER_PUBLIC_FILE),
  upload: basicReducer(UPLOAD_FILE),
  markSeenUnseen: basicReducer(MARK_FILE_SEEN_UNSEEN)
};