import { Action } from '../core/Action';
const WEB_INTEGRATIONS_GET_MANY = {
  entity: 'WEB_INTEGRATIONS',
  action: Action.GET_MANY
};
const WEB_INTEGRATIONS_CREATE = {
  entity: 'WEB_INTEGRATIONS',
  action: Action.CREATE
};
const WEB_INTEGRATIONS_DELETE = {
  entity: 'WEB_INTEGRATIONS',
  action: Action.DELETE
};
export { WEB_INTEGRATIONS_GET_MANY, WEB_INTEGRATIONS_CREATE, WEB_INTEGRATIONS_DELETE };