const typographyOverrides = {
  MuiTypography: {
    root: {
      wordBreak: 'break-all'
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 500
    },
    body2: {
      fontSize: '0.8rem',
      fontWeight: 500
    },
    caption: {
      fontSize: '0.8rem',
      fontWeight: 500
    },
    h1: {
      fontSize: '2rem',
      fontWeight: 600
    },
    h2: {
      fontSize: '1.8rem',
      fontWeight: 600
    },
    h3: {
      fontSize: '1.6rem',
      fontWeight: 600
    },
    h4: {
      fontSize: '1.4rem',
      fontWeight: 600
    },
    h5: {
      fontSize: '1.2rem',
      fontWeight: 600
    },
    h6: {
      fontSize: '1.1rem',
      fontWeight: 600
    }
  }
};
export default typographyOverrides;