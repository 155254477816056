import { MODAL_CLOSE, MODAL_OPEN, MODAL_RESET } from './types';
const initialState = {
  data: [],
  version: 0
};
export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case MODAL_OPEN:
      {
        const uniqueValues = state.data.filter(e => e.id !== action.payload.id);
        return {
          data: [...uniqueValues, action.payload],
          version: +new Date()
        };
      }
    case MODAL_CLOSE:
      {
        if (action.payload.id) {
          return {
            data: state.data.filter(x => x.id !== action.payload.id),
            version: +new Date()
          };
        }
        state.data.pop();
        return {
          data: state.data,
          version: +new Date()
        };
      }
    case MODAL_RESET:
      return {
        data: [],
        version: +new Date()
      };
    default:
      return state;
  }
});