import { deleteExerciseApiCall, getExercisesApiCall, saveExerciseApiCall } from '@guryou/api-client-gql';
import { execute } from '../actions/actionWrapper';
import { Action, basicReducer } from '../core';
export const EXERCISES_GET = {
  entity: 'EXERCISES',
  action: Action.GET
};
export const CREATE_EXERCISE = {
  entity: 'EXERCISE',
  action: Action.CREATE
};
export const UPDATE_EXERCISE = {
  entity: 'EXERCISE',
  action: Action.UPDATE
};
export const ARCHIVE_EXERCISE = {
  entity: 'ARCHIVE_EXERCISE',
  action: Action.HANDLE
};
export const getExercises = () => async dispatch => execute(dispatch, () => getExercisesApiCall({
  equipments: ['id', 'equipmentId', 'weight', 'weightMode', 'quantity', 'name'],
  image: ['name', 'thumbnailUrl'],
  gif: ['name', 'thumbnailUrl'],
  video: ['name', 'thumbnailUrl']
}), EXERCISES_GET);
export const saveExercise = _ref => {
  let {
    id,
    equipments,
    gifId,
    videoId,
    imageId,
    ...rest
  } = _ref;
  return async dispatch => {
    const request = {
      ...rest,
      id: id || null,
      gifId: gifId || null,
      videoId: videoId || null,
      imageId: imageId || null,
      equipments: equipments === null || equipments === void 0 ? void 0 : equipments.map(x => ({
        ...x,
        id: x.id || null,
        weightMode: x.weightMode || null,
        weight: +x.weight,
        quantity: +x.quantity
      }))
    };
    execute(dispatch, () => saveExerciseApiCall({
      request
    }), UPDATE_EXERCISE);
  };
};
export const archiveExercise = id => async dispatch => execute(dispatch, () => deleteExerciseApiCall({
  id
}), ARCHIVE_EXERCISE);
export const exercisesReducer = {
  all: basicReducer(EXERCISES_GET),
  archive: basicReducer(ARCHIVE_EXERCISE),
  save: basicReducer(UPDATE_EXERCISE)
};