import { basicReducer } from '../core';
import { CALENDAR_EVENTS, CALENDAR_FILTER_GET_CLASSES, CALENDAR_FILTER_GET_COURSES, CALENDAR_FILTER_GET_EVENTS, CALENDAR_FILTER_GET_SERVICES, CALENDAR_FILTER_GET_SUBSERVICES, MARK_ORDER_AS_PAID, ORDER_EVENT_CREATE, ORDER_EVENTS } from '../types';
const calendar = {
  calendarEvents: basicReducer(CALENDAR_EVENTS),
  services: basicReducer(CALENDAR_FILTER_GET_SERVICES),
  courses: basicReducer(CALENDAR_FILTER_GET_COURSES),
  events: basicReducer(CALENDAR_FILTER_GET_EVENTS),
  subServices: basicReducer(CALENDAR_FILTER_GET_SUBSERVICES),
  classes: basicReducer(CALENDAR_FILTER_GET_CLASSES),
  orderEvents: basicReducer(ORDER_EVENTS),
  createOrderEvent: basicReducer(ORDER_EVENT_CREATE),
  markOrderAsPaid: basicReducer(MARK_ORDER_AS_PAID)
};
export { calendar };