import { getArticleBySlugApiCall, getArticlesApiCall, getAuthorBySlugApiCall, getBlogCategoriesApiCall } from '@guryou/api-client-gql';
import { execute } from '../actions/actionWrapper';
import { Action, basicReducer } from '../core';
export const GET_ARTICLES = {
  entity: 'GET_ARTICLES',
  action: Action.GET_MANY
};
export const GET_BLOG_CATEGORIES = {
  entity: 'GET_BLOG_CATEGORIES',
  action: Action.GET_MANY
};
export const GET_AUTHOR_BY_SLUG = {
  entity: 'GET_AUTHOR_BY_SLUG',
  action: Action.GET_MANY
};
export const GET_ARTICLE_BY_SLUG = {
  entity: 'GET_ARTICLE_BY_SLUG',
  action: Action.GET_ONE
};
export const getArticles = () => async dispatch => execute(dispatch, () => getArticlesApiCall(), GET_ARTICLES, false, true);
export const getBlogCategories = () => async dispatch => execute(dispatch, () => getBlogCategoriesApiCall(), GET_BLOG_CATEGORIES, false, true);
export const getAuthorBySlug = slug => async dispatch => execute(dispatch, () => getAuthorBySlugApiCall(slug), GET_AUTHOR_BY_SLUG, false, true);
export const getArticleBySlug = slug => async dispatch => execute(dispatch, () => getArticleBySlugApiCall(slug), GET_ARTICLE_BY_SLUG, false, true);
export const articleReducers = {
  articles: basicReducer(GET_ARTICLES),
  articleBySlug: basicReducer(GET_ARTICLE_BY_SLUG),
  blogCategories: basicReducer(GET_BLOG_CATEGORIES),
  authorBySlug: basicReducer(GET_AUTHOR_BY_SLUG)
};