import { ActionStatus } from './ActionStatus';
import { buildActionType } from './buildActionType';
const initialState = {
  counter: 0,
  version: 0
};
const countReducer = actionType => function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case buildActionType(actionType, ActionStatus.START):
      return {
        counter: ++state.counter,
        version: +new Date()
      };
    case buildActionType(actionType, ActionStatus.DONE):
      return {
        counter: --state.counter,
        version: +new Date()
      };
    case buildActionType(actionType, ActionStatus.RESET):
      return {
        counter: 0,
        version: 0
      };
    default:
      return state;
  }
};
export { countReducer };