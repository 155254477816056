import { getMyVideosApiCall } from '@guryou/api-client-gql';
import { execute } from '../actions/actionWrapper';
import { Action, basicReducer } from '../core';
export const MY_VIDEOS = {
  entity: 'MY_VIDEOS',
  action: Action.GET_MANY
};
export const getMyVideos = keyword => async dispatch => execute(dispatch, () => getMyVideosApiCall({
  keyword
}, {
  provider: ['name']
}), MY_VIDEOS, false);
export const myVideosReducers = {
  all: basicReducer(MY_VIDEOS)
};