import { TableCellBody } from './Components';
const tableRowToColumns = tableRow => {
  const columns = tableRow.props.children.filter(x => x.type.name === TableCellBody.name).map(x => {
    const {
      name,
      label,
      render,
      display,
      size,
      textPosition
    } = x.props;
    const result = {
      name,
      label,
      options: {
        sort: false
      }
    };
    if (display !== undefined) {
      result.options.display = display;
    }
    if (render) {
      result.options.customBodyRender = render;
      result.options.isSelectable = false;
    }
    if (size) {
      result.options.setCellProps = () => {
        if (size) {
          return {
            className: size
          };
        }
      };
    }
    if (textPosition) {
      result.options.setCellProps = () => {
        if (textPosition) {
          return {
            className: "".concat(textPosition, " ").concat(size)
          };
        }
      };
    }
    return result;
  });
  return columns;
};
export { tableRowToColumns };