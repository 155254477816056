import { getAllCategories, getAllCities, getAvailableBookings, getClassDetails, getProviderDetails, getRandomProviders, getRandomProvidersAlsoByKeywordOrAddressApiRequest, getSearchProviders } from '@guryou/api-client-gql';
import { ActionStatus, buildActionType } from '../core';
import { ALL_CATEGORIES, ALL_CITITES, CLASS_DETAILS, PROVIDER_DETAILS, RANDOM_PROVIDERS, SEARCH_PROVIDERS, SESSIONS_FOR_DAY } from '../types';
const randomProvidersAction = () => async dispatch => {
  dispatch({
    type: buildActionType(RANDOM_PROVIDERS, ActionStatus.START)
  });
  const response = await getRandomProviders();
  dispatch({
    type: buildActionType(RANDOM_PROVIDERS, ActionStatus.DONE),
    payload: {
      response
    }
  });
};
const randomProvidersActionByKeywordOrAddress = (keyword, city, country, type) => async dispatch => {
  dispatch({
    type: buildActionType(RANDOM_PROVIDERS, ActionStatus.START)
  });
  const response = await getRandomProvidersAlsoByKeywordOrAddressApiRequest(keyword, city, country, type);
  dispatch({
    type: buildActionType(RANDOM_PROVIDERS, ActionStatus.DONE),
    payload: {
      response
    }
  });
};
const providerDetailsAction = providerId => async dispatch => {
  dispatch({
    type: buildActionType(PROVIDER_DETAILS, ActionStatus.START)
  });
  const response = await getProviderDetails(providerId);
  if (response.success === false) {
    dispatch({
      type: buildActionType(PROVIDER_DETAILS, ActionStatus.FAILED),
      payload: {
        errors: response.message
      }
    });
  } else {
    dispatch({
      type: buildActionType(PROVIDER_DETAILS, ActionStatus.DONE),
      payload: {
        response
      }
    });
  }
};
const searchProvidersAction = (category, city, type, keyword, onlyMyMemberships) => async dispatch => {
  dispatch({
    type: buildActionType(SEARCH_PROVIDERS, ActionStatus.START)
  });
  const response = await getSearchProviders(category, city, type, keyword, onlyMyMemberships);
  dispatch({
    type: buildActionType(SEARCH_PROVIDERS, ActionStatus.DONE),
    payload: {
      response
    }
  });
};
const classDetailsAction = classId => async dispatch => {
  dispatch({
    type: buildActionType(CLASS_DETAILS, ActionStatus.START)
  });
  const response = await getClassDetails(classId);
  dispatch({
    type: buildActionType(CLASS_DETAILS, ActionStatus.DONE),
    payload: {
      response
    }
  });
};
const getCategoriesAction = () => async dispatch => {
  dispatch({
    type: buildActionType(ALL_CATEGORIES, ActionStatus.START)
  });
  const response = await getAllCategories();
  dispatch({
    type: buildActionType(ALL_CATEGORIES, ActionStatus.DONE),
    payload: {
      response
    }
  });
};
const getCitiesAction = () => async dispatch => {
  dispatch({
    type: buildActionType(ALL_CITITES, ActionStatus.START)
  });
  const response = await getAllCities();
  dispatch({
    type: buildActionType(ALL_CITITES, ActionStatus.DONE),
    payload: {
      response
    }
  });
};
const getSessionsForDayAction = (providerId, startDate) => async dispatch => {
  dispatch({
    type: buildActionType(SESSIONS_FOR_DAY, ActionStatus.START)
  });
  const response = await getAvailableBookings(providerId, startDate);
  dispatch({
    type: buildActionType(SESSIONS_FOR_DAY, ActionStatus.DONE),
    payload: {
      response
    }
  });
};
export { randomProvidersAction, providerDetailsAction, searchProvidersAction, classDetailsAction, getCategoriesAction, getCitiesAction, getSessionsForDayAction, randomProvidersActionByKeywordOrAddress };