import { deleteMyGoalApiCall, getMyBodyMeasurementsApiCall, getMyBodyMeasurementsGraphApiCall, getMyGoalsApiCall, updateMyBodyMeasurementsApiCall, updateMyGoalsApiCall } from '@guryou/api-client-gql';
import { execute } from '../actions/actionWrapper';
import { Action, basicReducer } from '../core';
export const MY_BODY_MEASUREMENTS_GET = {
  entity: 'MY_BODY_MEASUREMENTS',
  action: Action.GET
};
export const MY_BODY_MEASUREMENTS_GRAPH_GET = {
  entity: 'MY_BODY_MEASUREMENTS_GRAPH',
  action: Action.GET
};
export const MY_GOALS_GET = {
  entity: 'MY_GOALS',
  action: Action.GET
};
export const MY_BODY_MEASUREMENTS_UPDATE = {
  entity: 'MY_BODY_MEASUREMENTS',
  action: Action.UPDATE
};
export const MY_GOALS_UPDATE = {
  entity: 'MY_GOALS',
  action: Action.UPDATE
};
export const MY_GOAL_DELETE = {
  entity: 'MY_GOAL',
  action: Action.DELETE
};
export const getMyBodyMeasurements = () => async dispatch => execute(dispatch, () => getMyBodyMeasurementsApiCall(), MY_BODY_MEASUREMENTS_GET, false);
export const getMyBodyMeasurementsGraph = () => async dispatch => execute(dispatch, () => getMyBodyMeasurementsGraphApiCall(), MY_BODY_MEASUREMENTS_GRAPH_GET, false);
export const getMyGoals = () => async dispatch => execute(dispatch, () => getMyGoalsApiCall(), MY_GOALS_GET, false);
export const updateMyBodyMeasurements = request => async dispatch => execute(dispatch, () => updateMyBodyMeasurementsApiCall({
  request
}), MY_BODY_MEASUREMENTS_UPDATE, false);
export const updateMyGoals = request => async dispatch => execute(dispatch, () => updateMyGoalsApiCall({
  request
}), MY_GOALS_UPDATE, false);
export const deleteMyGoal = id => async dispatch => execute(dispatch, () => deleteMyGoalApiCall({
  id
}), MY_GOAL_DELETE, false);
export const userTrainingInfoReducers = {
  myBodyMeasurements: basicReducer(MY_BODY_MEASUREMENTS_GET),
  myBodyMeasurementsGraph: basicReducer(MY_BODY_MEASUREMENTS_GRAPH_GET),
  myGoals: basicReducer(MY_GOALS_GET),
  updateMyBodyMeasurements: basicReducer(MY_BODY_MEASUREMENTS_UPDATE),
  updateMyGoals: basicReducer(MY_GOALS_UPDATE),
  deleteMyGoal: basicReducer(MY_GOAL_DELETE)
};