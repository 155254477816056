import { DateLocalizer } from 'react-big-calendar';
import { PRINT_TIME_FORMAT, printDate, printTime } from '@guryou/utils';
import 'moment/locale/it';
import * as dates from './dates';
const dateRangeFormat = _ref => {
  let {
    start,
    end
  } = _ref;
  return "".concat(printDate(start), " - ").concat(printDate(end));
};
const timeRangeFormat = _ref2 => {
  let {
    start,
    end
  } = _ref2;
  return "".concat(printTime(start), " - ").concat(printTime(end));
};
const timeRangeStartFormat = _ref3 => {
  let {
    start
  } = _ref3;
  return "".concat(printTime(start), " - ");
};
const timeRangeEndFormat = _ref4 => {
  let {
    end
  } = _ref4;
  return " - ".concat(printTime(end));
};
const weekRangeFormat = (_ref5, culture, local) => {
  let {
    start,
    end
  } = _ref5;
  return "".concat(local.format(start, 'MMMM DD', culture), " \u2013 ").concat(local.format(end, dates.eq(start, end, 'month') ? 'DD' : 'MMMM DD', culture));
};
export const formats = {
  dateFormat: 'DD',
  dayFormat: 'DD ddd',
  weekdayFormat: 'ddd',
  selectRangeFormat: timeRangeFormat,
  eventTimeRangeFormat: timeRangeFormat,
  eventTimeRangeStartFormat: timeRangeStartFormat,
  eventTimeRangeEndFormat: timeRangeEndFormat,
  timeGutterFormat: PRINT_TIME_FORMAT,
  monthHeaderFormat: 'MMMM YYYY',
  dayHeaderFormat: 'dddd MMM DD',
  dayRangeHeaderFormat: weekRangeFormat,
  agendaHeaderFormat: dateRangeFormat,
  agendaDateFormat: 'ddd MMM DD',
  agendaTimeFormat: PRINT_TIME_FORMAT,
  agendaTimeRangeFormat: timeRangeFormat
};
const gyLocalizer = moment => {
  const locale = (m, c) => c ? m.locale(c) : m;
  return new DateLocalizer({
    formats,
    firstOfWeek: () => 1,
    format: (value, format, culture) => locale(moment(value), culture).format(format)
  });
};
export default gyLocalizer;