"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WorkoutLevels = exports.ExerciseWeightMode = exports.ExerciseExecutionMode = exports.ExerciseDisplayUnits = exports.CircuitTypes = void 0;
var WorkoutLevels = exports.WorkoutLevels = {
  allLevels: 'all_levels',
  beginner: 'beginner',
  intermediate: 'intermediate',
  expert: 'expert',
  athlete: 'athlete'
};
var CircuitTypes = exports.CircuitTypes = {
  warmUp: 'warm_up',
  training: 'training',
  cooldown: 'cooldown'
};
var ExerciseExecutionMode = exports.ExerciseExecutionMode = {
  repetition: 'repetition',
  repetitionPerSide: 'repetitionPerSide',
  duration: 'duration',
  durationPerSide: 'durationPerSide',
  distance: 'distance',
  laps: 'laps'
};
var ExerciseDisplayUnits = exports.ExerciseDisplayUnits = {
  times: 'times',
  min: 'min',
  sec: 'sec',
  meters: 'meters',
  km: 'km',
  kg: 'kg',
  pounds: 'pounds',
  percent: '%',
  kcal: 'kcal',
  hours: 'hours'
};
var ExerciseWeightMode = exports.ExerciseWeightMode = {
  kg: 'KG',
  kgPerSide: 'KG per side',
  rpe: 'RPE',
  oneRm: '1-RM',
  buffer: 'Buffer'
};