import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from '@guryou/hooks';
import { StateStatus } from '../core/StateStatus';
const defaultSelector = () => {
  return {
    status: StateStatus.NOT_INITIALIZED
  };
};
const useEffectOnError = function (stateSelector, effect) {
  let deps = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  const dispatch = useDispatch();
  const {
    data,
    status,
    version,
    errors,
    entity
  } = useSelector(state => {
    if (Array.isArray(stateSelector)) {
      const values = stateSelector.map(fn => fn(state));
      return values.sort((a, b) => a.version - b.version).pop();
    }
    if (stateSelector) {
      return stateSelector(state);
    }
    return defaultSelector(state);
  });
  useUpdateEffect(() => {
    if (status === StateStatus.ERROR) {
      effect(errors);
    }
  }, [dispatch, status, ...deps]);
  return {
    data: data || [],
    status,
    version,
    entity,
    errors,
    isLoading: status === StateStatus.LOADING
  };
};
export default useEffectOnError;