import { colors } from '@guryou/colors';
import typographyOverrides from './typography';
import { spacer } from '../constants';
const autoFillOverrides = {
  '-webkit-text-fill-color': colors.mineShaft,
  '-webkit-box-shadow': 'none',
  transition: 'background-color 5000s ease-in-out 0s',
  borderColor: 'inherit'
};
const globalRules = {
  body: {
    height: '100vh',
    padding: 0,
    margin: 0,
    backgroundColor: colors.springWood,
    color: colors.mineShaft,
    fontWeight: 500
  },
  form: {
    width: '100%'
  },
  'a, p, h1, h2, h3, h4, h5, h6': {
    padding: 0,
    margin: 0,
    color: colors.mineShaft,
    textDecoration: 'none'
  },
  h1: typographyOverrides.MuiTypography.h1,
  h2: typographyOverrides.MuiTypography.h2,
  h3: typographyOverrides.MuiTypography.h3,
  h4: typographyOverrides.MuiTypography.h4,
  h5: typographyOverrides.MuiTypography.h5,
  h6: typographyOverrides.MuiTypography.h6,
  'input:-webkit-autofill': autoFillOverrides,
  'input:-webkit-autofill:hover': autoFillOverrides,
  'input:-webkit-autofill:focus': autoFillOverrides,
  'textarea:-webkit-autofill': autoFillOverrides,
  'textarea:-webkit-autofill:hover': autoFillOverrides,
  'textarea:-webkit-autofill:focus': autoFillOverrides,
  'select:-webkit-autofill': autoFillOverrides,
  'select:-webkit-autofill:hover': autoFillOverrides,
  'select:-webkit-autofill:focus ': autoFillOverrides,
  '.text-center': {
    textAlign: 'center'
  },
  '.text-left': {
    textAlign: 'left'
  },
  '.mtb3': {
    marginTop: spacer.spacer3,
    marginBottom: spacer.spacer3
  },
  '.mtb2': {
    marginTop: spacer.spacer2,
    marginBottom: spacer.spacer2
  },
  '.flex-rows': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  '.flex-rows-equal-width': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    '& > div': {
      flexGrow: 1,
      flexBasis: 0
    }
  },
  '.flex-rows-equal-width-center-center': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    '& > div': {
      flexGrow: 1,
      flexBasis: 0
    }
  },
  '.flex-columns': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  '.flex-columns-center-center': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  '.full-screen': {
    width: '100% !important',
    marginLeft: '0 !important'
  }
};
export default globalRules;