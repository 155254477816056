import { Action } from '../core';
const STAFF_PROVIDERS_ASSIGNED_GET = {
  entity: 'STAFF_PROVIDERS_ASSIGNED',
  action: Action.GET
};
const STAFF_PROVIDERS_EDIT = {
  entity: 'STAFF_PROVIDERS',
  action: Action.UPDATE
};
const STAFF_PROVIDERS_NONASSIGNED_GET = {
  entity: 'STAFF_PROVIDERS_NONASSIGNED',
  action: Action.GET
};
const STAFF_RESOURCES_FILTER = {
  entity: 'STAFF_RESOURCES',
  action: Action.GET
};
export { STAFF_PROVIDERS_ASSIGNED_GET, STAFF_PROVIDERS_EDIT, STAFF_PROVIDERS_NONASSIGNED_GET, STAFF_RESOURCES_FILTER };