import { StateStatus } from './StateStatus';
const stateHasItems = obj => obj && obj === StateStatus.LOADED && obj.data && obj.data.length > 0;
const stateIsEmpty = obj => {
  var _obj$data;
  return (obj === null || obj === void 0 ? void 0 : obj.status) === StateStatus.LOADED && !(obj !== null && obj !== void 0 && (_obj$data = obj.data) !== null && _obj$data !== void 0 && _obj$data.length);
};
const stateIsLoading = obj => {
  if (Array.isArray(obj)) {
    return obj.some(x => x.status === StateStatus.LOADING);
  }
  return !obj || obj.status === StateStatus.LOADING;
};
const stateIsNotInitialized = obj => !obj || obj.status === StateStatus.NOT_INITIALIZED;
const stateIsNotReady = obj => {
  if (Array.isArray(obj)) {
    return obj.some(x => x.status <= StateStatus.LOADING || x.status === StateStatus.REFRESHING);
  }
  return !obj || obj.status <= StateStatus.LOADING || obj.status === StateStatus.REFRESHING;
};
const stateIsRefreshing = obj => obj && obj.status === StateStatus.REFRESHING;
const stateIsLoaded = obj => {
  if (Array.isArray(obj)) {
    return obj.some(x => x.status === StateStatus.LOADED);
  }
  return obj && obj.status === StateStatus.LOADED;
};
const stateError = obj => {
  if (Array.isArray(obj)) {
    return obj.some(x => x.status === StateStatus.ERROR);
  }
  return obj && obj.status === StateStatus.ERROR;
};
export { stateHasItems, stateIsEmpty, stateIsLoading, stateIsNotInitialized, stateIsNotReady, stateIsLoaded, stateIsRefreshing, stateError };