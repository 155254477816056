import { Action } from '../core/Action';
const CONTACT = {
  entity: 'CONTACT',
  action: Action.GET
};
const CUSTOMER_COURSES = {
  entity: 'COURSES',
  action: Action.GET_MANY
};
const CUSTOMER_COURSE = {
  entity: 'COURSE',
  action: Action.GET
};
const CUSTOMER_ORDER = {
  entity: 'ORDER',
  action: Action.GET
};
const CUSTOMER_ORDERS = {
  entity: 'CUSTOMER_ORDERS',
  action: Action.GET_MANY
};
const CUSTOMER_PRODUCT = {
  entity: 'CUSTOMER_PRODUCT',
  action: Action.GET
};
const CUSTOMER_FILES = {
  entity: 'CUSTOMER_FILES',
  action: Action.GET_MANY
};
const CUSTOMER_FILE_SEEN = {
  entity: 'CUSTOMER_FILE_SEEN',
  action: Action.GET
};
const COURSE_SECTIONS = {
  entity: 'COURSE_SECTIONS',
  action: Action.GET_MANY
};
const CUSTOMER_PRODUCT_BUY = {
  entity: 'CUSTOMER_PRODUCT_BUY',
  action: Action.CREATE
};
const CUSTOMERS_GET_MANY = {
  entity: 'CUSTOMERS',
  action: Action.GET_MANY
};
const CUSTOMER_BOOKING_EVENTS_GET_MANY = {
  entity: 'CUSTOMER_BOOKING_EVENTS',
  action: Action.GET_MANY
};
export { CONTACT, CUSTOMER_COURSES, CUSTOMER_ORDER, CUSTOMER_COURSE, CUSTOMER_ORDERS, CUSTOMER_PRODUCT, CUSTOMER_FILES, CUSTOMER_FILE_SEEN, COURSE_SECTIONS, CUSTOMER_PRODUCT_BUY, CUSTOMERS_GET_MANY, CUSTOMER_BOOKING_EVENTS_GET_MANY };