import { createUserWorkoutProgressApiCall, getUserWorkoutProgressDetailsApiCall, getUserWorkoutProgressForMonthApiCall, getUserWorkoutProgressMonthsApiCall, getUserWorkoutProgressPeriodApiCall } from '@guryou/api-client-gql';
import { resetState } from '../actions';
import { execute } from '../actions/actionWrapper';
import { Action, basicReducer } from '../core';
export const CREATE_WORKOUT_PROGRESS = {
  entity: 'WORKOUT_PROGRESS',
  action: Action.CREATE
};
export const GET_WORKOUT_PROGRESS = {
  entity: 'WORKOUT_PROGRESS',
  action: Action.GET
};
export const GET_WORKOUT_PROGRESS_PERIOD = {
  entity: 'WORKOUT_PROGRESS_PERIOD',
  action: Action.GET
};
export const GET_WORKOUT_PROGRESS_MONTHS = {
  entity: 'WORKOUT_PROGRESS_MONTHS',
  action: Action.GET
};
export const GET_WORKOUT_PROGRESS_FOR_MONTH = {
  entity: 'WORKOUT_PROGRESS_FOR_MONTH',
  action: Action.GET
};
export const createUserWorkoutProgress = request => async dispatch => execute(dispatch, () => createUserWorkoutProgressApiCall({
  request
}), CREATE_WORKOUT_PROGRESS, false);
export const getUserWorkoutProgressDetails = request => async dispatch => execute(dispatch, () => getUserWorkoutProgressDetailsApiCall({
  request
}), GET_WORKOUT_PROGRESS, false);
export const getUserWorkoutProgressPeriod = periodDays => async dispatch => execute(dispatch, () => getUserWorkoutProgressPeriodApiCall({
  periodDays
}), GET_WORKOUT_PROGRESS_PERIOD, false);
export const getUserWorkoutProgressMonths = () => async dispatch => execute(dispatch, () => getUserWorkoutProgressMonthsApiCall(), GET_WORKOUT_PROGRESS_MONTHS, false);
export const getUserWorkoutProgressForMonth = date => async dispatch => execute(dispatch, () => getUserWorkoutProgressForMonthApiCall({
  date
}, {
  workout: ['id', 'title']
}), GET_WORKOUT_PROGRESS_FOR_MONTH, false);
export const resetCreateUserWorkoutProgress = () => async dispatch => dispatch(resetState(CREATE_WORKOUT_PROGRESS));
export const workoutProgressReducer = {
  create: basicReducer(CREATE_WORKOUT_PROGRESS),
  getPeriod: basicReducer(GET_WORKOUT_PROGRESS_PERIOD),
  getMonths: basicReducer(GET_WORKOUT_PROGRESS_MONTHS),
  getForMonth: basicReducer(GET_WORKOUT_PROGRESS_FOR_MONTH)
};