import { Action } from '../core/Action';
const ARCHIVED_PRODUCTS = {
  entity: 'ARCHIVED_PRODUCTS',
  action: Action.GET
};
const PRODUCT_ARCHIVE = {
  entity: 'PRODUCT',
  action: Action.ARCHIVE
};
const PRODUCT_CREATE = {
  entity: 'PRODUCT',
  action: Action.CREATE
};
const PRODUCT_FILE_PREVIEW = {
  entity: 'PRODUCT_FILE_PREVIEW',
  action: Action.GET
};
const PRODUCT_GET = {
  entity: 'PRODUCT',
  action: Action.GET
};
const PRODUCT_GROUP_CREATE = {
  entity: 'PRODUCT_GROUP',
  action: Action.CREATE
};
const PRODUCT_GROUP_DELETE = {
  entity: 'PRODUCT_GROUP',
  action: Action.DELETE
};
const PRODUCT_GROUP_UPDATE = {
  entity: 'PRODUCT_GROUP',
  action: Action.UPDATE
};
const PRODUCT_GROUPS_GET = {
  entity: 'PRODUCT_GROUPS',
  action: Action.GET
};
const PRODUCT_S3 = {
  entity: 'PRODUCT_S3',
  action: Action.CREATE
};
const PRODUCT_SECTION_CREATE = {
  entity: 'PRODUCT_SECTION',
  action: Action.CREATE
};
const PRODUCT_SECTION_DELETE = {
  entity: 'PRODUCT_SECTION',
  action: Action.DELETE
};
const PRODUCT_SECTION_UPDATE = {
  entity: 'PRODUCT_SECTION',
  action: Action.UPDATE
};
const PRODUCT_STATUS_UPDATE = {
  entity: 'PRODUCT_STATUS',
  action: Action.UPDATE
};
const PRODUCT_UPDATE = {
  entity: 'PRODUCT',
  action: Action.UPDATE
};
const PRODUCT_VIDEO_CREATE = {
  entity: 'PRODUCT_VIDEO',
  action: Action.CREATE
};
const PRODUCT_VIDEO_DELETE = {
  entity: 'PRODUCT_VIDEO',
  action: Action.DELETE
};
const PRODUCT_VIDEO_UPDATE = {
  entity: 'PRODUCT_VIDEO',
  action: Action.UPDATE
};
const PRODUCT_VIDEOS_GET_MANY = {
  entity: 'PRODUCT_VIDEOS',
  action: Action.GET_MANY
};
const PRODUCTS_GET_MANY = {
  entity: 'PRODUCT',
  action: Action.GET_MANY
};
const PRODUCTS_SECTIONS_GET_MANY = {
  entity: 'SECTIONS',
  action: Action.GET_MANY
};
const PRODUCT_FILES = {
  entity: 'PRODUCT_FILES',
  action: Action.GET_MANY
};
const RESTORE_PRODUCT = {
  entity: 'RESTORE_PRODUCT',
  action: Action.UPDATE
};
const SECTION_VIDEOS_GET = {
  entity: 'SECTION_VIDEOS',
  action: Action.GET
};
const SUB_SERVICES_REORDER = {
  entity: 'SUB_SERVICES_REORDER',
  action: Action.UPDATE
};
const CLASSES_REORDER = {
  entity: 'CLASSES_REORDER',
  action: Action.UPDATE
};
export { ARCHIVED_PRODUCTS, PRODUCT_ARCHIVE, PRODUCT_CREATE, PRODUCT_FILE_PREVIEW, PRODUCT_GET, PRODUCT_GROUP_CREATE, PRODUCT_GROUP_DELETE, PRODUCT_GROUP_UPDATE, PRODUCT_GROUPS_GET, PRODUCT_S3, PRODUCT_SECTION_CREATE, PRODUCT_SECTION_DELETE, PRODUCT_SECTION_UPDATE, PRODUCT_STATUS_UPDATE, PRODUCT_UPDATE, PRODUCT_VIDEO_CREATE, PRODUCT_VIDEO_DELETE, PRODUCT_VIDEO_UPDATE, PRODUCT_VIDEOS_GET_MANY, PRODUCTS_GET_MANY, PRODUCTS_SECTIONS_GET_MANY, PRODUCT_FILES, RESTORE_PRODUCT, SECTION_VIDEOS_GET, SUB_SERVICES_REORDER, CLASSES_REORDER };