import { assignUserApiCall, editUserByAdminApiCall, getAllFreeUsersApiCall, getAllUsersApiCall, getCognitoUserDataApiCall, getOwnerProvidersApiCall, getOwnerResourcesApiCall, getTop100MostActiveUsersApiCall, listAllUsersApiCall, resetUserCognitoPasswordApiCall, setDefaultUserPasswordApiCall, toggleUserStatusApiCall, toggleUserStatusByAdminApiCall, validateAppVersionApiCall } from '@guryou/api-client-gql';
import { execute } from '../actions/actionWrapper';
import { resetState } from '../actions/resetActions';
import { Action, basicReducer } from '../core';

// Types
export const USERS = {
  entity: 'USERS',
  action: Action.GET_MANY
};
export const TOGGLE_USER_STATUS = {
  entity: 'TOGGLE_USER_STATUS',
  action: Action.HANDLE
};
export const TOGGLE_USER_STATUS_BY_OWNER = {
  entity: 'TOGGLE_USER_STATUS_BY_OWNER',
  action: Action.HANDLE
};
export const ASSIGN_USER = {
  entity: 'ASSIGN_USER',
  action: Action.HANDLE
};
export const OWNER_RESOURCES = {
  entity: 'OWNER_RESOURCES',
  action: Action.GET_MANY
};
export const OWNER_PROVIDERS = {
  entity: 'OWNER_PROVIDERS',
  action: Action.GET_MANY
};
export const APP_VERSION = {
  entity: 'APP_VERSION',
  action: Action.GET
};
export const ALL_FREE_USERS = {
  entity: 'ALL_FREE_USERS',
  action: Action.GET_MANY
};
export const ALL_USERS = {
  entity: 'ALL_USERS',
  action: Action.GET_MANY
};
export const TOP_10_MOST_ACTIVE_USERS = {
  entity: 'TOP_10_MOST_ACTIVE_USERS',
  action: Action.GET_MANY
};
export const EDIT_USER_BY_ADMIN = {
  entity: 'EDIT_USER_BY_ADMIN',
  action: Action.UPDATE
};
export const SET_DEFAULT_PASSWORD = {
  entity: 'SET_DEFAULT_PASSWORD',
  action: Action.GET
};
export const GET_COGNITO_USER = {
  entity: 'GET_COGNITO_USER',
  action: Action.GET
};
export const RESET_USER_COGNITO_PASSWORD = {
  entity: 'RESET_USER_COGNITO_PASSWORD',
  action: Action.GET
};

// Actions
export const listAllUsers = () => async dispatch => execute(dispatch, () => listAllUsersApiCall(), USERS, false);
export const toggleUserStatus = (id, status) => async dispatch => execute(dispatch, () => toggleUserStatusApiCall({
  id,
  status
}), TOGGLE_USER_STATUS, false);
export const assignUser = (id, providerId, resourceId, role) => async dispatch => execute(dispatch, () => assignUserApiCall({
  id,
  providerId,
  resourceId,
  role
}), ASSIGN_USER);
export const getOwnerResources = () => async dispatch => execute(dispatch, () => getOwnerResourcesApiCall(), OWNER_RESOURCES, false);
export const getOwnerProviders = () => async dispatch => execute(dispatch, () => getOwnerProvidersApiCall(), OWNER_PROVIDERS, false);
export const checkAppVersion = version => async dispatch => execute(dispatch, () => validateAppVersionApiCall({
  version
}), APP_VERSION);
export const getAllFreeUsers = () => async dispatch => execute(dispatch, () => getAllFreeUsersApiCall(), ALL_FREE_USERS);
export const getAllUsers = function () {
  let silent = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  return async dispatch => execute(dispatch, () => getAllUsersApiCall(), ALL_USERS, false, null, silent);
};
export const getTop100MostActiveUsers = function (startDate, endDate) {
  let silent = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return async dispatch => execute(dispatch, () => getTop100MostActiveUsersApiCall({
    startDate,
    endDate
  }), TOP_10_MOST_ACTIVE_USERS, false, null, silent);
};
export const editUserByAdmin = data => async dispatch => {
  const {
    id,
    ...rest
  } = data;
  const request = {
    id: +id,
    ...rest
  };
  execute(dispatch, () => editUserByAdminApiCall({
    request
  }), EDIT_USER_BY_ADMIN);
};
export const toggleUserStatusByAdmin = (id, status) => async dispatch => execute(dispatch, () => toggleUserStatusByAdminApiCall({
  id,
  status
}), TOGGLE_USER_STATUS_BY_OWNER);
export const setDefaultUserPassword = email => async dispatch => execute(dispatch, () => setDefaultUserPasswordApiCall({
  email
}), SET_DEFAULT_PASSWORD);
export const getCognitoUserData = email => async dispatch => execute(dispatch, () => getCognitoUserDataApiCall({
  email
}), GET_COGNITO_USER);
export const resetUserCognitoPassword = email => async dispatch => execute(dispatch, () => resetUserCognitoPasswordApiCall({
  email
}), RESET_USER_COGNITO_PASSWORD);

// Reset actions
export const resetAppVersionState = () => async dispatch => dispatch(resetState(APP_VERSION));

// Reducers
export const usersReducers = {
  all: basicReducer(USERS),
  status: basicReducer(TOGGLE_USER_STATUS),
  assign: basicReducer(ASSIGN_USER),
  ownerResources: basicReducer(OWNER_RESOURCES),
  ownerProviders: basicReducer(OWNER_PROVIDERS),
  version: basicReducer(APP_VERSION),
  freeUsers: basicReducer(ALL_FREE_USERS)
};