import { apiCall } from './gqlClient';
const LOGIN_QUERY = "\n  query login($email: String!, $password: String!, $appName: String, $appVersion: String, $appOs: String) {\n    login(email: $email, password: $password, appName: $appName, appVersion: $appVersion, appOs: $appOs)\n    {\n      success\n\t\t\taccessToken\n\t\t\texpirationTimestamp\n\t\t\trefreshToken\n\t\t\tuserId\n\t\t\tuserRole\n\t\t\terrorCode\n      ...fragments\n    }\n  }";
export const loginApiCall = async function (_ref) {
  let {
    email,
    password,
    appName,
    appVersion,
    appOs
  } = _ref;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: LOGIN_QUERY,
    variables: {
      email,
      password,
      appName,
      appVersion,
      appOs
    },
    fragments
  });
};
const REFRESHTOKEN_QUERY = "\n  query refreshToken($refToken: String!, $providerId: Int, $appVersion: String, $appName: String) {\n    refreshToken(refToken: $refToken, providerId: $providerId, appVersion: $appVersion, appName: $appName)\n    {\n      success\n\t\t\taccessToken\n\t\t\texpirationTimestamp\n\t\t\trefreshToken\n\t\t\tuserId\n\t\t\tuserRole\n\t\t\terrorCode\n      ...fragments\n    }\n  }";
export const refreshTokenApiCall = async function (_ref2) {
  let {
    refToken,
    providerId,
    appVersion,
    appName
  } = _ref2;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: REFRESHTOKEN_QUERY,
    variables: {
      refToken,
      providerId,
      appVersion,
      appName
    },
    fragments
  });
};
const GETCURRENTUSER_QUERY = "\n  query getCurrentUser {\n    getCurrentUser\n    {\n      id\n\t\t\tresourceId\n\t\t\townerId\n\t\t\townerIds\n\t\t\tname\n\t\t\tfirstname\n\t\t\tlastname\n\t\t\tfullname\n\t\t\tgender\n\t\t\tbirthday\n\t\t\taddress\n\t\t\tcity\n\t\t\tpostcode\n\t\t\tcountry\n\t\t\ttags\n\t\t\turl\n\t\t\tfacebook\n\t\t\tphone\n\t\t\temail\n\t\t\trole\n\t\t\tcode\n\t\t\tcodeExpiration\n\t\t\tactive\n\t\t\timage\n\t\t\tstatus\n\t\t\tproviderId\n\t\t\tprovidersIds\n\t\t\tuserLanguage\n\t\t\tblacklisted\n\t\t\tblockContent\n\t\t\tdescribedName\n\t\t\tnote\n\t\t\tcodiceFiscale\n\t\t\tpiva\n\t\t\tsdi\n\t\t\tcreated\n\t\t\tordersNumber\n\t\t\tcustomerId\n\t\t\tsvcGroupId\n\t\t\tgroupColor\n      ...fragments\n    }\n  }";
export const getCurrentUserApiCall = async function () {
  let fragments = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  return apiCall({
    query: GETCURRENTUSER_QUERY,
    variables: {},
    fragments
  });
};
const LISTALLUSERS_QUERY = "\n  query listAllUsers {\n    listAllUsers\n    {\n      id\n\t\t\tresourceId\n\t\t\townerId\n\t\t\townerIds\n\t\t\tname\n\t\t\tfirstname\n\t\t\tlastname\n\t\t\tfullname\n\t\t\tgender\n\t\t\tbirthday\n\t\t\taddress\n\t\t\tcity\n\t\t\tpostcode\n\t\t\tcountry\n\t\t\ttags\n\t\t\turl\n\t\t\tfacebook\n\t\t\tphone\n\t\t\temail\n\t\t\trole\n\t\t\tcode\n\t\t\tcodeExpiration\n\t\t\tactive\n\t\t\timage\n\t\t\tstatus\n\t\t\tproviderId\n\t\t\tprovidersIds\n\t\t\tuserLanguage\n\t\t\tblacklisted\n\t\t\tblockContent\n\t\t\tdescribedName\n\t\t\tnote\n\t\t\tcodiceFiscale\n\t\t\tpiva\n\t\t\tsdi\n\t\t\tcreated\n\t\t\tordersNumber\n\t\t\tcustomerId\n\t\t\tsvcGroupId\n\t\t\tgroupColor\n      ...fragments\n    }\n  }";
export const listAllUsersApiCall = async function () {
  let fragments = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  return apiCall({
    query: LISTALLUSERS_QUERY,
    variables: {},
    fragments
  });
};
const GETOWNERPROVIDERS_QUERY = "\n  query getOwnerProviders {\n    getOwnerProviders\n    {\n      id\n\t\t\tcityId\n\t\t\tname\n\t\t\ttype\n\t\t\tdescription\n\t\t\taddress\n\t\t\tpostcode\n\t\t\tcountry\n\t\t\tphone\n\t\t\tlatitude\n\t\t\tlongitude\n\t\t\tacceptPayments\n\t\t\tonlineDiscount\n\t\t\ttime\n\t\t\ttimeNote\n\t\t\ttags\n\t\t\turl\n\t\t\tblog\n\t\t\tfacebook\n\t\t\ttwitter\n\t\t\tlinkedin\n\t\t\tskype\n\t\t\tinstagram\n\t\t\tyoutube\n\t\t\temail\n\t\t\trole\n\t\t\tcreated\n\t\t\tactive\n\t\t\tsubscribed\n\t\t\tuserHasMembership\n\t\t\tmembershipsCounter\n\t\t\tfreeCancellationHours\n\t\t\treservationOffset\n\t\t\thasConnectedPaymentAccount\n\t\t\tdefaultTimeSlotStep\n\t\t\tlanguage\n\t\t\townerId\n\t\t\tlocked\n\t\t\tfree\n\t\t\tordersNumber\n\t\t\tnotificationEmail\n\t\t\tappName\n      ...fragments\n    }\n  }";
export const getOwnerProvidersApiCall = async function () {
  let fragments = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  return apiCall({
    query: GETOWNERPROVIDERS_QUERY,
    variables: {},
    fragments
  });
};
const VALIDATEAPPVERSION_QUERY = "\n  query validateAppVersion($version: String!) {\n    validateAppVersion(version: $version)\n    {\n      success\n\t\t\tmessage\n\t\t\tid\n\t\t\tid2\n\t\t\tname\n\t\t\tstatus\n      ...fragments\n    }\n  }";
export const validateAppVersionApiCall = async function (_ref3) {
  let {
    version
  } = _ref3;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: VALIDATEAPPVERSION_QUERY,
    variables: {
      version
    },
    fragments
  });
};
const GETALLFREEUSERS_QUERY = "\n  query getAllFreeUsers {\n    getAllFreeUsers\n    {\n      id\n\t\t\tresourceId\n\t\t\townerId\n\t\t\townerIds\n\t\t\tname\n\t\t\tfirstname\n\t\t\tlastname\n\t\t\tfullname\n\t\t\tgender\n\t\t\tbirthday\n\t\t\taddress\n\t\t\tcity\n\t\t\tpostcode\n\t\t\tcountry\n\t\t\ttags\n\t\t\turl\n\t\t\tfacebook\n\t\t\tphone\n\t\t\temail\n\t\t\trole\n\t\t\tcode\n\t\t\tcodeExpiration\n\t\t\tactive\n\t\t\timage\n\t\t\tstatus\n\t\t\tproviderId\n\t\t\tprovidersIds\n\t\t\tuserLanguage\n\t\t\tblacklisted\n\t\t\tblockContent\n\t\t\tdescribedName\n\t\t\tnote\n\t\t\tcodiceFiscale\n\t\t\tpiva\n\t\t\tsdi\n\t\t\tcreated\n\t\t\tordersNumber\n\t\t\tcustomerId\n\t\t\tsvcGroupId\n\t\t\tgroupColor\n      ...fragments\n    }\n  }";
export const getAllFreeUsersApiCall = async function () {
  let fragments = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  return apiCall({
    query: GETALLFREEUSERS_QUERY,
    variables: {},
    fragments
  });
};
const GETALLUSERS_QUERY = "\n  query getAllUsers {\n    getAllUsers\n    {\n      id\n\t\t\tname\n\t\t\tphone\n\t\t\temail\n\t\t\trole\n\t\t\tactive\n\t\t\timage\n\t\t\tcodiceFiscale\n\t\t\tpiva\n\t\t\tsdi\n\t\t\tcreated\n      ...fragments\n    }\n  }";
export const getAllUsersApiCall = async function () {
  let fragments = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  return apiCall({
    query: GETALLUSERS_QUERY,
    variables: {},
    fragments
  });
};
const GETTOP100MOSTACTIVEUSERS_QUERY = "\n  query getTop100MostActiveUsers($startDate: DateTime!, $endDate: DateTime!) {\n    getTop100MostActiveUsers(startDate: $startDate, endDate: $endDate)\n    {\n      id\n\t\t\tresourceId\n\t\t\townerId\n\t\t\townerIds\n\t\t\tname\n\t\t\tfirstname\n\t\t\tlastname\n\t\t\tfullname\n\t\t\tgender\n\t\t\tbirthday\n\t\t\taddress\n\t\t\tcity\n\t\t\tpostcode\n\t\t\tcountry\n\t\t\ttags\n\t\t\turl\n\t\t\tfacebook\n\t\t\tphone\n\t\t\temail\n\t\t\trole\n\t\t\tcode\n\t\t\tcodeExpiration\n\t\t\tactive\n\t\t\timage\n\t\t\tstatus\n\t\t\tproviderId\n\t\t\tprovidersIds\n\t\t\tuserLanguage\n\t\t\tblacklisted\n\t\t\tblockContent\n\t\t\tdescribedName\n\t\t\tnote\n\t\t\tcodiceFiscale\n\t\t\tpiva\n\t\t\tsdi\n\t\t\tcreated\n\t\t\tordersNumber\n\t\t\tcustomerId\n\t\t\tsvcGroupId\n\t\t\tgroupColor\n      ...fragments\n    }\n  }";
export const getTop100MostActiveUsersApiCall = async function (_ref4) {
  let {
    startDate,
    endDate
  } = _ref4;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: GETTOP100MOSTACTIVEUSERS_QUERY,
    variables: {
      startDate,
      endDate
    },
    fragments
  });
};
const GETUSERSFORDELETE_QUERY = "\n  query getUsersForDelete {\n    getUsersForDelete\n  }";
export const getUsersForDeleteApiCall = async () => apiCall({
  query: GETUSERSFORDELETE_QUERY,
  variables: {}
});
const SETDEFAULTUSERPASSWORD_QUERY = "\n  query setDefaultUserPassword($email: String!) {\n    setDefaultUserPassword(email: $email)\n    {\n      success\n\t\t\tmessage\n\t\t\tid\n\t\t\tid2\n\t\t\tname\n\t\t\tstatus\n      ...fragments\n    }\n  }";
export const setDefaultUserPasswordApiCall = async function (_ref5) {
  let {
    email
  } = _ref5;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: SETDEFAULTUSERPASSWORD_QUERY,
    variables: {
      email
    },
    fragments
  });
};
const GETCOGNITOUSERDATA_QUERY = "\n  query getCognitoUserData($email: String!) {\n    getCognitoUserData(email: $email)\n  }";
export const getCognitoUserDataApiCall = async _ref6 => {
  let {
    email
  } = _ref6;
  return apiCall({
    query: GETCOGNITOUSERDATA_QUERY,
    variables: {
      email
    }
  });
};
const RESETUSERCOGNITOPASSWORD_QUERY = "\n  query resetUserCognitoPassword($email: String!) {\n    resetUserCognitoPassword(email: $email)\n  }";
export const resetUserCognitoPasswordApiCall = async _ref7 => {
  let {
    email
  } = _ref7;
  return apiCall({
    query: RESETUSERCOGNITOPASSWORD_QUERY,
    variables: {
      email
    }
  });
};
const CONFIRMUSER_QUERY = "\n  mutation confirmUser($id: Int!) {\n    confirmUser(id: $id)\n    {\n      success\n\t\t\tmessage\n\t\t\tid\n\t\t\tid2\n\t\t\tname\n\t\t\tstatus\n      ...fragments\n    }\n  }";
export const confirmUserApiCall = async function (_ref8) {
  let {
    id
  } = _ref8;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: CONFIRMUSER_QUERY,
    variables: {
      id
    },
    fragments
  });
};
const UPDATEUSERPROFILE_QUERY = "\n  mutation updateUserProfile($request: UserInput!) {\n    updateUserProfile(request: $request)\n    {\n      success\n\t\t\tmessage\n\t\t\tid\n\t\t\tid2\n\t\t\tname\n\t\t\tstatus\n      ...fragments\n    }\n  }";
export const updateUserProfileApiCall = async function (_ref9) {
  let {
    request
  } = _ref9;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: UPDATEUSERPROFILE_QUERY,
    variables: {
      request
    },
    fragments
  });
};
const CHANGEUSERAVATAR_QUERY = "\n  mutation changeUserAvatar($fileType: String!) {\n    changeUserAvatar(fileType: $fileType)\n    {\n      id\n\t\t\tpresignedUrl\n\t\t\tsignedUrl\n\t\t\tsuccess\n\t\t\tname\n\t\t\tsectionIndex\n      ...fragments\n    }\n  }";
export const changeUserAvatarApiCall = async function (_ref10) {
  let {
    fileType
  } = _ref10;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: CHANGEUSERAVATAR_QUERY,
    variables: {
      fileType
    },
    fragments
  });
};
const TOGGLEUSERSTATUS_QUERY = "\n  mutation toggleUserStatus($id: Int!, $status: Boolean!) {\n    toggleUserStatus(id: $id, status: $status)\n    {\n      success\n\t\t\tmessage\n\t\t\tid\n\t\t\tid2\n\t\t\tname\n\t\t\tstatus\n      ...fragments\n    }\n  }";
export const toggleUserStatusApiCall = async function (_ref11) {
  let {
    id,
    status
  } = _ref11;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: TOGGLEUSERSTATUS_QUERY,
    variables: {
      id,
      status
    },
    fragments
  });
};
const ASSIGNUSER_QUERY = "\n  mutation assignUser($id: Int!, $providerId: Int!, $resourceId: Int, $role: String) {\n    assignUser(id: $id, providerId: $providerId, resourceId: $resourceId, role: $role)\n    {\n      success\n\t\t\tmessage\n\t\t\tid\n\t\t\tid2\n\t\t\tname\n\t\t\tstatus\n      ...fragments\n    }\n  }";
export const assignUserApiCall = async function (_ref12) {
  let {
    id,
    providerId,
    resourceId,
    role
  } = _ref12;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: ASSIGNUSER_QUERY,
    variables: {
      id,
      providerId,
      resourceId,
      role
    },
    fragments
  });
};
const INVITEUSER_QUERY = "\n  mutation inviteUser($email: String!, $firstName: String!, $lastName: String!, $role: String!) {\n    inviteUser(email: $email, firstName: $firstName, lastName: $lastName, role: $role)\n    {\n      id\n\t\t\tresourceId\n\t\t\townerId\n\t\t\townerIds\n\t\t\tname\n\t\t\tfirstname\n\t\t\tlastname\n\t\t\tfullname\n\t\t\tgender\n\t\t\tbirthday\n\t\t\taddress\n\t\t\tcity\n\t\t\tpostcode\n\t\t\tcountry\n\t\t\ttags\n\t\t\turl\n\t\t\tfacebook\n\t\t\tphone\n\t\t\temail\n\t\t\trole\n\t\t\tcode\n\t\t\tcodeExpiration\n\t\t\tactive\n\t\t\timage\n\t\t\tstatus\n\t\t\tproviderId\n\t\t\tprovidersIds\n\t\t\tuserLanguage\n\t\t\tblacklisted\n\t\t\tblockContent\n\t\t\tdescribedName\n\t\t\tnote\n\t\t\tcodiceFiscale\n\t\t\tpiva\n\t\t\tsdi\n\t\t\tcreated\n\t\t\tordersNumber\n\t\t\tcustomerId\n\t\t\tsvcGroupId\n\t\t\tgroupColor\n      ...fragments\n    }\n  }";
export const inviteUserApiCall = async function (_ref13) {
  let {
    email,
    firstName,
    lastName,
    role
  } = _ref13;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: INVITEUSER_QUERY,
    variables: {
      email,
      firstName,
      lastName,
      role
    },
    fragments
  });
};
const INVITEANDCREATECOLLABORATOR_QUERY = "\n  mutation inviteAndCreateCollaborator($email: String!, $firstName: String!, $lastName: String!, $role: String!, $resourceId: Int) {\n    inviteAndCreateCollaborator(email: $email, firstName: $firstName, lastName: $lastName, role: $role, resourceId: $resourceId)\n    {\n      success\n\t\t\tmessage\n\t\t\tid\n\t\t\tid2\n\t\t\tname\n\t\t\tstatus\n      ...fragments\n    }\n  }";
export const inviteAndCreateCollaboratorApiCall = async function (_ref14) {
  let {
    email,
    firstName,
    lastName,
    role,
    resourceId
  } = _ref14;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: INVITEANDCREATECOLLABORATOR_QUERY,
    variables: {
      email,
      firstName,
      lastName,
      role,
      resourceId
    },
    fragments
  });
};
const CREATEUSER_QUERY = "\n  mutation createUser($email: String!, $firstName: String!, $lastName: String!, $agreements: [AgreementInput]!, $role: String!) {\n    createUser(email: $email, firstName: $firstName, lastName: $lastName, agreements: $agreements, role: $role)\n    {\n      id\n\t\t\tresourceId\n\t\t\townerId\n\t\t\townerIds\n\t\t\tname\n\t\t\tfirstname\n\t\t\tlastname\n\t\t\tfullname\n\t\t\tgender\n\t\t\tbirthday\n\t\t\taddress\n\t\t\tcity\n\t\t\tpostcode\n\t\t\tcountry\n\t\t\ttags\n\t\t\turl\n\t\t\tfacebook\n\t\t\tphone\n\t\t\temail\n\t\t\trole\n\t\t\tcode\n\t\t\tcodeExpiration\n\t\t\tactive\n\t\t\timage\n\t\t\tstatus\n\t\t\tproviderId\n\t\t\tprovidersIds\n\t\t\tuserLanguage\n\t\t\tblacklisted\n\t\t\tblockContent\n\t\t\tdescribedName\n\t\t\tnote\n\t\t\tcodiceFiscale\n\t\t\tpiva\n\t\t\tsdi\n\t\t\tcreated\n\t\t\tordersNumber\n\t\t\tcustomerId\n\t\t\tsvcGroupId\n\t\t\tgroupColor\n      ...fragments\n    }\n  }";
export const createUserApiCall = async function (_ref15) {
  let {
    email,
    firstName,
    lastName,
    agreements,
    role
  } = _ref15;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: CREATEUSER_QUERY,
    variables: {
      email,
      firstName,
      lastName,
      agreements,
      role
    },
    fragments
  });
};
const CREATEUSERANDPROVIDER_QUERY = "\n  mutation createUserAndProvider($email: String!, $providerName: String!, $providerType: String!, $category: Int!, $agreements: [AgreementInput]!, $role: String!) {\n    createUserAndProvider(email: $email, providerName: $providerName, providerType: $providerType, category: $category, agreements: $agreements, role: $role)\n    {\n      id\n\t\t\tresourceId\n\t\t\townerId\n\t\t\townerIds\n\t\t\tname\n\t\t\tfirstname\n\t\t\tlastname\n\t\t\tfullname\n\t\t\tgender\n\t\t\tbirthday\n\t\t\taddress\n\t\t\tcity\n\t\t\tpostcode\n\t\t\tcountry\n\t\t\ttags\n\t\t\turl\n\t\t\tfacebook\n\t\t\tphone\n\t\t\temail\n\t\t\trole\n\t\t\tcode\n\t\t\tcodeExpiration\n\t\t\tactive\n\t\t\timage\n\t\t\tstatus\n\t\t\tproviderId\n\t\t\tprovidersIds\n\t\t\tuserLanguage\n\t\t\tblacklisted\n\t\t\tblockContent\n\t\t\tdescribedName\n\t\t\tnote\n\t\t\tcodiceFiscale\n\t\t\tpiva\n\t\t\tsdi\n\t\t\tcreated\n\t\t\tordersNumber\n\t\t\tcustomerId\n\t\t\tsvcGroupId\n\t\t\tgroupColor\n      ...fragments\n    }\n  }";
export const createUserAndProviderApiCall = async function (_ref16) {
  let {
    email,
    providerName,
    providerType,
    category,
    agreements,
    role
  } = _ref16;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: CREATEUSERANDPROVIDER_QUERY,
    variables: {
      email,
      providerName,
      providerType,
      category,
      agreements,
      role
    },
    fragments
  });
};
const RESIGNUP_QUERY = "\n  mutation reSignUp($email: String!) {\n    reSignUp(email: $email)\n    {\n      success\n\t\t\tmessage\n\t\t\tid\n\t\t\tid2\n\t\t\tname\n\t\t\tstatus\n      ...fragments\n    }\n  }";
export const reSignUpApiCall = async function (_ref17) {
  let {
    email
  } = _ref17;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: RESIGNUP_QUERY,
    variables: {
      email
    },
    fragments
  });
};
const UPDATEUSERLOGIN_QUERY = "\n  mutation updateUserLogin($providerId: Int!) {\n    updateUserLogin(providerId: $providerId)\n    {\n      success\n\t\t\tmessage\n\t\t\tid\n\t\t\tid2\n\t\t\tname\n\t\t\tstatus\n      ...fragments\n    }\n  }";
export const updateUserLoginApiCall = async function (_ref18) {
  let {
    providerId
  } = _ref18;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: UPDATEUSERLOGIN_QUERY,
    variables: {
      providerId
    },
    fragments
  });
};
const DEACTIVATEUSERACCOUNT_QUERY = "\n  mutation deactivateUserAccount($forDelete: Boolean) {\n    deactivateUserAccount(forDelete: $forDelete)\n    {\n      success\n\t\t\tmessage\n\t\t\tid\n\t\t\tid2\n\t\t\tname\n\t\t\tstatus\n      ...fragments\n    }\n  }";
export const deactivateUserAccountApiCall = async function (_ref19) {
  let {
    forDelete
  } = _ref19;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: DEACTIVATEUSERACCOUNT_QUERY,
    variables: {
      forDelete
    },
    fragments
  });
};
const REQUESTREACTIVATEUSERACCOUNT_QUERY = "\n  mutation requestReactivateUserAccount($email: String!) {\n    requestReactivateUserAccount(email: $email)\n    {\n      success\n\t\t\tmessage\n\t\t\tid\n\t\t\tid2\n\t\t\tname\n\t\t\tstatus\n      ...fragments\n    }\n  }";
export const requestReactivateUserAccountApiCall = async function (_ref20) {
  let {
    email
  } = _ref20;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: REQUESTREACTIVATEUSERACCOUNT_QUERY,
    variables: {
      email
    },
    fragments
  });
};
const REACTIVATEUSERACCOUNT_QUERY = "\n  mutation reactivateUserAccount($email: String!, $code: String!) {\n    reactivateUserAccount(email: $email, code: $code)\n    {\n      success\n\t\t\tmessage\n\t\t\tid\n\t\t\tid2\n\t\t\tname\n\t\t\tstatus\n      ...fragments\n    }\n  }";
export const reactivateUserAccountApiCall = async function (_ref21) {
  let {
    email,
    code
  } = _ref21;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: REACTIVATEUSERACCOUNT_QUERY,
    variables: {
      email,
      code
    },
    fragments
  });
};
const SAVEUSERREGION_QUERY = "\n  mutation saveUserRegion($region: String!) {\n    saveUserRegion(region: $region)\n    {\n      success\n\t\t\tmessage\n\t\t\tid\n\t\t\tid2\n\t\t\tname\n\t\t\tstatus\n      ...fragments\n    }\n  }";
export const saveUserRegionApiCall = async function (_ref22) {
  let {
    region
  } = _ref22;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: SAVEUSERREGION_QUERY,
    variables: {
      region
    },
    fragments
  });
};
const EDITUSERBYADMIN_QUERY = "\n  mutation editUserByAdmin($request: UserInput!) {\n    editUserByAdmin(request: $request)\n    {\n      success\n\t\t\tmessage\n\t\t\tid\n\t\t\tid2\n\t\t\tname\n\t\t\tstatus\n      ...fragments\n    }\n  }";
export const editUserByAdminApiCall = async function (_ref23) {
  let {
    request
  } = _ref23;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: EDITUSERBYADMIN_QUERY,
    variables: {
      request
    },
    fragments
  });
};
const TOGGLEUSERSTATUSBYADMIN_QUERY = "\n  mutation toggleUserStatusByAdmin($id: Int!, $status: Boolean!) {\n    toggleUserStatusByAdmin(id: $id, status: $status)\n    {\n      success\n\t\t\tmessage\n\t\t\tid\n\t\t\tid2\n\t\t\tname\n\t\t\tstatus\n      ...fragments\n    }\n  }";
export const toggleUserStatusByAdminApiCall = async function (_ref24) {
  let {
    id,
    status
  } = _ref24;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: TOGGLEUSERSTATUSBYADMIN_QUERY,
    variables: {
      id,
      status
    },
    fragments
  });
};
const REMOVEUSERFROMGROUP_QUERY = "\n  mutation removeUserFromGroup($groupId: Int!, $providerId: Int!) {\n    removeUserFromGroup(groupId: $groupId, providerId: $providerId)\n    {\n      success\n\t\t\tmessage\n\t\t\tid\n\t\t\tid2\n\t\t\tname\n\t\t\tstatus\n      ...fragments\n    }\n  }";
export const removeUserFromGroupApiCall = async function (_ref25) {
  let {
    groupId,
    providerId
  } = _ref25;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: REMOVEUSERFROMGROUP_QUERY,
    variables: {
      groupId,
      providerId
    },
    fragments
  });
};
const CREATEUSERNOTIFICATIONENDPOINT_QUERY = "\n  mutation createUserNotificationEndpoint($token: String) {\n    createUserNotificationEndpoint(token: $token)\n    {\n      success\n\t\t\tmessage\n\t\t\tid\n\t\t\tid2\n\t\t\tname\n\t\t\tstatus\n      ...fragments\n    }\n  }";
export const createUserNotificationEndpointApiCall = async function (_ref26) {
  let {
    token
  } = _ref26;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: CREATEUSERNOTIFICATIONENDPOINT_QUERY,
    variables: {
      token
    },
    fragments
  });
};
const ADDFRANCESCOASDIRECTORTOPROVIDER_QUERY = "\n  mutation addFrancescoAsDirectorToProvider($id: Int) {\n    addFrancescoAsDirectorToProvider(id: $id)\n    {\n      success\n\t\t\tmessage\n\t\t\tid\n\t\t\tid2\n\t\t\tname\n\t\t\tstatus\n      ...fragments\n    }\n  }";
export const addFrancescoAsDirectorToProviderApiCall = async function (_ref27) {
  let {
    id
  } = _ref27;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: ADDFRANCESCOASDIRECTORTOPROVIDER_QUERY,
    variables: {
      id
    },
    fragments
  });
};