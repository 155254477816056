import { ActionStatus, buildActionType, StateStatus } from '../core';
const initialState = {
  data: null,
  errors: null,
  status: StateStatus.NOT_INITIALIZED,
  version: 0,
  report: null
};
const sections = function (actionType) {
  let reduceFunction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return function () {
    let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    let action = arguments.length > 1 ? arguments[1] : undefined;
    switch (action.type) {
      case buildActionType(actionType, ActionStatus.START):
        return {
          ...initialState,
          status: StateStatus.LOADING,
          version: state.version,
          entity: actionType.entity
        };
      case buildActionType(actionType, ActionStatus.DONE):
        let payload = action.payload.payload || action.payload;
        const request = action.payload.request;
        if (reduceFunction && typeof reduceFunction === 'function') {
          payload = reduceFunction(payload);
        }
        const data = 'data' in payload ? payload.data : payload;
        return {
          data,
          status: StateStatus.LOADED,
          entity: actionType.entity,
          version: +new Date(),
          report: action.report,
          request
        };
      case buildActionType(actionType, ActionStatus.FAILED):
        return {
          status: StateStatus.ERROR,
          errors: action.payload,
          entity: actionType.entity
        };
      case buildActionType(actionType, ActionStatus.RESET):
        return initialState;
      case buildActionType(actionType, ActionStatus.INCREASE):
        return {
          ...state,
          data: [...state.data.slice(0, action.index), {
            ...state.data[action.index],
            numberOfFiles: parseInt(state.data[action.index].numberOfFiles) + 1
          }, ...state.data.slice(action.index + 1)]
        };
      case buildActionType(actionType, ActionStatus.DECREASE):
        return {
          ...state,
          data: [...state.data.slice(0, action.index), {
            ...state.data[action.index],
            numberOfFiles: parseInt(state.data[action.index].numberOfFiles) - 1
          }, ...state.data.slice(action.index + 1)]
        };
      case buildActionType(actionType, ActionStatus.UPDATE):
        return {
          ...state,
          data: [...state.data.slice(0, action.index), {
            ...state.data[action.index],
            numberOfFiles: 0
          }, ...state.data.slice(action.index + 1)]
        };
      default:
        return state;
    }
  };
};
export { sections };