"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IntervalTypes = void 0;
var IntervalTypes = exports.IntervalTypes = {
  weekly: 'weekly',
  daily: 'daily',
  months: 'months',
  weeks: 'weeks',
  days: 'days'
};