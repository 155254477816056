import { assignFreeUserWorkoutPlanApiCall, getFreeWorkoutPlansApiCall, unassignFreeUserWorkoutPlanApiCall } from '@guryou/api-client-gql';
import { resetState } from '../actions';
import { execute } from '../actions/actionWrapper';
import { Action, basicReducer } from '../core';
export const FREE_WORKOUT_PLANS_GET = {
  entity: 'FREE_WORKOUT_PLANS',
  action: Action.GET_MANY
};
export const ASSIGN_FREE_WORKOUT_PLAN_CREATE = {
  entity: 'ASSIGN_FREE_WORKOUT_PLAN',
  action: Action.CREATE
};
export const UNASSIGN_FREE_WORKOUT_PLAN_UPDATE = {
  entity: 'UNASSIGN_FREE_WORKOUT_PLAN',
  action: Action.UPDATE
};
export const getFreeWorkoutPlans = () => async dispatch => execute(dispatch, () => getFreeWorkoutPlansApiCall({
  workouts: ['id', 'title', 'level']
}), FREE_WORKOUT_PLANS_GET, false);
export const assignFreePlanToUser = id => async dispatch => execute(dispatch, () => assignFreeUserWorkoutPlanApiCall({
  id
}), ASSIGN_FREE_WORKOUT_PLAN_CREATE, false);
export const unassignFreePlanFromUser = id => async dispatch => execute(dispatch, () => unassignFreeUserWorkoutPlanApiCall({
  id
}), UNASSIGN_FREE_WORKOUT_PLAN_UPDATE, false);

//Reset actions
export const resetAssignFreePlan = () => async dispatch => dispatch(resetState(ASSIGN_FREE_WORKOUT_PLAN_CREATE));
export const resetUnassignFreePlan = () => async dispatch => dispatch(resetState(UNASSIGN_FREE_WORKOUT_PLAN_UPDATE));
export const workoutPlansReducer = {
  freePlans: basicReducer(FREE_WORKOUT_PLANS_GET),
  assignFreeToUser: basicReducer(ASSIGN_FREE_WORKOUT_PLAN_CREATE),
  unassignFreeFromUser: basicReducer(UNASSIGN_FREE_WORKOUT_PLAN_UPDATE)
};