import { reSignUpApiCall } from '@guryou/api-client-gql';
import { Auth } from 'aws-amplify';
import { resetState } from './resetActions';
import { ActionStatus, basicReducer, buildActionType, NotificationType, SuccessNotification } from '../core';
import { CURRENT_USER } from '../new';
import { CHANGE_PASSWORD, CHECK_AUTH, FORGOT_PASSWORD, LOGIN, LOGOUT, NOTIFICATION, OWNER, RESEND_SIGN_UP, RESET_PASSWORD, SET_PASSOWRD, SIGN_UP, STORAGE_PERSIST } from '../types';
const NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED';

// TEMP
const SUCCESS_SIGN_UP = 'Iscrizione riuscita';
const PLEASE_CHECK_EMAIL = 'Si prega di controllare la tua email';
const cognitoLogin = function (email, password, persistor) {
  let appName = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : process.env.REACT_APP_NAME_DEAL;
  let appVersion = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : process.env.REACT_APP_VERSION;
  let appOs = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : process.env.REACT_APP_APP_OS;
  return async dispatch => {
    dispatch(startDispatch(LOGIN));
    try {
      const user = await Auth.signIn(email, password, {
        appName: appName,
        appVersion: appVersion,
        appOs: appOs
      });
      if (user.challengeName === NEW_PASSWORD_REQUIRED) {
        return NEW_PASSWORD_REQUIRED;
      }
      const {
        idToken: {
          payload,
          jwtToken
        }
      } = await Auth.currentSession();
      dispatch(successDispatch(LOGIN, {
        ...payload,
        jwtToken
      }, SuccessNotification.SUCCESS));
      if (persistor) {
        await persistor.flush();
      }
      dispatch(successDispatch(STORAGE_PERSIST));
    } catch (e) {
      dispatch(errorDispatch(e, LOGIN));
    }
  };
};
const cognitoForgotPassword = email => async dispatch => {
  dispatch(startDispatch(FORGOT_PASSWORD));
  try {
    const responseData = await Auth.forgotPassword(email);
    dispatch(successDispatch(FORGOT_PASSWORD, responseData, SuccessNotification.SUCCESS));
  } catch (e) {
    dispatch(errorDispatch(e, FORGOT_PASSWORD));
  }
};
const cognitoSetNewPassword = data => async dispatch => {
  dispatch(startDispatch(RESET_PASSWORD));
  const {
    email,
    code,
    newPassword
  } = data;
  try {
    const responseData = await Auth.forgotPasswordSubmit(email, code, newPassword);
    dispatch(successDispatch(RESET_PASSWORD, responseData, SuccessNotification._UPDATE));
  } catch (e) {
    dispatch(errorDispatch(e, RESET_PASSWORD));
  }
};
const cognitoVerifyUser = (email, code) => Auth.signIn(email, code, {
  activate: 'true'
});
const cognitoFirstLoginSetPassword = (_ref, user, persistor) => {
  let {
    newPassword
  } = _ref;
  return async dispatch => {
    dispatch(startDispatch(SET_PASSOWRD));
    try {
      if (user.challengeName === NEW_PASSWORD_REQUIRED) {
        try {
          await Auth.completeNewPassword(user, newPassword);
          const {
            idToken: {
              payload,
              jwtToken
            }
          } = await Auth.currentSession();
          dispatch(successDispatch(SET_PASSOWRD, {
            ...payload,
            jwtToken
          }, SuccessNotification.SUCCESS));
          if (persistor) {
            await persistor.flush();
          }
        } catch (e) {
          dispatch(errorDispatch(e, SET_PASSOWRD));
        }
      }
    } catch (e) {
      dispatch(errorDispatch(e, SET_PASSOWRD));
    }
  };
};
const cognitoSignUp = (email, password) => async dispatch => {
  dispatch(startDispatch(SIGN_UP));
  try {
    const {
      user
    } = await Auth.signUp({
      email,
      password
    });
    dispatch(successDispatch(SIGN_UP, user, SUCCESS_SIGN_UP));
    dispatch({
      type: NOTIFICATION,
      payload: {
        message: PLEASE_CHECK_EMAIL,
        variant: NotificationType.Default
      }
    });
  } catch (e) {
    dispatch(errorDispatch(e, SIGN_UP));
  }
};
const cognitoResendSignUp = email => async dispatch => {
  dispatch(startDispatch(RESEND_SIGN_UP));
  try {
    // const { user } = await Auth.resendSignUp(email);
    const resend = await reSignUpApiCall({
      email
    });
    dispatch(successDispatch(RESEND_SIGN_UP, resend, SUCCESS_SIGN_UP));
    dispatch({
      type: NOTIFICATION,
      payload: {
        message: PLEASE_CHECK_EMAIL,
        variant: NotificationType.Default
      }
    });
  } catch (e) {
    dispatch(errorDispatch(e, RESEND_SIGN_UP));
  }
};
const cognitoChangeUserPassword = (oldPassword, newPassword) => async dispatch => {
  dispatch(startDispatch(CHANGE_PASSWORD));
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, oldPassword, newPassword);
    dispatch(successDispatch(CHANGE_PASSWORD, {}, SuccessNotification.SUCCESS));
  } catch (e) {
    dispatch(errorDispatch(e, CHANGE_PASSWORD));
  }
};
const cognitoLogout = () => async dispatch => {
  try {
    dispatch(resetState(CURRENT_USER));
    dispatch(resetState(OWNER));
    dispatch({
      type: LOGOUT
    });
    await Auth.signOut();
  } catch (e) {
    new Error(e);
  }
};
const checkAuth = () => async dispatch => {
  dispatch(startDispatch(CHECK_AUTH));
  try {
    await Auth.currentAuthenticatedUser();
    dispatch(successDispatch(CHECK_AUTH));
  } catch (e) {
    dispatch(errorDispatch(e, CHECK_AUTH));
  }
};
const resetForgotPassState = () => async dispatch => dispatch(resetState(FORGOT_PASSWORD));
const resetLoginState = () => async dispatch => dispatch(resetState(LOGIN));
const resetCheckAuthState = () => async dispatch => dispatch(resetState(CHECK_AUTH));
export { cognitoResendSignUp, cognitoLogin, cognitoForgotPassword, cognitoSetNewPassword, cognitoLogout, cognitoSignUp, cognitoVerifyUser, cognitoFirstLoginSetPassword, cognitoChangeUserPassword, checkAuth, resetForgotPassState, resetLoginState, resetCheckAuthState };
const startDispatch = action => dispatch => dispatch({
  type: buildActionType(action, ActionStatus.START)
});
const errorDispatch = (error, action) => dispatch => {
  dispatch({
    type: buildActionType(action, ActionStatus.FAILED),
    payload: error
  });
  dispatch({
    type: NOTIFICATION,
    payload: {
      message: error.message,
      variant: NotificationType.Error
    }
  });
};
const successDispatch = function (action) {
  let payload = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  let message = arguments.length > 2 ? arguments[2] : undefined;
  return dispatch => {
    dispatch({
      type: buildActionType(action, ActionStatus.DONE),
      payload
    });
    message && dispatch({
      type: NOTIFICATION,
      payload: {
        message,
        variant: NotificationType.Success
      }
    });
  };
};
export const cognitoReducers = {
  forgotPassword: basicReducer(FORGOT_PASSWORD),
  setNewPassword: basicReducer(RESET_PASSWORD),
  firstLogin: basicReducer(SET_PASSOWRD),
  signUp: basicReducer(SIGN_UP),
  changePassword: basicReducer(CHANGE_PASSWORD),
  resendSignUp: basicReducer(RESEND_SIGN_UP)
};