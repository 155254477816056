import { defaultPalette } from '@guryou/colors';
import { dropShadow, smallBorderRadius, spacer } from '../constants';
const buttonOverrides = {
  MuiButton: {
    root: {
      margin: spacer.spacer1,
      maxHeight: '2rem'
    },
    contained: {
      boxShadow: dropShadow,
      '&:hover': {
        boxShadow: dropShadow
      }
    },
    outlinedSizeLarge: {
      fontSize: '1.2rem',
      padding: "20px ".concat(spacer.spacer5, "px")
    }
  },
  MuiChip: {
    root: {
      borderRadius: spacer.spacer1
    }
  },
  MuiIconButton: {
    label: {
      '& .MuiSwitch-thumb': {
        borderRadius: '15%'
      }
    },
    root: {
      '&.providerIcons': {
        borderRadius: smallBorderRadius,
        border: '1px solid lightgray'
      },
      '&.providerIcons:hover': {
        border: '1px solid lightgray'
      }
    }
  },
  MuiBadge: {
    badge: {
      padding: 0,
      backgroundColor: defaultPalette.background.default
    }
  },
  MuiButtonBase: {
    root: {
      '&.MuiButton-textPrimary': {
        color: '#252525'
      }
    }
  },
  MuiButtonGroup: {
    root: {
      width: '100%',
      '&+div': {
        zIndex: 1
      },
      '& button': {
        margin: 0
      },
      '& .MuiButtonGroup-groupedContainedPrimary:not(:last-child)': {
        borderColor: 'unset'
      }
    }
  }
};
export default buttonOverrides;