import { getCoursesAndEventsApiCall } from '@guryou/api-client-gql';
import { execute } from '../actions/actionWrapper';
import { Action, basicReducer } from '../core';

// Types
export const COURSES_AND_EVENTS_GET_MANY = {
  entity: 'COURSES_AND_EVENTS',
  action: Action.GET_MANY
};
// Actions
export const getCoursesAndEvents = function () {
  let silent = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  return async dispatch => execute(dispatch, () => getCoursesAndEventsApiCall({
    group: ['id', 'name', 'color']
  }), COURSES_AND_EVENTS_GET_MANY, false, null, !silent);
};

// Reducers
export const coursesAndEventsReducer = {
  all: basicReducer(COURSES_AND_EVENTS_GET_MANY)
};