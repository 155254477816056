"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StripePlans = exports.PromoCodeDuration = exports.PaymentTypes = exports.PaymentSettings = exports.PaymentOptionsTypes = void 0;
var PaymentSettings = exports.PaymentSettings = {
  NoPayments: 'off',
  OnlineOnly: 'on',
  Optional: 'on,off',
  Membership: 'membership'
};
var PaymentTypes = exports.PaymentTypes = {
  Membership: 'membership',
  Card: 'card'
};
var StripePlans = exports.StripePlans = {
  Free: 0,
  Grow: 1,
  Video: 2,
  AllInOne: 3
};
var PromoCodeDuration = exports.PromoCodeDuration = {
  forever: 'forever',
  repeating: 'repeating',
  once: 'once'
};
var PaymentOptionsTypes = exports.PaymentOptionsTypes = {
  ServiceOnsite: 'serviceOnsite',
  ServiceOnline: 'serviceOnline',
  ServiceMembership: 'serviceMembership',
  CourseOnsite: 'courseOnsite',
  CourseOnline: 'courseOnline',
  CourseMembership: 'courseMembership',
  MembershipOnsite: 'membershipOnsite',
  MembershipOnline: 'membershipOnline'
};