import { dealThemePalette, defaultPalette, feelThemePalette } from '@guryou/colors';
import { borderDefinition, boxShadow, boxShadowLight, DEFAULT_SCALE, largeBorderRadius, mediumBorderRadius, sizes, smallBorderRadius, spacer } from './constants';
import metropolisFont from './Metropolis/metropolis.regular.otf';
import { accordionOverrides } from './overrides/accordion';
import { autocomplete } from './overrides/autocomplete';
import buttonOverrides from './overrides/button';
import { cardOverrides } from './overrides/card';
import { containerOverrides } from './overrides/container';
import { expansionPanelOverrides } from './overrides/expansionPanel';
import { formOverrides } from './overrides/form';
import globalRules from './overrides/global';
import { loadingAnimations } from './overrides/loadingAnimations';
import modalOverrides from './overrides/modal';
import { navigationOverrides } from './overrides/navigation';
import { switchOverrides } from './overrides/switch';
import { tableOverrides } from './overrides/table';
import typographyOverrides from './overrides/typography';
const bodyOverflowHidden = {
  body: {
    overflow: 'hidden'
  }
};
const bodyOverflowAuto = {
  body: {
    overflow: 'auto',
    overflowX: 'hidden'
  }
};
const createTheme = (palette, bodyOverflow, formControlSize, appBarMargin) => ({
  palette,
  typography: {
    fontSize: 12,
    fontFamily: ['Metropolis', 'sans-serif'].join(',')
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': {
          fontFamily: 'Metropolis',
          src: "url(".concat(metropolisFont, ")")
        },
        ...globalRules,
        ...loadingAnimations,
        ...autocomplete,
        ...bodyOverflow
      }
    },
    MuiFlatPagination: {
      root: {
        textAlign: 'center'
      }
    },
    MuiPickersBasePicker: {
      container: {
        width: 340,
        '& .MuiButton-root': {
          margin: 0,
          padding: 0
        },
        '& .MuiTypography-h3': {
          fontSize: '3rem'
        },
        '& .MuiTypography-h4': {
          fontSize: '2.125rem',
          fontWeight: 400,
          lineHeight: 1.17,
          letterSpacing: '0.00735em'
        },
        '& .MuiTypography-subtitle1': {
          fontSize: '1rem',
          fontWeight: 400,
          lineHeight: 1.75,
          letterSpacing: '0.00938em'
        }
      }
    },
    MuiStepper: {
      root: {
        backgroundColor: 'transparent',
        borderBottom: borderDefinition(),
        paddingBottom: '12.5px',
        marginBottom: spacer.spacer3
      }
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0
      }
    },
    MuiListItem: {
      button: {
        '.customLi&:hover': {
          backgroundColor: 'white',
          '& div span': {
            color: '#252525'
          },
          '& .expandIcon path': {
            color: '#252525'
          }
        }
      },
      root: {
        '&.w-auto': {
          width: 'auto'
        }
      }
    },
    MuiSvgIcon: {
      root: {
        '&.colorWhite': {
          fill: 'white'
        },
        '&.size-26': {
          height: 26,
          width: 26
        }
      },
      fontSizeLarge: {
        fontSize: '3.875rem'
      }
    },
    MuiAvatar: {
      root: {
        '&.border': {
          border: borderDefinition(undefined, '3px')
        },
        '&.small': {
          width: spacer.spacer5,
          height: spacer.spacer5
        },
        '&.large': {
          width: spacer.spacer7 + spacer.spacer3,
          height: spacer.spacer7 + spacer.spacer3
        }
      }
    },
    ...navigationOverrides(palette, appBarMargin),
    ...containerOverrides,
    ...typographyOverrides,
    ...formOverrides(formControlSize),
    ...expansionPanelOverrides,
    ...cardOverrides,
    ...modalOverrides,
    ...buttonOverrides,
    ...tableOverrides,
    ...switchOverrides,
    ...accordionOverrides
  }
});
const dealTheme = createTheme(dealThemePalette, bodyOverflowHidden, sizes.small, true);
const adminTheme = createTheme(dealThemePalette, bodyOverflowHidden, sizes.small, true);
const feelTheme = mainColor => {
  if (mainColor) {
    return createTheme({
      ...feelThemePalette,
      primary: {
        ...feelThemePalette.primary,
        main: mainColor
      }
    }, bodyOverflowAuto, sizes.xsmedium, false);
  }
  return createTheme(feelThemePalette, bodyOverflowAuto, sizes.xsmedium, false);
};
export { borderDefinition, boxShadow, boxShadowLight, largeBorderRadius, dealTheme, DEFAULT_SCALE, defaultPalette, feelTheme, adminTheme, feelThemePalette, sizes, spacer, typographyOverrides, smallBorderRadius, mediumBorderRadius };