import { basicReducer } from '../core';
import { PROVIDER_CREATE, PROVIDERS_GET_MANY } from '../new';
import { ACCOUNT_AVATAR_UPDATE, ACCOUNT_GET_AGREEMENTS, ACCOUNT_PASSWORD_UPDATE, ACCOUNT_UPDATE, PROVIDERS_CATEGORIES_GET, STAFF_PROVIDERS_ASSIGNED_GET, STAFF_PROVIDERS_EDIT, STAFF_PROVIDERS_NONASSIGNED_GET, STAFF_RESOURCES_FILTER, USERS_POST, USERS_STAFF_GET, USERS_STATUS } from '../types';
const business = {
  providers: basicReducer(PROVIDERS_GET_MANY),
  categories: basicReducer(PROVIDERS_CATEGORIES_GET),
  staff: basicReducer(USERS_STAFF_GET),
  staffProvidersAssigned: basicReducer(STAFF_PROVIDERS_ASSIGNED_GET),
  staffProvidersNonassigned: basicReducer(STAFF_PROVIDERS_NONASSIGNED_GET),
  staffResourcesFitler: basicReducer(STAFF_RESOURCES_FILTER),
  staffProvidersEdit: basicReducer(STAFF_PROVIDERS_EDIT),
  providerCreate: basicReducer(PROVIDER_CREATE),
  userCreate: basicReducer(USERS_POST),
  accountUpdate: basicReducer(ACCOUNT_UPDATE),
  accountAgreements: basicReducer(ACCOUNT_GET_AGREEMENTS),
  accountUpdatePassword: basicReducer(ACCOUNT_PASSWORD_UPDATE),
  accountUpdateAvatar: basicReducer(ACCOUNT_AVATAR_UPDATE),
  usersStatus: basicReducer(USERS_STATUS)
};
export { business };