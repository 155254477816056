"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getReplyEmailAddress = exports.generateTemplateHeaderAndFooter = void 0;
var generateHeader = function generateHeader(color, logo) {
  return "\n        <div style=\"background: linear-gradient(145deg, #".concat(color ? color : 'e6616a', " 79%, rgba(37,37,37,1) 80%); width: 100%; padding: 1vh; padding-left: 0; padding-right: 0\">\n        <div style=\"background-image: url('").concat(logo ? logo : 'https://gy-prod-public-media.s3-eu-west-1.amazonaws.com/logo.png', "'); background-size:contain; background-position: center center; background-repeat: no-repeat; width: 350px; height: 90px; margin: 0 auto;\">\n        </div>\n        </div>\n      ");
};
var generateGoodByeMessage = function generateGoodByeMessage(lang, providerName, logo) {
  return lang === 'it' ? "Grazie, ".concat(logo ? providerName : 'GURYOU Business Team.') : "Thank you, ".concat(logo ? providerName : 'GURYOU Business Team.');
};
var generateFooter = function generateFooter(color) {
  return "\n  <div style=\"background: linear-gradient(145deg,rgba(37,37,37,1) 19%, #".concat(color ? color : 'e6616a', " 20%); width: 100%;\">\n  <div style=\"display:flex; width:75%; margin-left: auto; ").concat(color ? 'height: 18.3vh' : '', "\">\n  ").concat(color ? "" : " <a target=\"_blank\" style=\"color:white; font-size: 1.9rem; margin: 0rem 3rem; margin-top: 0.3rem;\"\n                href=\"https://www.facebook.com/guryou/\"><img\n                    src=https://gy-prod-public-media.s3-eu-west-1.amazonaws.com/facebook.png /></a>\n            <a target=\"_blank\" style=\"color:white; font-size: 1.9rem; margin: 0rem 3rem; margin-top: 0.3rem;\"\n                href=\"https://www.youtube.com/channel/UCW_LkvMb7dGWJrLdflZoAxA\"><img\n                    src=https://gy-prod-public-media.s3-eu-west-1.amazonaws.com/youtube.png /></a>\n            <a target=\"_blank\" style=\"color:white; font-size: 1.9rem; margin: 0rem 3rem; margin-top: 0.3rem;\"\n                href=\"https://www.linkedin.com/company/gy\"><img\n                    src=https://gy-prod-public-media.s3-eu-west-1.amazonaws.com/linkedin.png /></a>\n            <a target=\"_blank\" style=\"color:white; font-size: 1.9rem; margin: 0rem 3rem; margin-top: 0.3rem;\"\n                href=\"https://www.instagram.com/guryou_official/\"><img\n                    src=https://gy-prod-public-media.s3-eu-west-1.amazonaws.com/instagram.png /></a>", "\n  </div>\n  </div>");
};
var getReplyEmailAddress = exports.getReplyEmailAddress = function getReplyEmailAddress(email, notificationEmail) {
  var trimmedEmail = email && email.trim();
  var trimmedNotificationEmail = notificationEmail && notificationEmail.trim();
  if (trimmedNotificationEmail && trimmedNotificationEmail !== process.env.EMAIL_SENDER) {
    return trimmedNotificationEmail;
  }
  return trimmedEmail;
};
var generateTemplateHeaderAndFooter = exports.generateTemplateHeaderAndFooter = function generateTemplateHeaderAndFooter(provider) {
  var emailTemplate = provider.emailTemplate,
    providerLanguage = provider.providerLanguage,
    name = provider.name;
  return {
    headerDiv: generateHeader(emailTemplate === null || emailTemplate === void 0 ? void 0 : emailTemplate.color, emailTemplate === null || emailTemplate === void 0 ? void 0 : emailTemplate.logo),
    footerDiv: generateFooter(emailTemplate === null || emailTemplate === void 0 ? void 0 : emailTemplate.color),
    goodbyeMessage: generateGoodByeMessage(providerLanguage, name, emailTemplate === null || emailTemplate === void 0 ? void 0 : emailTemplate.logo)
  };
};