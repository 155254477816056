import { activateReservationApiCall, cancelMultipleReservationsByIdsApiCall, cancelReservationApiRequest, changeUserReservationPresenceApiCall, changeUserReservationsNumberApiCall, createMultipleReservationApiCall, createReservationApiCall, createReservationAsAnonymousApiCall, getCustomersSubserviceReservationsApiRequest, getReservationPriceApiRequest, getReservationsForMultipleCancelApiCall, updateReservationApiCall } from '@guryou/api-client-gql';
import { parseDate } from '@guryou/utils';
import { execute } from '../actions/actionWrapper';
import { resetState } from '../actions/resetActions';
import { Action, basicReducer, handleLocationInput } from '../core';

// Types
export const RESERVATIONS_GET_PRICE = {
  entity: 'RESERVATIONS_PRICE',
  action: Action.GET
};
export const RESERVATIONS_CREATE = {
  entity: 'RESERVATIONS',
  action: Action.CREATE
};
export const RESERVATIONS_CREATE_MULTIPLE = {
  entity: 'RESERVATIONS_CREATE_MULTIPLE',
  action: Action.CREATE
};
export const RESERVATIONS_UPDATE = {
  entity: 'RESERVATIONS',
  action: Action.UPDATE
};
export const RESERVATIONS_CANCEL = {
  entity: 'RESERVATIONS',
  action: Action.ARCHIVE
};
export const RESERVATIONS_ACTIVATE = {
  entity: 'RESERVATIONS_ACTIVATE',
  action: Action.UPDATE
};
export const USER_MEMBERSHIP_RESERVATIONS_NUMBER_UPDATE = {
  entity: 'USER_MEMBERSHIP_RESERVATIONS_NUMBER',
  action: Action.UPDATE
};
export const RESERVATION_USER_PRESENCE = {
  entity: 'RESERVATION_USER_PRESENCE',
  action: Action.UPDATE
};
export const USER_RESERVATIONS = {
  entity: 'USER_RESERVATIONS',
  action: Action.GET_MANY
};
export const GET_RESERVATIONS_FOR_MULTIPLE_CANCEL = {
  entity: 'GET_RESERVATIONS_FOR_MULTIPLE_CANCEL',
  action: Action.GET_MANY
};
export const RESERVATIONS_MULTIPLE_CANCEL = {
  entity: 'RESERVATIONS_MULTIPLE_CANCEL',
  action: Action.UPDATE
};

// Actions
export const createReservation = data => async dispatch => {
  const customerId = +data.customerId || null;
  const customerWalkInId = +data.customerWalkInId || null;
  const email = data.email || (customerId ? null : data.customerId);
  const {
    dateFrom,
    timeFrom,
    orderEvent
  } = data;
  const request = {
    customerId,
    customerWalkInId,
    email: data.customerSide ? null : email,
    subServiceId: +data.subServiceId,
    resourceId: +data.resourceId,
    isPaid: data.isPaid === 'true',
    note: data.note,
    dateTimeFrom: data.dateTimeFrom || parseDate("".concat(dateFrom, " ").concat(timeFrom)).toISOString(),
    useMembership: data.useMembership,
    status: data.status,
    duration: +data.duration || null,
    orderEvent,
    ...handleLocationInput(data)
  };
  if (data !== null && data !== void 0 && data.materialResource) {
    request.materialResource = +data.materialResource;
  }
  return execute(dispatch, () => createReservationApiCall({
    request
  }), RESERVATIONS_CREATE, true);
};
export const createMultipleReservation = data => async dispatch => {
  const customerId = +data.customerId || null;
  const customerWalkInId = +data.customerWalkInId || null;
  const email = data.email || (customerId ? null : data.customerId);
  const workingDays = data.workingDays || null;
  const addedDays = data.addedDays || null;
  const {
    dateFrom,
    timeFrom,
    orderEvent
  } = data;
  const request = {
    customerId,
    customerWalkInId,
    email: data.customerSide ? null : email,
    subServiceId: +data.subServiceId,
    resourceId: +data.resourceId,
    isPaid: data.isPaid === 'true',
    note: data.note,
    dateTimeFrom: data.dateTimeFrom || parseDate("".concat(dateFrom, " ").concat(timeFrom)).toISOString(),
    endDate: parseDate("".concat(data.endDate, " ").concat(timeFrom)).toISOString(),
    useMembership: data.useMembership,
    userMembershipId: data.userMembershipId || null,
    status: data.status,
    duration: +data.duration || null,
    orderEvent,
    repeatInterval: +data.repeatInterval || null,
    repeatIntervalType: data.repeatIntervalType,
    ...handleLocationInput(data)
  };
  if (workingDays) {
    const wdays = Array.isArray(workingDays) ? workingDays : [workingDays];
    request.workingDays = wdays.filter(day => day !== '').map(d => +d);
  }
  if (addedDays) {
    request.addedDays = addedDays;
  }
  if (data !== null && data !== void 0 && data.materialResource) {
    request.materialResource = +data.materialResource;
  }
  return execute(dispatch, () => createMultipleReservationApiCall({
    request
  }), RESERVATIONS_CREATE_MULTIPLE, true);
};
export const createReservationAsAnonymous = data => async dispatch => {
  const {
    firstname,
    lastname,
    phone
  } = data;
  const request = {
    ...data,
    firstname: firstname || null,
    lastname: lastname || null,
    phone: phone || null
  };
  execute(dispatch, () => createReservationAsAnonymousApiCall(request), RESERVATIONS_CREATE, true);
};
export const updateReservation = data => async dispatch => {
  const {
    dateFrom,
    timeFrom
  } = data;
  const request = {
    id: +data.id,
    dateTimeFrom: parseDate("".concat(dateFrom, " ").concat(timeFrom)).toISOString(),
    email: data.email,
    note: data.note,
    resourceId: +data.resourceId,
    subServiceId: +data.subServiceId,
    customerId: +data.customerId || null,
    duration: +data.duration,
    ...handleLocationInput(data)
  };
  execute(dispatch, () => updateReservationApiCall({
    request
  }), RESERVATIONS_UPDATE);
};
export const changeUserReservationsNumber = (id, numberOfReservations, startDate, endDate, action) => async dispatch => {
  let startTime = null;
  let endTime = null;
  if (startDate && endDate) {
    startTime = "".concat(startDate, "T00:00:00.000Z");
    endTime = "".concat(endDate, "T23:59:59.000Z");
  }
  execute(dispatch, () => changeUserReservationsNumberApiCall({
    id,
    numberOfReservations,
    startTime,
    endTime,
    action
  }), USER_MEMBERSHIP_RESERVATIONS_NUMBER_UPDATE);
};
export const getCustomersSubserviceReservations = (subserviceId, userId, customerId) => async dispatch => execute(dispatch, () => getCustomersSubserviceReservationsApiRequest(subserviceId, userId, customerId), USER_RESERVATIONS);
export const activateReservation = reservationId => async dispatch => execute(dispatch, () => activateReservationApiCall({
  reservationId
}), RESERVATIONS_ACTIVATE);
export const cancelReservation = reservationId => async dispatch => execute(dispatch, () => cancelReservationApiRequest(reservationId), RESERVATIONS_CANCEL);
export const getReservationPrice = reservationId => async dispatch => execute(dispatch, () => getReservationPriceApiRequest(reservationId), RESERVATIONS_GET_PRICE);
export const changeUserReservationPresence = (reservationId, presence) => async dispatch => {
  let p = presence;
  if (presence === 'Y') {
    p = 'true';
  }
  if (presence === 'N') {
    p = 'false';
  }
  execute(dispatch, () => changeUserReservationPresenceApiCall({
    reservationId,
    presence: p
  }), RESERVATION_USER_PRESENCE);
};
export const getReservationsForMultipleCancel = data => async dispatch => {
  const request = {
    serviceId: +data.serviceId,
    subServiceId: +data.subServiceId || null,
    fromDate: "".concat(data.fromDate, "T00:00:00"),
    toDate: "".concat(data.toDate, "T23:59:00"),
    userId: +data.userId || null,
    customerId: +data.customerId || null,
    resourceId: +data.resourcesId || null
  };
  execute(dispatch, () => getReservationsForMultipleCancelApiCall({
    request
  }), GET_RESERVATIONS_FOR_MULTIPLE_CANCEL);
};
export const cancelMultipleReservations = reservationIds => async dispatch => {
  execute(dispatch, () => cancelMultipleReservationsByIdsApiCall({
    reservationIds
  }), RESERVATIONS_MULTIPLE_CANCEL);
};

// Reset actions
export const resetCreateReservationState = () => async dispatch => dispatch(resetState(RESERVATIONS_CREATE));

// Reducers
export const reservationReducers = {
  create: basicReducer(RESERVATIONS_CREATE),
  createMultiple: basicReducer(RESERVATIONS_CREATE_MULTIPLE),
  update: basicReducer(RESERVATIONS_UPDATE),
  cancel: basicReducer(RESERVATIONS_CANCEL),
  activate: basicReducer(RESERVATIONS_ACTIVATE),
  userMembershipReservations: basicReducer(USER_MEMBERSHIP_RESERVATIONS_NUMBER_UPDATE),
  userPresence: basicReducer(RESERVATION_USER_PRESENCE),
  userReservations: basicReducer(USER_RESERVATIONS),
  reservationsForCancel: basicReducer(GET_RESERVATIONS_FOR_MULTIPLE_CANCEL),
  reservationsMultiCancel: basicReducer(RESERVATIONS_MULTIPLE_CANCEL)
};
export const reservationFeelReducers = {
  create: basicReducer(RESERVATIONS_CREATE)
};