const initialState = {
  open: true
};
const blackFridayModalPopup = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case 'blackFriday':
      return {
        ...state,
        open: action.value
      };
    default:
      return state;
  }
};
export { blackFridayModalPopup };