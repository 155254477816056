import { apiCall } from './gqlClient';
const GETMYCATEGORIES_QUERY = "\n  query getMyCategories($providerId: JSON!) {\n    getMyCategories(providerId: $providerId)\n    {\n      id\n\t\t\tname\n\t\t\tcounter\n\t\t\ttype\n\t\t\torderIndex\n      ...fragments\n    }\n  }";
export const getMyCategoriesApiCall = async function (_ref) {
  let {
    providerId
  } = _ref;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: GETMYCATEGORIES_QUERY,
    variables: {
      providerId
    },
    fragments
  });
};
const GETALLCATEGORIES_QUERY = "\n  query getAllCategories {\n    getAllCategories\n    {\n      id\n\t\t\tname\n\t\t\tcounter\n\t\t\ttype\n\t\t\torderIndex\n      ...fragments\n    }\n  }";
export const getAllCategoriesApiCall = async function () {
  let fragments = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  return apiCall({
    query: GETALLCATEGORIES_QUERY,
    variables: {},
    fragments
  });
};
const GETPROVIDERCATEGORIES_QUERY = "\n  query getProviderCategories($providerId: Int, $providerType: String) {\n    getProviderCategories(providerId: $providerId, providerType: $providerType)\n    {\n      id\n\t\t\tname\n\t\t\tcounter\n\t\t\ttype\n\t\t\torderIndex\n      ...fragments\n    }\n  }";
export const getProviderCategoriesApiCall = async function (_ref2) {
  let {
    providerId,
    providerType
  } = _ref2;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: GETPROVIDERCATEGORIES_QUERY,
    variables: {
      providerId,
      providerType
    },
    fragments
  });
};
const GETSERVICECATEGORIES_QUERY = "\n  query getServiceCategories($type: String!, $providerId: Int) {\n    getServiceCategories(type: $type, providerId: $providerId)\n    {\n      id\n\t\t\tname\n\t\t\tcounter\n\t\t\ttype\n\t\t\torderIndex\n      ...fragments\n    }\n  }";
export const getServiceCategoriesApiCall = async function (_ref3) {
  let {
    type,
    providerId
  } = _ref3;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: GETSERVICECATEGORIES_QUERY,
    variables: {
      type,
      providerId
    },
    fragments
  });
};
const GETPRODUCTCATEGORIES_QUERY = "\n  query getProductCategories($providerId: Int) {\n    getProductCategories(providerId: $providerId)\n    {\n      id\n\t\t\tname\n\t\t\tcounter\n\t\t\ttype\n\t\t\torderIndex\n      ...fragments\n    }\n  }";
export const getProductCategoriesApiCall = async function (_ref4) {
  let {
    providerId
  } = _ref4;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: GETPRODUCTCATEGORIES_QUERY,
    variables: {
      providerId
    },
    fragments
  });
};