import camelcaseKeys from 'camelcase-keys';
import { GuryouApiConfig } from './GuryouApiConfig';
const reGexAmp = new RegExp('&amp;', 'g');
const replaceAmp = val => val.replace(reGexAmp, '&');
const toSnakeCase = str => str && str.replace(/[\w]([A-Z])/g, m => "".concat(m[0], "_").concat(m[1])).toLowerCase();
const jsonToUrlEncodedString = obj => Object.keys(obj).map(key => {
  if (Array.isArray(obj[key])) {
    return obj[key].map((x, idx) => "".concat(toSnakeCase(key), "[").concat(idx, "]=").concat(encodeURIComponent(x))).join('&');
  }
  if (obj[key] && typeof obj[key] === 'object') {
    return jsonToUrlEncodedString(obj[key]);
  }
  return "".concat(toSnakeCase(key), "=").concat(encodeURIComponent(obj[key]));
}).join('&');
const jsonToFormData = (obj, formData) => {
  Object.keys(obj).forEach(key => {
    if (Array.isArray(obj[key])) {
      obj[key].forEach((x, idx) => {
        formData.append("".concat(toSnakeCase(key), "[").concat(idx, "]"), "".concat(encodeURIComponent(x)));
      });
    } else if (obj[key] && typeof obj[key] === 'object') {
      if (obj[key] instanceof File) {
        formData.append(toSnakeCase(key), obj[key]);
      } else {
        jsonToFormData(obj[key], formData);
      }
    } else {
      formData.append("".concat(toSnakeCase(key)), "".concat(encodeURIComponent(obj[key])));
    }
  });
  return formData;
};
const handleAmpReplace = function (data) {
  let isArray = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  if (isArray) {
    const result = data.map(obj => {
      const tempObj = {};
      for (const key in obj) {
        if (obj[key] && typeof obj[key] === 'string') {
          tempObj[key] = replaceAmp(obj[key]);
        } else {
          tempObj[key] = obj[key];
        }
      }
      return tempObj;
    });
    return result;
  } else {
    const result = {};
    for (const key in data) {
      if (data[key] && typeof data[key] === 'string') {
        result[key] = replaceAmp(data[key]);
      } else {
        result[key] = data[key];
      }
    }
    return result;
  }
};
const triggerPost = async function (url, body) {
  let requiresAccessToken = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  let headersType = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'application/x-www-form-urlencoded';
  const headers = {
    'Content-Type': headersType
  };
  if (requiresAccessToken) {
    const token = await GuryouApiConfig.getAccessToken();
    headers.Authorization = "Bearer ".concat(token);
  }
  return fetch("".concat(GuryouApiConfig.getApiEndpoint()).concat(url), {
    method: 'POST',
    headers,
    body: jsonToUrlEncodedString(body)
  });
};
const triggerPostFormData = async (url, body) => {
  const token = await GuryouApiConfig.getAccessToken();
  const headers = {
    Authorization: "Bearer ".concat(token)
  };
  return fetch("".concat(GuryouApiConfig.getApiEndpoint()).concat(url), {
    method: 'POST',
    headers,
    body: jsonToFormData(body, new FormData())
  });
};
const triggerGet = async function (url) {
  let requiresAccessToken = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  const token = await GuryouApiConfig.getAccessToken();
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded'
  };
  if (requiresAccessToken) {
    headers.Authorization = "Bearer ".concat(token);
  }
  return fetch("".concat(GuryouApiConfig.getApiEndpoint()).concat(url), {
    method: 'GET',
    headers
  });
};
const triggerStripePostCreate = async (url, body) => {
  const token = await GuryouApiConfig.getAccessToken();
  const headers = {
    'Content-Type': 'application/json',
    Authorization: "Bearer ".concat(token)
  };
  return fetch("".concat(url), {
    method: 'POST',
    headers,
    body: JSON.stringify(body)
  });
};
const triggerStripePost = async (url, body) => {
  const token = await GuryouApiConfig.getAccessToken();
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: "Bearer ".concat(token)
  };
  return fetch("".concat(url), {
    method: 'POST',
    headers,
    body: jsonToUrlEncodedString(body)
  });
};
const triggerStripePut = async (url, body) => {
  const token = await GuryouApiConfig.getAccessToken();
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: "Bearer ".concat(token)
  };
  return fetch("".concat(url), {
    method: 'PUT',
    headers,
    body: jsonToUrlEncodedString(body)
  });
};
const triggerStripeGet = async url => {
  const token = await GuryouApiConfig.getAccessToken();
  const headers = {
    Accept: 'application/json',
    Authorization: "Bearer ".concat(token)
  };
  return fetch("".concat(url), {
    method: 'GET',
    headers
  });
};
const triggerPut = async (url, body) => {
  const token = await GuryouApiConfig.getAccessToken();
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: "Bearer ".concat(token)
  };
  return fetch("".concat(GuryouApiConfig.getApiEndpoint()).concat(url), {
    method: 'PUT',
    headers,
    body: jsonToUrlEncodedString(body)
  });
};
const triggerDelete = async url => {
  const token = await GuryouApiConfig.getAccessToken();
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: "Bearer ".concat(token)
  };
  return fetch("".concat(GuryouApiConfig.getApiEndpoint()).concat(url), {
    method: 'DELETE',
    headers
  });
};
const triggerUploadToS3 = (file, presignedUrl) => fetch(presignedUrl, {
  method: 'PUT',
  body: file,
  headers: new Headers({
    'Access-Control-Allow-Origin': '*'
  })
});
const handleResponse = async apiResponse => {
  const responseData = await apiResponse.json();
  if ([200, 201].includes(responseData.report.status)) {
    return handleSuccess(responseData);
  }
  return handleError(responseData);
};
const handleSuccess = function (response) {
  let getOnlyFirstItem = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  let rest = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  const responseIsArray = Array.isArray(response.data);
  if (!responseIsArray || getOnlyFirstItem) {
    const data = response.data ? {
      ...camelcaseKeys(getOnlyFirstItem ? response.data[0] : response.data),
      ...camelcaseKeys(rest, {
        deep: true
      })
    } : {};
    return {
      success: true,
      data: handleAmpReplace(data),
      report: response.report || {},
      id: response.params ? response.params.id ? response.params.id : false : false,
      message: response.message || '',
      type: response === null || response === void 0 ? void 0 : response.type
    };
  }
  const tempResult = Object.values({
    ...camelcaseKeys(response.data),
    ...camelcaseKeys(rest, {
      deep: true
    })
  });
  return {
    success: true,
    data: !response.data.length ? [] : handleAmpReplace(tempResult, true),
    report: response.report || {},
    id: response.params && response.params.id ? response.params.id : false,
    message: response.message || '',
    type: response === null || response === void 0 ? void 0 : response.type
  };
};
const handleError = response => {
  var _response$report, _response$report$erro;
  let checkErrors = null;
  if (response.status_code) {
    return {
      success: false,
      status: 'failed',
      message: response.message
    };
  }
  if (response !== null && response !== void 0 && (_response$report = response.report) !== null && _response$report !== void 0 && (_response$report$erro = _response$report.errors) !== null && _response$report$erro !== void 0 && _response$report$erro.info) {
    checkErrors = Object.values(response.report.errors.info)[0];
  }
  return {
    success: false,
    status: response.report ? response.report.status || 'failed' : '',
    message: checkErrors ? checkErrors : response.message || ''
  };
};
const handleGetRequest = async url => {
  const apiResponse = await triggerGet(url);
  const responseData = await apiResponse.json();
  if ([200, 201].includes(Number(responseData.report.status))) {
    return handleSuccess(responseData);
  }
  return handleError(responseData);
};
const triggerBizApi = async query => {
  return fetch("".concat(GuryouApiConfig.getBizApiEndpoint()), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query
    })
  });
};
const groupBy = key => array => array.reduce((objectsByKeyValue, obj) => ({
  ...objectsByKeyValue,
  [obj[key]]: (objectsByKeyValue[obj[key]] || []).concat(obj)
}), {});

// COOKIES
const loginCookie = cookieValue => {
  const cookieName = 'accInfo';
  const accInfoValue = JSON.stringify(cookieValue);
  const myDate = new Date();
  myDate.setMonth(myDate.getMonth() + 12);
  document.cookie = "".concat(cookieName, "=").concat(accInfoValue, ";expires=").concat(myDate, ";domain=").concat(process.env.REACT_APP_HYBRID_SUBDOMAIN, ";path=/");
};
export { triggerStripePostCreate, triggerStripePut, triggerStripePost, triggerStripeGet, handleSuccess, handleError, handleResponse, triggerGet, triggerPost, triggerPut, triggerDelete, triggerUploadToS3, triggerPostFormData, handleGetRequest, groupBy, loginCookie, triggerBizApi };