import { getAllSubservicesApiRequest } from '@guryou/api-client';
import { deleteSubServiceApiCall, getAllEvenArchivedSubservicesApiCall, getAllSubservicesApiCall, getProviderAllSubservicesApiCall, getPublicSubServicesApiCall, getSubServicesApiCall, getSubservicesForBookingApiCall, reorderSubServicesApiCall, restoreSubserviceApiCall, saveServiceWithSubServiceApiCall, saveSubServiceApiCall, toggleSubServiceStatusApiCall } from '@guryou/api-client-gql';
import { LocationType } from '@guryou/core';
import { parseAsBool } from '@guryou/utils';
import { execute } from '../actions/actionWrapper';
import { Action, basicReducer, executeApiCall } from '../core';
import { DEFAULT_END_DATE, DEFAULT_END_TIME, DEFAULT_START_DATE, DEFAULT_START_TIME } from '..';

// Types
export const SUB_SERVICES_GET_MANY = {
  entity: 'SUB_SERVICES',
  action: Action.GET_MANY
};
export const PROVIDER_ADD_SUB_SERVICE = {
  entity: 'SUB_SERVICES',
  action: Action.CREATE
};
export const PROVIDER_SUB_SERVICE_ACTIVE_STATUS = {
  entity: 'SUB_SERVICES',
  action: Action.UPDATE
};
export const PROVIDER_SUB_SERVICE_DELETE = {
  entity: 'SUB_SERVICES',
  action: Action.DELETE
};
export const PROVIDER_SUB_SERVICE_UPDATE = {
  entity: 'SUB_SERVICES',
  action: Action.UPDATE
};
export const PROVIDER_SUB_SERVICES_GET_MANY = {
  entity: 'PROVIDER_SUB_SERVICES',
  action: Action.GET_MANY
};
export const PROVIDER_REORDER_SUB_SERVICE = {
  entity: 'SUB_SERVICES',
  action: Action.REORDER
};
export const PROVIDER_SUB_SERVICE_TOGGLE_STATUS = {
  entity: 'SUB_SERVICE_STATUS',
  action: Action.UPDATE
};
export const SUBSERVICE_RESTORE = {
  entity: 'SUBSERVICE_RESTORE',
  action: Action.UPDATE
};
export const SUBSERVICES_FOR_BOOKING = {
  entity: 'SUBSERVICES_FOR_BOOKING',
  action: Action.GET_MANY
};
export const PROVIDER_SERVICE_PLUS_SUB_SERVICE = {
  entity: 'SERVICE_PLUS_SUB_SERVICE',
  action: Action.CREATE
};

// Actions
export const getPublicSubServices = (id, actionType, widget) => async dispatch => execute(dispatch, () => getPublicSubServicesApiCall({
  serviceId: +id
}, {
  detailPrice: ['price', 'discount', 'priceWithoutDiscount'],
  location: ['note', 'latitude', 'longitude', 'address', 'addressUrl', 'url']
}), actionType || SUB_SERVICES_GET_MANY, false, {
  serviceId: +id,
  widget
});
export const getAllSubservicesGQL = (id, actionType) => async dispatch => execute(dispatch, () => getAllSubservicesApiCall({
  serviceId: +id
}, {
  detailPrice: ['price', 'discount', 'priceWithoutDiscount'],
  location: ['note', 'latitude', 'longitude', 'address', 'addressUrl', 'url']
}), actionType || SUB_SERVICES_GET_MANY, false, {
  serviceId: +id
});
export const getAllEvenArchivedSubservices = (id, actionType) => async dispatch => execute(dispatch, () => getAllEvenArchivedSubservicesApiCall({
  serviceId: +id
}, {
  detailPrice: ['price', 'discount', 'priceWithoutDiscount'],
  location: ['note', 'latitude', 'longitude', 'address', 'addressUrl', 'url']
}), actionType || SUB_SERVICES_GET_MANY, false, {
  serviceId: +id
});
export const saveSubService = data => async dispatch => {
  const {
    id,
    serviceId,
    price,
    duration,
    capacity,
    resources,
    locationType,
    latitude,
    longitude,
    address,
    locationNote,
    startDate,
    endDate,
    startTime,
    endTime,
    propagateLocation,
    defaultTimeSlotStep,
    materialResource,
    ...rest
  } = data;
  const request = {
    serviceId: +serviceId,
    price: +price,
    duration: +duration,
    capacity: +capacity,
    resources: Array.isArray(resources) ? resources.map(x => +x) : [+resources],
    locationType: +locationType,
    id: +id || null,
    propagateLocation: parseAsBool(propagateLocation),
    defaultTimeSlotStep: +defaultTimeSlotStep,
    materialResource: +materialResource,
    ...rest
  };
  if (startTime && endTime) {
    request.startTime = "".concat(startDate || DEFAULT_START_DATE, "T").concat(startTime, ":00.000Z");
    request.endTime = "".concat(endDate || DEFAULT_END_DATE, "T").concat(endTime, ":00.000Z");
  } else if (startDate && endDate) {
    request.startTime = "".concat(startDate, "T").concat(DEFAULT_START_TIME, ".000Z");
    request.endTime = "".concat(endDate, "T").concat(DEFAULT_END_TIME, ".000Z");
  }
  if (+locationType === LocationType.mapLocation) {
    request.latitude = +latitude;
    request.longitude = +longitude;
    request.address = address;
  } else if (+locationType === LocationType.plainNote) {
    request.locationNote = locationNote;
  }
  execute(dispatch, () => saveSubServiceApiCall({
    request
  }), !id ? PROVIDER_ADD_SUB_SERVICE : PROVIDER_SUB_SERVICE_UPDATE, true);
};
export const saveServicePlusSubService = data => async dispatch => {
  const {
    svcGroupId,
    svcTypeId,
    price,
    resources,
    duration,
    capacity,
    startTime,
    endTime,
    startDate,
    endDate,
    defaultTimeSlotStep,
    applyTimeSlotToAllSubservices,
    materialResources,
    locationType,
    latitude,
    longitude,
    address,
    locationNote,
    propagateLocation,
    materialResource,
    serviceName,
    imageId,
    ...rest
  } = data;
  const imageUUID = imageId || null;
  const request = {
    ...rest,
    name: serviceName,
    svcGroupId: +svcGroupId,
    svcTypeId: +svcTypeId,
    price: +price,
    locationType: +locationType,
    resources: Array.isArray(resources) ? resources.map(x => +x) : [+resources],
    duration: +duration,
    capacity: +capacity,
    defaultTimeSlotStep: +defaultTimeSlotStep,
    applyTimeSlotToAllSubservices: !!applyTimeSlotToAllSubservices,
    materialResources: Array.isArray(materialResources) ? materialResources.map(x => +x) : materialResources.length > 0 ? [+materialResources] : [],
    materialResource: +materialResource,
    imageId: imageUUID,
    propagateLocation: parseAsBool(propagateLocation)
  };
  if (startTime && endTime) {
    request.startTime = "".concat(startDate || DEFAULT_START_DATE, "T").concat(startTime, ":00.000Z");
    request.endTime = "".concat(endDate || DEFAULT_END_DATE, "T").concat(endTime, ":00.000Z");
  } else if (startDate && endDate) {
    request.startTime = "".concat(startDate, "T").concat(DEFAULT_START_TIME, ".000Z");
    request.endTime = "".concat(endDate, "T").concat(DEFAULT_END_TIME, ".000Z");
  }
  //|| +locationType === LocationType.providerLocation)
  if (+locationType === LocationType.mapLocation) {
    request.latitude = +latitude;
    request.longitude = +longitude;
    request.address = address;
  } else if (+locationType === LocationType.plainNote) {
    request.locationNote = locationNote;
    // request.latitude = null;
    // request.longitude = null;
    // request.address = null;
  }
  return execute(dispatch, () => saveServiceWithSubServiceApiCall({
    request
  }), PROVIDER_SERVICE_PLUS_SUB_SERVICE, true);
};
export const getSubServices = (serviceId, silent) => async dispatch => execute(dispatch, () => getSubServicesApiCall({
  serviceId
}, {
  location: ['note', 'latitude', 'longitude', 'address', 'addressUrl', 'url']
}), PROVIDER_SUB_SERVICES_GET_MANY, false, {
  serviceId
}, silent);
export const getProviderAllSubservices = (serviceId, silent) => async dispatch => execute(dispatch, () => getProviderAllSubservicesApiCall({
  serviceId
}), PROVIDER_SUB_SERVICES_GET_MANY, false, {
  serviceId
}, silent);
export const getAllSubservices = (id, silent) => async dispatch => dispatch(executeApiCall(getAllSubservicesApiRequest(id), PROVIDER_SUB_SERVICES_GET_MANY, silent, undefined, true));
export const deleteSubService = id => async dispatch => execute(dispatch, () => deleteSubServiceApiCall({
  id
}), PROVIDER_SUB_SERVICE_DELETE, true);
export const reorderSubServices = (oldPosition, newPosition) => async dispatch => {
  execute(dispatch, () => reorderSubServicesApiCall({
    oldPosition,
    newPosition
  }), PROVIDER_REORDER_SUB_SERVICE, false, null, true);
};
export const toggleSubServiceStatus = (id, isActive, serviceId) => async dispatch => execute(dispatch, () => toggleSubServiceStatusApiCall({
  id,
  isActive,
  serviceId
}), PROVIDER_SUB_SERVICE_TOGGLE_STATUS, true);
export const restoreSubservice = id => async dispatch => {
  execute(dispatch, () => restoreSubserviceApiCall({
    id
  }), SUBSERVICE_RESTORE);
};
export const getSubservicesForBooking = providerId => async dispatch => {
  const resources = 'resources {id name}';
  execute(dispatch, () => getSubservicesForBookingApiCall({
    providerId
  }, {
    offers: ['id', 'name', 'price', 'duration', resources]
  }), SUBSERVICES_FOR_BOOKING);
};

// Reducers
export const subServiceReducers = {
  all: basicReducer(PROVIDER_SUB_SERVICES_GET_MANY),
  active: basicReducer(SUB_SERVICES_GET_MANY),
  create: basicReducer(PROVIDER_ADD_SUB_SERVICE),
  createServicePlusSubservice: basicReducer(PROVIDER_SERVICE_PLUS_SUB_SERVICE),
  delete: basicReducer(PROVIDER_SUB_SERVICE_DELETE),
  edit: basicReducer(PROVIDER_SUB_SERVICE_UPDATE),
  reorder: basicReducer(PROVIDER_REORDER_SUB_SERVICE),
  toggle: basicReducer(PROVIDER_SUB_SERVICE_TOGGLE_STATUS),
  restore: basicReducer(SUBSERVICE_RESTORE),
  allForBooking: basicReducer(SUBSERVICES_FOR_BOOKING)
};