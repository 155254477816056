import { ActionStatus, buildActionType } from '../core';
import { REGISTER_BIZ } from '../types';
const registerBiz = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    items: [],
    loading: false,
    error: null
  };
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case buildActionType(REGISTER_BIZ, ActionStatus.START):
      return {
        loading: true,
        items: [],
        error: null
      };
    case buildActionType(REGISTER_BIZ, ActionStatus.DONE):
      return {
        loading: false,
        items: action.payload,
        error: null
      };
    case buildActionType(REGISTER_BIZ, ActionStatus.FAILED):
      return {
        loading: false,
        items: [],
        error: action.payload
      };
    default:
      return state;
  }
};
export { registerBiz };