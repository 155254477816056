import { apiCall } from './gqlClient';
const GETARTICLES_QUERY = "\n  query getArticles {\n    getArticles\n  }";
export const getArticlesApiCall = async () => apiCall({
  query: GETARTICLES_QUERY,
  variables: {}
});
const GETARTICLEBYSLUG_QUERY = "\n  query getArticleBySlug($slug: String!) {\n    getArticleBySlug(slug: $slug)\n  }";
export const getArticleBySlugApiCall = async _ref => {
  let {
    slug
  } = _ref;
  return apiCall({
    query: GETARTICLEBYSLUG_QUERY,
    variables: {
      slug
    }
  });
};
const GETAUTHORBYSLUG_QUERY = "\n  query getAuthorBySlug($slug: String!) {\n    getAuthorBySlug(slug: $slug)\n  }";
export const getAuthorBySlugApiCall = async _ref2 => {
  let {
    slug
  } = _ref2;
  return apiCall({
    query: GETAUTHORBYSLUG_QUERY,
    variables: {
      slug
    }
  });
};
const GETBLOGCATEGORIES_QUERY = "\n  query getBlogCategories {\n    getBlogCategories\n  }";
export const getBlogCategoriesApiCall = async () => apiCall({
  query: GETBLOGCATEGORIES_QUERY,
  variables: {}
});