import { Action } from '../core';
export * from './auth';
export * from './crossStorage';
export * from './loading';
export * from './packages';
export * from './payments';
export * from './product';
export * from './provider';
export * from './staff';
export * from './users.js';
export * from './calendar';
export * from './customer';
export * from './widgetTypes';
export * from './webIntegrations';
export * from './providersFeel';
export * from './calendarFeel';
export * from './window';
export * from './authors';
export * from './registerBiz';
export const HOLIDAYS_GET_MANY = {
  entity: 'HOLIDAY',
  action: Action.GET_MANY
};