import { getPaymentOptionsApiCall, showPaymentButtonsCustomerApiCall, updatePaymentOptionsApiCall } from '@guryou/api-client-gql';
import { resetState } from '../actions';
import { execute } from '../actions/actionWrapper';
import { Action, basicReducer } from '../core';

// Types
export const SHOW_PAYMENT_BUTTONS = {
  entity: 'SHOW_PAYMENT_BUTTONS',
  action: Action.GET
};
export const PAYMENT_OPTIONS_GET = {
  entity: 'PAYMENT_OPTIONS',
  action: Action.GET
};
export const PAYMENT_OPTIONS_UPDATE = {
  entity: 'PAYMENT_OPTIONS',
  action: Action.UPDATE
};

//Actions
export const showPaymentButtonsCustomer = (serviceId, providerId, reservationDate, sessions, membershipId) => async dispatch => execute(dispatch, () => showPaymentButtonsCustomerApiCall({
  serviceId,
  providerId,
  reservationDate,
  sessions,
  membershipId
}, {
  userMembership: ['sessionsCounter', 'reservationsCounter']
}), SHOW_PAYMENT_BUTTONS, false, null);
export const getPaymentOptions = function () {
  let providerId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  return async dispatch => execute(dispatch, () => getPaymentOptionsApiCall({
    providerId
  }), PAYMENT_OPTIONS_GET, false, null);
};
export const updatePaymentOptions = silent => async dispatch => execute(dispatch, () => updatePaymentOptionsApiCall(), PAYMENT_OPTIONS_UPDATE, false, null, silent);

//Reset actions
export const resetShowPaymentButtonsCustomer = () => async dispatch => dispatch(resetState(SHOW_PAYMENT_BUTTONS));

//Reducers
export const paymentOptionsReducers = {
  get: basicReducer(PAYMENT_OPTIONS_GET),
  update: basicReducer(PAYMENT_OPTIONS_UPDATE)
};
export const paymentOptionsFeelReducers = {
  get: basicReducer(PAYMENT_OPTIONS_GET),
  showButtons: basicReducer(SHOW_PAYMENT_BUTTONS)
};