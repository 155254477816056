import { sizes, spacer } from '../constants';
const containerOverrides = {
  MuiContainer: {
    root: {
      width: "calc(100vw - ".concat(sizes.drawerWidth, "px)"),
      height: "calc(100vh - ".concat(sizes.appBarHeight + sizes.footerBarHeight, "px)"),
      marginLeft: sizes.drawerWidth,
      marginTop: 0,
      padding: spacer.spacer2,
      overflowY: 'auto'
    }
  },
  MuiPaper: {
    root: {
      position: 'relative'
    }
  },
  MuiTabs: {
    root: {
      width: '100%'
    },
    papercontainer: {
      width: '100%',
      margin: 'auto',
      paddingTop: spacer.spacer3,
      paddingBottom: spacer.spacer3
    }
  }
};
export { containerOverrides };