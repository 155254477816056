import { classesCheckApiRequest } from '@guryou/api-client';
import { deleteClassApiCall, getBusinessClassDetailsApiCall, getClassesForBookingApiCall, getProviderAllClassesApiCall, getProviderClassesApiCall, getPublicClassesApiCall, reorderClassesApiCall, restoreClassApiCall, saveClassApiCall, saveCourseWithClassApiCall, toggleClassStatusApiCall } from '@guryou/api-client-gql';
import { LocationType } from '@guryou/core';
import { parseAsBool } from '@guryou/utils';
import { resetState } from '../actions';
import { execute } from '../actions/actionWrapper';
import { Action, basicReducer, executeApiCall } from '../core';
import { CLASS_ACTIVE_STATUS } from '../types';
export const CLASSES_GET_MANY = {
  entity: 'CLASS',
  action: Action.GET_MANY
};
export const CLASS_GET = {
  entity: 'CLASS',
  action: Action.GET
};
export const PROVIDER_ADD_CLASS = {
  entity: 'PROVIDER_CLASS',
  action: Action.CREATE
};
export const PROVIDER_UPDATE_CLASS = {
  entity: 'PROVIDER_CLASS',
  action: Action.UPDATE
};
export const PROVIDER_CLASS_DELETE = {
  entity: 'PROVIDER_CLASS',
  action: Action.DELETE
};
export const PROVIDER_CLASSES_CHECK_CREATE = {
  entity: 'PROVIDER_CLASSES_CHECK',
  action: Action.CREATE
};
export const PROVIDER_GET_CLASSES = {
  entity: 'PROVIDER_CLASSES',
  action: Action.GET
};
export const PROVIDER_REORDER_CLASSES = {
  entity: 'PROVIDER_CLASSES',
  action: Action.REORDER
};
export const CLASS_RESTORE = {
  entity: 'CLASS_RESTORE',
  action: Action.UPDATE
};
export const CLASSES_FOR_BOOKING = {
  entity: 'CLASSES_FOR_BOOKING',
  action: Action.GET_MANY
};
export const PROVIDER_COURSE_PLUS_CLASS = {
  entity: 'PROVIDER_COURSE_PLUS_CLASS',
  action: Action.CREATE
};
export const getPublicClasses = (serviceId, actionType) => async dispatch => execute(dispatch, () => getPublicClassesApiCall({
  serviceId: +serviceId
}, {
  resources: ['id', 'name']
}), actionType || CLASSES_GET_MANY);
export const saveClass = request => async dispatch => {
  const {
    id,
    address,
    serviceId,
    capacity,
    minimumCapacity,
    duration,
    price,
    propagateLocation,
    latitude,
    longitude,
    locationType,
    locationNote,
    ...rest
  } = request;
  const input = {
    id: +id || null,
    serviceId: +serviceId,
    capacity: +capacity,
    minimumCapacity: +minimumCapacity || null,
    duration: +duration,
    price: +price,
    propagateLocation: parseAsBool(propagateLocation),
    ...rest
  };
  if (+locationType === LocationType.mapLocation) {
    input.latitude = +latitude;
    input.longitude = +longitude;
    input.address = address;
  } else if (+locationType === LocationType.plainNote) {
    input.locationNote = locationNote;
  }
  execute(dispatch, () => saveClassApiCall({
    request: input
  }), id ? PROVIDER_UPDATE_CLASS : PROVIDER_ADD_CLASS);
};
export const saveCoursePlusClass = data => async dispatch => {
  const {
    svcGroupId,
    svcTypeId,
    price,
    resources,
    duration,
    capacity,
    minimumCapacity,
    materialResources,
    locationType,
    latitude,
    longitude,
    address,
    locationNote,
    propagateLocation,
    materialResource,
    serviceName,
    imageId,
    ...rest
  } = data;
  const imageUUID = imageId || null;
  const request = {
    ...rest,
    name: serviceName,
    svcGroupId: +svcGroupId,
    svcTypeId: +svcTypeId,
    price: +price,
    locationType: +locationType,
    resources: Array.isArray(resources) ? resources.map(x => +x) : [+resources],
    duration: +duration,
    capacity: +capacity,
    minimumCapacity: +minimumCapacity || null,
    materialResources: Array.isArray(materialResources) ? materialResources.map(x => +x) : materialResources.length > 0 ? [+materialResources] : [],
    materialResource: +materialResource,
    imageId: imageUUID,
    propagateLocation: parseAsBool(propagateLocation)
  };
  if (+locationType === LocationType.mapLocation) {
    request.latitude = +latitude;
    request.longitude = +longitude;
    request.address = address;
  } else if (+locationType === LocationType.plainNote) {
    request.locationNote = locationNote;
  }
  return execute(dispatch, () => saveCourseWithClassApiCall({
    request
  }), PROVIDER_COURSE_PLUS_CLASS, true);
};
export const deleteClass = id => async dispatch => execute(dispatch, () => deleteClassApiCall({
  id
}), PROVIDER_CLASS_DELETE, true);
export const checkClass = data => async dispatch => dispatch(executeApiCall(classesCheckApiRequest(data), PROVIDER_CLASSES_CHECK_CREATE));
export const getProviderClasses = (id, silent) => async dispatch => execute(dispatch, () => getProviderClassesApiCall({
  serviceId: +id
}, {
  resources: ['id', 'name', 'userId'],
  materialResources: ['id', 'name'],
  location: ['note', 'latitude', 'longitude', 'address', 'addressUrl', 'url'],
  classSessions: ['id', 'startTime', 'endTime']
}), PROVIDER_GET_CLASSES, false, {
  serviceId: +id
}, silent);
export const getProviderAllClasses = (id, silent) => async dispatch => execute(dispatch, () => getProviderAllClassesApiCall({
  serviceId: +id
}, {
  resources: ['id', 'name'],
  location: ['note', 'latitude', 'longitude', 'address', 'addressUrl', 'url']
}), PROVIDER_GET_CLASSES, false, {
  serviceId: +id
}, silent);
export const getClassesForBooking = providerId => async dispatch => {
  const sessions = 'sessions {id classId startTime endTime price}';
  execute(dispatch, () => getClassesForBookingApiCall({
    providerId
  }, {
    offers: ['id', 'name', 'price', 'duration', 'model', sessions]
  }), CLASSES_FOR_BOOKING);
};
export const getBusinessClassDetails = id => async dispatch => {
  execute(dispatch, () => getBusinessClassDetailsApiCall({
    id
  }, {
    resources: ['id', 'name']
  }), CLASS_GET);
};

// Reset actions
export const resetClassState = () => async dispatch => {
  dispatch(resetState(PROVIDER_GET_CLASSES));
  dispatch(resetState(CLASSES_GET_MANY));
  dispatch(resetState(PROVIDER_ADD_CLASS));
  dispatch(resetState(PROVIDER_UPDATE_CLASS));
  dispatch(resetState(PROVIDER_CLASS_DELETE));
};
export const reorderClasses = (serviceId, oldPosition, newPosition) => async dispatch => {
  execute(dispatch, () => reorderClassesApiCall({
    serviceId,
    oldPosition,
    newPosition
  }), PROVIDER_REORDER_CLASSES, false, null, true);
};
export const toggleClassStatus = (id, isActive, serviceId) => async dispatch => {
  execute(dispatch, () => toggleClassStatusApiCall({
    id,
    isActive,
    serviceId
  }), CLASS_ACTIVE_STATUS, true);
};
export const restoreClass = id => async dispatch => {
  execute(dispatch, () => restoreClassApiCall({
    id
  }), CLASS_RESTORE);
};
export const classReducers = {
  one: basicReducer(CLASS_GET, data => {
    var _returnData$resources;
    const returnData = data.data;
    if ((_returnData$resources = returnData.resources) !== null && _returnData$resources !== void 0 && _returnData$resources.length) {
      returnData.resources = returnData.resources.map(c => ({
        label: c.name,
        value: c.id
      }));
      return returnData;
    }
    return returnData;
  }),
  all: basicReducer(PROVIDER_GET_CLASSES),
  active: basicReducer(CLASSES_GET_MANY),
  create: basicReducer(PROVIDER_ADD_CLASS),
  createCoursePlusClass: basicReducer(PROVIDER_COURSE_PLUS_CLASS),
  update: basicReducer(PROVIDER_UPDATE_CLASS),
  delete: basicReducer(PROVIDER_CLASS_DELETE),
  reorder: basicReducer(PROVIDER_REORDER_CLASSES),
  toggle: basicReducer(CLASS_ACTIVE_STATUS),
  restore: basicReducer(CLASS_RESTORE),
  allForBooking: basicReducer(CLASSES_FOR_BOOKING)
};