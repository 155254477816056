import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from '@guryou/hooks';
import { StateStatus } from '../core/StateStatus';
const defaultSelector = () => {
  return {
    status: StateStatus.NOT_INITIALIZED
  };
};
const useEffectOnLoading = function (stateSelector, effect) {
  let deps = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  const dispatch = useDispatch();
  const {
    data,
    status,
    version,
    entity
  } = useSelector(stateSelector || defaultSelector);
  useUpdateEffect(() => {
    if (status === StateStatus.LOADING) {
      effect();
    }
  }, [dispatch, status, ...deps]);
  return {
    data: data || [],
    status,
    version,
    entity
  };
};
export default useEffectOnLoading;