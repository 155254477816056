import { getProviderAnalyticsApiRequest } from '@guryou/api-client-gql';
import { execute } from '../actions/actionWrapper';
import { Action, basicReducer } from '../core';
export const GET_PROVIDER_ANALYTICS = {
  entity: 'GET_PROVIDER_ANALYTICS',
  action: Action.GET_ONE
};
export const getProviderAnalytics = () => async dispatch => execute(dispatch, () => getProviderAnalyticsApiRequest(), GET_PROVIDER_ANALYTICS, false, true);
export const providerAnalyticsReducers = {
  all: basicReducer(GET_PROVIDER_ANALYTICS)
};