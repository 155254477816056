import { archiveResourceApiCall, archiveWithReasignResourceApiCall, archiveWithReasignResourceToOwnerApiCall, createResourceApiRequest, editResourceApiCall, getAllArchivedResourcesApiRequest, getAllEventsByResourceApiCall, getAllNotAssignedResourcesOnProviderApiCall, getAllPersonnelApiRequest, getAllPhysicalPersonnelApiReqeust, getCollaboratorsApiRequest, getOffersByResourceApiRequest, getResourceAvailabilityDatesApiCall, getResourceAvailabilitySlotsApiCall, getResourcesByServiceApiCall, getResourcesBySubServiceApiCall, getResourceScheduleApiRequest, restoreArchivedResourceApiCall, saveResourceScheduleApiRequest } from '@guryou/api-client-gql';
import { addDays, addMonths, getWeekDay, printInputDate } from '@guryou/utils';
import { execute } from '../actions/actionWrapper';
import { resetState } from '../actions/resetActions';
import { Action, basicReducer } from '../core';
import { PROVIDER_SERVICES_GET_MANY } from '../new/service';
export const PERSONNEL_GET_MANY = {
  entity: 'PERSONNELS',
  action: Action.GET_MANY
};
export const PERSONNEL_GET_MANY2 = {
  entity: 'PERSONNELS2',
  action: Action.GET_MANY
};
export const PHYSICAL_PERSONNEL_GET_MANY = {
  entity: 'PHYSICAL_PERSONNELS',
  action: Action.GET_MANY
};
export const PERSONNEL_SCHEDULE_GET_MANY = {
  entity: 'PERSONNEL_SCHEDULE',
  action: Action.GET_MANY
};
export const PERSONNEL_SCHEDULE_UPDATE = {
  entity: 'PERSONNEL_SCHEDULE',
  action: Action.UPDATE
};
export const PERSONNEL_CREATE = {
  entity: 'PERSONNEL_SCHEDULE',
  action: Action.CREATE
};
export const PHYSICAL_PERSONNEL_CREATE = {
  entity: 'PHYSICAL_PERSONNEL_CREATE',
  action: Action.CREATE
};
export const PERSONNEL_EDIT = {
  entity: 'PERSONNEL_EDIT',
  action: Action.UPDATE
};
export const PERSONNEL_ARCHIVE = {
  entity: 'PERSONNEL_ARCHIVE',
  action: Action.DELETE
};
export const ALL_NOT_ASSIGNED_PERSONNELS = {
  entity: 'ALL_NOT_ASSIGNED_PERSONNELS',
  action: Action.GET_MANY
};
export const ALL_RESOURCE_UPCOMING_EVENTS = {
  entity: 'ALL_RESOURCE_UPCOMING_EVENTS',
  action: Action.GET_MANY
};
export const COLLABORATORS_GET_MANY = {
  entity: 'USER',
  action: Action.GET_MANY
};
export const PERSONNEL_TIME_SLOTS_GET_MANY = {
  entity: 'PERSONNEL_TIME_SLOTS',
  action: Action.GET_MANY
};
export const PERSONNEL_DATE_SLOTS_GET_MANY = {
  entity: 'PERSONNEL_DATE_SLOTS',
  action: Action.GET_MANY
};
export const PERSONNEL_HOLIDAYS_GET_MANY = {
  entity: 'PERSONNEL_HOLIDAY',
  action: Action.GET_MANY
};
export const RESOURCES_BY_SUBSERVICE = {
  entity: 'RESOURCES_BY_SUBSERVICE',
  action: Action.GET_MANY
};
export const RESOURCES_BY_SERVICE = {
  entity: 'RESOURCES_BY_SERVICE',
  action: Action.GET_MANY
};
export const PERSONNEL_HOLIDAY_CREATE = {
  entity: 'PERSONNEL_HOLIDAY',
  action: Action.CREATE
};
export const PERSONNEL_HOLIDAY_UPDATE = {
  entity: 'PERSONNEL_HOLIDAY',
  action: Action.UPDATE
};
export const PERSONNEL_HOLIDAY_DELETE = {
  entity: 'PERSONNEL_HOLIDAY',
  action: Action.DELETE
};
export const PERSONNEL_ARCHIVED_GET_MANY = {
  entity: 'PERSONNEL_ARCHIVED',
  action: Action.GET_MANY
};
export const PERSONNEL_ARCHIVED_RESTORE = {
  entity: 'PERSONNEL_ARCHIVED_RESTORE',
  action: Action.UPDATE
};
export const getPersonnelAvailabilitySlots = function (subServiceId, resourceId, date, reservationId, duration) {
  let isItDeal = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;
  let materialResource = arguments.length > 6 ? arguments[6] : undefined;
  return async dispatch => {
    execute(dispatch, () => getResourceAvailabilitySlotsApiCall({
      resourceId: +resourceId,
      subServiceId: +subServiceId,
      date,
      reservationId: +reservationId || null,
      duration: +duration || null,
      isItDeal,
      materialResource: +materialResource || null
    }), PERSONNEL_TIME_SLOTS_GET_MANY);
  };
};
export const getPersonnelAvailabilityDates = (subServiceId, resourceId, materialResourceId) => async dispatch => execute(dispatch, () => getResourceAvailabilityDatesApiCall({
  subServiceId: +subServiceId,
  resourceId: +resourceId,
  materialResourceId: +materialResourceId
}, {
  holidays: ['startTime', 'endTime', 'isWholeDay']
}), PERSONNEL_DATE_SLOTS_GET_MANY);
export const resetPersonnelAvailabilitySlots = () => async dispatch => {
  dispatch(resetState(PERSONNEL_TIME_SLOTS_GET_MANY));
  dispatch(resetState(PERSONNEL_DATE_SLOTS_GET_MANY));
};
export const resetPersonnelAvailabilityTime = () => async dispatch => {
  dispatch(resetState(PERSONNEL_TIME_SLOTS_GET_MANY));
};
export const resetResourceState = () => async dispatch => {
  dispatch(resetState(PERSONNEL_GET_MANY));
  dispatch(resetState(PERSONNEL_GET_MANY2));
  dispatch(resetState(PHYSICAL_PERSONNEL_GET_MANY));
  dispatch(resetState(PERSONNEL_CREATE));
  dispatch(resetState(PHYSICAL_PERSONNEL_CREATE));
};
export const getAllPersonnel = function (providerId) {
  let archivedAsWell = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return async dispatch => execute(dispatch, () => getAllPersonnelApiRequest(providerId, archivedAsWell), PERSONNEL_GET_MANY, false);
};
export const getArchivedPlusNormalPersonnel = function (providerId) {
  let archivedAsWell = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  return async dispatch => execute(dispatch, () => getAllPersonnelApiRequest(providerId, archivedAsWell), PERSONNEL_GET_MANY2, false);
};
export const getAllPhysicalPersonnel = providerId => async dispatch => execute(dispatch, () => getAllPhysicalPersonnelApiReqeust(providerId), PHYSICAL_PERSONNEL_GET_MANY, false);
export const getResourceOffers = resourceId => async dispatch => execute(dispatch, () => getOffersByResourceApiRequest(resourceId), PROVIDER_SERVICES_GET_MANY, false);
export const getPersonnelWorkHours = resourceId => async dispatch => execute(dispatch, () => getResourceScheduleApiRequest(resourceId), PERSONNEL_SCHEDULE_GET_MANY, false);
export const getCollaborators = () => async dispatch => execute(dispatch, () => getCollaboratorsApiRequest(), COLLABORATORS_GET_MANY, false);
export const saveResourceSchedule = (resourceId, request) => async dispatch => execute(dispatch, () => saveResourceScheduleApiRequest(resourceId, request), PERSONNEL_SCHEDULE_UPDATE);
export const createResource = _ref => {
  let {
    userId,
    ...rest
  } = _ref;
  return async dispatch => {
    execute(dispatch, () => createResourceApiRequest({
      userId: +userId,
      ...rest
    }), PERSONNEL_CREATE);
  };
};
export const createPhysicalResource = _ref2 => {
  let {
    userId,
    ...rest
  } = _ref2;
  return async dispatch => {
    execute(dispatch, () => createResourceApiRequest({
      userId: +userId,
      ...rest
    }), PHYSICAL_PERSONNEL_CREATE);
  };
};
export const editResource = (id, name, gender, role, userId, capacity) => async dispatch => execute(dispatch, () => editResourceApiCall({
  id,
  name,
  gender,
  role,
  userId,
  capacity
}), PERSONNEL_EDIT);
export const archiveResource = id => async dispatch => execute(dispatch, () => archiveResourceApiCall({
  id
}), PERSONNEL_ARCHIVE);
export const archiveWithReasignResource = (id, resourceId, past, future) => async dispatch => execute(dispatch, () => archiveWithReasignResourceApiCall({
  id,
  resourceId,
  past,
  future
}), PERSONNEL_ARCHIVE);
export const archiveWithReasignResourceToOwner = id => async dispatch => execute(dispatch, () => archiveWithReasignResourceToOwnerApiCall({
  id
}), PERSONNEL_ARCHIVE);
export const getAllNotAssignedResourcesOnProvider = () => async dispatch => execute(dispatch, () => getAllNotAssignedResourcesOnProviderApiCall(), ALL_NOT_ASSIGNED_PERSONNELS);
export const getResourcesBySubService = subServiceId => async dispatch => execute(dispatch, () => getResourcesBySubServiceApiCall({
  subServiceId
}), RESOURCES_BY_SUBSERVICE);
export const getResourcesByService = serviceId => async dispatch => execute(dispatch, () => getResourcesByServiceApiCall({
  serviceId
}), RESOURCES_BY_SERVICE);
export const getAllEventsByResource = id => async dispatch => execute(dispatch, () => getAllEventsByResourceApiCall({
  id
}, {
  reservations: ['id', 'startTime'],
  subscriptions: ['id', 'startTime']
}), ALL_RESOURCE_UPCOMING_EVENTS);
export const getAllArchivedResources = () => async dispatch => execute(dispatch, () => getAllArchivedResourcesApiRequest(), PERSONNEL_ARCHIVED_GET_MANY);
export const restoreArchivedResource = id => async dispatch => execute(dispatch, () => restoreArchivedResourceApiCall({
  id
}), PERSONNEL_ARCHIVED_RESTORE);
export const resourceReducers = {
  availabilityMobile: basicReducer(PERSONNEL_DATE_SLOTS_GET_MANY, data => {
    const receivedData = data.data;
    const startDate = receivedData.minDate;
    const endDate = printInputDate(addMonths(startDate, 12));
    const dateArray = [];
    let currentDate = startDate;
    while (currentDate <= endDate) {
      const dayOfDate = getWeekDay(currentDate);
      if (receivedData.availableDays.indexOf(dayOfDate) < 0) {
        dateArray.push(printInputDate(currentDate));
      }
      currentDate = printInputDate(addDays(currentDate, 1));
    }
    const response = Object.keys(dateArray).reduce((old, date) => {
      old[dateArray[date]] = {
        disabled: true,
        disableTouchEvent: true
      };
      return old;
    }, {});
    return response;
  }),
  resourceBySubService: basicReducer(RESOURCES_BY_SUBSERVICE, data => {
    const response = data.data.map(c => ({
      label: c.name,
      value: c.id
    }));
    return response;
  }),
  resourceByService: basicReducer(RESOURCES_BY_SERVICE, data => {
    const response = data.data.map(c => ({
      label: c.name,
      value: c.id
    }));
    return response;
  }),
  archivedResources: basicReducer(PERSONNEL_ARCHIVED_GET_MANY),
  restoreArchivedResource: basicReducer(PERSONNEL_ARCHIVED_RESTORE),
  archivedPlusNormalPersonnel: basicReducer(PERSONNEL_GET_MANY2)
};