import { sendMessageAnamnesiApiCall, sendMessageApiCall } from '@guryou/api-client-gql';
import { execute } from '../actions/actionWrapper';
import { Action, basicReducer } from '../core';
export const SEND_RESERVATION_REMINDER = {
  entity: 'SEND_RESERVATION_REMINDER',
  action: Action.CREATE
};
export const SEND_ANAMNESI_REMINDER = {
  entity: 'SEND_ANAMNESI_REMINDER',
  action: Action.CREATE
};
export const sendMessage = data => async dispatch => execute(dispatch, () => sendMessageApiCall(data), SEND_RESERVATION_REMINDER);
export const sendMessageAnamnesi = data => async dispatch => execute(dispatch, () => sendMessageAnamnesiApiCall(data), SEND_ANAMNESI_REMINDER);
export const sqsMessageReducers = {
  sendReminder: basicReducer(SEND_RESERVATION_REMINDER),
  sendReminderAnamnesi: basicReducer(SEND_ANAMNESI_REMINDER)
};