import { changeCustomerWalletStatusApiCall, createCustomerWalletApiCall, getCustomerWalletApiCall, getProviderCustomersWalletsApiCall, getWalletEventsApiCall, updateCustomerWalletApiCall } from '@guryou/api-client-gql';
import { execute } from '../actions/actionWrapper';
import { Action, basicReducer } from '../core';
import { DEFAULT_END_TIME, DEFAULT_START_TIME } from '..';
export const PROVIDER_CUSTOMERS_WALLETS = {
  entity: 'PROVIDER_CUSTOMERS_WALLETS',
  action: Action.GET_MANY
};
export const CREATE_CUSTOMER_WALLET = {
  entity: 'CREATE_CUSTOMER_WALLET',
  action: Action.CREATE
};
export const TOGGLE_WALLET_STATUS = {
  entity: 'TOGGLE_WALLET_STATUS',
  action: Action.UPDATE
};
export const WALLET_EVENTS = {
  entity: 'WALLET_EVENTS',
  action: Action.GET_MANY
};
export const CUSTOMER_WALLET = {
  entity: 'CUSTOMER_WALLET',
  action: Action.GET
};
export const UPDATE_WALLET = {
  entity: 'CUSTOMER_WALLET',
  action: Action.UPDATE
};

//WALLETS
export const getProviderCustomersWallets = function () {
  let silent = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  return async dispatch => execute(dispatch, () => getProviderCustomersWalletsApiCall({
    user: ['name']
  }), PROVIDER_CUSTOMERS_WALLETS, null, null, silent);
};
export const changeCustomerWalletStatus = (id, status) => async dispatch => execute(dispatch, () => changeCustomerWalletStatusApiCall({
  id,
  status
}), TOGGLE_WALLET_STATUS);
export const createCustomerWallet = data => async dispatch => {
  const {
    startDate,
    endDate,
    ...rest
  } = data;
  const request = {
    ...rest,
    startDate: "".concat(startDate, "T").concat(DEFAULT_START_TIME),
    endDate: "".concat(endDate, "T").concat(DEFAULT_END_TIME)
  };
  execute(dispatch, () => createCustomerWalletApiCall({
    request
  }), CREATE_CUSTOMER_WALLET);
};
export const updateCustomerWallet = request => async dispatch => execute(dispatch, () => updateCustomerWalletApiCall({
  request
}), UPDATE_WALLET);
export const getCustomerWallet = customerId => async dispatch => execute(dispatch, () => getCustomerWalletApiCall({
  customerId
}), CUSTOMER_WALLET);

//EVENTS
export const getWalletEvents = id => async dispatch => execute(dispatch, () => getWalletEventsApiCall({
  id
}), WALLET_EVENTS);
export const walletsReducer = {
  all: basicReducer(PROVIDER_CUSTOMERS_WALLETS),
  create: basicReducer(CREATE_CUSTOMER_WALLET),
  status: basicReducer(TOGGLE_WALLET_STATUS),
  events: basicReducer(WALLET_EVENTS),
  customer: basicReducer(CUSTOMER_WALLET),
  update: basicReducer(UPDATE_WALLET)
};