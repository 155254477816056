import { blockUserApiCall, convertWalkinIntoCustomerApiCall, copyDataFromProfileApiCall, createCustomerApiCall, createCustomerWalkInApiCall, createUsersFromCSVApiCall, createVisitatoreFromCSVApiCall, getArchivedCustomersApiCall, getCustomersApiCall, getInactiveCustomersApiCall, getProviderBlacklistedUsersApiCall, getUserProfileInfoForCopyApiCall, getUsersAssignedToProductApiCall, reinviteInactiveCustomersApiCall, removeCustomerApiCall, restoreCustomerApiCall, toggleBlockedUserContentApiCall, toggleUserBlacklistStatusApiCall, unblockUserApiCall, updateCustomerApiCall, updateCustomerGroupApiCall, updateWalkInCustomerApiCall } from '@guryou/api-client-gql';
import { execute } from '../actions/actionWrapper';
import { resetState } from '../actions/resetActions';
import { Action, basicReducer } from '../core';

// Types
export const PROVIDER_CUSTOMERS = {
  entity: 'PROVIDER_CUSTOMERS',
  action: Action.GET
};
export const CUSTOMER_CREATE = {
  entity: 'CUSTOMER_CREATE',
  action: Action.CREATE
};
export const CUSTOMERS_CREATE = {
  entity: 'CUSTOMERS_CREATE',
  action: Action.CREATE
};
export const CUSTOMER_UPDATE = {
  entity: 'CUSTOMER_UPDATE',
  action: Action.UPDATE
};
export const PROVIDER_BLACKLIST_USERS = {
  entity: 'PROVIDER_BLACKLIST_USERS',
  action: Action.GET_MANY
};
export const CUSTOMER_BLACKLIST_STATUS = {
  entity: 'CUSTOMER_BLACKLIST_STATUS',
  action: Action.UPDATE
};
export const CUSTOMER_BLACKLIST_BLOCK = {
  entity: 'CUSTOMER_BLACKLIST_BLOCK',
  action: Action.UPDATE
};
export const CUSTOMER_BLACKLIST_TOGGLE_CONTENT = {
  entity: 'CUSTOMER_BLACKLIST_TOGGLE_CONTENT',
  action: Action.UPDATE
};
export const CUSTOMER_BLACKLIST_UNBLOCK = {
  entity: 'CUSTOMER_BLACKLIST_UNBLOCK',
  action: Action.UPDATE
};
export const CUSTOMER_INFO_FOR_COPY = {
  entity: 'CUSTOMER_INFO_FOR_COPY',
  action: Action.GET_ONE
};
export const CUSTOMER_COPY_DATA = {
  entity: 'CUSTOMER_COPY_DATA',
  action: Action.UPDATE
};
export const REMOVE_CUSTOMER = {
  entity: 'REMOVE_CUSTOMER',
  action: Action.UPDATE
};
export const RESTORE_CUSTOMER = {
  entity: 'RESTORE_CUSTOMER',
  action: Action.UPDATE
};
export const GET_INACTIVE_CUSTOMERS = {
  entity: 'GET_INACTIVE',
  action: Action.GET_MANY
};
export const REINVITE_INACTIVE_CUSTOMERS = {
  entity: 'REINVITE_INACTIVE',
  action: Action.HANDLE
};
export const GET_USERS_ASSIGNED_TO_PRODUCT = {
  entity: 'GET_USERS_ASSIGNED_TO_PRODUCT',
  action: Action.GET_MANY
};
export const GET_ARCHIVED_CUSTOMERS = {
  entity: 'GET_ARCHIVED_CUSTOMERS',
  action: Action.GET_MANY
};
export const getProviderCustomers = () => async dispatch => execute(dispatch, () => getCustomersApiCall(), PROVIDER_CUSTOMERS);
export const createNewCustomer = request => async dispatch => {
  execute(dispatch, () => createCustomerApiCall({
    request
  }), CUSTOMER_CREATE);
};
export const createNewCustomerWalkIn = _ref => {
  let {
    name,
    note,
    svcGroupId
  } = _ref;
  return async dispatch => {
    execute(dispatch, () => createCustomerWalkInApiCall({
      name,
      note,
      svcGroupId
    }), CUSTOMER_CREATE);
  };
};
export const getProviderBlacklistedUsers = () => async dispatch => execute(dispatch, () => getProviderBlacklistedUsersApiCall(), PROVIDER_BLACKLIST_USERS);
export const toggleUserBlacklistStatus = (userId, blacklisted) => async dispatch => execute(dispatch, () => toggleUserBlacklistStatusApiCall({
  userId,
  blacklisted
}), CUSTOMER_BLACKLIST_STATUS, true, null, true);
export const blockUser = (userId, blockContent) => async dispatch => execute(dispatch, () => blockUserApiCall({
  userId,
  blockContent
}), CUSTOMER_BLACKLIST_BLOCK, true, null);
export const unblockUser = userId => async dispatch => execute(dispatch, () => unblockUserApiCall({
  userId
}), CUSTOMER_BLACKLIST_UNBLOCK, true, null, true);
export const toggleBlockedUserContent = (userId, blockContent) => async dispatch => execute(dispatch, () => toggleBlockedUserContentApiCall({
  userId,
  blockContent
}), CUSTOMER_BLACKLIST_TOGGLE_CONTENT, true, null, true);
export const updateCustomerGroup = (id, svcGroupId) => async dispatch => execute(dispatch, () => updateCustomerGroupApiCall({
  id,
  svcGroupId
}), CUSTOMER_UPDATE);
export const updateCustomer = customer => async dispatch => {
  const newCustomer = {
    ...customer,
    email: customer.email || null,
    note: customer.note || null,
    firstname: customer.firstname || null,
    lastname: customer.lastname || null,
    address: customer.address || null,
    phone: customer.phone || null,
    gender: customer.gender || null,
    birthday: customer.birthday || null,
    country: customer.country || null,
    codiceFiscale: customer.codiceFiscale || null,
    piva: customer.piva || null,
    sdi: customer.sdi || null
  };
  execute(dispatch, () => updateCustomerApiCall({
    customer: newCustomer
  }), CUSTOMER_UPDATE);
};
export const convertWalkinIntoCustomer = (id, name, email) => async dispatch => execute(dispatch, () => convertWalkinIntoCustomerApiCall({
  id,
  name,
  email
}), CUSTOMER_UPDATE);
export const updateWalkInCustomer = walkinCustomer => async dispatch => {
  const newWalkinCustomer = {
    ...walkinCustomer,
    email: walkinCustomer.email || null,
    note: walkinCustomer.note || null,
    firstname: walkinCustomer.firstname || null,
    lastname: walkinCustomer.lastname || null,
    address: walkinCustomer.address || null,
    phone: walkinCustomer.phone || null,
    gender: walkinCustomer.gender || null,
    birthday: walkinCustomer.birthday || null,
    country: walkinCustomer.country || null,
    codiceFiscale: walkinCustomer.codiceFiscale || null,
    piva: walkinCustomer.piva || null,
    sdi: walkinCustomer.sdi || null
  };
  execute(dispatch, () => updateWalkInCustomerApiCall({
    walkinCustomer: newWalkinCustomer
  }), CUSTOMER_UPDATE);
};
export const createUsersFromCSV = (customers, svcGroupId) => async dispatch => execute(dispatch, () => createUsersFromCSVApiCall({
  customers,
  svcGroupId
}), CUSTOMERS_CREATE);
export const createVisitatoreFromCSV = (customers, svcGroupId) => async dispatch => execute(dispatch, () => createVisitatoreFromCSVApiCall({
  customers,
  svcGroupId
}), CUSTOMERS_CREATE);
export const getUserProfileInfoForCopy = id => async dispatch => execute(dispatch, () => getUserProfileInfoForCopyApiCall({
  id
}), CUSTOMER_INFO_FOR_COPY, false, null, true);
export const copyDataFromProfile = id => async dispatch => execute(dispatch, () => copyDataFromProfileApiCall({
  id
}), CUSTOMER_COPY_DATA);
export const removeCustomer = id => async dispatch => execute(dispatch, () => removeCustomerApiCall({
  id
}), REMOVE_CUSTOMER);
export const restoreCustomer = id => async dispatch => execute(dispatch, () => restoreCustomerApiCall({
  id: +id
}), RESTORE_CUSTOMER);
export const resetCreateNewCustomerState = () => async dispatch => {
  dispatch(resetState(CUSTOMER_CREATE));
};
export const reinviteInactiveCustomers = inactiveCustomers => async dispatch => execute(dispatch, () => reinviteInactiveCustomersApiCall({
  inactiveCustomers
}), REINVITE_INACTIVE_CUSTOMERS, true);
export const getInactiveCustomers = () => async dispatch => execute(dispatch, () => getInactiveCustomersApiCall(), GET_INACTIVE_CUSTOMERS);
export const getUsersAssignedToProduct = productId => async dispatch => execute(dispatch, () => getUsersAssignedToProductApiCall({
  productId
}), GET_USERS_ASSIGNED_TO_PRODUCT);
export const getArchivedCustomers = () => async dispatch => execute(dispatch, () => getArchivedCustomersApiCall(), GET_ARCHIVED_CUSTOMERS);
export const customerReducers = {
  providerCustomers: basicReducer(PROVIDER_CUSTOMERS),
  create: basicReducer(CUSTOMER_CREATE),
  update: basicReducer(CUSTOMER_UPDATE),
  blacklist: basicReducer(PROVIDER_BLACKLIST_USERS),
  blackListStatus: basicReducer(CUSTOMER_BLACKLIST_STATUS),
  csv: basicReducer(CUSTOMERS_CREATE),
  block: basicReducer(CUSTOMER_BLACKLIST_BLOCK),
  unblock: basicReducer(CUSTOMER_BLACKLIST_UNBLOCK),
  toggleBlockedUserContent: basicReducer(CUSTOMER_BLACKLIST_TOGGLE_CONTENT),
  infoForCopy: basicReducer(CUSTOMER_INFO_FOR_COPY),
  copyData: basicReducer(CUSTOMER_COPY_DATA),
  removeCustomer: basicReducer(REMOVE_CUSTOMER),
  restoreCustomer: basicReducer(RESTORE_CUSTOMER),
  inactiveCustomers: basicReducer(GET_INACTIVE_CUSTOMERS),
  reinviteInactiveCustomers: basicReducer(REINVITE_INACTIVE_CUSTOMERS),
  assignedUsersToProduct: basicReducer(GET_USERS_ASSIGNED_TO_PRODUCT),
  archivedCustomers: basicReducer(GET_ARCHIVED_CUSTOMERS)
};