import { Action } from '../core';
const CHECK_COUPON = {
  entity: 'CHECK_COUPON',
  action: Action.VALIDATE
};
const CREATE_CUSTOMER_BILLING_INFO_HANDLE = {
  entity: 'CREATE_CUSTOMER_BILLING_INFO',
  action: Action.CREATE
};
const GET_PLANS = {
  entity: 'PLANS',
  action: Action.GET
};
const OWNER_PROVIDER_CHECK_STRIPE_CUSTOMER_HANDLE = {
  entity: 'OWNER_PROVIDER_CHECK_STRIPE_CUSTOMER',
  action: Action.HANDLE
};
const OWNER_SUBSCRIPTIONS_GET = {
  entity: 'OWNER_SUBSCRIPTIONS',
  action: Action.GET
};
const PROVIDER_CHECK_STRIPE_CUSTOMER_HANDLE = {
  entity: 'PROVIDER_CHECK_STRIPE_CUSTOMER',
  action: Action.HANDLE
};
const PROVIDER_SUBSCRIPTION_GET = {
  entity: 'PROVIDER_SUBSCRIPTION',
  action: Action.GET
};
const SEND_BILLING_INFO_AND_SUBSCRIBE_HANDLE = {
  entity: 'SEND_BILLING_INFO_AND_SUBSCRIBE',
  action: Action.HANDLE
};
const SEND_CONFIRM_SUB_B2B_HANDLE = {
  entity: 'SEND_CONFIRM_SUB_B2B',
  action: Action.HANDLE
};
const SEND_PAYMENT_METHOD_TOKEN_TO_BACKEND_HANDLE = {
  entity: 'SEND_PAYMENT_METHOD_TOKEN_TO_BACKEND',
  action: Action.HANDLE
};
const SEND_STRIPE_AUTH_CODE = {
  entity: 'SEND_STRIPE_AUTH_CODE',
  action: Action.HANDLE
};
const SEND_STRIPE_FROM_OTHER_PROVIDER_HANDLE = {
  entity: 'SEND_STRIPE_FROM_OTHER_PROVIDER',
  action: Action.HANDLE
};
const SEND_SUBSCRIBE_BACKEND_HANDLE = {
  entity: 'SEND_SUBSCRIBE_BACKEND',
  action: Action.HANDLE
};
const PURCHASES_GET_MANY = {
  entity: 'PURCHASES',
  action: Action.GET_MANY
};
const PAYMENT_INTENT_GET = {
  entity: 'PAYMENT_INTENT',
  action: Action.GET
};
export { PAYMENT_INTENT_GET, PURCHASES_GET_MANY, CHECK_COUPON, CREATE_CUSTOMER_BILLING_INFO_HANDLE, GET_PLANS, OWNER_PROVIDER_CHECK_STRIPE_CUSTOMER_HANDLE, OWNER_SUBSCRIPTIONS_GET, PROVIDER_CHECK_STRIPE_CUSTOMER_HANDLE, PROVIDER_SUBSCRIPTION_GET, SEND_BILLING_INFO_AND_SUBSCRIBE_HANDLE, SEND_CONFIRM_SUB_B2B_HANDLE, SEND_PAYMENT_METHOD_TOKEN_TO_BACKEND_HANDLE, SEND_STRIPE_AUTH_CODE, SEND_STRIPE_FROM_OTHER_PROVIDER_HANDLE, SEND_SUBSCRIBE_BACKEND_HANDLE };