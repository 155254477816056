import { activateEmailTemplateApiCall, deleteEmailTemplateApiCall, getAllEmailTemplatesApiCall, saveEmailTemplateApiCall, sendTestTemplateMailApiCall, updateEmailTemplateApiCall } from '@guryou/api-client-gql';
import { execute } from '../actions/actionWrapper';
import { Action, basicReducer } from '../core';
export const PROVIDER_GET_ALL_EMAIL_TEMLPATES = {
  entity: 'PROVIDER_ALL_EMAIL_TEMLPATES',
  action: Action.GET_MANY
};
export const PROVIDER_CREATE_EMAIL_TEMPLATE = {
  entity: 'PROVIDER_EMAIL_TEMLPATES',
  action: Action.CREATE
};
export const PROVIDER_UPDATE_EMAIL_TEMPLATE = {
  entity: 'PROVIDER_EMAIL_TEMLPATES',
  action: Action.UPDATE
};
export const PROVIDER_DELETE_EMAIL_TEMPLATE = {
  entity: 'PROVIDER_EMAIL_TEMLPATES',
  action: Action.DELETE
};
export const PROVIDER_ACTIVATE_EMAIL_TEMPLATE = {
  entity: 'PROVIDER_ACTIVATE_EMAIL_TEMPLATE',
  action: Action.HANDLE
};
export const SEND_TEST_EMAIL_TEMPLATE = {
  entity: 'SEND_TEST_EMAIL_TEMPLATE',
  action: Action.CREATE
};
export const getAllEmailTemplates = () => async dispatch => execute(dispatch, () => getAllEmailTemplatesApiCall(), PROVIDER_GET_ALL_EMAIL_TEMLPATES, false, true);
export const saveEmailTemplate = data => async dispatch => {
  const {
    color,
    ...rest
  } = data;
  execute(dispatch, () => saveEmailTemplateApiCall({
    template: {
      color: color.replace('#', ''),
      ...rest
    }
  }), PROVIDER_CREATE_EMAIL_TEMPLATE, true);
};
export const updateEmailTemplate = data => async dispatch => {
  const {
    id,
    color,
    active,
    ...rest
  } = data;
  execute(dispatch, () => updateEmailTemplateApiCall({
    id,
    template: {
      color: color.replace('#', ''),
      active: active === 'true' ? true : false,
      ...rest
    }
  }), PROVIDER_UPDATE_EMAIL_TEMPLATE, true);
};
export const deleteEmailTemplate = data => async dispatch => execute(dispatch, () => deleteEmailTemplateApiCall({
  ...data
}), PROVIDER_DELETE_EMAIL_TEMPLATE, true);
export const activateEmailTemplate = data => async dispatch => execute(dispatch, () => activateEmailTemplateApiCall({
  ...data
}), PROVIDER_ACTIVATE_EMAIL_TEMPLATE, true);
export const sendTestTemplateMail = () => async dispatch => execute(dispatch, () => sendTestTemplateMailApiCall(), SEND_TEST_EMAIL_TEMPLATE, true);
export const emailTemplateReducers = {
  all: basicReducer(PROVIDER_GET_ALL_EMAIL_TEMLPATES),
  create: basicReducer(PROVIDER_CREATE_EMAIL_TEMPLATE),
  update: basicReducer(PROVIDER_UPDATE_EMAIL_TEMPLATE),
  delete: basicReducer(PROVIDER_DELETE_EMAIL_TEMPLATE),
  activate: basicReducer(PROVIDER_ACTIVATE_EMAIL_TEMPLATE)
};