import { broadcastMessageByProviderApiCall, getBroadcastMessagesApiCall } from '@guryou/api-client-gql';
import { execute } from '../actions/actionWrapper';
import { Action, basicReducer } from '../core';
export const PROVIDER_GET_BROADCAST_MESSAGES = {
  entity: 'PROVIDER_GET_BROADCAST_MESSAGES',
  action: Action.GET_MANY
};
export const PROVIDER_BROADCAST_MESSAGE = {
  entity: 'PROVIDER_BROADCAST_MESSAGE',
  action: Action.HANDLE
};
export const broadcastMessageByProvider = (subject, message) => async dispatch => execute(dispatch, () => broadcastMessageByProviderApiCall({
  subject,
  message
}), PROVIDER_BROADCAST_MESSAGE, true);
export const getBroadcastMessagesByProvider = () => async dispatch => execute(dispatch, () => getBroadcastMessagesApiCall(), PROVIDER_GET_BROADCAST_MESSAGES);
export const broadcastReducers = {
  getMessages: basicReducer(PROVIDER_GET_BROADCAST_MESSAGES),
  publishMessage: basicReducer(PROVIDER_BROADCAST_MESSAGE)
};