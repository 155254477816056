import { changeUserPlanStartEndTimeApiCall, changeUserWorkoutPlanStatusApiCall, changeUserWorkoutScheduleApiCall, changeWorkoutPlanStatusApiCall, checkIfPlanShouldUpdateFromCurrentConfigApiCall, createCircuitApiCall, createCircuitExerciseApiCall, createCircuitExerciseEquipmentApiCall, createUserCircuitApiCall, createUserCircuitEquipmentApiCall, createUserWorkoutPlanApiCall, createUserWorkoutPlanByGroupApiCall, createWorkoutApiCall, createWorkoutPlanApiCall, deleteWorkoutApiCall, deleteWorkoutEquipmentOverrideItemApiCall, deleteWorkoutInPlanApiCall, deleteWorkoutItemApiCall, duplicateWorkoutApiCall, duplicateWorkoutPlanApiCall, getAllNotAssignedGroupsForPlanApiCall, getAllNotAssignedUsersForPlanApiCall, getAllUserWorkoutActivitiesApiRequest, getAllUserWorkoutPlansApiRequest, getProviderArchivedWorkoutPlansApiCall, getProviderUsersWorkoutPlansApiRequest, getProviderWorkoutPlansApiCall, getUserWorkoutPlanDatesApiCall, getUserWorkoutPlanDetailsApiRequest, getUserWorkoutProgressDetailsByGroupApiRequest, getUserWorkoutProgressDetailsByOwnerApiRequest, getWorkoutDetailsApiRequest, getWorkoutPlanDetailsApiCall, getWorkoutsApiCall, reorderCircuitItemsApiCall, restoreWorkoutPlanApiCall, toggleWorkoutStatusApiCall, updateCircuitApiCall, updateCircuitExerciseApiCall, updateCircuitExerciseEquipmentApiCall, updateUserCircuitApiCall, updateUserCircuitEquipmentApiCall, updateUserWorkoutPlanFromCurrentPlanConfigurationApiCall, updateWorkoutApiCall, updateWorkoutPlanApiCall } from '@guryou/api-client-gql';
import { parseAsBool } from '../../../utils/build/stringUtils';
import { execute } from '../actions/actionWrapper';
import { Action, basicReducer } from '../core';
export const WORKOUTS_GET = {
  entity: 'WORKOUTS',
  action: Action.GET_MANY
};
export const WORKOUT_DETAILS = {
  entity: 'WORKOUTS',
  action: Action.GET_ONE
};
export const WORKOUT_CREATE = {
  entity: 'WORKOUTS',
  action: Action.CREATE
};
export const WORKOUT_UPDATE = {
  entity: 'WORKOUTS',
  action: Action.UPDATE
};
export const WORKOUT_DELETE = {
  entity: 'WORKOUTS',
  action: Action.DELETE
};
export const WORKOUT_DUPLICATE = {
  entity: 'WORKOUTS_DUPLICATE',
  action: Action.HANDLE
};
export const WORKOUT_STATUS = {
  entity: 'WORKOUTS',
  action: Action.UPDATE
};
export const WORKOUT_CIRCUIT_CREATE = {
  entity: 'WORKOUT_CIRCUIT',
  action: Action.CREATE
};
export const WORKOUT_CIRCUIT_UPDATE = {
  entity: 'WORKOUT_CIRCUIT',
  action: Action.UPDATE
};
export const WORKOUT_CIRCUIT_DELETE = {
  entity: 'WORKOUT_CIRCUIT',
  action: Action.DELETE
};
export const WORKOUT_CIRCUIT_EXERCISE_CREATE = {
  entity: 'WORKOUT_CIRCUIT_EXERCISE',
  action: Action.CREATE
};
export const WORKOUT_CIRCUIT_EXERCISE_UPDATE = {
  entity: 'WORKOUT_CIRCUIT_EXERCISE',
  action: Action.UPDATE
};
export const WORKOUT_CIRCUIT_EXERCISE_DELETE = {
  entity: 'WORKOUT_CIRCUIT_EXERCISE',
  action: Action.DELETE
};
export const WORKOUT_CIRCUIT_EXERCISE_EQUIPMENT_CREATE = {
  entity: 'WORKOUT_CIRCUIT_EXERCISE_EQUIPMENT',
  action: Action.CREATE
};
export const WORKOUT_CIRCUIT_EXERCISE_EQUIPMENT_UPDATE = {
  entity: 'WORKOUT_CIRCUIT_EXERCISE_EQUIPMENT',
  action: Action.UPDATE
};
export const WORKOUT_CIRCUIT_EXERCISE_EQUIPMENT_DELETE = {
  entity: 'WORKOUT_CIRCUIT_EXERCISE_EQUIPMENT',
  action: Action.DELETE
};
export const WORKOUT_CIRCUIT_EXERCISE_REORDER = {
  entity: 'WORKOUT_CIRCUIT',
  action: Action.REORDER
};
export const WORKOUT_PLANS_TEMPLATES = {
  entity: 'WORKOUT_PLANS_TEMPLATES',
  action: Action.GET_MANY
};
export const ARCHIVED_WORKOUT_PLANS_TEMPLATES = {
  entity: 'ARCHIVED_WORKOUT_PLANS_TEMPLATES',
  action: Action.GET_MANY
};
export const WORKOUT_PLAN_CREATE = {
  entity: 'WORKOUT_PLAN',
  action: Action.CREATE
};
export const WORKOUT_PLAN_UPDATE = {
  entity: 'WORKOUT_PLAN',
  action: Action.UPDATE
};
export const WORKOUT_PLAN_DELETE = {
  entity: 'WORKOUT_PLAN',
  action: Action.DELETE
};
export const WORKOUT_PLAN_RESTORE = {
  entity: 'WORKOUT_PLAN',
  action: Action.HANDLE
};
export const WORKOUT_PLAN_DUPLICATE = {
  entity: 'WORKOUT_PLAN_DUPLICATE',
  action: Action.HANDLE
};
export const WORKOUT_PLAN_STATUS = {
  entity: 'WORKOUT_PLAN_STATUS',
  action: Action.UPDATE
};
export const WORKOUT_PLAN_DETAILS = {
  entity: 'WORKOUT_PLAN_DETAILS',
  action: Action.GET_ONE
};
export const USERS_WORKOUT_PLANS = {
  entity: 'USERS_WORKOUT_PLANS',
  action: Action.GET_MANY
};
export const USERS_WORKOUT_PLAN_CREATE = {
  entity: 'USERS_WORKOUT_PLAN',
  action: Action.CREATE
};
export const USER_WORKOUT_PLAN_STATUS = {
  entity: 'USER_WORKOUT_PLAN_STATUS',
  action: Action.UPDATE
};
export const USER_WORKOUT_PLAN_DETAILS = {
  entity: 'USER_WORKOUT_PLAN_DETAILS',
  action: Action.GET_ONE
};
export const USER_WORKOUT_CIRCUIT_CREATE = {
  entity: 'USER_WORKOUT_CIRCUIT',
  action: Action.CREATE
};
export const USER_WORKOUT_CIRCUIT_UPDATE = {
  entity: 'USER_WORKOUT_CIRCUIT',
  action: Action.UPDATE
};
export const USER_WORKOUT_CIRCUIT_EQUIPMENT_CREATE = {
  entity: 'USER_WORKOUT_CIRCUIT_EQUIPMENT',
  action: Action.CREATE
};
export const USER_WORKOUT_CIRCUIT_EQUIPMENT_UPDATE = {
  entity: 'USER_WORKOUT_CIRCUIT_EQUIPMENT',
  action: Action.UPDATE
};
export const USER_WORKOUT_SCHEDULE_UPDATE = {
  entity: 'USER_WORKOUT_SCHEDULE',
  action: Action.UPDATE
};
export const USERS_WORKOUT_PLAN_PROGRESS = {
  entity: 'USERS_WORKOUT_PLAN_PROGRESS',
  action: Action.GET
};
export const USERS_WORKOUT_GROUP_PLAN_PROGRESS = {
  entity: 'USERS_WORKOUT_GROUP_PLAN_PROGRESS',
  action: Action.GET
};
export const USER_ALL_WORKOUTS = {
  entity: 'USER_ALL_WORKOUTS',
  action: Action.GET_MANY
};
export const USER_ALL_ACTIVITIES = {
  entity: 'USER_ALL_ACTIVITIES',
  action: Action.GET_MANY
};
export const USER_PLAN_CHANGE_START_END_TIME = {
  entity: 'USER_PLAN_CHANGE_START_END_TIME',
  action: Action.HANDLE
};
export const UPDATE_USER_PLAN_WITH_CURRENT_PLAN_CONFIGURATION = {
  entity: 'UPDATE_USER_PLAN_WITH_CURRENT_PLAN_CONFIGURATION',
  action: Action.UPDATE
};
export const CHECK_IF_PLAN_SHOULD_UPDATE_FROM_CURRENT_CONFIGURATION = {
  entity: 'CHECK_IF_PLAN_SHOULD_UPDATE_FROM_CURRENT_CONFIGURATION',
  action: Action.GET
};
export const ALL_NOT_ASSIGNED_USERS_BY_PLAN = {
  entity: 'ALL_NOT_ASSIGNED_USERS_BY_PLAN',
  action: Action.GET_MANY
};
export const ALL_NOT_ASSIGNED_GROUPS_BY_PLAN = {
  entity: 'ALL_NOT_ASSIGNED_GROUPS_BY_PLAN',
  action: Action.GET_MANY
};
export const USER_WORKOUT_PLAN_DATES = {
  entity: 'USER_WORKOUT_PLAN_DATES',
  action: Action.GET_MANY
};
export const getWorkouts = silent => async dispatch => execute(dispatch, () => getWorkoutsApiCall(), WORKOUTS_GET, false, null, silent);
export const getWorkoutDetails = (id, userId, groupId) => async dispatch => execute(dispatch, () => getWorkoutDetailsApiRequest(id, userId, false, groupId), WORKOUT_DETAILS, false);
export const createWorkout = request => async dispatch => execute(dispatch, () => createWorkoutApiCall({
  request
}), WORKOUT_CREATE);
export const updateWorkout = data => async dispatch => {
  const {
    price,
    ...request
  } = data;
  execute(dispatch, () => updateWorkoutApiCall({
    request: {
      ...request,
      price: +price
    }
  }), WORKOUT_UPDATE);
};
export const createCircuit = (workoutId, title) => async dispatch => execute(dispatch, () => createCircuitApiCall({
  workoutId,
  title
}), WORKOUT_CIRCUIT_CREATE);
export const updateCircuit = _ref => {
  let {
    repetitions,
    idx,
    ...rest
  } = _ref;
  return async dispatch => execute(dispatch, () => updateCircuitApiCall({
    request: {
      repetitions: +repetitions,
      ...rest
    }
  }), WORKOUT_CIRCUIT_UPDATE, false, {
    repetitions,
    idx: +idx,
    ...rest
  }, true);
};
export const createCircuitExercise = circuitId => async dispatch => execute(dispatch, () => createCircuitExerciseApiCall({
  circuitId
}), WORKOUT_CIRCUIT_EXERCISE_CREATE, false, {
  circuitId
}, true);
export const updateCircuitExercise = _ref2 => {
  let {
    executionMinValue,
    executionMaxValue,
    restTime,
    circuitId,
    repetitions,
    idx,
    ...rest
  } = _ref2;
  return async dispatch => execute(dispatch, () => updateCircuitExerciseApiCall({
    request: {
      executionMinValue: +executionMinValue,
      executionMaxValue: +executionMaxValue,
      restTime: +restTime,
      repetitions: +repetitions || 1,
      ...rest
    }
  }), WORKOUT_CIRCUIT_EXERCISE_UPDATE, false, {
    executionMinValue,
    executionMaxValue,
    restTime,
    circuitId,
    idx: +idx,
    repetitions: +repetitions || 1,
    ...rest
  }, true);
};
export const createCircuitExerciseEquipment = _ref3 => {
  let {
    circuitId,
    workoutCircuitId,
    quantity,
    weight,
    equipmentId,
    ...rest
  } = _ref3;
  return async dispatch => execute(dispatch, () => createCircuitExerciseEquipmentApiCall({
    request: {
      workoutCircuitId,
      quantity: +quantity || 1,
      weight: +weight,
      equipmentId
    }
  }), WORKOUT_CIRCUIT_EXERCISE_EQUIPMENT_CREATE, false, {
    circuitId,
    workoutCircuitId,
    quantity: +quantity || 1,
    weight: +weight,
    equipmentId,
    ...rest
  }, true);
};
export const updateCircuitExerciseEquipment = _ref4 => {
  let {
    quantity,
    weight,
    circuitId,
    id,
    equipmentId,
    weightMode,
    ...rest
  } = _ref4;
  return async dispatch => execute(dispatch, () => updateCircuitExerciseEquipmentApiCall({
    request: {
      quantity: +quantity,
      weight: +weight,
      id,
      equipmentId,
      weightMode: weightMode || null
    }
  }), WORKOUT_CIRCUIT_EXERCISE_EQUIPMENT_UPDATE, false, {
    quantity,
    weight,
    circuitId,
    id,
    equipmentId,
    ...rest
  }, true);
};
export const deleteWorkout = id => async dispatch => execute(dispatch, () => deleteWorkoutApiCall({
  id
}), WORKOUT_DELETE, false, {
  id
}, true);
export const duplicateWorkout = id => async dispatch => execute(dispatch, () => duplicateWorkoutApiCall({
  id
}), WORKOUT_DUPLICATE);
export const toggleWorkoutStatus = (id, active) => async dispatch => execute(dispatch, () => toggleWorkoutStatusApiCall({
  id,
  active
}), WORKOUT_STATUS, false, true);
export const deleteWorkoutItem = (_ref5, isCircuit) => {
  let {
    id,
    ...rest
  } = _ref5;
  return async dispatch => execute(dispatch, () => deleteWorkoutItemApiCall({
    id
  }), isCircuit ? WORKOUT_CIRCUIT_DELETE : WORKOUT_CIRCUIT_EXERCISE_DELETE, false, {
    id,
    isCircuit,
    ...rest
  }, true);
};
export const deleteWorkoutEquipmentOverrideItem = _ref6 => {
  let {
    id,
    workoutCircuitsEquipmentConfigId,
    ...rest
  } = _ref6;
  return async dispatch => execute(dispatch, () => deleteWorkoutEquipmentOverrideItemApiCall({
    id: id || workoutCircuitsEquipmentConfigId
  }), WORKOUT_CIRCUIT_EXERCISE_EQUIPMENT_DELETE, false, {
    id,
    workoutCircuitsEquipmentConfigId,
    ...rest
  }, true);
};
export const reorderCircuitItems = _ref7 => {
  let {
    firstId,
    secondId,
    ...rest
  } = _ref7;
  return async dispatch => execute(dispatch, () => reorderCircuitItemsApiCall({
    firstId,
    secondId
  }), WORKOUT_CIRCUIT_EXERCISE_REORDER, false, {
    firstId,
    secondId,
    ...rest
  }, true);
};
export const getProviderWorkoutPlans = userId => async dispatch => execute(dispatch, () => getProviderWorkoutPlansApiCall({
  userId
}, {
  planSchedules: ['id', 'workoutId', 'workoutWeekdays', 'workoutTimeFrom', 'workoutTimeTo', 'title', 'workoutOrder', 'workoutSchedule'],
  planGroup: ['id', 'name', 'providerId', 'color', 'type']
}), WORKOUT_PLANS_TEMPLATES, false);
export const getProviderArchivedWorkoutPlans = () => async dispatch => execute(dispatch, () => getProviderArchivedWorkoutPlansApiCall({
  planSchedules: ['id,workoutId,workoutWeekdays,workoutTimeFrom,workoutTimeTo,title']
}), ARCHIVED_WORKOUT_PLANS_TEMPLATES, false);
export const changeWorkoutPlanStatus = (id, active) => async dispatch => {
  execute(dispatch, () => changeWorkoutPlanStatusApiCall({
    id,
    active
  }), WORKOUT_PLAN_STATUS, true);
};
export const getWorkoutPlanDetails = id => async dispatch => {
  execute(dispatch, () => getWorkoutPlanDetailsApiCall({
    id
  }, {
    planSchedules: ['id,workoutId,workoutWeekdays,workoutTimeFrom,workoutTimeTo,title']
  }), WORKOUT_PLAN_DETAILS, false);
};
export const createWorkoutPlan = data => async dispatch => {
  const {
    price,
    numberOfWeeks,
    isPublic,
    planGroupId,
    description,
    title,
    workouts,
    imageId
  } = data;
  const imageUUID = imageId || null;
  const request = {
    price: +price,
    numberOfWeeks: +numberOfWeeks,
    public: parseAsBool(isPublic),
    imageId: imageUUID,
    planGroupId: planGroupId ? +planGroupId : null,
    description,
    title,
    workouts
  };
  execute(dispatch, () => createWorkoutPlanApiCall({
    request
  }), WORKOUT_PLAN_CREATE, true);
};
export const updateWorkoutPlan = data => async dispatch => {
  const {
    price,
    numberOfWeeks,
    isPublic,
    imageId,
    planGroupId,
    description,
    title,
    workouts,
    id
  } = data;
  const imageUUID = imageId || null;
  const request = {
    id,
    price: +price,
    numberOfWeeks: +numberOfWeeks,
    public: parseAsBool(isPublic),
    imageId: imageUUID,
    planGroupId: planGroupId ? +planGroupId : null,
    description,
    title,
    workouts
  };
  execute(dispatch, () => updateWorkoutPlanApiCall({
    request
  }), WORKOUT_PLAN_UPDATE, true);
};
export const deleteWorkoutPlan = id => async dispatch => execute(dispatch, () => deleteWorkoutInPlanApiCall({
  id
}), WORKOUT_PLAN_DELETE, true);
export const restoreWorkoutPlan = id => async dispatch => execute(dispatch, () => restoreWorkoutPlanApiCall({
  id
}), WORKOUT_PLAN_RESTORE, true);
export const duplicateWorkoutPlan = id => async dispatch => execute(dispatch, () => duplicateWorkoutPlanApiCall({
  id
}), WORKOUT_PLAN_DUPLICATE, true);
export const getProviderUsersWorkoutPlans = function () {
  let silent = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  return async dispatch => execute(dispatch, () => getProviderUsersWorkoutPlansApiRequest(), USERS_WORKOUT_PLANS, false, null, silent);
};
export const createUserWorkoutPlan = data => async dispatch => {
  const {
    price,
    numberOfWeeks,
    userId,
    groupId,
    ...rest
  } = data;
  const request = {
    price: +price,
    numberOfWeeks: +numberOfWeeks,
    userId: userId ? +userId : null,
    groupId: groupId ? +groupId : null,
    ...rest
  };
  execute(dispatch, () => createUserWorkoutPlanApiCall({
    request
  }), USERS_WORKOUT_PLAN_CREATE, true);
};
export const updateUserWorkoutPlanFromCurrentPlanConfiguration = (userPlanId, startTime) => async dispatch => {
  execute(dispatch, () => updateUserWorkoutPlanFromCurrentPlanConfigurationApiCall({
    userPlanId,
    startTime
  }), UPDATE_USER_PLAN_WITH_CURRENT_PLAN_CONFIGURATION, true);
};
export const checkIfPlanShouldUpdateFromCurrentConfig = userPlanId => async dispatch => {
  execute(dispatch, () => checkIfPlanShouldUpdateFromCurrentConfigApiCall({
    userPlanId
  }), CHECK_IF_PLAN_SHOULD_UPDATE_FROM_CURRENT_CONFIGURATION, false);
};
export const createUserWorkoutPlanByGroup = data => async dispatch => {
  const {
    price,
    numberOfWeeks,
    groupId,
    ...rest
  } = data;
  const request = {
    price: +price,
    numberOfWeeks: +numberOfWeeks,
    groupId: +groupId,
    ...rest
  };
  execute(dispatch, () => createUserWorkoutPlanByGroupApiCall({
    request
  }), USERS_WORKOUT_PLAN_CREATE, true);
};
export const changeUserWorkoutPlanStatus = (id, active) => async dispatch => {
  execute(dispatch, () => changeUserWorkoutPlanStatusApiCall({
    id,
    active
  }), USER_WORKOUT_PLAN_STATUS, true);
};
export const getUserWorkoutPlanDetails = (workoutPlanId, userId, groupId, silent) => async dispatch => {
  execute(dispatch, () => getUserWorkoutPlanDetailsApiRequest(workoutPlanId, userId, groupId), USER_WORKOUT_PLAN_DETAILS, false, null, silent);
};
export const createUserCircuit = data => async dispatch => {
  const {
    repetitions,
    restTime,
    executionMaxValue,
    executionMinValue,
    id,
    userId,
    groupId,
    indexes
  } = data;
  const request = {
    repetitions: +repetitions,
    restTime: +restTime,
    executionMaxValue: +executionMaxValue,
    executionMinValue: +executionMinValue,
    userId,
    groupId,
    circuitId: id
  };
  execute(dispatch, () => createUserCircuitApiCall({
    request
  }), USER_WORKOUT_CIRCUIT_CREATE, false, {
    ...indexes
  });
};
export const updateUserCircuit = data => async dispatch => {
  const {
    repetitions,
    restTime,
    executionMaxValue,
    executionMinValue,
    userCircuitId,
    userId,
    groupId,
    id
  } = data;
  const request = {
    repetitions: +repetitions,
    restTime: +restTime,
    executionMaxValue: +executionMaxValue,
    executionMinValue: +executionMinValue,
    userId,
    groupId,
    id: userCircuitId,
    circuitId: id
  };
  execute(dispatch, () => updateUserCircuitApiCall({
    request
  }), USER_WORKOUT_CIRCUIT_UPDATE, false);
};
export const createUserCircuitEquipment = data => async dispatch => {
  const {
    quantity,
    weight,
    indexes,
    ...rest
  } = data;
  const request = {
    quantity: +quantity,
    weight: +weight,
    ...rest
  };
  execute(dispatch, () => createUserCircuitEquipmentApiCall({
    request
  }), USER_WORKOUT_CIRCUIT_EQUIPMENT_CREATE, false, {
    ...indexes
  });
};
export const updateUserCircuitEquipment = data => async dispatch => {
  const {
    quantity,
    weight,
    userWorkoutCircuitEquipmentId,
    ...rest
  } = data;
  const request = {
    id: userWorkoutCircuitEquipmentId,
    quantity: +quantity,
    weight: +weight,
    ...rest
  };
  execute(dispatch, () => updateUserCircuitEquipmentApiCall({
    request
  }), USER_WORKOUT_CIRCUIT_EQUIPMENT_UPDATE, false);
};
export const changeUserWorkoutSchedule = request => dispatch => {
  execute(dispatch, () => changeUserWorkoutScheduleApiCall({
    request
  }), USER_WORKOUT_SCHEDULE_UPDATE);
};
export const getUserWorkoutProgressDetailsByOwner = (userId, userPlanId) => async dispatch => execute(dispatch, () => getUserWorkoutProgressDetailsByOwnerApiRequest(userId, userPlanId), USERS_WORKOUT_PLAN_PROGRESS, false);
export const getUserWorkoutProgressDetailsByGroup = (groupId, groupPlanId) => async dispatch => execute(dispatch, () => getUserWorkoutProgressDetailsByGroupApiRequest(groupId, groupPlanId), USERS_WORKOUT_GROUP_PLAN_PROGRESS, false);
export const getAllUserWorkoutPlans = userId => async dispatch => execute(dispatch, () => getAllUserWorkoutPlansApiRequest(userId), USER_ALL_WORKOUTS, false);
export const getAllUserWorkoutActivities = userId => async dispatch => execute(dispatch, () => getAllUserWorkoutActivitiesApiRequest(userId), USER_ALL_ACTIVITIES, false);
export const changeUserPlanStartEndTime = (id, startTime, endTime, userId, groupId) => async dispatch => execute(dispatch, () => changeUserPlanStartEndTimeApiCall({
  id,
  startTime,
  endTime,
  userId,
  groupId
}), USER_PLAN_CHANGE_START_END_TIME, true);
export const getAllNotAssignedUsersForPlan = id => async dispatch => execute(dispatch, () => getAllNotAssignedUsersForPlanApiCall({
  id
}), ALL_NOT_ASSIGNED_USERS_BY_PLAN, false);
export const getAllNotAssignedGroupsForPlan = id => async dispatch => execute(dispatch, () => getAllNotAssignedGroupsForPlanApiCall({
  id
}), ALL_NOT_ASSIGNED_GROUPS_BY_PLAN, false);
export const getUserWorkoutPlanDates = (templatePlanId, userId, ignorePlanId) => async dispatch => execute(dispatch, () => getUserWorkoutPlanDatesApiCall({
  templatePlanId,
  userId,
  ignorePlanId
}), USER_WORKOUT_PLAN_DATES, false);
export const workoutsReducer = {
  all: basicReducer(WORKOUTS_GET),
  details: basicReducer(WORKOUT_DETAILS),
  create: basicReducer(WORKOUT_CREATE),
  update: basicReducer(WORKOUT_UPDATE),
  delete: basicReducer(WORKOUT_DELETE),
  status: basicReducer(WORKOUT_STATUS),
  duplicate: basicReducer(WORKOUT_DUPLICATE),
  circuitReorder: basicReducer(WORKOUT_CIRCUIT_EXERCISE_REORDER),
  circuitsCreate: basicReducer(WORKOUT_CIRCUIT_CREATE),
  circuitsUpdate: basicReducer(WORKOUT_CIRCUIT_UPDATE),
  circuitsDelete: basicReducer(WORKOUT_CIRCUIT_DELETE),
  circuitExerciseCreate: basicReducer(WORKOUT_CIRCUIT_EXERCISE_CREATE),
  circuitExerciseUpdate: basicReducer(WORKOUT_CIRCUIT_EXERCISE_UPDATE),
  circuitExerciseDelete: basicReducer(WORKOUT_CIRCUIT_EXERCISE_DELETE),
  circuitExerciseEquipmentCreate: basicReducer(WORKOUT_CIRCUIT_EXERCISE_EQUIPMENT_CREATE),
  circuitExerciseEquipmentUpdate: basicReducer(WORKOUT_CIRCUIT_EXERCISE_EQUIPMENT_UPDATE),
  circuitExerciseEquipmentDelete: basicReducer(WORKOUT_CIRCUIT_EXERCISE_EQUIPMENT_DELETE),
  createWorkoutPlan: basicReducer(WORKOUT_PLAN_CREATE),
  updateWorkoutPlan: basicReducer(WORKOUT_PLAN_UPDATE),
  deleteWorkoutPlan: basicReducer(WORKOUT_PLAN_DELETE),
  restoreWorkoutPlan: basicReducer(WORKOUT_PLAN_RESTORE),
  duplicateWorkoutPlan: basicReducer(WORKOUT_PLAN_DUPLICATE),
  workoutPlansTemplates: basicReducer(WORKOUT_PLANS_TEMPLATES),
  archivedWorkoutPlansTemplates: basicReducer(ARCHIVED_WORKOUT_PLANS_TEMPLATES),
  workoutPlanStatus: basicReducer(WORKOUT_PLAN_STATUS),
  workoutPlanDetails: basicReducer(WORKOUT_PLAN_DETAILS),
  usersWorkoutPlans: basicReducer(USERS_WORKOUT_PLANS),
  userWorkoutPlanCreate: basicReducer(USERS_WORKOUT_PLAN_CREATE),
  userWorkoutPlanUpdateFromCurrentPlanConfiguration: basicReducer(UPDATE_USER_PLAN_WITH_CURRENT_PLAN_CONFIGURATION),
  userWorkoutPlanStatus: basicReducer(USER_WORKOUT_PLAN_STATUS),
  userWorkoutPlanDetails: basicReducer(USER_WORKOUT_PLAN_DETAILS),
  createUserWorkoutCircuit: basicReducer(USER_WORKOUT_CIRCUIT_CREATE),
  updateUserWorkoutCircuit: basicReducer(USER_WORKOUT_CIRCUIT_UPDATE),
  userWorkoutScheduleUpdate: basicReducer(USER_WORKOUT_SCHEDULE_UPDATE),
  createUserWorkoutCircuitEquipment: basicReducer(USER_WORKOUT_CIRCUIT_EQUIPMENT_CREATE),
  updateUserWorkoutCircuitEquipment: basicReducer(USER_WORKOUT_CIRCUIT_EQUIPMENT_UPDATE),
  userWorkoutProgress: basicReducer(USERS_WORKOUT_PLAN_PROGRESS),
  userWorkoutGroupProgress: basicReducer(USERS_WORKOUT_GROUP_PLAN_PROGRESS),
  userAllWorkoutPlans: basicReducer(USER_ALL_WORKOUTS),
  userAllActivities: basicReducer(USER_ALL_ACTIVITIES),
  changeUserPlanStartEndTime: basicReducer(USER_PLAN_CHANGE_START_END_TIME),
  notAssignedUsersForPlan: basicReducer(ALL_NOT_ASSIGNED_USERS_BY_PLAN),
  notAssignedGroupsForPlan: basicReducer(ALL_NOT_ASSIGNED_GROUPS_BY_PLAN),
  userWorkoutPlanDates: basicReducer(USER_WORKOUT_PLAN_DATES),
  checkIfPlanShouldUpdate: basicReducer(CHECK_IF_PLAN_SHOULD_UPDATE_FROM_CURRENT_CONFIGURATION)
};