import { getEquipmentsApiCall } from '@guryou/api-client-gql';
import { execute } from '../actions/actionWrapper';
import { Action, basicReducer } from '../core';
export const EQUIPMENTS_GET = {
  entity: 'EQUIPMENTS',
  action: Action.GET_MANY
};
export const getEquipments = () => async dispatch => execute(dispatch, () => getEquipmentsApiCall(), EQUIPMENTS_GET);
export const equipmentsReducer = {
  all: basicReducer(EQUIPMENTS_GET)
};