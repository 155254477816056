import { borderDefinition, boxShadowLight, sizes, spacer } from '../constants';
const navigationOverrides = (palette, appBarMargin) => ({
  MuiStepper: {
    horizontal: {
      justifyContent: 'space-between',
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center'
    }
  },
  MuiDrawer: {
    paper: {
      width: sizes.drawerWidth,
      height: "calc(100vh - ".concat(sizes.appBarHeight - 1, "px)"),
      marginTop: sizes.appBarHeight + 1,
      backgroundColor: palette.primary.main,
      '& *': {
        color: palette.primary.contrastText
      },
      // TODO: refactor SubMenu component and remove this
      '& .active .MuiListItem-button': {
        backgroundColor: palette.primary.light,
        '&:hover': {
          backgroundColor: palette.primary.contrastText
        }
      },
      '&.users-active .MuiCardHeader-avatar img': {
        border: borderDefinition(palette.success.main)
      },
      '&.users-non-active .MuiCardHeader-avatar img': {
        border: borderDefinition(palette.error.main)
      },
      '&.users-not-confirmed .MuiCardHeader-avatar img': {
        border: borderDefinition(palette.warning.main)
      }
    },
    paperAnchorDockedLeft: {
      border: 'none'
    }
  },
  MuiCollapse: {
    container: {
      paddingLeft: spacer.spacer2
    }
  },
  MuiAppBar: {
    root: {
      boxShadow: 'none',
      flexDirection: 'row',
      alignItems: 'center',
      '& + div': {
        marginTop: appBarMargin ? sizes.appBarHeight + spacer.spacer1 : 0
      },
      '&.footer': {
        backgroundColor: 'white',
        width: "calc(100vw - ".concat(sizes.drawerWidth - 1, "px)"),
        marginLeft: sizes.drawerWidth
      }
    },
    colorInherit: {
      boxShadow: boxShadowLight
    }
  },
  MuiToolbar: {
    root: {
      width: '100%'
    },
    gutters: {
      '@media (min-width: 600px)': {
        paddingLeft: spacer.spacer3,
        paddingRight: spacer.spacer3
      }
    },
    regular: {
      '@media (min-width: 600px)': {
        minHeight: 48
      }
    }
  },
  MuiListItem: {
    button: {
      width: 'auto',
      '&:hover': {
        backgroundColor: palette.grey[300],
        color: palette.primary.main,
        '& *': {
          color: palette.primary.main
        }
      },
      '&.active': {
        backgroundColor: palette.primary.light
      }
    },
    root: {
      '&.Mui-selected': {
        backgroundColor: 'white',
        '& *': {
          color: '#252525 !important'
        },
        '&:hover': {
          backgroundColor: 'white'
        }
      }
    }
  },
  MuiListItemIcon: {
    root: {
      minWidth: '35px'
    }
  },
  MuiMenu: {
    list: {
      padding: 0,
      border: borderDefinition()
    },
    paper: {
      maxHeight: sizes.small
    }
  }
});
export { navigationOverrides };