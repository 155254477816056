import { getAllUserWorkoutsApiCall, getMyWorkoutPlansApiCall, getWorkoutDetailsApiRequest } from '@guryou/api-client-gql';
import { execute } from '../actions/actionWrapper';
import { Action, basicReducer } from '../core';
export const MY_WORKOUT_PLANS_GET = {
  entity: 'MY_WORKOUT_PLANS_GET',
  action: Action.GET_MANY
};
export const ALL_USER_WORKOUTS_GET = {
  entity: 'ALL_USER_WORKOUTS_GET',
  action: Action.GET_MANY
};
export const USER_WORKOUT_GET = {
  entity: 'USER_WORKOUT_GET',
  action: Action.GET
};
export const getMyWorkoutPlans = () => async dispatch => execute(dispatch, () => getMyWorkoutPlansApiCall(), MY_WORKOUT_PLANS_GET, false);
export const getAllUserWorkouts = () => async dispatch => execute(dispatch, () => getAllUserWorkoutsApiCall({
  plan: ['id, startTime, endTime, numberOfWeeks, price, thumbnailUrl, groupId'],
  workoutSchedule: ['workoutSchedule']
}), ALL_USER_WORKOUTS_GET, false);
export const getUserWorkoutDetails = id => async dispatch => execute(dispatch, () => getWorkoutDetailsApiRequest(id, null, true), USER_WORKOUT_GET, false);
export const workoutsCustomerReducer = {
  myPlans: basicReducer(MY_WORKOUT_PLANS_GET),
  all: basicReducer(ALL_USER_WORKOUTS_GET),
  workoutDetails: basicReducer(USER_WORKOUT_GET)
};