"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LocationType = exports.DEFAULT_LONGITUDE = exports.DEFAULT_LATITUDE = void 0;
var LocationType = exports.LocationType = {
  providerLocation: 1,
  plainNote: 2,
  mapLocation: 3,
  onlineLocation: 4
};
var DEFAULT_LATITUDE = exports.DEFAULT_LATITUDE = 41.9028;
var DEFAULT_LONGITUDE = exports.DEFAULT_LONGITUDE = 12.4964;