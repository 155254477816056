import { DEFAULT_SCALE } from '@guryou/html-theme';
import { paramCase } from 'change-case';
const breakpoints = ['xs', 'md', 'lg'];
const calculateTotalMargin = props => {
  const {
    margin,
    marginLeft,
    marginRight
  } = props;
  const globalMargin = typeof margin !== 'string' && margin || 0;
  let totalMargin = typeof marginLeft !== 'string' && marginLeft || globalMargin;
  totalMargin += typeof marginRight !== 'string' && marginRight || globalMargin;
  return totalMargin * DEFAULT_SCALE;
};
const calculateSize = val => Math.round(val / 12 * 10e7) / 10e5;
const toPixel = function (val) {
  let scale = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return val && (typeof val === 'string' ? val : "".concat(val * scale, "px"));
};
const parseProperty = (key, value, obj) => {
  const breakpoint = key.substring(0, 2);
  const result = {
    value,
    breakpoint: breakpoints.find(x => x === breakpoint) || 'xs'
  };
  const isKeyBreakpoint = breakpoints.some(x => x === key);
  result.property = isKeyBreakpoint ? key : paramCase(key.replace(result.breakpoint, ''));
  const lowerCaseKey = result.property.toLocaleLowerCase();
  if (['xs-offset', 'offset'].includes(lowerCaseKey)) {
    result.property = 'margin-left';
    result.value = "".concat(calculateSize(value), "%");
    return result;
  }
  if (breakpoints.includes(lowerCaseKey)) {
    result.value = "calc(".concat(calculateSize(value), "% - ").concat(calculateTotalMargin(obj), "px)");
    result.property = 'width';
    return result;
  }
  if (typeof value === 'number') {
    if (lowerCaseKey.includes('width') || lowerCaseKey.includes('height')) {
      result.value = toPixel(value);
    } else if (lowerCaseKey.includes('padding') || lowerCaseKey.includes('margin') || lowerCaseKey === 'borderRadius') {
      result.value = toPixel(value, DEFAULT_SCALE);
    }
  }
  return result;
};
const filterProps = (key, obj) => {
  // allow only strings and numbers
  if (!['number', 'string'].includes(typeof obj[key])) {
    return false;
  }
  const blackListProps = ['id', 'className', 'children', 'text', 'variant', 'forwardedComponent', 'forwardedRef', 'theme', 'primary', 'secondary', 'default', 'info', 'warning', 'link', 'url', 'src'];
  return !blackListProps.includes(key) && !blackListProps.includes(obj[key]) && (obj[key] || obj[key] === 0);
};
const propsToCss = props => {
  const {
    onClick,
    ...rest
  } = props;
  const mediaGroups = {
    xs: {},
    md: {},
    lg: {}
  };
  Object.keys(rest).filter(key => filterProps(key, rest)).sort().forEach(key => {
    const {
      breakpoint,
      property,
      value
    } = parseProperty(key, rest[key], rest);
    if (property === 'width' && mediaGroups[breakpoint][property]) {
      return;
    }
    mediaGroups[breakpoint][property] = value;
  });
  if (onClick) {
    mediaGroups.xs.cursor = 'pointer';
  }
  let result = "".concat(Object.keys(mediaGroups.xs).map(key => "".concat(key, ":").concat(mediaGroups.xs[key], " !important;")).join(''));
  const mdKeys = Object.keys(mediaGroups.md);
  if (mdKeys.length) {
    result += "@media screen and (min-width: 768px){\n    ".concat(mdKeys.map(key => "".concat(key, ":").concat(mediaGroups.md[key], " !important;")).join(''), "}");
  }
  const lgKeys = Object.keys(mediaGroups.lg);
  if (lgKeys.length) {
    result += "@media screen and (min-width: 1216px){\n    ".concat(lgKeys.map(key => "".concat(key, ":").concat(mediaGroups.lg[key], " !important;")).join(''), "}");
  }
  return result;
};
export default propsToCss;
export { toPixel, calculateSize as mapXsToWidth, calculateTotalMargin };