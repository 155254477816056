import { getBodyMeasurementsApiCall } from '@guryou/api-client-gql';
import { execute } from '../actions/actionWrapper';
import { Action, basicReducer } from '../core';
export const GET_USER_BODY_MEASUREMENTS = {
  entity: 'GET_USER_BODY_MEASUREMENTS',
  action: Action.GET_MANY
};
export const getUserBodyMeasurements = userId => async dispatch => execute(dispatch, () => getBodyMeasurementsApiCall(userId), GET_USER_BODY_MEASUREMENTS, false, true);
export const bodyMeasurementsReducers = {
  measurements: basicReducer(GET_USER_BODY_MEASUREMENTS)
};