import { getAllEventsApiCall, getPublicEventsApiCall, getServicesApiCall } from '@guryou/api-client-gql';
import { execute } from '../actions/actionWrapper';
import { Action, basicReducer } from '../core';

// Types
export const EVENTS_GET_MANY = {
  entity: 'EVENTS',
  action: Action.GET_MANY
};
export const PROVIDER_EVENTS_GET_MANY = {
  entity: 'PROVIDER_EVENTS',
  action: Action.GET_MANY
};
// Actions
export const getPublicEvents = (providerId, actionType) => async dispatch => execute(dispatch, () => getPublicEventsApiCall({
  providerId
}), actionType || EVENTS_GET_MANY);
export const getAllEvents = (providerId, actionType) => async dispatch => execute(dispatch, () => getAllEventsApiCall({
  providerId
}), actionType);
export const getEvents = function () {
  let silent = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  return async dispatch => execute(dispatch, () => getServicesApiCall({
    type: 'E'
  }, {
    group: ['id', 'name', 'color']
  }), PROVIDER_EVENTS_GET_MANY, false, null, !silent);
};

// Reducers
export const eventReducers = {
  all: basicReducer(PROVIDER_EVENTS_GET_MANY),
  active: basicReducer(EVENTS_GET_MANY)
};