import { ActionStatus, buildActionType, StateStatus } from '../core';
const initialState = {
  data: null,
  errors: null,
  status: StateStatus.NOT_INITIALIZED,
  version: 0,
  report: null
};
const classes = actionType => function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case buildActionType(actionType, ActionStatus.START):
      return {
        ...initialState,
        status: StateStatus.LOADING,
        version: state.version,
        entity: actionType.entity
      };
    case buildActionType(actionType, ActionStatus.DONE):
      const payload = action.payload.payload || action.payload;
      const data = 'data' in payload ? payload.data : payload;
      return {
        status: StateStatus.LOADED,
        data,
        entity: actionType.entity,
        version: +new Date(),
        report: action.report
      };
    case buildActionType(actionType, ActionStatus.FAILED):
      return {
        status: StateStatus.ERROR,
        errors: action.payload,
        entity: actionType.entity
      };
    case buildActionType(actionType, ActionStatus.RESET):
      return initialState;
    case buildActionType(actionType, ActionStatus.UPDATE):
      return {
        ...state,
        data: [...action.newArr]
      };
    case buildActionType(actionType, ActionStatus.INCREASE):
      return {
        ...state,
        data: [...state.data.slice(0, action.index), {
          ...state.data[action.index],
          related: parseInt(state.data[action.index].related) + 1
        }, ...state.data.slice(action.index + 1)]
      };
    case buildActionType(actionType, ActionStatus.DECREASE):
      return {
        ...state,
        data: [...state.data.slice(0, action.index), {
          ...state.data[action.index],
          related: parseInt(state.data[action.index].related) - 1
        }, ...state.data.slice(action.index + 1)]
      };
    default:
      return state;
  }
};
export { classes };