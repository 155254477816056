import { getAllSubservicesApiRequest, getClassesApiRequest, getCoursesApiRequest, getEventsApiRequest, getOneTimeApiRequest } from '@guryou/api-client';
import { createOrderEventApiCall, getBookedEventsApiCall, getBookedEventsByProviderIdFromAdminApiCall, getCalendarEventsApiRequest, getOrderEventsApiCall, markOrderAsPaidApiCall } from '@guryou/api-client-gql';
import { execute } from './actionWrapper';
import { Action, ActionStatus, buildActionType, executeApiCall } from '../core';
import { CALENDAR_EVENTS, CALENDAR_FILTER_GET_CLASSES, CALENDAR_FILTER_GET_COURSES, CALENDAR_FILTER_GET_EVENTS, CALENDAR_FILTER_GET_SERVICES, CALENDAR_FILTER_GET_SUBSERVICES, MARK_ORDER_AS_PAID, ORDER_EVENT_CREATE, ORDER_EVENTS } from '../types';
export const RESERVATIONS_GET_CURRENT = {
  entity: 'BOOKED_EVENTS',
  action: Action.GET_MANY
};
export const parseCalendarEventUser = event => {
  var _event$userInfo, _event$userInfo2, _event$userInfo3, _event$userInfo4, _event$user, _event$user2;
  if ((_event$userInfo = event.userInfo) !== null && _event$userInfo !== void 0 && _event$userInfo.firstname && (_event$userInfo2 = event.userInfo) !== null && _event$userInfo2 !== void 0 && _event$userInfo2.lastname) {
    return "".concat(event.userInfo.firstname, " ").concat(event.userInfo.lastname);
  }
  if ((_event$userInfo3 = event.userInfo) !== null && _event$userInfo3 !== void 0 && _event$userInfo3.firstname) {
    return event.userInfo.firstname;
  }
  if ((_event$userInfo4 = event.userInfo) !== null && _event$userInfo4 !== void 0 && _event$userInfo4.lastname) {
    return event.userInfo.lastname;
  }
  if ((_event$user = event.user) !== null && _event$user !== void 0 && _event$user.firstname && (_event$user2 = event.user) !== null && _event$user2 !== void 0 && _event$user2.lastname) {
    var _event$user3, _event$user4;
    return "".concat((_event$user3 = event.user) === null || _event$user3 === void 0 ? void 0 : _event$user3.firstname, " ").concat((_event$user4 = event.user) === null || _event$user4 === void 0 ? void 0 : _event$user4.lastname);
  }
  return null;
};
const getCalendarEvents = (startTime, endTime) => async dispatch => execute(dispatch, () => getCalendarEventsApiRequest(startTime, endTime), CALENDAR_EVENTS);
const getCalendarFilterServices = silent => async dispatch => dispatch(executeApiCall(getOneTimeApiRequest(), CALENDAR_FILTER_GET_SERVICES, silent, undefined, true));
const getCalendarFilterCourses = silent => async dispatch => dispatch(executeApiCall(getCoursesApiRequest(), CALENDAR_FILTER_GET_COURSES, silent, undefined, true));
const getCalendarFilterEvents = silent => async dispatch => dispatch(executeApiCall(getEventsApiRequest(), CALENDAR_FILTER_GET_EVENTS, silent, undefined, true));
const getCalendarFilterSubservices = (id, silent) => async dispatch => dispatch(executeApiCall(getAllSubservicesApiRequest(id), CALENDAR_FILTER_GET_SUBSERVICES, silent, undefined, true));
const getCalendarFilterClasses = (id, silent) => async dispatch => dispatch(executeApiCall(getClassesApiRequest(id), CALENDAR_FILTER_GET_CLASSES, silent, undefined, true));
const getOrderEvents = orderId => async dispatch => execute(dispatch, () => getOrderEventsApiCall({
  orderId
}), ORDER_EVENTS);
const createOrderEvent = request => async dispatch => execute(dispatch, () => createOrderEventApiCall({
  request
}), ORDER_EVENT_CREATE);
const markOrderAsPaid = id => async dispatch => execute(dispatch, () => markOrderAsPaidApiCall({
  id
}), MARK_ORDER_AS_PAID);
const resetCalendarFilters = () => async dispatch => {
  dispatch({
    type: buildActionType(CALENDAR_EVENTS, ActionStatus.RESET)
  });
  dispatch({
    type: buildActionType(CALENDAR_FILTER_GET_SERVICES, ActionStatus.RESET)
  });
  dispatch({
    type: buildActionType(CALENDAR_FILTER_GET_COURSES, ActionStatus.RESET)
  });
  dispatch({
    type: buildActionType(CALENDAR_FILTER_GET_EVENTS, ActionStatus.RESET)
  });
  dispatch({
    type: buildActionType(CALENDAR_FILTER_GET_SUBSERVICES, ActionStatus.RESET)
  });
  dispatch({
    type: buildActionType(CALENDAR_FILTER_GET_CLASSES, ActionStatus.RESET)
  });
};
const getBookedEvents = function () {
  let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let silent = arguments.length > 1 ? arguments[1] : undefined;
  return async dispatch => {
    execute(dispatch, () => getBookedEventsApiCall({
      ...data,
      email: data.search || null,
      startTime: data.startTime,
      endTime: data.endTime,
      resourceId: +data.resourceId || null,
      entityType: data.entityType || null
    }, {
      user: ['id', 'name', 'firstname', 'lastname', 'email', 'phone'],
      users: ['id', 'name', 'firstname', 'lastname', 'email', 'phone'],
      resources: ['id', 'name']
    }), RESERVATIONS_GET_CURRENT, false, null, silent);
  };
};
const getBookedEventsByProviderIdFromAdmin = function () {
  let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let silent = arguments.length > 1 ? arguments[1] : undefined;
  return async dispatch => {
    execute(dispatch, () => getBookedEventsByProviderIdFromAdminApiCall({
      ...data,
      providerId: data === null || data === void 0 ? void 0 : data.providerId,
      email: data.search || null,
      startTime: data.startTime || new Date().toISOString(),
      endTime: data.endTime || new Date().toISOString(),
      resourceId: +data.resourceId || null,
      entityType: data.entityType || null
    }, {
      user: ['id', 'name', 'firstname', 'lastname', 'email', 'phone'],
      users: ['id', 'name', 'firstname', 'lastname', 'email', 'phone'],
      resources: ['id', 'name']
    }), RESERVATIONS_GET_CURRENT, false, null, silent);
  };
};
export { markOrderAsPaid, createOrderEvent, getOrderEvents, getCalendarEvents, getCalendarFilterServices, getCalendarFilterCourses, getCalendarFilterEvents, getCalendarFilterSubservices, getCalendarFilterClasses, resetCalendarFilters, getBookedEvents, getBookedEventsByProviderIdFromAdmin };