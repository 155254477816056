import { addFileIntoSectionApiCall, deleteSectionApiCall, duplicateSectionApiCall, getSectionsApiCall, removeFileFromSectionApiCall, reorderSectionsApiCall, saveSectionApiCall } from '@guryou/api-client-gql';
import { parseAsBool } from '@guryou/utils';
import { execute } from '../actions/actionWrapper';
import { Action, basicReducer } from '../core';
import { sections } from '../reducers/sections';

// Types
export const SECTIONS = {
  entity: 'SECTIONS',
  action: Action.GET
};
export const CREATE_SECTION = {
  entity: 'CREATE_SECTION',
  action: Action.CREATE
};
export const UPDATE_SECTION = {
  entity: 'UPDATE_SECTION',
  action: Action.UPDATE
};
export const DELETE_SECTION = {
  entity: 'DELETE_SECTION',
  action: Action.DELETE
};
export const SECTION_ADD_FILE = {
  entity: 'SECTION_ADD_FILE',
  action: Action.CREATE
};
export const SECTION_REMOVE_FILE = {
  entity: 'SECTION_REMOVE_FILE',
  action: Action.DELETE
};
export const SECTION_REORDER = {
  entity: 'SECTIONS',
  action: Action.REORDER
};
export const DUPLICATE_SECTION = {
  entity: 'DUPLICATE_SECTION',
  action: Action.HANDLE
};

// Actions
export const getSections = productId => async dispatch => execute(dispatch, () => getSectionsApiCall({
  productId
}), SECTIONS, false, {
  productId
});
export const createSection = (name, productId, availableAfter, periodType) => async dispatch => execute(dispatch, () => saveSectionApiCall({
  name,
  productId,
  availableAfter,
  periodType
}), CREATE_SECTION);
export const updateSection = (id, name, availableAfter, periodType) => async dispatch => execute(dispatch, () => saveSectionApiCall({
  id,
  name,
  availableAfter,
  periodType
}), UPDATE_SECTION);
export const deleteSection = id => async dispatch => execute(dispatch, () => deleteSectionApiCall({
  id
}), DELETE_SECTION, false);
export const addFileIntoSection = _ref => {
  let {
    fileId,
    sectionId,
    intro,
    free
  } = _ref;
  return async dispatch => execute(dispatch, () => addFileIntoSectionApiCall({
    fileId,
    sectionId,
    intro: parseAsBool(intro),
    free: parseAsBool(free)
  }), SECTION_ADD_FILE, true);
};
export const removeFileFromSection = _ref2 => {
  let {
    fileId,
    sectionId
  } = _ref2;
  return async dispatch => execute(dispatch, () => removeFileFromSectionApiCall({
    fileId,
    sectionId
  }), SECTION_REMOVE_FILE, true);
};
export const reorderSections = (videoCourseId, oldPosition, newPosition) => async dispatch => {
  execute(dispatch, () => reorderSectionsApiCall({
    videoCourseId,
    oldPosition,
    newPosition
  }), SECTION_REORDER, false, null, true);
};
export const duplicateSection = (id, videoId) => async dispatch => execute(dispatch, () => duplicateSectionApiCall({
  id,
  videoId
}), DUPLICATE_SECTION);

// Reducers
export const sectionReducers = {
  all: sections(SECTIONS),
  create: basicReducer(CREATE_SECTION),
  update: basicReducer(UPDATE_SECTION),
  remove: basicReducer(DELETE_SECTION),
  addFile: basicReducer(SECTION_ADD_FILE),
  removeFile: basicReducer(SECTION_REMOVE_FILE),
  reorder: basicReducer(SECTION_REORDER),
  duplicate: basicReducer(DUPLICATE_SECTION)
};