const wysiwygToolbarOptions = {
  options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link'],
  inline: {
    options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
    className: {
      className: 'bordered-option-classname'
    },
    bold: {
      className: 'bordered-option-classname'
    },
    italic: {
      className: 'bordered-option-classname'
    },
    underline: {
      className: 'bordered-option-classname'
    },
    strikethrough: {
      className: 'bordered-option-classname'
    },
    code: {
      className: 'bordered-option-classname'
    }
  },
  blockType: {
    className: 'bordered-option-classname'
  },
  fontSize: {
    className: 'bordered-option-classname'
  },
  fontFamily: {
    className: 'bordered-option-classname'
  },
  list: {
    options: ['unordered', 'ordered', 'indent', 'outdent'],
    className: 'bordered-option-classname',
    unordered: {
      className: 'bordered-option-classname'
    },
    ordered: {
      className: 'bordered-option-classname'
    },
    indent: {
      className: 'bordered-option-classname'
    },
    outdent: {
      className: 'bordered-option-classname'
    }
  },
  textAlign: {
    options: ['left', 'center', 'right', 'justify'],
    className: {
      className: 'bordered-option-classname'
    },
    left: {
      className: 'bordered-option-classname'
    },
    center: {
      className: 'bordered-option-classname'
    },
    right: {
      className: 'bordered-option-classname'
    },
    justify: {
      className: 'bordered-option-classname'
    }
  },
  link: {
    options: ['link', 'unlink'],
    className: {
      className: 'bordered-option-classname'
    },
    defaultTargetOption: '_self',
    link: {
      className: 'bordered-option-classname'
    },
    unlink: {
      className: 'bordered-option-classname'
    },
    linkCallback: undefined
  }
};
export { wysiwygToolbarOptions };