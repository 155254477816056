import { Action } from '../core/Action';
const ACCOUNT_AND_AGREEMENTS_GET = {
  entity: 'ACCOUNT_AND_AGREEMENTS',
  action: Action.GET
};
const ACCOUNT_AVATAR_UPDATE = {
  entity: 'AVATAR',
  action: Action.UPDATE
};
const ACCOUNT_GET_AGREEMENTS = {
  entity: 'AGREEMENTS',
  action: Action.GET
};
const ACCOUNT_PASSWORD_UPDATE = {
  entity: 'PASSWORD',
  action: Action.UPDATE
};
const ACCOUNT_UPDATE = {
  entity: 'ACCOUNT',
  action: Action.UPDATE
};
const ACTIVATE = {
  entity: 'ACTIVATE',
  action: Action.HANDLE
};
const DOMAIN_VALIDATE = {
  entity: 'DOMAIN',
  action: Action.HANDLE
};
const FORGOT_PASSWORD = {
  entity: 'FORGOT_PASSWORD',
  action: Action.HANDLE
};
const FORGOT_PASSWORD_RESET = {
  entity: 'FORGOT_PASSWORD',
  action: Action.RESET
};
const LOGIN = {
  entity: 'LOGIN',
  action: Action.HANDLE
};
const CHECK_AUTH = {
  entity: 'CHECK_AUTH',
  action: Action.HANDLE
};
const LOGOUT = 'PROVIDER_OUT';
const NOT_AUTHORIZED = 'NOT_AUTHORIZED';
const OWNER = {
  entity: 'OWNER',
  action: Action.GET
};
const PASSWORDS_NOT_MATCH = 'Passwords do not match';
const PROVIDER_IN = {
  entity: 'PROVIDER_IN',
  action: Action.HANDLE
};
const PROVIDER_NOT_AUTHORIZED = 'PROVIDER_NOT_AUTHORIZED';
const PROVIDER_OUT = 'LOGOUT';
const RESET_PASSWORD = {
  entity: 'RESET_PASSWORD',
  action: Action.HANDLE
};
const CHANGE_PASSWORD = {
  entity: 'CHANGE_PASSWORD',
  action: Action.HANDLE
};
const SIGN_UP = {
  entity: 'SIGN_UP',
  action: Action.HANDLE
};
const RESEND_SIGN_UP = {
  entity: 'RESEND_SIGN_UP',
  action: Action.HANDLE
};
const STORAGE_PERSIST = {
  entity: 'STORAGE',
  action: Action.UPDATE
};
const SUBDOMAIN_NAME_VALIDATION = 'Invalid subdomain name';
const UPDATE_PASSWORD = {
  entity: 'PASSWORD',
  action: Action.UPDATE
};
const UPDATE_PROFILE = {
  entity: 'PROFILE',
  action: Action.UPDATE
};
const SET_PASSOWRD = {
  entity: 'SET_PASSOWRD',
  action: Action.HANDLE
};
const REFRESH_TOKEN = {
  entity: 'REFRESH_TOKEN',
  action: Action.HANDLE
};
const TOKEN_PROVIDER = {
  entity: 'TOKEN_PROVIDER',
  action: Action.GET
};
export { RESEND_SIGN_UP, CHANGE_PASSWORD, REFRESH_TOKEN, ACCOUNT_AND_AGREEMENTS_GET, ACCOUNT_AVATAR_UPDATE, ACCOUNT_GET_AGREEMENTS, ACCOUNT_PASSWORD_UPDATE, ACCOUNT_UPDATE, ACTIVATE, DOMAIN_VALIDATE, FORGOT_PASSWORD_RESET, FORGOT_PASSWORD, LOGIN, CHECK_AUTH, LOGOUT, NOT_AUTHORIZED, OWNER, PASSWORDS_NOT_MATCH, PROVIDER_IN, PROVIDER_NOT_AUTHORIZED, PROVIDER_OUT, RESET_PASSWORD, SIGN_UP, STORAGE_PERSIST, SUBDOMAIN_NAME_VALIDATION, UPDATE_PASSWORD, UPDATE_PROFILE, SET_PASSOWRD, TOKEN_PROVIDER };