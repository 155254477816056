import { combineReducers } from 'redux';
import { admin } from './admin';
import { auth } from './auth';
import { blackFridayModalPopup } from './blackFridayPopup';
import { business } from './business';
import { calendar } from './calendar';
import { confirmRegisterBiz } from './confirmRegisterBiz';
import { crossStorage } from './crossStorage';
import { notification } from './notification';
import { packages } from './packages';
import { provider } from './provider';
import { providerAuth } from './providerAuth';
import { registerBiz } from './registerBiz';
import { widgetReducers } from './widgetReducers';
import { windowSize } from './windowSize';
import { cognitoReducers, reservationReducers } from '../actions';
import { basicReducer, countReducer } from '../core';
import { broadcastReducers, paymentsReducer, plansReducer, PROVIDER_GET_GENERAL_INFO, PROVIDER_LATEST_WIDGET, providerReducers, PROVIDERS_GET_MANY, userReducers, usersReducers } from '../new';
import { articleReducers } from '../new/articles';
import { bodyMeasurementsReducers } from '../new/bodyMeasurements';
import { calendarReducers } from '../new/calendar';
import { categoryReducers } from '../new/category';
import { classReducers } from '../new/class';
import { courseReducers } from '../new/course';
import { coursesAndEventsReducer } from '../new/coursesAndEvents';
import { customerReducers } from '../new/customers';
import { emailTemplateReducers } from '../new/emailTemplate';
import { equipmentsReducer } from '../new/equipments';
import { eventReducers } from '../new/event';
import { exercisesReducer } from '../new/exercises';
import { fileCustomerReducers, fileReducers } from '../new/file';
import { groupReducers } from '../new/group';
import { membershipFeelReducers, membershipReducers, MY_MEMBERSHIPS } from '../new/membership';
import { myVideosReducers } from '../new/myVideos';
import { paymentOptionsFeelReducers, paymentOptionsReducers } from '../new/paymentOptions';
import { paymentsFeelReducer } from '../new/payments';
import { providerAnalyticsReducers } from '../new/providerAnalytics';
import { reservationFeelReducers } from '../new/reservation';
import { PERSONNEL_TIME_SLOTS_GET_MANY, resourceReducers } from '../new/resource';
import { sectionReducers } from '../new/section';
import { serviceReducers } from '../new/service';
import { sessionReducers } from '../new/session';
import { sqsMessageReducers } from '../new/sqsMessage';
import { subscriptionFeelReducers, subscriptionReducers } from '../new/subscription';
import { subServiceReducers } from '../new/subService';
import { translationReducers } from '../new/translations';
import { userProviderFilesReducers } from '../new/userProviderFiles';
import { userTrainingInfoReducers } from '../new/userTrainingInfo';
import { feelVideoReducers, videoReducers } from '../new/video';
import { walletsReducer } from '../new/wallets';
import { workoutPlansReducer } from '../new/workoutPlans';
import { workoutProgressReducer } from '../new/workoutProgress';
import { workoutsReducer } from '../new/workouts';
import { workoutsCustomerReducer } from '../new/workoutsCustomer';
import { ACCOUNT_AND_AGREEMENTS_GET, ACCOUNT_AVATAR_UPDATE, ACCOUNT_PASSWORD_UPDATE, ACCOUNT_UPDATE, ACTIVATE, ALL_CATEGORIES, ALL_CITITES, AUTHORS, BUSY_INDICATOR, CHECK_AUTH, CLASS_DETAILS, CONTACT, COURSE_SECTIONS, CUSTOMER_COURSE, CUSTOMER_COURSES, CUSTOMER_ORDER, CUSTOMER_ORDERS, CUSTOMER_PRODUCT, CUSTOMER_PRODUCT_BUY, FORGOT_PASSWORD, LOGIN, MY_BOOKED_EVENTS, OWNER, OWNER_SUBSCRIPTIONS_GET, PRODUCT_FILES, PROVIDER_DETAILS, RANDOM_PROVIDERS, REFRESH_TOKEN, RESET_PASSWORD, SEARCH_PROVIDERS, SESSIONS_FOR_DAY, SIGN_UP, TOKEN_PROVIDER, USER_ALL_ORDERS } from '../types';
const dealReducer = {
  activate: basicReducer(ACTIVATE),
  auth,
  checkAuth: basicReducer(CHECK_AUTH),
  cognito: combineReducers(cognitoReducers),
  user: combineReducers(userReducers),
  admin: combineReducers(admin),
  business: combineReducers(business),
  busy: countReducer(BUSY_INDICATOR),
  crossStorage,
  forgotPassword: basicReducer(FORGOT_PASSWORD),
  notification,
  owner: basicReducer(OWNER),
  ownerSubscriptions: basicReducer(OWNER_SUBSCRIPTIONS_GET),
  packageSubReducer: packages,
  provider: combineReducers(provider),
  calendar: combineReducers(calendar),
  providerAuth,
  resetPassword: basicReducer(RESET_PASSWORD),
  signup: basicReducer(SIGN_UP),
  users: combineReducers(usersReducers),
  coursesAndEvents: combineReducers(coursesAndEventsReducer),
  personnelTimeSlotsMobile: basicReducer(PERSONNEL_TIME_SLOTS_GET_MANY, data => {
    const response = data.data.map(c => ({
      label: c.split(':')[0] + ':' + c.split(':')[1],
      value: c.split(':')[0] + ':' + c.split(':')[1]
    }));
    return response;
  }),
  // v2
  membership: combineReducers(membershipReducers),
  subServices: combineReducers(subServiceReducers),
  courses: combineReducers(courseReducers),
  events: combineReducers(eventReducers),
  services: combineReducers(serviceReducers),
  videos: combineReducers(videoReducers),
  sections: combineReducers(sectionReducers),
  files: combineReducers(fileReducers),
  sessions: combineReducers(sessionReducers),
  reservations: combineReducers(reservationReducers),
  sqsMessages: combineReducers(sqsMessageReducers),
  subscriptions: combineReducers(subscriptionReducers),
  classes: combineReducers(classReducers),
  groups: combineReducers(groupReducers),
  categories: combineReducers(categoryReducers),
  providerToken: basicReducer(LOGIN),
  customer: combineReducers(customerReducers),
  providers: combineReducers(providerReducers),
  plans: combineReducers(plansReducer),
  payments: combineReducers(paymentsReducer),
  refreshToken: basicReducer(REFRESH_TOKEN),
  translations: combineReducers(translationReducers),
  tokenProvider: basicReducer(TOKEN_PROVIDER),
  workouts: combineReducers(workoutsReducer),
  exercises: combineReducers(exercisesReducer),
  equipments: combineReducers(equipmentsReducer),
  resource: combineReducers(resourceReducers),
  emailTemplates: combineReducers(emailTemplateReducers),
  bodyMeasurements: combineReducers(bodyMeasurementsReducers),
  providerAnalytics: combineReducers(providerAnalyticsReducers),
  userProviderFiles: combineReducers(userProviderFilesReducers),
  wallets: combineReducers(walletsReducer),
  paymentOptions: combineReducers(paymentOptionsReducers),
  broadcast: combineReducers(broadcastReducers)
};
const feelReducer = {
  generalInfo: basicReducer(PROVIDER_GET_GENERAL_INFO),
  activate: basicReducer(ACTIVATE),
  files: combineReducers(fileCustomerReducers),
  cognito: combineReducers(cognitoReducers),
  auth,
  checkAuth: basicReducer(CHECK_AUTH),
  busy: countReducer(BUSY_INDICATOR),
  crossStorage,
  forgotPassword: basicReducer(FORGOT_PASSWORD),
  notification,
  widget: combineReducers(widgetReducers),
  resetPassword: basicReducer(RESET_PASSWORD),
  signup: basicReducer(SIGN_UP),
  contact: basicReducer(CONTACT),
  course: basicReducer(CUSTOMER_COURSE),
  courses: basicReducer(CUSTOMER_COURSES),
  order: basicReducer(CUSTOMER_ORDER),
  orders: basicReducer(CUSTOMER_ORDERS),
  product: basicReducer(CUSTOMER_PRODUCT),
  productFiles: basicReducer(PRODUCT_FILES),
  courseSections: basicReducer(COURSE_SECTIONS),
  boughtProduct: basicReducer(CUSTOMER_PRODUCT_BUY),
  providerLatestWidget: basicReducer(PROVIDER_LATEST_WIDGET),
  profileAndAgreements: basicReducer(ACCOUNT_AND_AGREEMENTS_GET),
  accountUpdatePassword: basicReducer(ACCOUNT_PASSWORD_UPDATE),
  accountUpdate: basicReducer(ACCOUNT_UPDATE),
  accountUpdateAvatar: basicReducer(ACCOUNT_AVATAR_UPDATE),
  myOrders: basicReducer(USER_ALL_ORDERS),
  randomProviders: basicReducer(RANDOM_PROVIDERS),
  providerDetails: basicReducer(PROVIDER_DETAILS),
  searchProviders: basicReducer(SEARCH_PROVIDERS, data => {
    const response = data.response.reduce((prev, curr) => {
      if (curr.type === 'C') {
        prev.centers.push(curr);
      } else {
        prev.professionals.push(curr);
      }
      return prev;
    }, {
      centers: [],
      professionals: []
    });
    return response;
  }),
  classDetails: basicReducer(CLASS_DETAILS),
  allCategories: basicReducer(ALL_CATEGORIES, data => {
    const centerCategories = data.response.filter(cat => cat.type === 'C').map(c => ({
      label: c.name,
      value: c.id
    }));
    const professionalCategories = data.response.filter(cat => cat.type === 'P').map(c => ({
      label: c.name,
      value: c.id
    }));
    const response = {
      centerCategories,
      professionalCategories
    };
    return response;
  }),
  allCities: basicReducer(ALL_CITITES, data => {
    const response = data.response.map(c => ({
      label: c.name,
      value: c.id
    }));
    return response;
  }),
  myMemberships: basicReducer(MY_MEMBERSHIPS),
  myBookedEvents: basicReducer(MY_BOOKED_EVENTS),
  sessionsForDay: basicReducer(SESSIONS_FOR_DAY),
  // v2
  classes: combineReducers(classReducers),
  resource: combineReducers(resourceReducers),
  personnelTimeSlotsMobile: basicReducer(PERSONNEL_TIME_SLOTS_GET_MANY, data => {
    const response = data.data.map(c => ({
      label: c.split(':')[0] + ':' + c.split(':')[1],
      value: c.split(':')[0] + ':' + c.split(':')[1]
    }));
    return response;
  }),
  calendar: combineReducers(calendarReducers),
  user: combineReducers(userReducers),
  sections: combineReducers(sectionReducers),
  users: combineReducers(usersReducers),
  providers: basicReducer(PROVIDERS_GET_MANY, data => {
    const response = data.data.reduce((prev, curr) => {
      if (curr.type === 'C') {
        prev.centers.push(curr);
      } else {
        prev.professionals.push(curr);
      }
      return prev;
    }, {
      centers: [],
      professionals: []
    });
    return response;
  }),
  memberships: combineReducers(membershipFeelReducers),
  reservations: combineReducers(reservationFeelReducers),
  subscriptions: combineReducers(subscriptionFeelReducers),
  payments: combineReducers(paymentsFeelReducer),
  paymentOptions: combineReducers(paymentOptionsFeelReducers),
  videoCourses: combineReducers(feelVideoReducers)
};
const landingReducer = {
  auth,
  providerAuth,
  basicReducer,
  busy: countReducer(BUSY_INDICATOR),
  notification,
  owner: basicReducer(OWNER),
  signup: basicReducer(SIGN_UP),
  contact: basicReducer(CONTACT),
  windowSize,
  blackFridayModalPopup,
  authors: basicReducer(AUTHORS),
  providers: basicReducer(PROVIDERS_GET_MANY),
  randomProviders: basicReducer(RANDOM_PROVIDERS),
  generalInfo: basicReducer(PROVIDER_GET_GENERAL_INFO),
  providerLatestWidget: basicReducer(PROVIDER_LATEST_WIDGET),
  registerBiz,
  confirmRegisterBiz,
  user: combineReducers(userReducers),
  cognito: combineReducers(cognitoReducers),
  categories: combineReducers(categoryReducers),
  articles: combineReducers(articleReducers)
};
const adminReducer = {
  auth,
  busy: countReducer(BUSY_INDICATOR),
  notification,
  translations: combineReducers(translationReducers),
  files: combineReducers(fileReducers)
};
const trainingReducer = {
  auth,
  checkAuth: basicReducer(CHECK_AUTH),
  forgotPassword: basicReducer(FORGOT_PASSWORD),
  myVideos: combineReducers(myVideosReducers),
  user: combineReducers(userReducers),
  users: combineReducers(usersReducers),
  sections: combineReducers(sectionReducers),
  files: combineReducers(fileCustomerReducers),
  workouts: combineReducers(workoutsCustomerReducer),
  workoutProgress: combineReducers(workoutProgressReducer),
  workoutPlans: combineReducers(workoutPlansReducer),
  userTrainingInfo: combineReducers(userTrainingInfoReducers)
};
export { dealReducer, feelReducer, landingReducer, adminReducer, trainingReducer };