const handleApiResponse = async apiCall => {
  try {
    const response = await apiCall;
    return Object.values(response.data)[0];
  } catch (e) {
    var _e$errors, _e$errors$;
    const message = (_e$errors = e.errors) === null || _e$errors === void 0 ? void 0 : (_e$errors$ = _e$errors[0]) === null || _e$errors$ === void 0 ? void 0 : _e$errors$.message;
    return {
      success: false,
      message: message || 'Unknown error'
    };
  }
};
export default handleApiResponse;