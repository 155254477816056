import { sizes, spacer } from '../constants';
const autocomplete = {
  '.MuiAutocomplete-root': {
    width: '100%',
    maxWidth: sizes.small,
    margin: '0 auto',
    font: 'inherit',
    '&.serieAutocomplete': {
      maxWidth: '100%'
    },
    '&.error': {
      '& label, & label.Mui-focused': {
        color: '#e6616a'
      }
    },
    '& .MuiAutocomplete-placeholder': {
      fontSize: 'inherit',
      fontWeight: 'inherit'
    },
    '& .MuiAutocomplete-noOptions': {
      padding: spacer.spacer1
    },
    '& .MuiInput-input': {
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    '& .MuiAutocomplete-values': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
      '& + div > div': {
        padding: spacer.spacer1,
        paddingBottom: spacer.spacer1 + 1
      }
    },
    '& .MuiChip-root:not(:first-child)': {
      marginLeft: spacer.spacer1
    },
    '& .MuiPaper-root': {
      position: 'static',
      zIndex: 1000
    },
    '&.md .MuiPaper-root, & .MuiFormControl-root': {
      width: '150% !important',
      maxWidth: "".concat(sizes.medium, " !important")
    },
    '&.sm .MuiPaper-root, & .MuiFormControl-root': {
      width: '100% !important',
      maxWidth: "".concat(sizes.small, " !important")
    },
    '&.serieAutocomplete  .MuiPaper-root, &.serieAutocomplete .MuiFormControl-root': {
      width: '100%',
      maxWidth: '100%'
    }
  }
};
export { autocomplete };