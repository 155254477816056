import { Action } from '../core/Action';
const USER_EMAIL_GET = {
  entity: 'USER_EMAIL',
  action: Action.GET
};
const USER_UPDATE = {
  entity: 'USER',
  action: Action.UPDATE
};
const USERS_CREATE = {
  entity: 'USERS',
  action: Action.CREATE
};
const USERS_FILTER = {
  entity: 'USERS',
  action: Action.GET
};
const USERS_FILTER_RESET = {
  entity: 'USERS',
  action: Action.RESET
};
const USERS_GET_MANY = {
  entity: 'USERS',
  action: Action.GET_MANY
};
const USERS_POST = {
  entity: 'USERS',
  action: Action.CREATE
};
const USERS_STAFF_GET = {
  entity: 'USERS_STAFF',
  action: Action.GET
};
const USERS_STATUS = {
  entity: 'USERS_STATUS',
  action: Action.HANDLE
};
const USER_ALL_ORDERS = {
  entity: 'USER_ALL_ORDERS',
  action: Action.GET
};
const ADD_FRANCESCO_AS_DIRECTOR = {
  entity: 'ADD_FRANCESCO_AS_DIRECTOR',
  action: Action.CREATE
};
export { USERS_STATUS, USERS_GET_MANY, USERS_CREATE, USER_EMAIL_GET, USER_UPDATE, USERS_POST, USERS_STAFF_GET, USERS_FILTER, USERS_FILTER_RESET, USER_ALL_ORDERS, ADD_FRANCESCO_AS_DIRECTOR };