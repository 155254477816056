import { basicReducer } from '../core';
import { ALL_PROVIDERS, ALL_USERS, EDIT_PROVIDER_BY_ADMIN, EDIT_USER_BY_ADMIN, GET_COGNITO_USER, RESET_USER_COGNITO_PASSWORD, SET_DEFAULT_PASSWORD, TOGGLE_PROVIDER_STATUS, TOGGLE_USER_STATUS_BY_OWNER, TOP_10_MOST_ACTIVE_PROVIDERS, TOP_10_MOST_ACTIVE_USERS } from '../new';
const admin = {
  providers: basicReducer(ALL_PROVIDERS),
  toggleProviderStatus: basicReducer(TOGGLE_PROVIDER_STATUS),
  toggleUserStatus: basicReducer(TOGGLE_USER_STATUS_BY_OWNER),
  users: basicReducer(ALL_USERS),
  top10Users: basicReducer(TOP_10_MOST_ACTIVE_USERS),
  top10Providers: basicReducer(TOP_10_MOST_ACTIVE_PROVIDERS),
  editUser: basicReducer(EDIT_USER_BY_ADMIN),
  editProvider: basicReducer(EDIT_PROVIDER_BY_ADMIN),
  cognitoUserData: basicReducer(GET_COGNITO_USER),
  setDefaultCognitoPassword: basicReducer(SET_DEFAULT_PASSWORD),
  resetUserCognitoPassword: basicReducer(RESET_USER_COGNITO_PASSWORD)
};
export { admin };