"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setValueByPath = exports.getValueByPath = exports.getObjectValue = exports.deleteValueByPath = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      (0, _defineProperty2["default"])(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
var setValueByPath = exports.setValueByPath = function setValueByPath(obj, propertyPath, value) {
  var delimiter = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '.';
  var mergeObjects = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;
  if (!propertyPath) {
    return true;
  }
  var properties = Array.isArray(propertyPath) ? propertyPath : propertyPath.split(delimiter);
  properties = properties.filter(function (x) {
    return x;
  });
  var key = properties.shift();
  if (properties.length === 0) {
    if (key) {
      if (obj[key] && (0, _typeof2["default"])(obj[key]) === 'object' && (0, _typeof2["default"])(value) === 'object' && mergeObjects && !Array.isArray(value)) {
        obj[key] = _objectSpread(_objectSpread({}, obj[key]), value);
      } else {
        obj[key] = value;
      }
    }
    return true; // this is the end
  }
  if (key.includes('[')) {
    var tokens = key.replace(/(\[|\])/g, '.').split(delimiter);
    key = tokens.shift();
    properties = [].concat((0, _toConsumableArray2["default"])(tokens), (0, _toConsumableArray2["default"])(properties));
    obj[key] = obj[key] || [];
  } else if (!obj[key]) {
    obj[key] = {};
  }
  return setValueByPath(obj[key], properties, value, delimiter);
};
var getValueByPath = exports.getValueByPath = function getValueByPath(obj, propertyPath) {
  var delimiter = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '.';
  if (!obj || !propertyPath) {
    return null;
  }
  var properties = Array.isArray(propertyPath) ? propertyPath : propertyPath.replace(/(\[|\])/g, '.').split(delimiter);
  properties = properties.filter(function (x) {
    return x;
  });
  var key = properties.shift();
  return properties.length === 0 ? obj[key] : getValueByPath(obj[key], properties, delimiter);
};
var deleteValueByPath = exports.deleteValueByPath = function deleteValueByPath(obj, propertyPath) {
  var delimiter = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '.';
  if (!obj || !propertyPath) {
    return null;
  }
  var properties = Array.isArray(propertyPath) ? propertyPath : propertyPath.replace(/(\[|\])/g, '.').split(delimiter);
  properties = properties.filter(function (x) {
    return x;
  });
  var key = properties.shift();
  if (properties.length === 0) {
    var oldValue = obj[key];
    delete obj[key];
    return oldValue;
  }
  return deleteValueByPath(obj[key], properties, delimiter);
};
var getObjectValue = exports.getObjectValue = function getObjectValue(obj, key) {
  var origKey = Object.keys(obj).find(function (x) {
    return x.toLowerCase() === key.toLowerCase();
  });
  return obj[origKey];
};