import { Action, ActionStatus, buildActionType, FailedNotification, NotificationType, SuccessNotification } from '../core';
import { CHECK_USER_MEMBERSHIP } from '../new';
import { BUSY_INDICATOR, DECREASE, INCREASE, NOT_AUTHORIZED, NOTIFICATION } from '../types';
export const silentExecute = async (dispatch, execMethod, actionType) => {
  await execute(dispatch, execMethod, actionType, false, null, true);
};
export const transparentExecute = async (dispatch, execMethod, actionType) => {
  await execute(dispatch, execMethod, actionType, true);
};
export const execute = async function (dispatch, execMethod, actionType) {
  let notification = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  let requestPayload = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
  let silent = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;
  let customMessage = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : false;
  const entity = actionType.entity;
  const action = actionType.action;
  const actionName = "".concat(entity).concat(action);
  let showNotification = notification;
  if ((action === Action.GET_MANY || action === Action.GET || action === Action.HANDLE) && notification === null) {
    showNotification = false;
  }
  if (showNotification === null) {
    showNotification = true;
  }
  if (requestPayload) {
    dispatch({
      type: "".concat(actionName).concat(ActionStatus.START),
      payload: requestPayload
    });
  } else {
    if (!silent) {
      dispatch({
        type: "".concat(actionName).concat(ActionStatus.START)
      });
    }
  }
  dispatch({
    type: INCREASE
  });
  dispatch({
    type: buildActionType(BUSY_INDICATOR, ActionStatus.START)
  });
  try {
    const payload = await execMethod();
    if (payload.success === false) {
      var _payload$errors;
      if ((_payload$errors = payload.errors) !== null && _payload$errors !== void 0 && _payload$errors.length) {
        dispatch({
          type: "".concat(actionName).concat(ActionStatus.FAILED),
          payload: payload.errors
        });
      } else {
        throw new Error(payload.message || 'system error');
      }
    } else {
      dispatch({
        type: "".concat(actionName).concat(ActionStatus.DONE),
        payload: {
          payload,
          request: requestPayload
        }
      });
      if (customMessage) {
        generateCustomNotificationMessage(dispatch, actionType, payload);
      } else {
        notify(dispatch, "".concat(SuccessNotification[action]), NotificationType.Success, showNotification);
      }
    }
  } catch (ex) {
    dispatch({
      type: "".concat(actionName).concat(ActionStatus.FAILED),
      payload: ex.message
    });
    notify(dispatch, "".concat(FailedNotification[action]), NotificationType.Error, showNotification);
    try {
      if (ex.graphQLErrors && ex.graphQLErrors.some(err => err.extensions.code === 'UNAUTHENTICATED')) {
        dispatch({
          type: NOT_AUTHORIZED,
          payload: false
        });
      }
    } catch (err) {
      // ignore
    }
  } finally {
    dispatch({
      type: DECREASE
    });
    dispatch({
      type: buildActionType(BUSY_INDICATOR, ActionStatus.DONE)
    });
  }
};
const notify = (dispatch, message, variant, show) => {
  if (show) {
    dispatch({
      type: NOTIFICATION,
      payload: {
        message,
        variant
      }
    });
  }
};
//  POSIBLE NEEDED IN FUTURE
const langs = {
  [buildActionType(CHECK_USER_MEMBERSHIP)]: {
    en: {
      available: 'Membership Available',
      notAvailable: 'Membership not available'
    },
    it: {
      available: 'Abbonamento disponibile',
      notAvailable: 'Iscrizione non disponibile'
    }
  }
};
const generateCustomNotificationMessage = (dispatch, actionType, payload) => {
  const lang = localStorage.getItem('i18nextLng') || 'it';
  if (buildActionType(actionType) === buildActionType(CHECK_USER_MEMBERSHIP)) {
    var _payload$data;
    if (payload !== null && payload !== void 0 && (_payload$data = payload.data) !== null && _payload$data !== void 0 && _payload$data.userHasMembership) {
      notify(dispatch, langs[buildActionType(actionType)][lang]['available'], NotificationType.Success, true);
    } else {
      notify(dispatch, langs[buildActionType(actionType)][lang]['notAvailable'], NotificationType.Warning, true);
    }
  }
};
// POSIBLE NEEDED IN FUTURE