import { apiCall } from './gqlClient';
const GETALLPROVIDERS_QUERY = "\n  query getAllProviders {\n    getAllProviders\n    {\n      id\n\t\t\tcityId\n\t\t\tname\n\t\t\ttype\n\t\t\tdescription\n\t\t\taddress\n\t\t\tpostcode\n\t\t\tcountry\n\t\t\tphone\n\t\t\tlatitude\n\t\t\tlongitude\n\t\t\tacceptPayments\n\t\t\tonlineDiscount\n\t\t\ttime\n\t\t\ttimeNote\n\t\t\ttags\n\t\t\turl\n\t\t\tblog\n\t\t\tfacebook\n\t\t\ttwitter\n\t\t\tlinkedin\n\t\t\tskype\n\t\t\tinstagram\n\t\t\tyoutube\n\t\t\temail\n\t\t\trole\n\t\t\tcreated\n\t\t\tactive\n\t\t\tsubscribed\n\t\t\tuserHasMembership\n\t\t\tmembershipsCounter\n\t\t\tfreeCancellationHours\n\t\t\treservationOffset\n\t\t\thasConnectedPaymentAccount\n\t\t\tdefaultTimeSlotStep\n\t\t\tlanguage\n\t\t\townerId\n\t\t\tlocked\n\t\t\tfree\n\t\t\tordersNumber\n\t\t\tnotificationEmail\n\t\t\tappName\n      ...fragments\n    }\n  }";
export const getAllProvidersApiCall = async function () {
  let fragments = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  return apiCall({
    query: GETALLPROVIDERS_QUERY,
    variables: {},
    fragments
  });
};
const GETPROVIDERDETAILS_QUERY = "\n  query getProviderDetails($id: Int!) {\n    getProviderDetails(id: $id)\n    {\n      id\n\t\t\tcityId\n\t\t\tname\n\t\t\ttype\n\t\t\tdescription\n\t\t\taddress\n\t\t\tpostcode\n\t\t\tcountry\n\t\t\tphone\n\t\t\tlatitude\n\t\t\tlongitude\n\t\t\tacceptPayments\n\t\t\tonlineDiscount\n\t\t\ttime\n\t\t\ttimeNote\n\t\t\ttags\n\t\t\turl\n\t\t\tblog\n\t\t\tfacebook\n\t\t\ttwitter\n\t\t\tlinkedin\n\t\t\tskype\n\t\t\tinstagram\n\t\t\tyoutube\n\t\t\temail\n\t\t\trole\n\t\t\tcreated\n\t\t\tactive\n\t\t\tsubscribed\n\t\t\tuserHasMembership\n\t\t\tmembershipsCounter\n\t\t\tfreeCancellationHours\n\t\t\treservationOffset\n\t\t\thasConnectedPaymentAccount\n\t\t\tdefaultTimeSlotStep\n\t\t\tlanguage\n\t\t\townerId\n\t\t\tlocked\n\t\t\tfree\n\t\t\tordersNumber\n\t\t\tnotificationEmail\n\t\t\tappName\n      ...fragments\n    }\n  }";
export const getProviderDetailsApiCall = async function (_ref) {
  let {
    id
  } = _ref;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: GETPROVIDERDETAILS_QUERY,
    variables: {
      id
    },
    fragments
  });
};
const GETRANDOMPROVIDERS_QUERY = "\n  query getRandomProviders {\n    getRandomProviders\n  }";
export const getRandomProvidersApiCall = async () => apiCall({
  query: GETRANDOMPROVIDERS_QUERY,
  variables: {}
});
const GETRANDOMPROVIDERSALSOBYKEYWORDORADDRESS_QUERY = "\n  query getRandomProvidersAlsoByKeywordOrAddress($keyword: String, $city: String, $country: String, $type: String) {\n    getRandomProvidersAlsoByKeywordOrAddress(keyword: $keyword, city: $city, country: $country, type: $type)\n  }";
export const getRandomProvidersAlsoByKeywordOrAddressApiCall = async _ref2 => {
  let {
    keyword,
    city,
    country,
    type
  } = _ref2;
  return apiCall({
    query: GETRANDOMPROVIDERSALSOBYKEYWORDORADDRESS_QUERY,
    variables: {
      keyword,
      city,
      country,
      type
    }
  });
};
const GETPROVIDERS_QUERY = "\n  query getProviders($categoryId: Int, $cityId: Int, $providerType: String, $keyword: String, $onlyMyMemberships: Boolean, $onlyActive: Boolean) {\n    getProviders(categoryId: $categoryId, cityId: $cityId, providerType: $providerType, keyword: $keyword, onlyMyMemberships: $onlyMyMemberships, onlyActive: $onlyActive)\n    {\n      id\n\t\t\tcityId\n\t\t\tname\n\t\t\ttype\n\t\t\tdescription\n\t\t\taddress\n\t\t\tpostcode\n\t\t\tcountry\n\t\t\tphone\n\t\t\tlatitude\n\t\t\tlongitude\n\t\t\tacceptPayments\n\t\t\tonlineDiscount\n\t\t\ttime\n\t\t\ttimeNote\n\t\t\ttags\n\t\t\turl\n\t\t\tblog\n\t\t\tfacebook\n\t\t\ttwitter\n\t\t\tlinkedin\n\t\t\tskype\n\t\t\tinstagram\n\t\t\tyoutube\n\t\t\temail\n\t\t\trole\n\t\t\tcreated\n\t\t\tactive\n\t\t\tsubscribed\n\t\t\tuserHasMembership\n\t\t\tmembershipsCounter\n\t\t\tfreeCancellationHours\n\t\t\treservationOffset\n\t\t\thasConnectedPaymentAccount\n\t\t\tdefaultTimeSlotStep\n\t\t\tlanguage\n\t\t\townerId\n\t\t\tlocked\n\t\t\tfree\n\t\t\tordersNumber\n\t\t\tnotificationEmail\n\t\t\tappName\n      ...fragments\n    }\n  }";
export const getProvidersApiCall = async function (_ref3) {
  let {
    categoryId,
    cityId,
    providerType,
    keyword,
    onlyMyMemberships,
    onlyActive
  } = _ref3;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: GETPROVIDERS_QUERY,
    variables: {
      categoryId,
      cityId,
      providerType,
      keyword,
      onlyMyMemberships,
      onlyActive
    },
    fragments
  });
};
const GETMYPROVIDERS_QUERY = "\n  query getMyProviders {\n    getMyProviders\n    {\n      id\n\t\t\tcityId\n\t\t\tname\n\t\t\ttype\n\t\t\tdescription\n\t\t\taddress\n\t\t\tpostcode\n\t\t\tcountry\n\t\t\tphone\n\t\t\tlatitude\n\t\t\tlongitude\n\t\t\tacceptPayments\n\t\t\tonlineDiscount\n\t\t\ttime\n\t\t\ttimeNote\n\t\t\ttags\n\t\t\turl\n\t\t\tblog\n\t\t\tfacebook\n\t\t\ttwitter\n\t\t\tlinkedin\n\t\t\tskype\n\t\t\tinstagram\n\t\t\tyoutube\n\t\t\temail\n\t\t\trole\n\t\t\tcreated\n\t\t\tactive\n\t\t\tsubscribed\n\t\t\tuserHasMembership\n\t\t\tmembershipsCounter\n\t\t\tfreeCancellationHours\n\t\t\treservationOffset\n\t\t\thasConnectedPaymentAccount\n\t\t\tdefaultTimeSlotStep\n\t\t\tlanguage\n\t\t\townerId\n\t\t\tlocked\n\t\t\tfree\n\t\t\tordersNumber\n\t\t\tnotificationEmail\n\t\t\tappName\n      ...fragments\n    }\n  }";
export const getMyProvidersApiCall = async function () {
  let fragments = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  return apiCall({
    query: GETMYPROVIDERS_QUERY,
    variables: {},
    fragments
  });
};
const GETPROVIDERGENERALINFORMATION_QUERY = "\n  query getProviderGeneralInformation($id: Int) {\n    getProviderGeneralInformation(id: $id)\n    {\n      id\n\t\t\tcityId\n\t\t\tname\n\t\t\ttype\n\t\t\tdescription\n\t\t\taddress\n\t\t\tpostcode\n\t\t\tcountry\n\t\t\tphone\n\t\t\tlatitude\n\t\t\tlongitude\n\t\t\tacceptPayments\n\t\t\tonlineDiscount\n\t\t\ttime\n\t\t\ttimeNote\n\t\t\ttags\n\t\t\turl\n\t\t\tblog\n\t\t\tfacebook\n\t\t\ttwitter\n\t\t\tlinkedin\n\t\t\tskype\n\t\t\tinstagram\n\t\t\tyoutube\n\t\t\temail\n\t\t\trole\n\t\t\tcreated\n\t\t\tactive\n\t\t\tsubscribed\n\t\t\tuserHasMembership\n\t\t\tmembershipsCounter\n\t\t\tfreeCancellationHours\n\t\t\treservationOffset\n\t\t\thasConnectedPaymentAccount\n\t\t\tdefaultTimeSlotStep\n\t\t\tlanguage\n\t\t\townerId\n\t\t\tlocked\n\t\t\tfree\n\t\t\tordersNumber\n\t\t\tnotificationEmail\n\t\t\tappName\n      ...fragments\n    }\n  }";
export const getProviderGeneralInformationApiCall = async function (_ref4) {
  let {
    id
  } = _ref4;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: GETPROVIDERGENERALINFORMATION_QUERY,
    variables: {
      id
    },
    fragments
  });
};
const GETPROVIDERIMAGES_QUERY = "\n  query getProviderImages {\n    getProviderImages\n    {\n      id\n\t\t\tproviderId\n\t\t\torderIndex\n\t\t\tfile\n\t\t\torig\n\t\t\txLarge\n\t\t\tlarge\n\t\t\tmedium\n\t\t\tsmall\n\t\t\ttempFileId\n      ...fragments\n    }\n  }";
export const getProviderImagesApiCall = async function () {
  let fragments = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  return apiCall({
    query: GETPROVIDERIMAGES_QUERY,
    variables: {},
    fragments
  });
};
const GETTOP100MOSTACTIVEPROVIDERS_QUERY = "\n  query getTop100MostActiveProviders($startDate: DateTime!, $endDate: DateTime!) {\n    getTop100MostActiveProviders(startDate: $startDate, endDate: $endDate)\n  }";
export const getTop100MostActiveProvidersApiCall = async _ref5 => {
  let {
    startDate,
    endDate
  } = _ref5;
  return apiCall({
    query: GETTOP100MOSTACTIVEPROVIDERS_QUERY,
    variables: {
      startDate,
      endDate
    }
  });
};
const GETNOTIFICATIONSSETTINGS_QUERY = "\n  query getNotificationsSettings {\n    getNotificationsSettings\n    {\n      users\n\t\t\tvisitatore\n\t\t\tcollaborators\n\t\t\tprovider\n      ...fragments\n    }\n  }";
export const getNotificationsSettingsApiCall = async function () {
  let fragments = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  return apiCall({
    query: GETNOTIFICATIONSSETTINGS_QUERY,
    variables: {},
    fragments
  });
};
const GETSMSNOTIFICATIONSSETTINGS_QUERY = "\n  query getSmsNotificationsSettings {\n    getSmsNotificationsSettings\n    {\n      users\n\t\t\tvisitatore\n\t\t\tcollaborators\n\t\t\tprovider\n      ...fragments\n    }\n  }";
export const getSmsNotificationsSettingsApiCall = async function () {
  let fragments = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  return apiCall({
    query: GETSMSNOTIFICATIONSSETTINGS_QUERY,
    variables: {},
    fragments
  });
};
const GETPROVIDERSWHEREUSERASSIGNEDASCOLLABORATOR_QUERY = "\n  query getProvidersWhereUserAssignedAsCollaborator($userId: Int!) {\n    getProvidersWhereUserAssignedAsCollaborator(userId: $userId)\n    {\n      id\n\t\t\tname\n\t\t\trole\n      ...fragments\n    }\n  }";
export const getProvidersWhereUserAssignedAsCollaboratorApiCall = async function (_ref6) {
  let {
    userId
  } = _ref6;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: GETPROVIDERSWHEREUSERASSIGNEDASCOLLABORATOR_QUERY,
    variables: {
      userId
    },
    fragments
  });
};
const TOGGLEPROVIDERSTATUS_QUERY = "\n  mutation toggleProviderStatus($id: Int!, $status: Boolean!) {\n    toggleProviderStatus(id: $id, status: $status)\n    {\n      success\n\t\t\tmessage\n\t\t\tid\n\t\t\tid2\n\t\t\tname\n\t\t\tstatus\n      ...fragments\n    }\n  }";
export const toggleProviderStatusApiCall = async function (_ref7) {
  let {
    id,
    status
  } = _ref7;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: TOGGLEPROVIDERSTATUS_QUERY,
    variables: {
      id,
      status
    },
    fragments
  });
};
const UPDATEPROVIDERPAYMENTSETTINGS_QUERY = "\n  mutation updateProviderPaymentSettings($freeCancellationHours: Int!, $onlineDiscount: Int, $reservationOffset: Int, $paymentOptions: PaymentOptionsInput) {\n    updateProviderPaymentSettings(freeCancellationHours: $freeCancellationHours, onlineDiscount: $onlineDiscount, reservationOffset: $reservationOffset, paymentOptions: $paymentOptions)\n    {\n      success\n\t\t\tmessage\n\t\t\tid\n\t\t\tid2\n\t\t\tname\n\t\t\tstatus\n      ...fragments\n    }\n  }";
export const updateProviderPaymentSettingsApiCall = async function (_ref8) {
  let {
    freeCancellationHours,
    onlineDiscount,
    reservationOffset,
    paymentOptions
  } = _ref8;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: UPDATEPROVIDERPAYMENTSETTINGS_QUERY,
    variables: {
      freeCancellationHours,
      onlineDiscount,
      reservationOffset,
      paymentOptions
    },
    fragments
  });
};
const CREATEPROVIDER_QUERY = "\n  mutation createProvider($request: ProviderCreateInput!) {\n    createProvider(request: $request)\n    {\n      success\n\t\t\tid\n\t\t\tcityId\n\t\t\tname\n\t\t\ttype\n\t\t\tdescription\n\t\t\taddress\n\t\t\tpostcode\n\t\t\tcountry\n\t\t\tphone\n\t\t\tlatitude\n\t\t\tlongitude\n\t\t\tacceptPayments\n\t\t\tonlineDiscount\n\t\t\ttime\n\t\t\ttimeNote\n\t\t\ttags\n\t\t\turl\n\t\t\tblog\n\t\t\tfacebook\n\t\t\ttwitter\n\t\t\tlinkedin\n\t\t\tskype\n\t\t\tinstagram\n\t\t\tyoutube\n\t\t\temail\n\t\t\trole\n\t\t\tcreated\n\t\t\tactive\n\t\t\tlocked\n      ...fragments\n    }\n  }";
export const createProviderApiCall = async function (_ref9) {
  let {
    request
  } = _ref9;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: CREATEPROVIDER_QUERY,
    variables: {
      request
    },
    fragments
  });
};
const UPDATEPROVIDER_QUERY = "\n  mutation updateProvider($request: ProviderUpdateInput!) {\n    updateProvider(request: $request)\n    {\n      success\n\t\t\tmessage\n\t\t\tid\n\t\t\tid2\n\t\t\tname\n\t\t\tstatus\n      ...fragments\n    }\n  }";
export const updateProviderApiCall = async function (_ref10) {
  let {
    request
  } = _ref10;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: UPDATEPROVIDER_QUERY,
    variables: {
      request
    },
    fragments
  });
};
const UPDATEPROVIDERBYADMIN_QUERY = "\n  mutation updateProviderByAdmin($id: Int!, $request: ProviderUpdateInput!) {\n    updateProviderByAdmin(id: $id, request: $request)\n    {\n      success\n\t\t\tmessage\n\t\t\tid\n\t\t\tid2\n\t\t\tname\n\t\t\tstatus\n      ...fragments\n    }\n  }";
export const updateProviderByAdminApiCall = async function (_ref11) {
  let {
    id,
    request
  } = _ref11;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: UPDATEPROVIDERBYADMIN_QUERY,
    variables: {
      id,
      request
    },
    fragments
  });
};
const DELETEPROVIDERIMAGE_QUERY = "\n  mutation deleteProviderImage($id: String!) {\n    deleteProviderImage(id: $id)\n    {\n      success\n\t\t\tmessage\n\t\t\tid\n\t\t\tname\n\t\t\tidx\n\t\t\ttitle\n      ...fragments\n    }\n  }";
export const deleteProviderImageApiCall = async function (_ref12) {
  let {
    id
  } = _ref12;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: DELETEPROVIDERIMAGE_QUERY,
    variables: {
      id
    },
    fragments
  });
};
const ADDIMAGEINPROVIDERGALLERY_QUERY = "\n  mutation addImageInProviderGallery($name: String!, $metadata: ImageMetadataInput!) {\n    addImageInProviderGallery(name: $name, metadata: $metadata)\n    {\n      id\n\t\t\tpresignedUrl\n\t\t\tsignedUrl\n\t\t\tsuccess\n\t\t\tname\n\t\t\tsectionIndex\n      ...fragments\n    }\n  }";
export const addImageInProviderGalleryApiCall = async function (_ref13) {
  let {
    name,
    metadata
  } = _ref13;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: ADDIMAGEINPROVIDERGALLERY_QUERY,
    variables: {
      name,
      metadata
    },
    fragments
  });
};
const REORDERPROVIDERGALLERY_QUERY = "\n  mutation reorderProviderGallery($oldPosition: Int!, $newPosition: Int!) {\n    reorderProviderGallery(oldPosition: $oldPosition, newPosition: $newPosition)\n    {\n      success\n\t\t\tmessage\n\t\t\tid\n\t\t\tname\n\t\t\tidx\n\t\t\ttitle\n      ...fragments\n    }\n  }";
export const reorderProviderGalleryApiCall = async function (_ref14) {
  let {
    oldPosition,
    newPosition
  } = _ref14;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: REORDERPROVIDERGALLERY_QUERY,
    variables: {
      oldPosition,
      newPosition
    },
    fragments
  });
};
const ADMINUPDATEPROVIDERSUBSCRIPTIONENDTIME_QUERY = "\n  mutation adminUpdateProviderSubscriptionEndTime($providerId: Int!, $endsAt: String!) {\n    adminUpdateProviderSubscriptionEndTime(providerId: $providerId, endsAt: $endsAt)\n    {\n      success\n\t\t\tmessage\n\t\t\tid\n\t\t\tname\n\t\t\tidx\n\t\t\ttitle\n      ...fragments\n    }\n  }";
export const adminUpdateProviderSubscriptionEndTimeApiCall = async function (_ref15) {
  let {
    providerId,
    endsAt
  } = _ref15;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: ADMINUPDATEPROVIDERSUBSCRIPTIONENDTIME_QUERY,
    variables: {
      providerId,
      endsAt
    },
    fragments
  });
};
const UNLOCKPROVIDER_QUERY = "\n  mutation unlockProvider($id: Int!) {\n    unlockProvider(id: $id)\n    {\n      success\n\t\t\tmessage\n\t\t\tid\n\t\t\tid2\n\t\t\tname\n\t\t\tstatus\n      ...fragments\n    }\n  }";
export const unlockProviderApiCall = async function (_ref16) {
  let {
    id
  } = _ref16;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: UNLOCKPROVIDER_QUERY,
    variables: {
      id
    },
    fragments
  });
};
const UPDATENOTIFICATIONSSETTINGS_QUERY = "\n  mutation updateNotificationsSettings($settings: NotificationsSettingsInput!) {\n    updateNotificationsSettings(settings: $settings)\n    {\n      success\n\t\t\tmessage\n\t\t\tid\n\t\t\tid2\n\t\t\tname\n\t\t\tstatus\n      ...fragments\n    }\n  }";
export const updateNotificationsSettingsApiCall = async function (_ref17) {
  let {
    settings
  } = _ref17;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: UPDATENOTIFICATIONSSETTINGS_QUERY,
    variables: {
      settings
    },
    fragments
  });
};
const UPDATESMSNOTIFICATIONSSETTINGS_QUERY = "\n  mutation updateSmsNotificationsSettings($settings: NotificationsSettingsInput!) {\n    updateSmsNotificationsSettings(settings: $settings)\n    {\n      success\n\t\t\tmessage\n\t\t\tid\n\t\t\tid2\n\t\t\tname\n\t\t\tstatus\n      ...fragments\n    }\n  }";
export const updateSmsNotificationsSettingsApiCall = async function (_ref18) {
  let {
    settings
  } = _ref18;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: UPDATESMSNOTIFICATIONSSETTINGS_QUERY,
    variables: {
      settings
    },
    fragments
  });
};