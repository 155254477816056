import { apiCall } from './gqlClient';
const GETWEBINTEGRATION_QUERY = "\n  query getWebIntegration($id: Int!) {\n    getWebIntegration(id: $id)\n    {\n      id\n\t\t\tbackground\n\t\t\tbase\n\t\t\tbody\n\t\t\tbutton\n\t\t\tfont\n\t\t\tfontname\n\t\t\tgradient\n\t\t\tlink\n\t\t\tname\n\t\t\tpage\n\t\t\tproviderId\n\t\t\tintegrationType\n\t\t\turl\n\t\t\tintegrationCode\n      ...fragments\n    }\n  }";
export const getWebIntegrationApiCall = async function (_ref) {
  let {
    id
  } = _ref;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: GETWEBINTEGRATION_QUERY,
    variables: {
      id
    },
    fragments
  });
};
const GETALLWEBINTEGRATIONS_QUERY = "\n  query getAllWebIntegrations {\n    getAllWebIntegrations\n    {\n      id\n\t\t\tbackground\n\t\t\tbase\n\t\t\tbody\n\t\t\tbutton\n\t\t\tfont\n\t\t\tfontname\n\t\t\tgradient\n\t\t\tlink\n\t\t\tname\n\t\t\tpage\n\t\t\tproviderId\n\t\t\tintegrationType\n\t\t\turl\n\t\t\tintegrationCode\n      ...fragments\n    }\n  }";
export const getAllWebIntegrationsApiCall = async function () {
  let fragments = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  return apiCall({
    query: GETALLWEBINTEGRATIONS_QUERY,
    variables: {},
    fragments
  });
};
const GETLATESTWEBINTEGRATIONWITHPROVIDERID_QUERY = "\n  query getLatestWebIntegrationWithProviderId($id: Int!) {\n    getLatestWebIntegrationWithProviderId(id: $id)\n    {\n      id\n\t\t\tbackground\n\t\t\tbase\n\t\t\tbody\n\t\t\tbutton\n\t\t\tfont\n\t\t\tfontname\n\t\t\tgradient\n\t\t\tlink\n\t\t\tname\n\t\t\tpage\n\t\t\tproviderId\n\t\t\tintegrationType\n\t\t\turl\n\t\t\tintegrationCode\n      ...fragments\n    }\n  }";
export const getLatestWebIntegrationWithProviderIdApiCall = async function (_ref2) {
  let {
    id
  } = _ref2;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: GETLATESTWEBINTEGRATIONWITHPROVIDERID_QUERY,
    variables: {
      id
    },
    fragments
  });
};
const SAVEWEBINTEGRATION_QUERY = "\n  mutation saveWebIntegration($request: WebIntegrationConfigInput!) {\n    saveWebIntegration(request: $request)\n    {\n      success\n\t\t\tmessage\n\t\t\tid\n\t\t\tid2\n\t\t\tname\n\t\t\tstatus\n      ...fragments\n    }\n  }";
export const saveWebIntegrationApiCall = async function (_ref3) {
  let {
    request
  } = _ref3;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: SAVEWEBINTEGRATION_QUERY,
    variables: {
      request
    },
    fragments
  });
};
const DELETEWEBINTEGRATION_QUERY = "\n  mutation deleteWebIntegration($id: Int) {\n    deleteWebIntegration(id: $id)\n    {\n      success\n\t\t\tmessage\n\t\t\tid\n\t\t\tid2\n\t\t\tname\n\t\t\tstatus\n      ...fragments\n    }\n  }";
export const deleteWebIntegrationApiCall = async function (_ref4) {
  let {
    id
  } = _ref4;
  let fragments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return apiCall({
    query: DELETEWEBINTEGRATION_QUERY,
    variables: {
      id
    },
    fragments
  });
};