import { cancelB2bSubscriptionApiCall, changeB2bSubscriptionPlanApiCall, createSetupIntentForProviderApiCall, deletePaymentMethodApiCall, generatePaymentIntentApiCall, getCustomersPaymentProvidersApiCall, getMyPaymentMethodsForProviderApiCall, linkConnectedPaymentAccountApiCall, unlinkConnectedPaymentAccountApiCall, updateSubscriptionPromoCodeApiCall, validatePromoCodeApiCall } from '@guryou/api-client-gql';
import { execute } from '../actions/actionWrapper';
import { resetState } from '../actions/resetActions';
import { Action, basicReducer } from '../core';

// Types
export const LINK_CONNECTED_ACCOUNT = {
  entity: 'CONNECTED_ACCOUNT',
  action: Action.HANDLE
};
export const UNLINK_CONNECTED_ACCOUNT = {
  entity: 'UNCONNECTED_ACCOUNT',
  action: Action.HANDLE
};
export const CANCEL_B2B_SUBSCRIPTION = {
  entity: 'B2B_SUBSCRIPTION',
  action: Action.DELETE
};
export const UPDATE_B2B_SUBSCRIPTION_PROMO_CODE = {
  entity: 'B2B_SUBSCRIPTION_PROMO_CODE',
  action: Action.UPDATE
};
export const UPDATE_B2B_SUBSCRIPTION = {
  entity: 'B2B_SUBSCRIPTION',
  action: Action.UPDATE
};
export const VALIDATE_PROMO_CODE = {
  entity: 'PROMO_CODE',
  action: Action.HANDLE
};
export const PAYMENT_INTENT = {
  entity: 'PAYMENT_INTENT_GET',
  action: Action.GET
};
export const PAYMENT_METHODS_GET = {
  entity: 'PAYMENT_METHODS_GET',
  action: Action.GET
};
export const SETUP_INTENT_CREATE = {
  entity: 'SETUP_INTENT_CREATE',
  action: Action.CREATE
};
export const PAYMENT_METHOD_DELETE = {
  entity: 'PAYMENT_METHOD_DELETE',
  action: Action.DELETE
};
export const CUSTOMERS_PAYMENT_PROVIDERS_GET = {
  entity: 'CUSTOMERS_PAYMENT_PROVIDERS_GET',
  action: Action.GET
};

// Actions
export const linkConnectedPaymentAccount = code => async dispatch => {
  execute(dispatch, () => linkConnectedPaymentAccountApiCall({
    code
  }), LINK_CONNECTED_ACCOUNT, false);
};
export const unlinkConnectedPaymentAccount = () => async dispatch => {
  execute(dispatch, () => unlinkConnectedPaymentAccountApiCall(), UNLINK_CONNECTED_ACCOUNT, false);
};
export const cancelB2bSubscription = () => async dispatch => {
  execute(dispatch, () => cancelB2bSubscriptionApiCall(), CANCEL_B2B_SUBSCRIPTION, false);
};
export const updateSubscriptionPromoCode = _ref => {
  let {
    promoCode
  } = _ref;
  return async dispatch => {
    execute(dispatch, () => updateSubscriptionPromoCodeApiCall({
      promoCode
    }), UPDATE_B2B_SUBSCRIPTION, true);
  };
};
export const changeB2bSubscriptionPlan = planId => async dispatch => {
  execute(dispatch, () => changeB2bSubscriptionPlanApiCall({
    planId
  }), UPDATE_B2B_SUBSCRIPTION, true);
};
export const validatePromoCode = (promoCode, planId) => async dispatch => {
  execute(dispatch, () => validatePromoCodeApiCall({
    promoCode,
    planId
  }), VALIDATE_PROMO_CODE, false);
};
export const generatePaymentIntentNew = _ref2 => {
  let {
    reservationId,
    subscriptionId,
    productId,
    membershipId,
    quantity,
    startTime,
    email,
    firstname,
    lastname,
    phone
  } = _ref2;
  return async dispatch => {
    execute(dispatch, () => generatePaymentIntentApiCall({
      reservationId,
      subscriptionId,
      productId,
      membershipId,
      quantity,
      startTime,
      email: email || null,
      firstname: firstname || null,
      lastname: lastname || null,
      phone: phone || null
    }), PAYMENT_INTENT, false);
  };
};
export const getMyPaymentMethodsForProvider = providerId => async dispatch => {
  execute(dispatch, () => getMyPaymentMethodsForProviderApiCall({
    providerId
  }, {
    card: ['last4', 'brand', 'expMonth', 'expYear', 'fingerprint']
  }), PAYMENT_METHODS_GET);
};
export const createSetupIntentForProvider = (providerId, paymentMethod) => async dispatch => {
  execute(dispatch, () => createSetupIntentForProviderApiCall({
    providerId,
    paymentMethod
  }), SETUP_INTENT_CREATE);
};
export const deletePaymentMethod = (providerId, paymentMethod) => async dispatch => {
  execute(dispatch, () => deletePaymentMethodApiCall({
    providerId,
    paymentMethod
  }), PAYMENT_METHOD_DELETE);
};
export const getCustomersPaymentProviders = () => async dispatch => {
  execute(dispatch, () => getCustomersPaymentProvidersApiCall(), CUSTOMERS_PAYMENT_PROVIDERS_GET);
};

// Reset actions
export const resetGeneratePaymentIntentState = () => async dispatch => dispatch(resetState(PAYMENT_INTENT));
export const resetCreateSetupIntentForProviderState = () => async dispatch => dispatch(resetState(SETUP_INTENT_CREATE));
export const resetGetMyPaymentMethodsForProviderState = () => async dispatch => dispatch(resetState(PAYMENT_METHODS_GET));

// Reducers
export const paymentsReducer = {
  update: basicReducer(UPDATE_B2B_SUBSCRIPTION),
  linkAccount: basicReducer(LINK_CONNECTED_ACCOUNT),
  unlinkAccount: basicReducer(UNLINK_CONNECTED_ACCOUNT),
  cancelSubscription: basicReducer(CANCEL_B2B_SUBSCRIPTION),
  promoCodeValidation: basicReducer(VALIDATE_PROMO_CODE)
};
export const paymentsFeelReducer = {
  paymentIntent: basicReducer(PAYMENT_INTENT),
  listPaymentMethods: basicReducer(PAYMENT_METHODS_GET),
  createSetupIntent: basicReducer(SETUP_INTENT_CREATE),
  deletePaymentMethod: basicReducer(PAYMENT_METHOD_DELETE),
  customersPaymentProviders: basicReducer(CUSTOMERS_PAYMENT_PROVIDERS_GET)
};