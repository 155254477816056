import { deleteGroupApiCall, getAllProviderGroupsApiCall, getGroupsApiCall, saveGroupApiCall } from '@guryou/api-client-gql';
import { resetState } from '../actions';
import { execute } from '../actions/actionWrapper';
import { Action, basicReducer } from '../core';
export const PROVIDER_DELETE_GROUP = {
  entity: 'PROVIDER_GROUPS',
  action: Action.DELETE
};
export const PROVIDER_EDIT_GROUP = {
  entity: 'PROVIDER_GROUPS',
  action: Action.UPDATE
};
export const SERVICE_GROUPS_GET_MANY = {
  entity: 'GROUPS',
  action: Action.GET_MANY
};
export const SERVICE_GROUPS_GET_MANY2 = {
  entity: 'GROUPS2',
  action: Action.GET_MANY
};
export const SERVICE_GROUPS_GET_MANY_FOR_SELECTION = {
  entity: 'GROUPS_FOR_SELECTION',
  action: Action.GET_MANY
};
export const ALL_GROUPS = {
  entity: 'ALL_GROUPS',
  action: Action.GET_MANY
};
export const GROUPS_CREATE = {
  entity: 'GROUPS',
  action: Action.CREATE
};
export const GROUPS_UPDATE = {
  entity: 'GROUPS',
  action: Action.UPDATE
};
export const getGroups = type => async dispatch => execute(dispatch, () => getGroupsApiCall({
  type
}), SERVICE_GROUPS_GET_MANY, false, {
  type
}, true);
export const getGroups2 = type => async dispatch => execute(dispatch, () => getGroupsApiCall({
  type
}), SERVICE_GROUPS_GET_MANY2, false, {
  type
}, true);
export const getGroupsForSelection = type => async dispatch => execute(dispatch, () => getGroupsApiCall({
  type
}), SERVICE_GROUPS_GET_MANY_FOR_SELECTION, false, {
  type
}, true);
export const getAllProviderGroups = () => async dispatch => execute(dispatch, () => getAllProviderGroupsApiCall(), ALL_GROUPS);
export const saveGroup = data => async dispatch => {
  const {
    id,
    color,
    ...rest
  } = data;
  execute(dispatch, () => saveGroupApiCall({
    id: +id || null,
    color: color.replace('#', ''),
    ...rest
  }), id ? GROUPS_UPDATE : GROUPS_CREATE, true);
};
export const resetGroupsState = () => async dispatch => dispatch(resetState(SERVICE_GROUPS_GET_MANY));
export const resetGroupsState2 = () => async dispatch => dispatch(resetState(SERVICE_GROUPS_GET_MANY2));
export const deleteGroup = function (id) {
  let type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return async dispatch => execute(dispatch, () => deleteGroupApiCall({
    id,
    type
  }), PROVIDER_DELETE_GROUP, true);
};
export const groupReducers = {
  all: basicReducer(SERVICE_GROUPS_GET_MANY),
  all2: basicReducer(SERVICE_GROUPS_GET_MANY2),
  allForSelection: basicReducer(SERVICE_GROUPS_GET_MANY_FOR_SELECTION),
  allProviderGroups: basicReducer(ALL_GROUPS),
  create: basicReducer(GROUPS_CREATE),
  update: basicReducer(GROUPS_UPDATE),
  delete: basicReducer(PROVIDER_DELETE_GROUP)
};