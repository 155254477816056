import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FlexColumn, FlexRow, LargeText, SmallText, Spinner } from '@guryou/html-components';
import { getProvidersForLanding, stateIsLoaded } from '@guryou/redux';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CardCarousel from 'components/carousel';
import CarouselSlide from './CarouselSlide';

const Centers = ({ width }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { providers } = useSelector(state => state);

  useEffect(() => {
    dispatch(getProvidersForLanding());
  }, [dispatch]);

  return (
    <FlexColumn marginTop={width < 900 ? 25 : 30} marginBottom={10}>
      <FlexRow xs={11} justifyContent={width < 650 ? 'center' : 'space-between'} marginBottom={10} alignItems="center">
        <LargeText fontSize={width < 1400 ? '2rem' : '2.7rem'} fontWeight={700}>
          {t('CONTENT.recommendedForYou')}
        </LargeText>
        {width < 650 ? (
          ''
        ) : (
          <SmallText fontSize={width < 1400 ? '1rem' : '1.5rem'}>
            {t('CONTENT.lookingForCenter')} <ArrowForwardIcon style={{ verticalAlign: 'middle' }} />
          </SmallText>
        )}
      </FlexRow>
      {!stateIsLoaded(providers) ? (
        <Spinner />
      ) : (
        <CardCarousel CarouselSlide={<CarouselSlide width={width} data={providers.data} />} width={width} totalSlides={10} />
      )}
    </FlexColumn>
  );
};

export default Centers;
