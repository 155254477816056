const submittersRegex = /^(?:submit|button|image|reset|file)$/i;
const controllsRegex = /^(?:input|select|textarea|keygen)/i;
const brackets = /(\[[^[\]]*\])/g;
const serialize = (form, options) => {
  if (typeof options != 'object') {
    options = {
      hash: !!options
    };
  } else if (options.hash === undefined) {
    options.hash = true;
  }
  let result = options.hash ? {} : '';
  const serializer = options.serializer || (options.hash ? hashSerializer : strSerialize);
  const elements = form && form.elements ? form.elements : [];
  //Object store each radio and set if it's empty or not
  const radioStore = Object.create(null);
  for (let i = 0; i < elements.length; ++i) {
    const element = elements[i];

    // ingore disabled fields
    if (!options.disabled && element.disabled || !element.name) {
      continue;
    }

    // ignore anyhting that is not considered a success field
    if (!controllsRegex.test(element.nodeName) || submittersRegex.test(element.type)) {
      continue;
    }
    const key = element.name;
    let val = element.value;

    // we can't just use element.value for checkboxes cause some browsers lie to us
    // they say "on" for value when the box isn't checked
    if ((element.type === 'checkbox' || element.type === 'radio') && !element.checked) {
      val = undefined;
    }

    // If we want empty elements
    if (options.empty) {
      // for checkbox
      if (element.type === 'checkbox' && !element.checked) {
        if (element.value === 'Y') {
          val = 'N';
        } else {
          val = key.match(brackets) ? 'N' : '';
        }
      }
      // for radio
      if (element.type === 'radio') {
        if (!radioStore[element.name] && !element.checked) {
          radioStore[element.name] = false;
        } else if (element.checked) {
          radioStore[element.name] = true;
        }
      }
      if (val === undefined && element.type === 'radio') {
        continue;
      }
    } else if (!val) {
      continue;
    }

    // multi select boxes
    if (element.type === 'select-multiple') {
      val = [];
      const selectOptions = element.options;
      let isSelectedOptions = false;
      for (let j = 0; j < selectOptions.length; ++j) {
        const option = selectOptions[j];
        const allowedEmpty = options.empty && !option.value;
        const hasValue = option.value || allowedEmpty;
        if (option.selected && hasValue) {
          isSelectedOptions = true;
          if (options.hash && key.slice(key.length - 2) !== '[]') {
            result = serializer(result, key + '[]', option.value);
          } else {
            result = serializer(result, key, option.value);
          }
        }
      }
      if (!isSelectedOptions && options.empty) {
        result = serializer(result, key, '');
      }
      continue;
    }
    result = serializer(result, key, val);
  }
  if (options.empty) {
    for (const rKey in radioStore) {
      if (!radioStore[rKey]) {
        result = serializer(result, rKey, '');
      }
    }
  }
  return result;
};
const parseKeys = string => {
  const keys = [];
  const prefix = /^([^[\]]*)/;
  const children = new RegExp(brackets);
  let match = prefix.exec(string);
  if (match[1]) {
    keys.push(match[1]);
  }
  while ((match = children.exec(string)) !== null) {
    keys.push(match[1]);
  }
  return keys;
};
const hashAssign = (result, keys, value) => {
  if (keys.length === 0) {
    result = value;
    return result;
  }
  const key = keys.shift();
  const between = key.match(/^\[(.+?)\]$/);
  if (key === '[]') {
    result = result || [];
    if (Array.isArray(result)) {
      result.push(hashAssign(null, keys, value));
    } else {
      // This might be the result of bad name attributes like "[][foo]",
      // in this case the original `result` object will already be
      // assigned to an object literal. Rather than coerce the object to
      // an array, or cause an exception the attribute "_values" is
      // assigned as an array.
      result._values = result._values || [];
      result._values.push(hashAssign(null, keys, value));
    }
    return result;
  }

  // Key is an attribute name and can be assigned directly.
  if (!between) {
    result[key] = hashAssign(result[key], keys, value);
  } else {
    const string = between[1];
    // +const converts the variable into a number
    // better than parseInt because it doesn't truncate away trailing
    // letters and actually fails if whole thing is not a number
    const index = +string;

    // If the characters between the brackets is not a number it is an
    // attribute name and can be assigned directly.
    if (isNaN(index)) {
      result = result || {};
      result[string] = hashAssign(result[string], keys, value);
    } else {
      result = result || [];
      result[index] = hashAssign(result[index], keys, value);
    }
  }
  return result;
};

// Object/hash encoding serializer.
const hashSerializer = (result, key, value) => {
  const matches = key.match(brackets);
  if (matches) {
    const keys = parseKeys(key);
    hashAssign(result, keys, value);
  } else {
    const existing = result[key];
    if (existing) {
      if (!Array.isArray(existing)) {
        result[key] = [existing];
      }
      result[key].push(value);
    } else {
      result[key] = value;
    }
  }
  return result;
};

// urlform encoding serializer
const strSerialize = (result, key, value) => {
  value = value.replace(/(\r)?\n/g, '\r\n');
  value = encodeURIComponent(value);
  value = value.replace(/%20/g, '+');
  return result + (result ? '&' : '') + encodeURIComponent(key) + '=' + value;
};
export { serialize };