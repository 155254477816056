import { getPlansApiCall } from '@guryou/api-client-gql';
import { execute } from '../actions/actionWrapper';
import { Action, basicReducer } from '../core';
export const PLANS_GET_MANY = {
  entity: 'PLAN',
  action: Action.GET_MANY
};
export const getPlans = () => async dispatch => {
  execute(dispatch, () => getPlansApiCall({}), PLANS_GET_MANY, false);
};
export const plansReducer = {
  all: basicReducer(PLANS_GET_MANY)
};