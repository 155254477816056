import { getAllCoursesApiCall, getPublicCoursesApiCall, getServicesApiCall } from '@guryou/api-client-gql';
import { execute } from '../actions/actionWrapper';
import { Action, basicReducer } from '../core';

// Types
export const COURSES_GET_MANY = {
  entity: 'COURSE',
  action: Action.GET_MANY
};
export const PROVIDER_COURSES_GET_MANY = {
  entity: 'PROVIDER_COURSE',
  action: Action.GET_MANY
};

// Actions
export const getPublicCourses = (providerId, actionType) => async dispatch => execute(dispatch, () => getPublicCoursesApiCall({
  providerId
}), actionType || COURSES_GET_MANY);
export const getAllCourses = (providerId, actionType) => async dispatch => execute(dispatch, () => getAllCoursesApiCall({
  providerId
}), actionType);
export const getCourses = function () {
  let silent = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  return async dispatch => execute(dispatch, () => getServicesApiCall({
    type: 'Y'
  }, {
    group: ['id', 'name', 'color']
  }), PROVIDER_COURSES_GET_MANY, false, null, !silent);
};

// Reducers
export const courseReducers = {
  all: basicReducer(PROVIDER_COURSES_GET_MANY),
  active: basicReducer(COURSES_GET_MANY)
};