import { Action } from '../core';
const CALENDAR_EVENTS = {
  entity: 'CALENDAR_EVENTS',
  action: Action.GET_MANY
};
const CALENDAR_FILTER_GET_SERVICES = {
  entity: 'CALENDAR_FILTER_SERVICES',
  action: Action.GET_MANY
};
const CALENDAR_FILTER_GET_COURSES = {
  entity: 'CALENDAR_FILTER_COURSES',
  action: Action.GET_MANY
};
const CALENDAR_FILTER_GET_EVENTS = {
  entity: 'CALENDAR_FILTER_EVENTS',
  action: Action.GET_MANY
};
const CALENDAR_FILTER_GET_SUBSERVICES = {
  entity: 'CALENDAR_FILTER_SUBSERVICES',
  action: Action.GET_MANY
};
const CALENDAR_FILTER_GET_CLASSES = {
  entity: 'CALENDAR_FILTER_CLASSES',
  action: Action.GET_MANY
};
const ORDER_EVENTS = {
  entity: 'ORDER_EVENTS',
  action: Action.GET_MANY
};
const ORDER_EVENT_CREATE = {
  entity: 'ORDER_EVENT',
  action: Action.CREATE
};
const MARK_ORDER_AS_PAID = {
  entity: 'MARK_ORDER_AS_PAID',
  action: Action.HANDLE
};
export { MARK_ORDER_AS_PAID, ORDER_EVENT_CREATE, ORDER_EVENTS, CALENDAR_EVENTS, CALENDAR_FILTER_GET_SERVICES, CALENDAR_FILTER_GET_COURSES, CALENDAR_FILTER_GET_EVENTS, CALENDAR_FILTER_GET_SUBSERVICES, CALENDAR_FILTER_GET_CLASSES };