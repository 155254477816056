"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserTypes = exports.GenderTarget = exports.Gender = void 0;
var UserTypes = exports.UserTypes = {
  admin: 'admin',
  author: 'author',
  collaborator: 'collaborator',
  customer: 'customer',
  director: 'director',
  manager: 'manager',
  owner: 'owner'
};
var Gender = exports.Gender = {
  male: 'M',
  female: 'F'
};
var GenderTarget = exports.GenderTarget = {
  all: 'all',
  male: 'male',
  female: 'female'
};