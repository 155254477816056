import { getMonthlyAvailableBookingsApiCall, getUsersHomeDetailsApiCall } from '@guryou/api-client-gql';
import { resetState } from '../actions';
import { execute } from '../actions/actionWrapper';
import { Action, basicReducer } from '../core';

// Types
export const MONTHLY_AVAILABLE_GET_BOOKINGS = {
  entity: 'MONTHLY_AVAILABLE_GET_BOOKINGS',
  action: Action.GET
};
export const USER_HOME_DETAILS = {
  entity: 'USER_HOME_DETAILS',
  action: Action.GET
};

//Actions
export const monthlyAvailableBookingsAction = (providerId, startTime, entityType) => async dispatch => execute(dispatch, () => getMonthlyAvailableBookingsApiCall({
  providerId,
  startTime,
  entityType
}, {
  resources: ['id', 'name']
}), MONTHLY_AVAILABLE_GET_BOOKINGS);
export const getUsersHomeDetails = () => async dispatch => execute(dispatch, () => getUsersHomeDetailsApiCall(), USER_HOME_DETAILS);

//Reset actions
export const resetmonthlyAvailableBookingsAction = () => async dispatch => dispatch(resetState(MONTHLY_AVAILABLE_GET_BOOKINGS));

//Reducers
export const calendarReducers = {
  monthlyAvailableBookings: basicReducer(MONTHLY_AVAILABLE_GET_BOOKINGS),
  usersHomeDetails: basicReducer(USER_HOME_DETAILS)
};