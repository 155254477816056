// ITALIAN

const SuccessNotification = {
  _CREATE: 'Creato con successo',
  _DELETE: 'Cancellato con successo',
  _UPDATE: 'Aggiornato con successo',
  _DEPLOY: 'Rilasciato successo',
  _INVITE: 'Invitato con successo',
  _CONNECT: 'Connesso con successo',
  _TRIGGER: 'Avviato con successo',
  _ARCHIVE: 'Archiviato con successo',
  _HANDLE: 'Eseguito con successo',
  SUCCESS: 'Successo'
};
const FailedNotification = {
  _CREATE: 'Errore durante la creazione',
  _DELETE: 'Errore durante la cancellazione',
  _UPDATE: "Errore durante l'aggiornamento",
  _DEPLOY: 'Errore durante il rilascio',
  _INVITE: 'Errore durante l´invito',
  _CONNECT: 'Errore durante la connessione',
  _TRIGGER: 'Errore durante l´avviamento',
  _ARCHIVE: "Errore durante l'archiviazione",
  _HANDLE: 'Errore durante'
};
export { SuccessNotification, FailedNotification };