import { useEffect } from 'react';
import { useFirstMountState } from './useFirstMountState';
const useUpdateEffect = (effect, deps) => {
  const isFirstMount = useFirstMountState();
  useEffect(() => {
    if (!isFirstMount) {
      effect();
    }
    // eslint-disable-next-line
  }, deps);
};
export default useUpdateEffect;