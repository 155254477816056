"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.supportedVideoFiles = exports.supportedImages = exports.SubscriptionPackages = exports.Status = exports.ServiceTypes = exports.ResourceTypes = exports.ResourceTypeId = exports.ProviderTypes = exports.ProviderMainCategories = exports.PricingModel = exports.PaymentMethods = exports.PaymentChannels = exports.PaidStatus = exports.MembershipTypes = exports.MembershipRuleTypes = exports.MembershipRulePeriods = exports.MainServiceTypes = exports.Languages = exports.GroupTypes = exports.GenderTypes = exports.FileTypes = exports.EntityTypes = exports.EntityStatus = exports.AgreementTypes = void 0;
var EntityTypes = exports.EntityTypes = {
  "break": 'break',
  "class": 'class',
  course: 'course',
  event: 'event',
  membership: 'membership',
  product: 'product',
  reservation: 'reservation',
  service: 'service',
  serviceOneTime: 'onetime',
  // old naming
  session: 'session',
  subscription: 'subscription',
  subService: 'subservice',
  video: 'video',
  workDay: 'work_day',
  holiday: 'holiday',
  audio: 'audio',
  image: 'image',
  document: 'document',
  workout: 'workout',
  plan: 'plan',
  client: 'client'
};
var GroupTypes = exports.GroupTypes = {
  course: 'course',
  event: 'event',
  membership: 'membership',
  service: 'service',
  serviceOneTime: 'onetime',
  video: 'video',
  plan: 'plan',
  client: 'client'
};
var MembershipTypes = exports.MembershipTypes = {
  service: 'service',
  courseOrEvent: 'course/event',
  video: 'video',
  workout: 'workout',
  subscription: 'subscription'
};
var MembershipRuleTypes = exports.MembershipRuleTypes = {
  reservationLimit: 'reservationLimit',
  subscriptionLimit: 'subscriptionLimit',
  cancelReservationLimit: 'cancelReservationLimit',
  cancelSubscriptionLimit: 'cancelSubscriptionLimit',
  cancelPeriod: 'cancelPeriod'
};
var MembershipRulePeriods = exports.MembershipRulePeriods = {
  day: 'day',
  week: 'week',
  month: 'month',
  allPeriod: 'allPeriod',
  noPeriod: 'noPeriod'
};
var MainServiceTypes = exports.MainServiceTypes = {
  service: 'N',
  course: 'Y',
  event: 'E'
};
var ServiceTypes = exports.ServiceTypes = {
  service: 'N',
  course: 'Y',
  event: 'E',
  videos: 'V',
  workout: 'W',
  membership: 'M'
};
var Status = exports.Status = {
  active: true,
  inactive: false,
  upcoming: 'upcoming',
  completed: 'completed'
};
var EntityStatus = exports.EntityStatus = {
  active: 'A',
  pending: 'P',
  canceled: 'C',
  waitingList: 'WL',
  reimbursed: 'R',
  reserved: 'RSD'
};
var PaidStatus = exports.PaidStatus = {
  yes: true,
  no: false
};
var GenderTypes = exports.GenderTypes = {
  male: 'M',
  female: 'F'
};
var PricingModel = exports.PricingModel = {
  total: 'total',
  session: 'session',
  hour: 'hour',
  custom: 'custom'
};
var FileTypes = exports.FileTypes = {
  document: 'document',
  image: 'image',
  video: 'video',
  audio: 'audio',
  gif: 'gif'
};
var AgreementTypes = exports.AgreementTypes = {
  sharing: 'sharing',
  email: 'email',
  marketing: 'marketing'
};
var ProviderTypes = exports.ProviderTypes = {
  centers: 'C',
  professionals: 'P'
};
var ProviderMainCategories = exports.ProviderMainCategories = {
  C: 1,
  P: 2
};
var SubscriptionPackages = exports.SubscriptionPackages = {
  grow: 1,
  video: 2,
  allInOne: 3
};
var Languages = exports.Languages = {
  italian: 'Italian',
  english: 'English',
  swedish: 'Swedish'
};
var ResourceTypes = exports.ResourceTypes = {
  collaborator: 'collaborator',
  director: 'director',
  manager: 'manager',
  owner: 'owner'
};
var ResourceTypeId = exports.ResourceTypeId = {
  human: 1,
  physical: 2
};
var supportedVideoFiles = exports.supportedVideoFiles = ['mp4', 'mp3', 'avi', 'mov', 'qt', 'wmv', 'webm', 'mkv', 'ogv', 'ogg', 'wav', 'm4a', '3gp'];
var supportedImages = exports.supportedImages = ['jpg', 'jpeg', 'png', 'webp', 'svg', 'peg', 'gif'];
var PaymentMethods = exports.PaymentMethods = [{
  label: 'Cash',
  value: 'Cash'
}, {
  label: 'CreditCard',
  value: 'CreditCard'
}, {
  label: 'DebitCard',
  value: 'DebitCard'
}, {
  label: 'Membership',
  value: 'Membership'
}, {
  label: 'Wallet',
  value: 'Wallet'
}, {
  label: 'BankTransfer',
  value: 'BankTransfer'
}];
var PaymentChannels = exports.PaymentChannels = [{
  label: 'YouDeal',
  value: 'YouDeal'
}, {
  label: 'YouFeel',
  value: 'YouFeel'
}, {
  label: 'WL',
  value: 'WL'
}, {
  label: 'Widget',
  value: 'Widget'
}, {
  label: 'POS',
  value: 'POS'
}, {
  label: 'SEPA',
  value: 'SEPA'
}];