import { Action } from '../core';
export const PROVIDER_ADD_BREAKS = {
  entity: 'PROVIDER',
  action: Action.CREATE
};
export const PROVIDER_ADD_GROUP = {
  entity: 'PROVIDER_GROUP',
  action: Action.CREATE
};
export const PROVIDER_ADD_SCHEDULE = {
  entity: 'PROVIDER',
  action: Action.CREATE
};
export const PROVIDER_DELETE_BREAKS = {
  entity: 'PROVIDER_DELETE_BREAKS',
  action: Action.DELETE
};
export const PROVIDER_GET_BREAKS = {
  entity: 'PROVIDER_BREAKS',
  action: Action.GET
};
export const PROVIDER_GET_CATEGORIES = {
  entity: 'PROVIDER_CATEGORIES',
  action: Action.GET
};
export const PROVIDER_COURSES_GET_MANY_GROUP = {
  entity: 'PROVIDER_COURSES_GROUP',
  action: Action.GET
};
export const PROVIDER_GET_EVENT_GROUPS = {
  entity: 'PROVIDER_EVENT_GROUPS',
  action: Action.GET
};
export const PROVIDER_GET_GROUPS = {
  entity: 'PROVIDER_GROUPS',
  action: Action.GET
};
export const PROVIDER_GET_ONETIME = {
  entity: 'PROVIDER_ONETIME',
  action: Action.GET
};
export const PROVIDER_GET_ONETIME_GROUP = {
  entity: 'PROVIDER_ONETIME_GROUP',
  action: Action.GET
};
export const PROVIDER_GET_RESOURCES = {
  entity: 'PROVIDER_RESOURCES',
  action: Action.GET
};
export const PROVIDER_GET_SCHEDULE = {
  entity: 'PROVIDER_SCHEDULE',
  action: Action.GET
};
export const PROVIDERS_CATEGORIES_GET = {
  entity: 'PROVIDERS_CATEGORIES',
  action: Action.GET_MANY
};
export const CLASS_ACTIVE_STATUS = {
  entity: 'CLASS_ACTIVE_STATUS',
  action: Action.UPDATE
};
export const CHANGE_ORDER = {
  entity: 'CHANGE_ORDER',
  action: Action.HANDLE
};