import _taggedTemplateLiteral from "/codebuild/output/src683759904/src/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject;
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import propsToCss, { calculateTotalMargin, mapXsToWidth, toPixel } from '../utils/propsToCss';
const BUTTONS_DEFAULT_SCALE = 6;
const handleProps = props => {
  const {
    width,
    xs,
    margin,
    marginLeft,
    marginRight,
    marginTop,
    marginBottom,
    padding,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingBottom,
    color,
    float,
    ...rest
  } = props;
  const cssProps = {
    float,
    margin: toPixel(margin || 1, BUTTONS_DEFAULT_SCALE),
    marginLeft: toPixel(marginLeft, BUTTONS_DEFAULT_SCALE),
    marginRight: toPixel(marginRight, BUTTONS_DEFAULT_SCALE),
    marginTop: toPixel(marginTop, BUTTONS_DEFAULT_SCALE),
    marginBottom: toPixel(marginBottom, BUTTONS_DEFAULT_SCALE)
  };
  padding && (cssProps.padding = toPixel(padding, BUTTONS_DEFAULT_SCALE));
  paddingLeft && (cssProps.paddingLeft = toPixel(paddingLeft, BUTTONS_DEFAULT_SCALE));
  paddingRight && (cssProps.paddingRight = toPixel(paddingRight, BUTTONS_DEFAULT_SCALE));
  paddingTop && (cssProps.paddingTop = toPixel(paddingTop, BUTTONS_DEFAULT_SCALE));
  paddingBottom && (cssProps.paddingBottom = toPixel(paddingBottom, BUTTONS_DEFAULT_SCALE));
  if (width) {
    cssProps.width = width;
  }
  if (xs) {
    const totalMargin = calculateTotalMargin({
      margin: margin || 1,
      marginLeft,
      marginRight
    });
    cssProps.width = "calc(".concat(mapXsToWidth(xs), "% - ").concat(totalMargin, "px)");
  }
  if (!(color !== null && color !== void 0 && color.startsWith('#'))) {
    rest.color = color || 'default';
  } else {
    cssProps.color = color;
  }
  rest.style = cssProps;
  return rest;
};
const basePropTypes = {
  id: PropTypes.string,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  color: PropTypes.string,
  to: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
  variant: PropTypes.string,
  action: PropTypes.string,
  entity: PropTypes.string
};
const StyledButton = styled(Button)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n"])), props => propsToCss(props));
export { handleProps, basePropTypes, StyledButton };