import moment from 'moment';
export const PRINT_DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';
export const PRINT_DATE_TIME_FORMAT_SECONDS = 'DD/MM/YYYY HH:mm:ss';
export const PRINT_DATE_FORMAT = 'DD/MM/YYYY';
export const PRINT_TIME_FORMAT = 'HH:mm';
export const PRINT_WEEKDAY_AND_DATE_FORMAT = 'ddd: MMM D YYYY';
export const INPUT_DATE_FORMAT = 'YYYY-MM-DD';
export const INPUT_TIME_FORMAT = 'HH:mm:ss';
export const INPUT_TIME_FORMAT_HOURS = 'HH';
export const INPUT_TIME_FORMAT_MINUTES = 'mm';
export const INPUT_DATE_TIME_CLIENT_FORMAT = 'YYYY-MM-DD HH:mm';
export const INPUT_DATE_TIME_CLIENT_FORMAT_SECONDS = 'YYYY-MM-DDTHH:mm:ss';
export const isValidDateTimeString = val => {
  if (!val || typeof val !== 'string') {
    return false;
  }
  const reg = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(?:\.\d+)?Z?/gm;
  return reg.test(val);
};
export const printTime = str => {
  if (!str) {
    return str;
  }
  if (typeof str === 'object' && str.format) {
    return str.format(PRINT_TIME_FORMAT);
  }
  const date = parseDate(str);
  return date.format(PRINT_TIME_FORMAT);
};
export const printInputTime = str => {
  if (!str) {
    return str;
  }
  if (typeof str === 'object' && str.format) {
    return str.format(INPUT_TIME_FORMAT);
  }
  if (typeof str === 'number') {
    return new Date(str).toTimeString().split(' ')[0];
  }
  const date = parseDate(str);
  return date.format(INPUT_TIME_FORMAT);
};
export const printDate = str => {
  if (!str) {
    return str;
  }
  if (typeof str === 'object' && str.format) {
    return str.format(PRINT_DATE_FORMAT);
  }
  const date = parseDate(str);
  return date.format(PRINT_DATE_FORMAT);
};
export const printInputDate = str => {
  if (!str) {
    return '';
  }
  if (typeof str === 'object' && str.format) {
    return str.format(INPUT_DATE_FORMAT);
  }
  return moment.utc(str).format(INPUT_DATE_FORMAT);
};
export const printDateTime = function (str) {
  let utc = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  let seconds = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  const format = seconds ? PRINT_DATE_TIME_FORMAT_SECONDS : PRINT_DATE_TIME_FORMAT;
  if (!str) {
    return str;
  }
  if (typeof str === 'object' && str.format) {
    return str.format(format);
  }
  if (utc) {
    return moment.utc(str).format(format);
  } else {
    return moment(str).format(format);
  }
};
export const printInputDateTime = function (str) {
  let utc = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  let T = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  const format = T ? INPUT_DATE_TIME_CLIENT_FORMAT_SECONDS : INPUT_DATE_TIME_CLIENT_FORMAT;
  if (!str) {
    return str;
  }
  if (typeof str === 'object' && str.format && T) {
    return str.format(format);
  }
  if (utc) {
    return moment.utc(str).format(format);
  } else {
    return moment(str).format(format);
  }
};
export const printWeekdayAndDate = str => {
  if (!str) {
    return str;
  }
  if (typeof str === 'object' && str.format) {
    return str.format(PRINT_WEEKDAY_AND_DATE_FORMAT);
  }
  return moment.utc(str).format(PRINT_WEEKDAY_AND_DATE_FORMAT);
};
export const dateIsBefore = function (x, y) {
  let compareOnlyDate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  if (!x) {
    return false;
  }
  const dateX = parseDate(x);
  const dateY = parseDate(y);
  if (compareOnlyDate) {
    return moment.utc(dateX.format(INPUT_DATE_FORMAT)).isBefore(dateY.format(INPUT_DATE_FORMAT));
  }
  return moment.utc(dateX).isBefore(dateY);
};
export const timeIsBefore = function (x, y) {
  let compareOnlyTime = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  if (!x) {
    return false;
  }
  const timeX = parseDate(printTime(x));
  const timeY = parseDate(printTime(y));
  if (compareOnlyTime) {
    return moment.utc(timeX.format(INPUT_DATE_FORMAT)).isBefore(timeY.format(INPUT_DATE_FORMAT));
  }
  return moment.utc(timeX).isBefore(timeY);
};
export const timeIsBeforeOrEqual = function (x, y) {
  let compareOnlyTime = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  if (!x) {
    return false;
  }
  const timeX = parseDate(printTime(x));
  const timeY = parseDate(printTime(y));
  if (compareOnlyTime) {
    return moment.utc(timeX.format(INPUT_DATE_FORMAT)).isSameOrBefore(timeY.format(INPUT_DATE_FORMAT));
  }
  return moment.utc(timeX).isSameOrBefore(timeY);
};
export const dateIsBeforeOrEqual = function (x, y) {
  let compareOnlyDate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  let valuesAreDates = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  if (!x) {
    return false;
  }
  const dateX = parseDate(x);
  const dateY = parseDate(y);
  if (valuesAreDates && compareOnlyDate) {
    return dateX.isSameOrBefore(dateY);
  }
  if (compareOnlyDate) {
    return moment.utc(dateX.format(INPUT_DATE_FORMAT)).isSameOrBefore(dateY.format(INPUT_DATE_FORMAT));
  }
  return moment.utc(dateX).isBefore(dateY);
};
export const dateIsAfter = function (x, y) {
  let compareOnlyDate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  if (!x) {
    return false;
  }
  const dateX = parseDate(x);
  const dateY = parseDate(y);
  if (compareOnlyDate) {
    return dateX.isAfter(dateY);
  }
  return moment.utc(dateX).isAfter(dateY);
};
export const timeIsAfter = (x, y) => {
  if (!x) {
    return false;
  }
  const timeX = parseDate(printTime(x));
  const timeY = parseDate(printTime(y));
  return moment.utc(timeX).isAfter(timeY);
};
export const timeIsAfterOrEqual = (x, y) => {
  if (!x) {
    return false;
  }
  const timeX = parseDate(printTime(x));
  const timeY = parseDate(printTime(y));
  return moment.utc(timeX).isSameOrAfter(timeY);
};
export const dateIsAfterOrEqual = function (x, y) {
  let compareOnlyDate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  let valuesAreDates = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  if (!x) {
    return false;
  }
  const dateX = parseDate(x);
  const dateY = parseDate(y);
  if (valuesAreDates && compareOnlyDate) {
    return dateX.isSameOrAfter(dateY);
  }
  if (compareOnlyDate) {
    return moment.utc(dateX.format(INPUT_DATE_FORMAT)).isSameOrAfter(dateY.format(INPUT_DATE_FORMAT));
  }
  return moment.utc(dateX).isAfter(dateY);
};
export const dateIsBetween = (input, x, y) => {
  const date = parseDate(input);
  const startDate = parseDate(x);
  const endDate = parseDate(y);
  return date.isSame(startDate, 'day') || date.isSame(endDate, 'day') || date.isBetween(startDate, endDate);
};
export const timeIsBetween = (input, x, y) => {
  const time = parseDate(printTime(input));
  const startTime = parseDate(printTime(x));
  const endTime = parseDate(printTime(y));
  return time.isBetween(startTime, endTime);
};
export const isToday = input => {
  if (!input) {
    return false;
  }
  return parseDate(input).isSame(moment.utc(), 'day');
};
export const addMinutes = (val, minutes) => {
  if (!val || !minutes) {
    return val;
  }
  const localDate = parseDate(val);
  if (minutes < 0) {
    return localDate.subtract(Math.abs(minutes), 'minutes');
  } else {
    return localDate.add(minutes, 'minutes');
  }
  // return localDate;
};
export const addHours = (val, hours) => {
  if (!val || !hours) {
    return val;
  }
  const localDate = parseDate(val);
  return localDate.add(hours, 'hours');
};
export const substractHours = (val, hours) => {
  if (!val || !hours) {
    return val;
  }
  const localDate = parseDate(val);
  return localDate.subtract(hours, 'hours');
};
export const addDays = (val, days) => {
  if (!val || !days) {
    return val;
  }
  const localDate = parseDate(val);
  if (days < 0) {
    return localDate.subtract(Math.abs(days), 'days');
  } else {
    return localDate.add(days, 'days');
  }
};
export const addWeeks = (val, weeks) => {
  if (!val || !weeks) {
    return val;
  }
  const localDate = parseDate(val);
  return localDate.add(weeks, 'weeks');
};
export const addMonths = (val, months) => {
  if (!val || !months) {
    return val;
  }
  const localDate = parseDate(val);
  return localDate.add(months, 'months');
};
export const parseDate = function (str) {
  let returnNowIfNull = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  if (!str) {
    if (returnNowIfNull) {
      const browserOffset = new Date().getTimezoneOffset();
      const result = moment.utc();
      result.add(result.utcOffset() - browserOffset, 'minutes');
      return result;
    }
    return str;
  }
  if (typeof str === 'object') {
    if (typeof str.getMonth === 'function') {
      const padValues = val => val.toString().padStart(2, '0');
      const year = padValues(str.getFullYear());
      const month = padValues(1 + str.getMonth());
      const date = padValues(str.getDate());
      const hour = padValues(str.getHours());
      const minute = padValues(str.getMinutes());
      const seconds = padValues(str.getSeconds());
      const dateStr = "".concat(year, "-").concat(month, "-").concat(date, "T").concat(hour, ":").concat(minute, ":").concat(seconds);
      return moment.utc(dateStr, INPUT_DATE_TIME_CLIENT_FORMAT_SECONDS, true);
    }
    return moment.utc(str);
  }
  let result = moment.utc(str, PRINT_TIME_FORMAT, true);
  if (result.isValid()) {
    return result;
  }
  result = moment.utc(str, INPUT_TIME_FORMAT, true);
  if (result.isValid()) {
    return result;
  }
  result = moment.utc(str, INPUT_DATE_FORMAT, true);
  if (result.isValid()) {
    return result;
  }
  result = moment.utc(str, INPUT_DATE_TIME_CLIENT_FORMAT_SECONDS, true);
  if (result.isValid()) {
    return result;
  }
  result = moment.utc(str, INPUT_DATE_TIME_CLIENT_FORMAT, true);
  if (result.isValid()) {
    return result;
  }
  result = moment.utc(str, "".concat(INPUT_DATE_TIME_CLIENT_FORMAT_SECONDS, ".000Z"), true);
  if (result.isValid()) {
    return result;
  }
  result = moment.utc(str, "".concat(INPUT_DATE_TIME_CLIENT_FORMAT_SECONDS, ".000000"), true);
  if (result.isValid()) {
    return result;
  }
  result = moment.utc(str);
  return result;
};
export const momentToDate = str => {
  const date = parseDate(str);
  const isoString = date.toISOString().split('.')[0];
  const result = new Date(isoString);
  return result;
};
export const dateToMoment = date => date && moment.utc({
  year: date.getFullYear(),
  month: date.getMonth(),
  date: date.getDate(),
  hour: date.getHours(),
  minute: date.getMinutes()
});
export const timeDiffInMinutes = (start, end) => {
  const a = parseDate(start);
  const b = parseDate(end);
  return b.diff(a, 'minutes');
};
export const timeDiffInDays = (start, end) => {
  const a = parseDate(start);
  const b = parseDate(end);
  return b.diff(a, 'days');
};
export const timeDiffInWeeks = (start, end) => {
  const a = parseDate(start);
  const b = parseDate(end);
  return b.diff(a, 'weeks');
};
export const getMinDate = (start, end) => {
  if (!start || !end) {
    return start || end;
  }
  if (typeof start === 'object') {
    if (typeof start.getMonth === 'function') {
      return +start > +end ? end : start;
    }
    return start.isBefore(end) ? start : end;
  } else if (typeof start === 'number') {
    return start > end ? end : start;
  }
  const a = parseDate(start);
  const b = parseDate(end);
  return a.isBefore(b) ? a : b;
};
export const getMaxDate = (start, end) => {
  if (!start || !end) {
    return start || end;
  }
  if (typeof start === 'object') {
    if (typeof start.getMonth === 'function') {
      return +start > +end ? start : end;
    }
    return start.isAfter(end) ? start : end;
  } else if (typeof start === 'number') {
    return start > end ? start : end;
  }
  const a = parseDate(start);
  const b = parseDate(end);
  return a.isAfter(b) ? a : b;
};
export const mergeDateTime = (date, time) => {
  return parseDate("".concat(printInputDate(date), "T").concat(printInputTime(time)));
};
export const isSameDay = (a, b) => printInputDate(a) === printInputDate(b);
export const isSameTime = (a, b) => printInputTime(a) === printInputTime(b);
export const getRoundedDate = function () {
  let minutes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 5;
  let d = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  const date = d || new Date(+new Date() + 5 * 60 * 1000);
  const ms = 1000 * 60 * minutes; // convert minutes to ms
  const roundedDate = new Date(Math.round(date.getTime() / ms) * ms);
  return roundedDate;
};
export const getWeekDay = val => {
  const weekDay = moment.utc(val).day();
  return weekDay;
};
export const getWeekDayName = val => {
  const weekDay = moment.utc(val).day();
  if (weekDay === 0) {
    return 'Sunday';
  }
  if (weekDay === 1) {
    return 'Monday';
  }
  if (weekDay === 2) {
    return 'Tuesday';
  }
  if (weekDay === 3) {
    return 'Wednesday';
  }
  if (weekDay === 4) {
    return 'Thursday';
  }
  if (weekDay === 5) {
    return 'Friday';
  }
  if (weekDay === 6) {
    return 'Saturday';
  }
};
export const roundToQuarter = (val, returnNowIfNull) => {
  const date = parseDate(val, returnNowIfNull);
  const minutes = date.minute();
  const hours = date.hour();
  const minute = ((minutes + 7.5) / 15 | 0) * 15 % 60;
  const hour = ((minutes / 105 + 0.5 | 0) + hours) % 24;
  date.set({
    hour,
    minute,
    second: 0
  });
  return date;
};
export const momentToInteger = m => +new Date(m.format('YYYY-MM-DDTHH:mm:ss'));
export const momentGetDayNumber = m => m.day();
export const roundUpDateTime = function (input) {
  let minutes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 5;
  if (!input) {
    return input;
  }
  const date = typeof input === 'number' ? new Date(input) : input;
  const coefficient = 1000 * 60 * minutes;
  return new Date(Math.ceil(date.getTime() / coefficient) * coefficient);
};
export const getDatesArray = (start, end) => {
  const d = [];
  for (const arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt));
    d.push(new Date(dt));
  }
  return d;
};
export const periodToDays = (duration, durationType) => {
  if (durationType === 'weeks') {
    return +duration * 7;
  }
  if (durationType === 'months') {
    return +duration * 30;
  }
  return +duration;
};