import { generateUrlForTranslationFileApiCall } from '@guryou/api-client-gql';
import { execute } from '../actions/actionWrapper';
import { Action, basicReducer } from '../core';
export const TRANSLATION_UPLOAD_URL = {
  entity: 'TRANSLATION_UPLOAD_URL',
  action: Action.GET
};
export const generateUrlForTranslationFile = (project, language) => async dispatch => execute(dispatch, () => generateUrlForTranslationFileApiCall({
  project,
  language
}), TRANSLATION_UPLOAD_URL);
export const translationReducers = {
  generateUploadUrl: basicReducer(TRANSLATION_UPLOAD_URL)
};