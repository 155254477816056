import { ActionStatus, buildActionType, StateStatus } from '../core';
const initialState = {
  data: null,
  errors: null,
  status: StateStatus.NOT_INITIALIZED,
  version: 0,
  report: null
};
const topLevelServices = actionType => function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case buildActionType(actionType, ActionStatus.START):
      return {
        ...initialState,
        status: StateStatus.LOADING,
        version: state.version,
        entity: actionType.entity
      };
    case buildActionType(actionType, ActionStatus.DONE):
      return {
        status: StateStatus.LOADED,
        data: action.payload,
        entity: actionType.entity,
        version: +new Date(),
        report: action.report
      };
    case buildActionType(actionType, ActionStatus.FAILED):
      return {
        status: StateStatus.ERROR,
        errors: action.payload,
        entity: actionType.entity
      };
    case buildActionType(actionType, ActionStatus.RESET):
      return initialState;
    case buildActionType(actionType, ActionStatus.UPDATE):
      return {
        ...state,
        data: [...action.newArr]
      };
    default:
      return state;
  }
};
export { topLevelServices };