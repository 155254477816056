export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_CLOSE = 'MODAL_CLOSE';
export const MODAL_RESET = 'MODAL_RESET';
export const MODAL_DEFAULT_OPENED = 'MODAL_DEFAULT_OPENED';
export const MODAL_DEFAULT_EXTENDED_OPENED = 'MODAL_DEFAULT_EXTENDED_OPENED';
const openModal = (id, options, title) => dispatch => dispatch({
  type: MODAL_OPEN,
  payload: {
    id,
    options,
    title
  }
});
const closeModal = id => dispatch => dispatch({
  type: MODAL_CLOSE,
  payload: {
    id
  }
});
const resetModal = () => dispatch => dispatch({
  type: MODAL_RESET
});
export { openModal, closeModal, resetModal };