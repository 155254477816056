import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCoursesAndEvents, StateStatus } from '@guryou/redux';
const useCoursesAndEvents = () => {
  const dispatch = useDispatch();
  const {
    data,
    status,
    version,
    entity
  } = useSelector(state => state.coursesAndEvents.all);
  useEffect(() => {
    if (status === StateStatus.NOT_INITIALIZED) {
      dispatch(getCoursesAndEvents());
    }
  }, [dispatch, status]);
  return {
    data: data || [],
    status,
    version,
    entity
  };
};
export default useCoursesAndEvents;