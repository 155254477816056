import { Action } from '../core';
export const WIDGET_INTEGRATION = {
  entity: 'WIDGET_INTEGRATION',
  action: Action.GET
};
export const WIDGET_SERVICES = {
  entity: 'WIDGET_SERVICES',
  action: Action.GET_MANY
};
export const WIDGET_SERVICE_GROUPS = {
  entity: 'WIDGET_SERVICE_GROUPS',
  action: Action.GET_MANY
};
export const WIDGET_CLASSES = {
  entity: 'WIDGET_CLASSES',
  action: Action.GET_MANY
};
export const WIDGET_RESOURCES_SET = {
  entity: 'WIDGET_RESOURCES',
  action: Action.SET
};
export const WIDGET_RESOURCES_GETONE = {
  entity: 'WIDGET_RESOURCES',
  action: Action.GET_ONE
};
export const WIDGET_RESERVATION_SUBSERVICE = {
  entity: 'WIDGET_RESERVATION_SUBSERVICE',
  action: Action.GET
};
export const WIDGET_RESERVATION_CREATE = {
  entity: 'WIDGET_RESERVATION',
  action: Action.CREATE
};
export const WIDGET_RESERVATION_SUBSERVICE_OPTIONS = {
  entity: 'WIDGET_RESERVATION_SUBSERVICE',
  action: Action.SET_OPTIONS
};
export const WIDGET_SCHEDULE_RESET = {
  entity: 'WIDGET_SCHEDULE',
  action: Action.RESET
};
export const WIDGET_SCHEDULE = {
  entity: 'WIDGET_SCHEDULE',
  action: Action.GET_MANY
};
export const WIDGET_SCHEDULE_OPTIONS = {
  entity: 'WIDGET_SCHEDULE',
  action: Action.SET_OPTIONS
};
export const WIDPROVIDER_SESSIONS_GET_MANY = {
  entity: 'WIDPROVIDER_SESSIONS_GET_MANY',
  action: Action.GET_MANY
};
export const WIDGET_SELECTED_SESSIONS_INSERT = {
  entity: 'WIDGET_SELECTED_SESSIONS',
  action: Action.INSERT
};
export const WIDGET_SELECTED_SESSIONS_REMOVE = {
  entity: 'WIDGET_SELECTED_SESSIONS',
  action: Action.DELETE
};
export const WIDGET_SELECTED_SESSIONS = {
  entity: 'WIDGET_SELECTED_SESSIONS',
  action: Action.SET,
  key: 'sessions_id'
};
export const WIDGET_SELECTIONS = {
  entity: 'WIDGET_SELECTIONS',
  action: Action.SET_KEYS
};