import { colors } from '@material-ui/core';
import { spacer } from '../constants';
const formOverrides = formControlSize => ({
  MuiFormControl: {
    root: {
      width: '100%',
      display: 'flex',
      margin: 0,
      maxWidth: formControlSize,
      whiteSpace: 'nowrap',
      '& label.Mui-focused': {
        color: colors.mandy,
        fontWeight: 500
      },
      '&.paymentForm': {
        maxWidth: '100%'
      },
      '&.serieAutocomplete': {
        maxWidth: '100%'
      },
      '& .myWorkoutCheckBox': {
        padding: '5px 5px'
      }
    },
    fullWidth: {
      maxWidth: 'unset'
    },
    marginNormal: {
      width: "calc(100% - ".concat(2 * spacer.spacer3, "px)"),
      margin: spacer.spacer3,
      marginBottom: spacer.spacer2
    }
  },
  MuiInput: {
    underline: {
      marginTop: 0,
      '&:after': {
        borderBottomColor: colors.mineShaft
      }
    },
    input: {
      '&[type="color"]': {
        width: '40%',
        marginLeft: 'auto',
        padding: 0,
        height: spacer.spacer4
      }
    }
  },
  MuiOutlinedInput: {
    root: {
      '&.Mui-focused fieldset': {
        borderColor: colors.mineShaft
      }
    },
    input: {
      padding: '12px 8px'
    }
  },
  MuiInputLabel: {
    shrink: {
      '&+.MuiInput-formControl input[type="color"]': {
        width: '100%'
      }
    },
    outlined: {
      top: '-6px'
    }
  },
  MuiInputAdornment: {
    root: {
      maxHeight: '100%',
      '& .MuiIconButton-root': {
        padding: 0,
        position: 'absolute',
        right: 0
      }
    }
  },
  MuiRadio: {
    colorPrimary: {
      '&.Mui-checked': {
        color: colors.mineShaft
      }
    }
  },
  MuiCheckbox: {
    colorPrimary: {
      '&.Mui-checked': {
        color: colors.amber
      }
    }
  },
  MuiFormControlLabel: {
    root: {
      '&.profileAgreements': {
        whiteSpace: 'pre-wrap'
      }
    }
  }
});
export { formOverrides };