"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.timeIsBetween = exports.timeIsBeforeOrEqual = exports.timeIsBefore = exports.timeIsAfterOrEqual = exports.timeIsAfter = exports.timeDiffInWeeks = exports.timeDiffInMinutes = exports.timeDiffInDays = exports.substractHours = exports.roundUpDateTime = exports.roundToQuarter = exports.printWeekdayAndDate = exports.printTime = exports.printInputTime = exports.printInputDateTime = exports.printInputDate = exports.printDateTime = exports.printDate = exports.periodToDays = exports.parseDate = exports.momentToInteger = exports.momentToDate = exports.momentGetDayNumber = exports.mergeDateTime = exports.isValidDateTimeString = exports.isToday = exports.isSameTime = exports.isSameDay = exports.getWeekDayName = exports.getWeekDay = exports.getRoundedDate = exports.getMinDate = exports.getMaxDate = exports.getDatesArray = exports.dateToMoment = exports.dateIsBetween = exports.dateIsBeforeOrEqual = exports.dateIsBefore = exports.dateIsAfterOrEqual = exports.dateIsAfter = exports.addWeeks = exports.addMonths = exports.addMinutes = exports.addHours = exports.addDays = exports.PRINT_WEEKDAY_AND_DATE_FORMAT = exports.PRINT_TIME_FORMAT = exports.PRINT_DATE_TIME_FORMAT_SECONDS = exports.PRINT_DATE_TIME_FORMAT = exports.PRINT_DATE_FORMAT = exports.INPUT_TIME_FORMAT_MINUTES = exports.INPUT_TIME_FORMAT_HOURS = exports.INPUT_TIME_FORMAT = exports.INPUT_DATE_TIME_CLIENT_FORMAT_SECONDS = exports.INPUT_DATE_TIME_CLIENT_FORMAT = exports.INPUT_DATE_FORMAT = void 0;
var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));
var _moment = _interopRequireDefault(require("moment"));
var PRINT_DATE_TIME_FORMAT = exports.PRINT_DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';
var PRINT_DATE_TIME_FORMAT_SECONDS = exports.PRINT_DATE_TIME_FORMAT_SECONDS = 'DD/MM/YYYY HH:mm:ss';
var PRINT_DATE_FORMAT = exports.PRINT_DATE_FORMAT = 'DD/MM/YYYY';
var PRINT_TIME_FORMAT = exports.PRINT_TIME_FORMAT = 'HH:mm';
var PRINT_WEEKDAY_AND_DATE_FORMAT = exports.PRINT_WEEKDAY_AND_DATE_FORMAT = 'ddd: MMM D YYYY';
var INPUT_DATE_FORMAT = exports.INPUT_DATE_FORMAT = 'YYYY-MM-DD';
var INPUT_TIME_FORMAT = exports.INPUT_TIME_FORMAT = 'HH:mm:ss';
var INPUT_TIME_FORMAT_HOURS = exports.INPUT_TIME_FORMAT_HOURS = 'HH';
var INPUT_TIME_FORMAT_MINUTES = exports.INPUT_TIME_FORMAT_MINUTES = 'mm';
var INPUT_DATE_TIME_CLIENT_FORMAT = exports.INPUT_DATE_TIME_CLIENT_FORMAT = 'YYYY-MM-DD HH:mm';
var INPUT_DATE_TIME_CLIENT_FORMAT_SECONDS = exports.INPUT_DATE_TIME_CLIENT_FORMAT_SECONDS = 'YYYY-MM-DDTHH:mm:ss';
var isValidDateTimeString = exports.isValidDateTimeString = function isValidDateTimeString(val) {
  if (!val || typeof val !== 'string') {
    return false;
  }
  var reg = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(?:\.\d+)?Z?/gm;
  return reg.test(val);
};
var printTime = exports.printTime = function printTime(str) {
  if (!str) {
    return str;
  }
  if ((0, _typeof2["default"])(str) === 'object' && str.format) {
    return str.format(PRINT_TIME_FORMAT);
  }
  var date = parseDate(str);
  return date.format(PRINT_TIME_FORMAT);
};
var printInputTime = exports.printInputTime = function printInputTime(str) {
  if (!str) {
    return str;
  }
  if ((0, _typeof2["default"])(str) === 'object' && str.format) {
    return str.format(INPUT_TIME_FORMAT);
  }
  if (typeof str === 'number') {
    return new Date(str).toTimeString().split(' ')[0];
  }
  var date = parseDate(str);
  return date.format(INPUT_TIME_FORMAT);
};
var printDate = exports.printDate = function printDate(str) {
  if (!str) {
    return str;
  }
  if ((0, _typeof2["default"])(str) === 'object' && str.format) {
    return str.format(PRINT_DATE_FORMAT);
  }
  var date = parseDate(str);
  return date.format(PRINT_DATE_FORMAT);
};
var printInputDate = exports.printInputDate = function printInputDate(str) {
  if (!str) {
    return '';
  }
  if ((0, _typeof2["default"])(str) === 'object' && str.format) {
    return str.format(INPUT_DATE_FORMAT);
  }
  return _moment["default"].utc(str).format(INPUT_DATE_FORMAT);
};
var printDateTime = exports.printDateTime = function printDateTime(str) {
  var utc = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var seconds = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var format = seconds ? PRINT_DATE_TIME_FORMAT_SECONDS : PRINT_DATE_TIME_FORMAT;
  if (!str) {
    return str;
  }
  if ((0, _typeof2["default"])(str) === 'object' && str.format) {
    return str.format(format);
  }
  if (utc) {
    return _moment["default"].utc(str).format(format);
  } else {
    return (0, _moment["default"])(str).format(format);
  }
};
var printInputDateTime = exports.printInputDateTime = function printInputDateTime(str) {
  var utc = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var T = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var format = T ? INPUT_DATE_TIME_CLIENT_FORMAT_SECONDS : INPUT_DATE_TIME_CLIENT_FORMAT;
  if (!str) {
    return str;
  }
  if ((0, _typeof2["default"])(str) === 'object' && str.format && T) {
    return str.format(format);
  }
  if (utc) {
    return _moment["default"].utc(str).format(format);
  } else {
    return (0, _moment["default"])(str).format(format);
  }
};
var printWeekdayAndDate = exports.printWeekdayAndDate = function printWeekdayAndDate(str) {
  if (!str) {
    return str;
  }
  if ((0, _typeof2["default"])(str) === 'object' && str.format) {
    return str.format(PRINT_WEEKDAY_AND_DATE_FORMAT);
  }
  return _moment["default"].utc(str).format(PRINT_WEEKDAY_AND_DATE_FORMAT);
};
var dateIsBefore = exports.dateIsBefore = function dateIsBefore(x, y) {
  var compareOnlyDate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  if (!x) {
    return false;
  }
  var dateX = parseDate(x);
  var dateY = parseDate(y);
  if (compareOnlyDate) {
    return _moment["default"].utc(dateX.format(INPUT_DATE_FORMAT)).isBefore(dateY.format(INPUT_DATE_FORMAT));
  }
  return _moment["default"].utc(dateX).isBefore(dateY);
};
var timeIsBefore = exports.timeIsBefore = function timeIsBefore(x, y) {
  var compareOnlyTime = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  if (!x) {
    return false;
  }
  var timeX = parseDate(printTime(x));
  var timeY = parseDate(printTime(y));
  if (compareOnlyTime) {
    return _moment["default"].utc(timeX.format(INPUT_DATE_FORMAT)).isBefore(timeY.format(INPUT_DATE_FORMAT));
  }
  return _moment["default"].utc(timeX).isBefore(timeY);
};
var timeIsBeforeOrEqual = exports.timeIsBeforeOrEqual = function timeIsBeforeOrEqual(x, y) {
  var compareOnlyTime = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  if (!x) {
    return false;
  }
  var timeX = parseDate(printTime(x));
  var timeY = parseDate(printTime(y));
  if (compareOnlyTime) {
    return _moment["default"].utc(timeX.format(INPUT_DATE_FORMAT)).isSameOrBefore(timeY.format(INPUT_DATE_FORMAT));
  }
  return _moment["default"].utc(timeX).isSameOrBefore(timeY);
};
var dateIsBeforeOrEqual = exports.dateIsBeforeOrEqual = function dateIsBeforeOrEqual(x, y) {
  var compareOnlyDate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var valuesAreDates = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  if (!x) {
    return false;
  }
  var dateX = parseDate(x);
  var dateY = parseDate(y);
  if (valuesAreDates && compareOnlyDate) {
    return dateX.isSameOrBefore(dateY);
  }
  if (compareOnlyDate) {
    return _moment["default"].utc(dateX.format(INPUT_DATE_FORMAT)).isSameOrBefore(dateY.format(INPUT_DATE_FORMAT));
  }
  return _moment["default"].utc(dateX).isBefore(dateY);
};
var dateIsAfter = exports.dateIsAfter = function dateIsAfter(x, y) {
  var compareOnlyDate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  if (!x) {
    return false;
  }
  var dateX = parseDate(x);
  var dateY = parseDate(y);
  if (compareOnlyDate) {
    return dateX.isAfter(dateY);
  }
  return _moment["default"].utc(dateX).isAfter(dateY);
};
var timeIsAfter = exports.timeIsAfter = function timeIsAfter(x, y) {
  if (!x) {
    return false;
  }
  var timeX = parseDate(printTime(x));
  var timeY = parseDate(printTime(y));
  return _moment["default"].utc(timeX).isAfter(timeY);
};
var timeIsAfterOrEqual = exports.timeIsAfterOrEqual = function timeIsAfterOrEqual(x, y) {
  if (!x) {
    return false;
  }
  var timeX = parseDate(printTime(x));
  var timeY = parseDate(printTime(y));
  return _moment["default"].utc(timeX).isSameOrAfter(timeY);
};
var dateIsAfterOrEqual = exports.dateIsAfterOrEqual = function dateIsAfterOrEqual(x, y) {
  var compareOnlyDate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var valuesAreDates = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  if (!x) {
    return false;
  }
  var dateX = parseDate(x);
  var dateY = parseDate(y);
  if (valuesAreDates && compareOnlyDate) {
    return dateX.isSameOrAfter(dateY);
  }
  if (compareOnlyDate) {
    return _moment["default"].utc(dateX.format(INPUT_DATE_FORMAT)).isSameOrAfter(dateY.format(INPUT_DATE_FORMAT));
  }
  return _moment["default"].utc(dateX).isAfter(dateY);
};
var dateIsBetween = exports.dateIsBetween = function dateIsBetween(input, x, y) {
  var date = parseDate(input);
  var startDate = parseDate(x);
  var endDate = parseDate(y);
  return date.isSame(startDate, 'day') || date.isSame(endDate, 'day') || date.isBetween(startDate, endDate);
};
var timeIsBetween = exports.timeIsBetween = function timeIsBetween(input, x, y) {
  var time = parseDate(printTime(input));
  var startTime = parseDate(printTime(x));
  var endTime = parseDate(printTime(y));
  return time.isBetween(startTime, endTime);
};
var isToday = exports.isToday = function isToday(input) {
  if (!input) {
    return false;
  }
  return parseDate(input).isSame(_moment["default"].utc(), 'day');
};
var addMinutes = exports.addMinutes = function addMinutes(val, minutes) {
  if (!val || !minutes) {
    return val;
  }
  var localDate = parseDate(val);
  if (minutes < 0) {
    return localDate.subtract(Math.abs(minutes), 'minutes');
  } else {
    return localDate.add(minutes, 'minutes');
  }
  // return localDate;
};
var addHours = exports.addHours = function addHours(val, hours) {
  if (!val || !hours) {
    return val;
  }
  var localDate = parseDate(val);
  return localDate.add(hours, 'hours');
};
var substractHours = exports.substractHours = function substractHours(val, hours) {
  if (!val || !hours) {
    return val;
  }
  var localDate = parseDate(val);
  return localDate.subtract(hours, 'hours');
};
var addDays = exports.addDays = function addDays(val, days) {
  if (!val || !days) {
    return val;
  }
  var localDate = parseDate(val);
  if (days < 0) {
    return localDate.subtract(Math.abs(days), 'days');
  } else {
    return localDate.add(days, 'days');
  }
};
var addWeeks = exports.addWeeks = function addWeeks(val, weeks) {
  if (!val || !weeks) {
    return val;
  }
  var localDate = parseDate(val);
  return localDate.add(weeks, 'weeks');
};
var addMonths = exports.addMonths = function addMonths(val, months) {
  if (!val || !months) {
    return val;
  }
  var localDate = parseDate(val);
  return localDate.add(months, 'months');
};
var parseDate = exports.parseDate = function parseDate(str) {
  var returnNowIfNull = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  if (!str) {
    if (returnNowIfNull) {
      var browserOffset = new Date().getTimezoneOffset();
      var _result = _moment["default"].utc();
      _result.add(_result.utcOffset() - browserOffset, 'minutes');
      return _result;
    }
    return str;
  }
  if ((0, _typeof2["default"])(str) === 'object') {
    if (typeof str.getMonth === 'function') {
      var padValues = function padValues(val) {
        return val.toString().padStart(2, '0');
      };
      var year = padValues(str.getFullYear());
      var month = padValues(1 + str.getMonth());
      var date = padValues(str.getDate());
      var hour = padValues(str.getHours());
      var minute = padValues(str.getMinutes());
      var seconds = padValues(str.getSeconds());
      var dateStr = "".concat(year, "-").concat(month, "-").concat(date, "T").concat(hour, ":").concat(minute, ":").concat(seconds);
      return _moment["default"].utc(dateStr, INPUT_DATE_TIME_CLIENT_FORMAT_SECONDS, true);
    }
    return _moment["default"].utc(str);
  }
  var result = _moment["default"].utc(str, PRINT_TIME_FORMAT, true);
  if (result.isValid()) {
    return result;
  }
  result = _moment["default"].utc(str, INPUT_TIME_FORMAT, true);
  if (result.isValid()) {
    return result;
  }
  result = _moment["default"].utc(str, INPUT_DATE_FORMAT, true);
  if (result.isValid()) {
    return result;
  }
  result = _moment["default"].utc(str, INPUT_DATE_TIME_CLIENT_FORMAT_SECONDS, true);
  if (result.isValid()) {
    return result;
  }
  result = _moment["default"].utc(str, INPUT_DATE_TIME_CLIENT_FORMAT, true);
  if (result.isValid()) {
    return result;
  }
  result = _moment["default"].utc(str, "".concat(INPUT_DATE_TIME_CLIENT_FORMAT_SECONDS, ".000Z"), true);
  if (result.isValid()) {
    return result;
  }
  result = _moment["default"].utc(str, "".concat(INPUT_DATE_TIME_CLIENT_FORMAT_SECONDS, ".000000"), true);
  if (result.isValid()) {
    return result;
  }
  result = _moment["default"].utc(str);
  return result;
};
var momentToDate = exports.momentToDate = function momentToDate(str) {
  var date = parseDate(str);
  var isoString = date.toISOString().split('.')[0];
  var result = new Date(isoString);
  return result;
};
var dateToMoment = exports.dateToMoment = function dateToMoment(date) {
  return date && _moment["default"].utc({
    year: date.getFullYear(),
    month: date.getMonth(),
    date: date.getDate(),
    hour: date.getHours(),
    minute: date.getMinutes()
  });
};
var timeDiffInMinutes = exports.timeDiffInMinutes = function timeDiffInMinutes(start, end) {
  var a = parseDate(start);
  var b = parseDate(end);
  return b.diff(a, 'minutes');
};
var timeDiffInDays = exports.timeDiffInDays = function timeDiffInDays(start, end) {
  var a = parseDate(start);
  var b = parseDate(end);
  return b.diff(a, 'days');
};
var timeDiffInWeeks = exports.timeDiffInWeeks = function timeDiffInWeeks(start, end) {
  var a = parseDate(start);
  var b = parseDate(end);
  return b.diff(a, 'weeks');
};
var getMinDate = exports.getMinDate = function getMinDate(start, end) {
  if (!start || !end) {
    return start || end;
  }
  if ((0, _typeof2["default"])(start) === 'object') {
    if (typeof start.getMonth === 'function') {
      return +start > +end ? end : start;
    }
    return start.isBefore(end) ? start : end;
  } else if (typeof start === 'number') {
    return start > end ? end : start;
  }
  var a = parseDate(start);
  var b = parseDate(end);
  return a.isBefore(b) ? a : b;
};
var getMaxDate = exports.getMaxDate = function getMaxDate(start, end) {
  if (!start || !end) {
    return start || end;
  }
  if ((0, _typeof2["default"])(start) === 'object') {
    if (typeof start.getMonth === 'function') {
      return +start > +end ? start : end;
    }
    return start.isAfter(end) ? start : end;
  } else if (typeof start === 'number') {
    return start > end ? start : end;
  }
  var a = parseDate(start);
  var b = parseDate(end);
  return a.isAfter(b) ? a : b;
};
var mergeDateTime = exports.mergeDateTime = function mergeDateTime(date, time) {
  return parseDate("".concat(printInputDate(date), "T").concat(printInputTime(time)));
};
var isSameDay = exports.isSameDay = function isSameDay(a, b) {
  return printInputDate(a) === printInputDate(b);
};
var isSameTime = exports.isSameTime = function isSameTime(a, b) {
  return printInputTime(a) === printInputTime(b);
};
var getRoundedDate = exports.getRoundedDate = function getRoundedDate() {
  var minutes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 5;
  var d = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var date = d || new Date(+new Date() + 5 * 60 * 1000);
  var ms = 1000 * 60 * minutes; // convert minutes to ms
  var roundedDate = new Date(Math.round(date.getTime() / ms) * ms);
  return roundedDate;
};
var getWeekDay = exports.getWeekDay = function getWeekDay(val) {
  var weekDay = _moment["default"].utc(val).day();
  return weekDay;
};
var getWeekDayName = exports.getWeekDayName = function getWeekDayName(val) {
  var weekDay = _moment["default"].utc(val).day();
  if (weekDay === 0) {
    return 'Sunday';
  }
  if (weekDay === 1) {
    return 'Monday';
  }
  if (weekDay === 2) {
    return 'Tuesday';
  }
  if (weekDay === 3) {
    return 'Wednesday';
  }
  if (weekDay === 4) {
    return 'Thursday';
  }
  if (weekDay === 5) {
    return 'Friday';
  }
  if (weekDay === 6) {
    return 'Saturday';
  }
};
var roundToQuarter = exports.roundToQuarter = function roundToQuarter(val, returnNowIfNull) {
  var date = parseDate(val, returnNowIfNull);
  var minutes = date.minute();
  var hours = date.hour();
  var minute = ((minutes + 7.5) / 15 | 0) * 15 % 60;
  var hour = ((minutes / 105 + 0.5 | 0) + hours) % 24;
  date.set({
    hour: hour,
    minute: minute,
    second: 0
  });
  return date;
};
var momentToInteger = exports.momentToInteger = function momentToInteger(m) {
  return +new Date(m.format('YYYY-MM-DDTHH:mm:ss'));
};
var momentGetDayNumber = exports.momentGetDayNumber = function momentGetDayNumber(m) {
  return m.day();
};
var roundUpDateTime = exports.roundUpDateTime = function roundUpDateTime(input) {
  var minutes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 5;
  if (!input) {
    return input;
  }
  var date = typeof input === 'number' ? new Date(input) : input;
  var coefficient = 1000 * 60 * minutes;
  return new Date(Math.ceil(date.getTime() / coefficient) * coefficient);
};
var getDatesArray = exports.getDatesArray = function getDatesArray(start, end) {
  var d = [];
  for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt));
    d.push(new Date(dt));
  }
  return d;
};
var periodToDays = exports.periodToDays = function periodToDays(duration, durationType) {
  if (durationType === 'weeks') {
    return +duration * 7;
  }
  if (durationType === 'months') {
    return +duration * 30;
  }
  return +duration;
};