import { cancelSessionApiCall, cancelTotalSessionApiCall, changeUserSessionsNumberApiCall, deleteInactiveSessionsApiCall, deleteSessionApiCall, getPublicSessionsApiCall, getSessionsByClassIdApiCall, saveSessionApiCall, updateSessionFromEventApiCall } from '@guryou/api-client-gql';
import { LocationType } from '@guryou/core';
import { isValidEmail } from '@guryou/utils/build/stringUtils';
import { resetState } from '../actions';
import { execute } from '../actions/actionWrapper';
import { Action, basicReducer } from '../core';

// Types
export const SESSIONS_GET_MANY = {
  entity: 'SESSION',
  action: Action.GET_MANY
};
export const CREATE_SESSION = {
  entity: 'SESSION',
  action: Action.CREATE
};
export const PROVIDER_SESSIONS_GET_MANY = {
  entity: 'PROVIDER_SESSIONS',
  action: Action.GET_MANY
};
export const SESSION_UPDATE = {
  entity: 'SESSION',
  action: Action.UPDATE
};
export const DELETE_SESSION = {
  entity: 'SESSION',
  action: Action.DELETE
};
export const CANCEL_SESSION = {
  entity: 'CANCEL_SESSION',
  action: Action.UPDATE
};
export const CANCEL_TOTAL_SESSION = {
  entity: 'CANCEL_TOTAL_SESSION',
  action: Action.UPDATE
};
export const USER_MEMBERSHIP_SESSIONS_NUMBER_UPDATE = {
  entity: 'USER_MEMBERSHIP_SESSIONS_NUMBER',
  action: Action.UPDATE
};
export const SESSION_FROM_EVENT = {
  entity: 'SESSION_FROM_EVENT',
  action: Action.UPDATE
};

// Actions
export const saveSession = function (data) {
  let isDeal = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return async dispatch => {
    const {
      id,
      classId,
      price,
      repeatInterval,
      duration,
      resourceId,
      latitude,
      longitude,
      locationType,
      locationNote,
      address,
      endDate,
      startDate,
      endTime,
      startTime,
      date,
      materialResource,
      workingDays,
      ...rest
    } = data;
    const request = {
      id: +id || null,
      classId: +classId,
      resourceId: +resourceId,
      price: +price,
      duration: +duration,
      repeatInterval: +repeatInterval || null,
      startTime,
      endTime,
      ...rest
    };
    if (!id) {
      request.materialResource = materialResource ? +materialResource : null;
    }
    if (workingDays) {
      request.workingDays = workingDays.filter(day => day !== '').map(d => +d);
    }
    if (startDate) {
      request.startTime = "".concat(startDate, "T").concat(startTime, ":00.000Z");
    }
    if (endDate) {
      request.endTime = "".concat(endDate, "T").concat(endTime, ":00.000Z");
    }
    if (date) {
      request.startTime = "".concat(date, "T").concat(startTime, ":00.000Z");
      request.endTime = "".concat(date, "T").concat(endTime, ":00.000Z");
    }
    if (+locationType === LocationType.mapLocation) {
      request.latitude = +latitude;
      request.longitude = +longitude;
      request.address = address;
    } else if (+locationType === LocationType.plainNote) {
      request.locationNote = locationNote;
    }
    execute(dispatch, () => saveSessionApiCall({
      request,
      isDeal
    }), id ? SESSION_UPDATE : CREATE_SESSION);
  };
};
export const deleteSession = id => async dispatch => execute(dispatch, () => deleteSessionApiCall({
  id
}), DELETE_SESSION, true);
export const cancelSession = id => async dispatch => execute(dispatch, () => cancelSessionApiCall({
  id
}), CANCEL_SESSION, true);
export const cancelTotalSession = (sessionId, classId) => async dispatch => execute(dispatch, () => cancelTotalSessionApiCall({
  sessionId,
  classId
}), CANCEL_TOTAL_SESSION, true);
export const deleteInactiveSessions = classId => async dispatch => execute(dispatch, () => deleteInactiveSessionsApiCall({
  classId
}), DELETE_SESSION, true);
export const getPublicSessions = classId => async dispatch => execute(dispatch, () => getPublicSessionsApiCall({
  classId: +classId
}), SESSIONS_GET_MANY, false, {
  classId: +classId
});
export const getSessionsByClassId = function (classId) {
  let silent = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return async dispatch => execute(dispatch, () => getSessionsByClassIdApiCall({
    classId: +classId
  }, {
    resources: ['id', 'name'],
    location: ['note', 'latitude', 'longitude', 'address', 'addressUrl', 'url'],
    materialResource: ['id', 'name']
  }), PROVIDER_SESSIONS_GET_MANY, false, {
    classId: +classId
  }, silent);
};
export const changeUserSessionNumber = (id, numberOfSessions, startDate, endDate, action) => async dispatch => {
  const startTime = "".concat(startDate, "T00:00:00.000Z");
  const endTime = "".concat(endDate, "T23:59:59.000Z");
  execute(dispatch, () => changeUserSessionsNumberApiCall({
    id,
    numberOfSessions,
    startTime,
    endTime,
    action
  }), USER_MEMBERSHIP_SESSIONS_NUMBER_UPDATE);
};
export const updateSessionFromEvent = _ref => {
  let {
    id,
    startDate,
    startTime,
    endTime,
    subscriptions,
    resourceId = null
  } = _ref;
  return async dispatch => {
    const sTime = "".concat(startDate, "T").concat(startTime, ":00.000Z");
    const eTime = "".concat(startDate, "T").concat(endTime, ":00.000Z");
    const sDate = "".concat(startDate, "T").concat(startTime, ":00.000Z");
    const subscriptionsEmails = subscriptions.length ? subscriptions.map(x => {
      const isValid = isValidEmail(x.email);
      return x.user.email || isValid && x.email;
    }).filter(y => y) : null;
    execute(dispatch, () => updateSessionFromEventApiCall({
      id,
      startDate: sDate,
      startTime: sTime,
      endTime: eTime,
      subscriptionsEmails,
      resourceId
    }), SESSION_FROM_EVENT);
  };
};
export const updateSessionFromEventMobile = (id, startTime, endTime, subscriptions) => async dispatch => {
  const subscriptionsEmails = subscriptions !== null && subscriptions !== void 0 && subscriptions.length ? subscriptions.map(x => x.user.email) : null;
  execute(dispatch, () => updateSessionFromEventApiCall({
    id,
    startDate: startTime,
    startTime,
    endTime,
    subscriptionsEmails
  }), SESSION_FROM_EVENT);
};

// Reset actions
export const resetSessionState = () => async dispatch => {
  dispatch(resetState(PROVIDER_SESSIONS_GET_MANY));
  dispatch(resetState(SESSIONS_GET_MANY));
  dispatch(resetState(CREATE_SESSION));
  dispatch(resetState(SESSION_UPDATE));
  dispatch(resetState(DELETE_SESSION));
};
export const resetSessionFromEventState = () => async dispatch => {
  dispatch(resetState(SESSION_FROM_EVENT));
};

// Reducers
export const sessionReducers = {
  all: basicReducer(PROVIDER_SESSIONS_GET_MANY),
  active: basicReducer(SESSIONS_GET_MANY),
  create: basicReducer(CREATE_SESSION),
  update: basicReducer(SESSION_UPDATE),
  delete: basicReducer(DELETE_SESSION),
  cancel: basicReducer(CANCEL_SESSION),
  cancelTotal: basicReducer(CANCEL_TOTAL_SESSION),
  userMembershipSessions: basicReducer(USER_MEMBERSHIP_SESSIONS_NUMBER_UPDATE),
  updateFromEvent: basicReducer(SESSION_FROM_EVENT)
};