import { getAllCategoriesApiCall, getProductCategoriesApiCall, getProviderCategoriesApiCall, getServiceCategoriesApiCall } from '@guryou/api-client-gql';
import { resetState } from '../actions';
import { execute } from '../actions/actionWrapper';
import { Action, basicReducer } from '../core';
export const SERVICE_CATEGORIES_GET_MANY = {
  entity: 'SERVICE_CATEGORIES',
  action: Action.GET_MANY
};
export const PRODUCT_CATEGORIES_GET_MANY = {
  entity: 'PRODUCT_CATEGORIES',
  action: Action.GET_MANY
};
export const PROVIDER_CATEGORIES_GET_MANY = {
  entity: 'PROVIDER_CATEGORIES',
  action: Action.GET_MANY
};
export const PROVIDER_ALL_CATEGORIES_GET_MANY = {
  entity: 'PROVIDER_ALL_CATEGORIES',
  action: Action.GET_MANY
};
export const getServiceCategories = (type, providerId) => async dispatch => execute(dispatch, () => getServiceCategoriesApiCall({
  type,
  providerId
}), SERVICE_CATEGORIES_GET_MANY, false, {
  type,
  providerId
});
export const getProductCategories = providerId => async dispatch => execute(dispatch, () => getProductCategoriesApiCall({
  providerId
}), PRODUCT_CATEGORIES_GET_MANY, false, {
  providerId
});
export const getProviderCategories = _ref => {
  let {
    providerId,
    providerType
  } = _ref;
  return async dispatch => execute(dispatch, () => getProviderCategoriesApiCall({
    providerId: +providerId,
    providerType
  }), PROVIDER_CATEGORIES_GET_MANY, false, {
    providerId
  });
};
export const getAllProviderCategories = () => async dispatch => execute(dispatch, () => getAllCategoriesApiCall(), PROVIDER_ALL_CATEGORIES_GET_MANY, false);
export const resetServiceCategoriesState = () => async dispatch => dispatch(resetState(SERVICE_CATEGORIES_GET_MANY));
export const resetProductCategoriesState = () => async dispatch => dispatch(resetState(PRODUCT_CATEGORIES_GET_MANY));
export const categoryReducers = {
  services: basicReducer(SERVICE_CATEGORIES_GET_MANY),
  products: basicReducer(PRODUCT_CATEGORIES_GET_MANY),
  providers: basicReducer(PROVIDER_CATEGORIES_GET_MANY),
  providersAllCategories: basicReducer(PROVIDER_ALL_CATEGORIES_GET_MANY)
};