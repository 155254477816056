"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  ALL_CONSTANT: true,
  DEFAULT_APP_NAME: true,
  DEFAULT_DEAL_APP_NAME: true,
  DEFAULT_APP_NAME_TRAINING: true,
  DEFAULT_TIME_SLOT_STEP: true,
  PROFESIONAL_FREE_30_DAYS_PLAN: true,
  CENTER_FREE_30_DAYS_PLAN: true
};
exports.PROFESIONAL_FREE_30_DAYS_PLAN = exports.DEFAULT_TIME_SLOT_STEP = exports.DEFAULT_DEAL_APP_NAME = exports.DEFAULT_APP_NAME_TRAINING = exports.DEFAULT_APP_NAME = exports.CENTER_FREE_30_DAYS_PLAN = exports.ALL_CONSTANT = void 0;
var _apiNames = require("./apiNames");
Object.keys(_apiNames).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _apiNames[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _apiNames[key];
    }
  });
});
var _entity = require("./entity");
Object.keys(_entity).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _entity[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _entity[key];
    }
  });
});
var _errorCodes = require("./errorCodes");
Object.keys(_errorCodes).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _errorCodes[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _errorCodes[key];
    }
  });
});
var _payments = require("./payments");
Object.keys(_payments).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _payments[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _payments[key];
    }
  });
});
var _user = require("./user");
Object.keys(_user).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _user[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _user[key];
    }
  });
});
var _intervals = require("./intervals");
Object.keys(_intervals).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _intervals[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _intervals[key];
    }
  });
});
var _locations = require("./locations");
Object.keys(_locations).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _locations[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _locations[key];
    }
  });
});
var _appOs = require("./appOs");
Object.keys(_appOs).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _appOs[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _appOs[key];
    }
  });
});
var _s3Actions = require("./s3Actions");
Object.keys(_s3Actions).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _s3Actions[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _s3Actions[key];
    }
  });
});
var _workouts = require("./workouts");
Object.keys(_workouts).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _workouts[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _workouts[key];
    }
  });
});
var ALL_CONSTANT = exports.ALL_CONSTANT = 'all';
var DEFAULT_APP_NAME = exports.DEFAULT_APP_NAME = 'guryou';
var DEFAULT_DEAL_APP_NAME = exports.DEFAULT_DEAL_APP_NAME = 'guryou-deal';
var DEFAULT_APP_NAME_TRAINING = exports.DEFAULT_APP_NAME_TRAINING = 'guryou-training';
var DEFAULT_TIME_SLOT_STEP = exports.DEFAULT_TIME_SLOT_STEP = 5;
var PROFESIONAL_FREE_30_DAYS_PLAN = exports.PROFESIONAL_FREE_30_DAYS_PLAN = {
  id: 2,
  name: 'Professional Plus + Video',
  title: 'Plus+Video Prof',
  active: true,
  amount: 59,
  offers: ['Gestione catalogo video corsi', 'Video streaming platform (web/Mobile)', '1 Free Video class', '3% commissione'],
  interval: 'month',
  originalId: 'plan_GlNN4RfpSJAfY7',
  description: 'Tutte le funzionalitá GROW',
  providerType: 'P'
};
var CENTER_FREE_30_DAYS_PLAN = exports.CENTER_FREE_30_DAYS_PLAN = {
  id: 2,
  name: 'Centro Plus + Video',
  title: 'Gestionale Plus+Video',
  active: true,
  amount: 99,
  offers: ['Gestione catalogo video corsi', 'Video streaming platform (web/Mobile)', '1 Free Video class', '3% commissione'],
  interval: 'month',
  originalId: 'plan_GlNI06pkgopERO',
  description: 'Tutte le funzionalitá GROW',
  providerType: 'C'
};