import { addFileToRecordApiCall, deleteCustomerProviderRecordApiCall, getCustomerProviderRecordsApiCall, removeFileFromRecordApiCall, saveCustomerProviderRecordApiCall, toggleRecordPriorityApiCall, updateCustomerProviderRecordApiCall } from '@guryou/api-client-gql';
import { execute } from '../actions/actionWrapper';
import { Action, basicReducer } from '../core';

// NEW ANAMNESI

export const GET_CUSTOMER_PROVIDER_RECORDS = {
  entity: 'GET_CUSTOMER_PROVIDER_RECORDS',
  action: Action.GET_MANY
};
export const CREATE_CUSTOMER_PROVIDER_RECORD = {
  entity: 'CREATE_CUSTOMER_PROVIDER_RECORD',
  action: Action.CREATE
};
export const UPDATE_CUSTOMER_PROVIDER_RECORD = {
  entity: 'UPDATE_CUSTOMER_PROVIDER_RECORD',
  action: Action.UPDATE
};
export const ADD_FILE_TO_CUSTOMER_PROVIDER_RECORD = {
  entity: 'ADD_FILE_TO_CUSTOMER_PROVIDER_RECORD',
  action: Action.CREATE
};
export const TOGGLE_RECORD_PRIORITY = {
  entity: 'TOGGLE_RECORD_PRIORITY',
  action: Action.UPDATE
};
export const DELETE_CUSTOMER_PROVIDER_RECORD = {
  entity: 'DELETE_CUSTOMER_PROVIDER_RECORD',
  action: Action.DELETE
};
export const REMOVE_FILE_FROM_CUSTOMER_PROVIDER_RECORD = {
  entity: 'REMOVE_FILE_FROM_CUSTOMER_PROVIDER_RECORD',
  action: Action.DELETE
};
export const getCustomerProviderRecords = function (customerId) {
  let silent = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return async dispatch => execute(dispatch, () => getCustomerProviderRecordsApiCall({
    customerId
  }, {
    files: ['id', 'title', 'description', 'type', 'name', 'dateCreated', 'transcoded', 'thumbnailUrl']
  }), GET_CUSTOMER_PROVIDER_RECORDS, null, null, silent);
};
export const saveCustomerProviderRecord = data => async dispatch => {
  execute(dispatch, () => saveCustomerProviderRecordApiCall({
    record: {
      ...data,
      priority: data.priority === 'true',
      dueDate: data.dueDate || null,
      category: data.category || null
    }
  }), CREATE_CUSTOMER_PROVIDER_RECORD, true);
};
export const updateCustomerProviderRecord = data => async dispatch => {
  execute(dispatch, () => updateCustomerProviderRecordApiCall({
    record: {
      ...data,
      priority: data.priority === 'true',
      dueDate: data.dueDate || null,
      category: data.category || null
    }
  }), UPDATE_CUSTOMER_PROVIDER_RECORD, true);
};
export const addCustomerProviderFileToRecord = data => async dispatch => {
  execute(dispatch, () => addFileToRecordApiCall({
    file: data
  }), ADD_FILE_TO_CUSTOMER_PROVIDER_RECORD, true);
};
export const toggleRecordPriority = function (data) {
  let silent = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return async dispatch => {
    execute(dispatch, () => toggleRecordPriorityApiCall({
      recordId: data.recordId,
      status: data.status
    }), TOGGLE_RECORD_PRIORITY, null, null, silent);
  };
};
export const removeCustomerProviderFileFromRecord = fileId => async dispatch => {
  execute(dispatch, () => removeFileFromRecordApiCall({
    fileId
  }), REMOVE_FILE_FROM_CUSTOMER_PROVIDER_RECORD, true);
};
export const deleteCustomerProviderRecord = recordId => async dispatch => {
  execute(dispatch, () => deleteCustomerProviderRecordApiCall({
    id: recordId
  }), DELETE_CUSTOMER_PROVIDER_RECORD, true);
};
export const userProviderFilesReducers = {
  addFileToRecord: basicReducer(ADD_FILE_TO_CUSTOMER_PROVIDER_RECORD),
  all: basicReducer(GET_CUSTOMER_PROVIDER_RECORDS),
  create: basicReducer(CREATE_CUSTOMER_PROVIDER_RECORD),
  update: basicReducer(UPDATE_CUSTOMER_PROVIDER_RECORD),
  togglePriority: basicReducer(TOGGLE_RECORD_PRIORITY),
  remove: basicReducer(DELETE_CUSTOMER_PROVIDER_RECORD),
  removeFileFromRecord: basicReducer(REMOVE_FILE_FROM_CUSTOMER_PROVIDER_RECORD)
};