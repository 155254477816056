"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.authService = void 0;
var PERSIST_KEY = 'persist:waas';
var PERSIST_KEY_ADMIN = 'persist:admin';
var authService = exports.authService = {
  _user: null,
  get userRole() {
    var persist = localStorage.getItem(PERSIST_KEY);
    try {
      var data = JSON.parse(persist);
      var auth = JSON.parse(data.auth);
      return auth.data.userRole;
    } catch (error) {
      return null;
    }
  },
  get user() {
    var persist = localStorage.getItem(PERSIST_KEY);
    try {
      var data = JSON.parse(persist);
      var auth = JSON.parse(data.auth);
      var _auth$data = auth === null || auth === void 0 ? void 0 : auth.data,
        name = _auth$data.name,
        username = _auth$data.username,
        userId = _auth$data.userId,
        userRole = _auth$data.userRole,
        familyName = _auth$data.family_name;
      return {
        name: name,
        username: username,
        userId: userId,
        userRole: userRole,
        family_name: familyName
      };
    } catch (error) {
      return null;
    }
  },
  get token() {
    var persist = localStorage.getItem(PERSIST_KEY);
    try {
      var data = JSON.parse(persist);
      var auth = JSON.parse(data.auth);
      return auth.data.jwtToken;
    } catch (error) {
      return null;
    }
  },
  get tokenAdmin() {
    var persist = localStorage.getItem(PERSIST_KEY_ADMIN);
    try {
      var data = JSON.parse(persist);
      var auth = JSON.parse(data.auth);
      return auth.data.jwtToken;
    } catch (error) {
      return null;
    }
  },
  get refreshToken() {
    var persist = localStorage.getItem(PERSIST_KEY);
    try {
      var data = JSON.parse(persist);
      var auth = JSON.parse(data.auth);
      return auth.data.refreshToken;
    } catch (error) {
      return null;
    }
  },
  get providerId() {
    var persist = localStorage.getItem(PERSIST_KEY);
    try {
      var data = JSON.parse(persist);
      var auth = JSON.parse(data.auth);
      return auth.data.providerId;
    } catch (error) {
      return null;
    }
  },
  get authDomain() {
    var baseDomain = process.env.REACT_APP_BASE_DOMAIN;
    var tenant = window.location.hostname.replace(baseDomain, '');
    // reserve www
    if (!tenant || tenant === 'www') {
      return '';
    }
    // remove ending '.' from subdomain
    return tenant.slice(0, -1);
  },
  getLoginRedirectUrl: function getLoginRedirectUrl(domain) {
    var _window$location = window.location,
      port = _window$location.port,
      protocol = _window$location.protocol;
    var host = "".concat(protocol, "//").concat(domain, ".").concat(process.env.REACT_APP_BASE_DOMAIN).concat(port && ":".concat(port));
    return "".concat(host, "/login");
  },
  getSignUpUrl: function getSignUpUrl() {
    var _window$location2 = window.location,
      port = _window$location2.port,
      protocol = _window$location2.protocol;
    var host = "".concat(protocol, "//").concat(process.env.REACT_APP_BASE_DOMAIN).concat(port && ":".concat(port));
    return "".concat(host, "/sign-up");
  }
};