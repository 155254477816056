"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isUserWorkoutPlanDateNotAvailable = void 0;
var isUserWorkoutPlanDateNotAvailable = exports.isUserWorkoutPlanDateNotAvailable = function isUserWorkoutPlanDateNotAvailable(date, unavailbleDates) {
  return unavailbleDates === null || unavailbleDates === void 0 ? void 0 : unavailbleDates.some(function (x) {
    return date >= x.startTime && date <= x.endTime;
  });
};