"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cognitoPasswordRequirements = void 0;
var ATLEAST_ONE_LOWER_CASE_REGEX = /(?=.*?[a-z])/;
var ATLEAST_ONE_UPPER_CASE_REGEX = /(?=.*?[A-Z])/;
var ATLEAST_ONE_DIGIT_REGEX = /(?=.*?[0-9])/;
var ATLEAST_ONE_SPECIAL_CHARACTER_REGEX = /(?=.*?[#?!@$%^&*-])/;
var cognitoPasswordRequirements = exports.cognitoPasswordRequirements = function cognitoPasswordRequirements(password) {
  var check = !!password;
  if (check) {
    if (password.length > +process.env.REACT_APP_PASSWORD_MAX_LENGTH) {
      return 'Password maximum characters is 50';
    }
    if (password.length < +process.env.REACT_APP_PASSWORD_MIN_LENGTH) {
      return 'Password need atleast 8 characters';
    }
    if (process.env.REACT_APP_PASSWORD_LOWER_CASE && !ATLEAST_ONE_LOWER_CASE_REGEX.test(password)) {
      return 'Password needs atleast one lowercase letter (abc)';
    }
    if (process.env.REACT_APP_PASSWORD_UPPERCASE_CASE && !ATLEAST_ONE_UPPER_CASE_REGEX.test(password)) {
      return 'Password needs atleast one uppercase letter (ABC)';
    }
    if (process.env.REACT_APP_PASSWORD_NUMBERS_CASE && !ATLEAST_ONE_DIGIT_REGEX.test(password)) {
      return 'Password needs atleast one number (123)';
    }
    if (process.env.REACT_APP_PASSWORD_CHARACTERS_CASE && !ATLEAST_ONE_SPECIAL_CHARACTER_REGEX.test(password)) {
      return 'Password needs atleast one special character (!@#)';
    }
    return false;
  }
  return true;
};